import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';
import { DropdownFilter, Table } from 'table';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { TeamProfile } from '../../components/profile/TeamProfile';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

// import { TeamOptionsPage } from './TeamOptionsPage';
import styles from './TeamInformationPage.module.css';

export const TeamInformationPage = props => {
    const params = useParams();
    const teamId = params.teamId;
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [team, setTeam] = useState(null);
    const [members, setMembers] = useState(null);
    const [activity, setActivity] = useState(null);

    const TABLE_COLUMNS_ACTIVITY = useMemo(
        () => [
            {
                accessor: 'user',
                Header: t('User'),
                Cell: ({ value: user }) => {
                    if (!user) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../../users/${user.email}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Date'),
                accessor: 'date'
            },
            {
                accessor: 'type',
                Header: t('Type'),
                Filter: DropdownFilter
            },
            {
                accessor: 'description',
                Header: t('Description')
            }
        ],
        // eslint-disable-next-line
        []
    );

    const TABLE_COLUMNS_MEMBERS = useMemo(
        () => [
            {
                accessor: 'user',
                Header: t('Name'),
                Cell: ({ value: user }) => {
                    if (!user) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../../users/${user.email}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Email'),
                accessor: 'email'
            },
            {
                Header: t('Member-since'),
                accessor: 'memberSince'
            },
            {
                Header: t('Inserts'),
                accessor: 'propertiesAdded'
            },
            {
                Header: t('Edits'),
                accessor: 'propertiesEdited'
            },
            {
                Header: t('Manager'),
                accessor: 'role',
                Cell: ({ value: isManager }) => {
                    if (isManager) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            }
        ],
        // eslint-disable-next-line
        []
    );

    const fetchAndSetData = useCallback(async () => {
        const [members, team, activity] = await Promise.all([
            requests.getTeamMembers(teamId, 5), //getfirst five members
            requests.getTeam(teamId),
            requests.getTeamActivity(teamId, 5)
        ]);

        setMembers(members.data);
        setTeam(team.data);
        setActivity(activity.data.map(transformActivity));
    }, [teamId]);

    useEffect(() => {
        fetchAndSetData().catch(console.log);
    }, [fetchAndSetData]);

    useEffect(() => {
        requests.getAllPropertiesOfTeam(teamId).then(response => {
            return response;
        });
    }, [teamId]);

    const routeChange = path => {
        navigate(path);
    };

    function transformActivity(log) {
        return {
            user: log.user,
            date: `${Utils.timestampToHumanizedDate(
                log.createdAt
            )} - ${Utils.timestampToHumanizedTime(log.createdAt)}`,
            type: t(log.type),
            description: t(log.description)
        };
    }

    function transformMembers(member) {
        return {
            user: member.user,
            email: member.user.email,
            memberSince: Utils.timestampToHumanizedDate(member.joinedAt),
            propertiesAdded: member.propertiesAdded,
            propertiesEdited: member.propertiesEdited,
            role: member.isManager
        };
    }

    return (
        <LoaderWrapper>
            <div className={styles.pageWrap}>
                {props.permissions.canPreviewActivity && (
                    <div className={styles.section}>
                        <div className={styles.propertiesTitleDiv}>
                            <div className={styles.categoryHeader}>{t('Most-Recent-Activity')}</div>
                            <div
                                className={styles.showMore}
                                onClick={() => routeChange('../recent-activity')}
                            >
                                {t('Show More')}
                            </div>
                        </div>

                        <LoaderWrapper>
                            {activity && (
                                <Table
                                    resizableColumns={false}
                                    sortableColumns={false}
                                    filters={false}
                                    searchBar={false}
                                    pagination={false}
                                    columns={TABLE_COLUMNS_ACTIVITY}
                                    data={activity}
                                    exporting={false}
                                />
                            )}
                        </LoaderWrapper>
                    </div>
                )}

                {props.permissions.canPreviewMembers && members && (
                    <div className={styles.section}>
                        <div className={styles.propertiesTitleDiv}>
                            <div className={styles.categoryHeader}>{t('Top-Members')}</div>
                            <div
                                className={styles.showMore}
                                onClick={() => routeChange('../members')}
                            >
                                {t('Show More')}
                            </div>
                        </div>

                        <LoaderWrapper>
                            {members && (
                                <Table
                                    resizableColumns={false}
                                    sortableColumns={false}
                                    filters={false}
                                    searchBar={false}
                                    pagination={false}
                                    columns={TABLE_COLUMNS_MEMBERS}
                                    data={members.map(transformMembers)}
                                    exporting={false}
                                />
                            )}
                        </LoaderWrapper>
                    </div>
                )}
            </div>
        </LoaderWrapper>
    );
};
