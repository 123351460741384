// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyEditInfoPage_pageWrap__fvwbB {\n    display: flex;\n    flex-direction: column;\n}\n\n.PropertyEditInfoPage_actionBtnsContainer__8SIpS {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end !important;\n}\n\n.PropertyEditInfoPage_actionBtn__l2g73 {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    border-radius: 5px;\n}\n\n.PropertyEditInfoPage_titlePage__dXx\\+2 {\n    margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/propertyEditPages/PropertyEditInfoPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;;IAEX,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,SAAS;;IAET,aAAa;IACb,mBAAmB;IACnB,SAAS;;IAET,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end !important;\n}\n\n.actionBtn {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    border-radius: 5px;\n}\n\n.titlePage {\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "PropertyEditInfoPage_pageWrap__fvwbB",
	"actionBtnsContainer": "PropertyEditInfoPage_actionBtnsContainer__8SIpS",
	"actionBtn": "PropertyEditInfoPage_actionBtn__l2g73",
	"titlePage": "PropertyEditInfoPage_titlePage__dXx+2"
};
export default ___CSS_LOADER_EXPORT___;
