import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

import styles from './ManagePropertiesPage.module.css';

// ---------------------------------------------------------------------------------------

export const ManagePropertiesPage = () => {
    return (
        <div className={styles.managePropertiesPage}>
            <SearchPaginatedPropertiesWithMap
                mainFilters={{ category: 'building', area: null, subcategory: 'workspace' }}
            />
        </div>
    );
};
