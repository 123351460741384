import React, { useEffect, useState } from 'react';

import hash from 'object-hash';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import requests from '../../utils/requests';

import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';

import formDescription from '../../assets/json/AddProperty.js';
import greeceCenterOfRegionalUnits from '../../assets/json/greece-center-of-regional-units';
import greeceCenterOfRegions from '../../assets/json/greece-center-of-regions.json';
import greeceRegionsRegionalUnits from '../../assets/json/greece-regions-regional-units';

// import styles from './AddPropertyForm.module.css';

const AddPropertyForm = ({ formRef, includeTeam }) => {
    const { t } = useTranslation();
    const [typesOfProperties, setTypesOfProperties] = useState(null);
    const [description, setDescription] = useState(null);
    const [formKey, setFormKey] = useState(null);
    const [teams, setTeams] = useState(null);

    useEffect(() => {
        requests
            .getTypesOfProperties()
            .then(({ data }) => setTypesOfProperties(data))
            .catch(setTypesOfProperties(null));
    }, []);

    useEffect(() => {
        requests.getMyTeams().then(({ data }) => setTeams(data));
    }, []);

    useEffect(() => {
        if (!typesOfProperties || (includeTeam && !teams)) {
            return;
        }

        const _description = JSON.parse(JSON.stringify(formDescription()));
        const propertyCategory = _description.questions.find(
            question => question.name === 'propertyCategory'
        );
        propertyCategory.choices = Object.keys(typesOfProperties).map(type => ({
            title: t(type),
            value: type
        }));

        if (includeTeam) {
            _description.questions.unshift({
                title: t('team'),
                name: 'teamId',
                type: 'dropdown',
                isRequired: true,
                choices: teams.map(team => ({
                    title: team.name,
                    value: team.id
                }))
            });
        }

        setDescription(_description);
        setFormKey(hash(_description));
    }, [typesOfProperties, teams, t]);

    const onPropertyCategoryChange = val => {
        if (!val || !formRef.current || !typesOfProperties) {
            return;
        }

        formRef.current.getQuestion('propertyType').setOptions(
            typesOfProperties[val].map(type => ({
                title: t(type),
                value: type
            }))
        );
    };

    const onRegionalUnitChange = val => {
        if (!formRef.current) {
            return;
        }

        formRef.current.getQuestion('propertyLocationMap').setZoom(10);
        formRef.current.getQuestion('propertyLocationMap').setCenter({
            lat: Number(greeceCenterOfRegionalUnits[val].lat),
            lng: Number(greeceCenterOfRegionalUnits[val].lon)
        });
    };

    const onRegionChange = val => {
        if (!formRef.current) {
            return;
        }

        formRef.current.getQuestion('propertyLocationMap').setZoom(8);
        formRef.current.getQuestion('propertyLocationMap').setCenter({
            lat: Number(greeceCenterOfRegions[val].lat),
            lng: Number(greeceCenterOfRegions[val].lon)
        });
        formRef.current.getQuestion('locationInfoRegionalUnit').setOptions(
            greeceRegionsRegionalUnits[val].map(regionalUnit => ({
                title: t(regionalUnit),
                value: regionalUnit
            }))
        );
    };

    const onFormChange = (path, val) => {
        if (path.includes('propertyCategory')) {
            onPropertyCategoryChange(val);
        } else if (path.includes('RegionalUnit')) {
            onRegionalUnitChange(val);
        } else if (path.includes('Region')) {
            onRegionChange(val);
        }
    };

    return (
        <LoaderWrapper>
            {formKey && (!includeTeam || teams) && (
                <Form
                    key={formKey}
                    description={description}
                    onChange={onFormChange}
                    ref={formRef}
                />
            )}
        </LoaderWrapper>
    );
};

export default AddPropertyForm;
