import React, { useEffect, useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import propertyDescription from '../../../assets/json/PropertyStats';

import styles from './PropertyEditPropertyPage.module.css';

const PropertyEditPropertyPage = ({ property, updatePropertyToDB, returnBackBtn }) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const addToast = useToasts();
    const submitInfos = async () => {
        if (!property || !formRef.current) {
            return;
        }

        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const _property = JSON.parse(JSON.stringify(property));
        const writable = _property.building || _property;

        const data = formRef.current.getData();

        writable.floor = data.floor;
        writable.yearOfConstruction = data.yearOfConstruction;
        writable.yearOfRenovation = data.yearOfRenovation;
        writable.name = data.name;
        writable.kaekBuilding = data.kaekBuilding;
        writable.APC = data.APC;
        writable.atak = data.atak;
        writable.numberOA = data.numberOA;
        writable.yearOA = data.yearOA;
        writable.currentUse = data.currentUse;
        writable.legalCityPlanningUse = data.legalCityPlanningUse;
        writable.areaOA = data.areaOA;

        writable.areaTitle = data.areaTitle;
        writable.areaHX = data.areaHX;
        writable.areaCurrentState = data.areaCurrentState;
        writable.areaSD = data.areaSD;
        writable.spaces = data.spaces;
        writable.poweredNumberSupply = data.waterNumberSupply;
        writable.electrified = data.electrified;
        writable.waterNumberSupply = data.waterNumberSupply;
        writable.waterSupplied = data.waterSupplied;

        if (JSON.stringify(property) !== JSON.stringify(_property)) {
            await updatePropertyToDB(property.id, _property);
        }
    };

    useEffect(() => {
        if (!property || !formRef.current) {
            return;
        }

        formRef.current.setData?.(property.building || property);
    }, [property]);

    if (!property) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <h1>{t('property')}</h1>
            <Form description={propertyDescription()} ref={formRef} />
            <div
                className={styles.actionBtnsContainer}
                style={{
                    justifyContent: !!!property.building ? 'space-between' : 'flex-end'
                }}
            >
                {returnBackBtn}
                <ButtonWithLoading
                    className={`btn btn-primary-0 ${styles.actionBtn}`}
                    onClick={submitInfos}
                >
                    <AiOutlineCheck /> {t('save')}
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export default PropertyEditPropertyPage;
