import React, { useCallback, useEffect, useRef, useState } from 'react';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { MarkerClusterer } from '@react-google-maps/api';
import hash from 'object-hash';
import ReactDOMServer from 'react-dom/server';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose, AiOutlineEuroCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { BiArea, BiBath } from 'react-icons/bi';
import { IoConstructOutline } from 'react-icons/io5';
import {
    MdOutlineKingBed,
    MdOutlineModeEditOutline,
    MdOutlinePlace,
    MdOutlineStairs
} from 'react-icons/md';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests, { URI } from '../../utils/requests';

import GoogleMapComponent from '../../interfaces/GoogleMapComponent';
import InfoWindow from '../../interfaces/InfoWindow';
import Marker from '../../interfaces/Marker';
import OverlayView from '../../interfaces/MarkerOverlay';
import CustomOverlayView from '../../interfaces/MarkerOverlay';
import MarkerOverlay from '../../interfaces/MarkerOverlay';
import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';
import { Modal } from '../modal/Modal';

import defaultImage from '../../assets/images/AlphaLogo.png';
import formDescription from '../../assets/json/PropertyMapEdit.js';
import formVariablesForMap from '../../assets/json/VariableMaps';

import styles from './addPropertyMapVariable.module.css';

const AddPropertyMapVariable = ({ formRef, landProperty, buildingProperty }) => {
    const { t } = useTranslation();
    var keysOfLand = [];
    var keysOfBuilding = [];
    var keysOfProperty = [];
    const mapRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [properties, setProperties] = useState(null);
    const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
    const formRefMap = useRef();
    const [variablesInMap, setVariablesInMap] = useState(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(null);
    const addToast = useToasts();
    var testmarker;

    function iconBuildingStyle(property) {
        return {
            path: faHome.icon[4],
            fillColor: 'black',
            fillOpacity: 1,

            strokeWeight: 1,
            strokeColor: '#ffffff',
            scale: 0.04
        };
    }

    function iconPlotStyle(property) {
        return {
            path: faLayerGroup.icon[4],
            fillColor: 'black',
            fillOpacity: 1,

            strokeWeight: 1,
            strokeColor: '#ffffff',
            scale: 0.04
        };
    }

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        if (landProperty) {
            keysOfLand = Object.keys(landProperty);
            formRef.current.getQuestion('variablesOnMapLand').setOptions(
                keysOfLand.map(type => ({
                    title: t(type),
                    value: type
                }))
            );
        }
        if (buildingProperty) {
            keysOfProperty = Object.keys(buildingProperty);
            keysOfBuilding = Object.keys(buildingProperty?.building);
            const allKeys = [...keysOfProperty, ...keysOfBuilding];

            formRef.current.getQuestion('variablesOnMapBuildings').setOptions(
                allKeys.map(type => ({
                    title: t(type),
                    value: type
                }))
            );
        }
    }, [landProperty, buildingProperty, t]);

    useEffect(() => {
        if (!mapRef.current) return;
    }, [mapRef]);

    useEffect(() => {
        requests
            .getAllProperties()
            .then(response => setProperties(response.data))
            .catch(console.error);
    }, []);

    const labelFloor = floor => {
        const floorNumber = floor;
        let floorLabel;
        if (floorNumber === null) {
            floorLabel = ' -';
        } else if (floorNumber === undefined) {
            floorLabel = ' -';
        } else if (floorNumber === 0) {
            floorLabel = t('Ground');
        } else {
            const absFloorNumber = Math.abs(floorNumber);
            const lastDigit = absFloorNumber % 10;
            const isTeen = absFloorNumber % 100 >= 11 && absFloorNumber % 100 <= 13;
            if (lastDigit === 1 && !isTeen) {
                floorLabel = `${floorNumber}${t('st')}`;
            } else if (lastDigit === 2 && !isTeen) {
                floorLabel = `${floorNumber}${t('nd')}`;
            } else if (lastDigit === 3 && !isTeen) {
                floorLabel = `${floorNumber}${t('rd')}`;
            } else {
                floorLabel = `${floorNumber}${t('th')}`;
            }
        }
        return floorLabel;
    };
    function displayVariablesOnMap(variable, property) {
        return variable?.map(v => {
            return (
                <div className={styles.placeForVariableMaps}>
                    {v === 'price' && (
                        <div className={styles.var}>
                            <AiOutlineEuroCircle />{' '}
                            {property?.building?.price || property?.plot?.price
                                ? property?.building?.price || property?.plot?.price + '$'
                                : '-'}
                        </div>
                    )}
                    {v === 'bedrooms' && (
                        <div className={styles.var}>
                            <MdOutlineKingBed /> 3 beds
                        </div>
                    )}
                    {v === 'bathrooms' && (
                        <div className={styles.var}>
                            <BiBath /> 5 baths
                        </div>
                    )}
                    {v === 'acreage' && (
                        <div className={styles.var}>
                            <BiArea />
                            {`${
                                ((property?.building?.areaCurrentState === undefined ||
                                    property?.building?.areaCurrentState === null) &&
                                    ' - ' + t('㎡')) ||
                                property?.building?.areaCurrentState + ' ' + `${t('㎡')}`
                            } `}
                        </div>
                    )}
                    {v === 'area' && (
                        <div className={styles.var}>
                            <MdOutlinePlace />{' '}
                            {((property?.building?.area === undefined ||
                                property?.building?.area === null) &&
                                '-') ||
                                property?.building?.area}
                        </div>
                    )}
                    {v === 'floor' && (
                        <div className={styles.var}>
                            <MdOutlineStairs />
                            {labelFloor(property?.floor || property?.building?.floor)}
                        </div>
                    )}

                    {v === 'contructDate' && (
                        <div className={styles.var}>
                            <IoConstructOutline />
                            {(property?.building?.yearOfConstruction === undefined && ' -') ||
                                (property?.building?.yearOfConstruction == null && ' -') ||
                                property?.building?.yearOfConstruction + ' ' + t('year')}
                        </div>
                    )}
                </div>
            );
        });
    }
    function submitVariablesInMap() {
        if (!formRefMap.current) return;
        formRefMap.current.clearSanityCheck();

        const sanityCheckRes = formRefMap.current.sanityCheckForm();

        if (!!sanityCheckRes.length) {
            return;
        }
        addToast({
            type: 'information',
            message: t('zoom in to see the compared variables'),
            duration: 5000
        });
        setVariablesInMap(formRefMap.current.getData().category);
        setIsDisabledAddModal(false);
    }

    function removeVariable(variableDispalyed) {
        setVariablesInMap(oldValues => {
            return oldValues.filter(vars => vars !== variableDispalyed);
        });
    }

    function renderAddModal() {
        if (!isDisableAddModal) return null;
        return (
            <Modal
                onClose={() => {
                    setIsDisabledAddModal(false);
                }}
                header={t('Επιλέξτε τις μεταβλητές που θα εμφανιστούν στο χάρτη')}
                content={
                    <div>
                        <Form
                            description={formVariablesForMap()}
                            ref={formRefMap}
                            initialValue={{ category: variablesInMap }}
                        />
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledAddModal(false);
                            }}
                        >
                            {t('Reject', 'Απόρριψη')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => submitVariablesInMap()}
                        >
                            {t('Confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }
    function renderPreviewModal() {
        if (!isDetailsModalOpen) return null;
        return (
            <Modal
                onClose={() => {
                    setIsDetailsModalOpen(false);
                }}
                header={t('preview variables on map')}
                content={
                    <div>
                        {variablesInMap &&
                            variablesInMap.map((v, i) => {
                                return (
                                    <div className={styles.rowDiv}>
                                        {t(v)}
                                        <AiOutlineClose
                                            onClick={() => {
                                                removeVariable(v);
                                            }}
                                            className={styles.deleteBtn}
                                            color={'red'}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                }
            />
        );
    }
    const checkImage = async imageUrl => {
        try {
            const response = await fetch(`${URI}/${imageUrl}`);
            if (response.ok) {
                return `${URI}/${imageUrl}`;
            } else {
                return defaultImage;
            }
        } catch (error) {
            alert(error);
            return defaultImage;
        }
    };

    const addMarkersProperties = async () => {
        try {
            properties &&
                properties.map(async (p, i) => {
                    const imageUrl = `${p?.propertyAssets[0]?.file?.path}`;
                    const markerImageUrl =
                        p?.propertyAssets.length === 0 ? defaultImage : await checkImage(imageUrl);

                    const marker = new Marker(
                        { lat: p.latitude, lng: p.longtitude },
                        p.id,
                        p?.building?.name || p?.plot?.name,
                        p.type === 'BUILDING' ? iconBuildingStyle(p) : iconPlotStyle(p),
                        markerImageUrl
                    );
                    mapRef.current.addMarker(marker);

                    const content = ReactDOMServer.renderToString(
                        <div className={styles.OverlayViewDiv}>
                            {displayVariablesOnMap(variablesInMap, p)}
                        </div>
                    );
                    const container = document.createElement('div');
                    container.innerHTML = content;
                    container.style.backgroundColor = 'rgba(255, 255, 255, 0.745)';
                    container.style.width = '100px';
                    container.style.position = 'absolute';
                    container.style.left = '-30px';
                    const markerOverlay = new MarkerOverlay(
                        { lat: p.latitude, lng: p.longtitude },
                        content,
                        mapRef.current,
                        marker
                    );
                    mapRef.current.addOverlayView(markerOverlay, container);
                });
        } catch (error) {
            console.log(error);
        }
    };
    const displayVariables = variables => {
        if (!variables) return;
        else if (variables.length <= 2) {
            return variables.map((variableOnMap, i) => {
                return (
                    <div className={styles.variableOnMap}>
                        {t(`${variableOnMap}`)}
                        <AiOutlineClose
                            onClick={() => {
                                removeVariable(variableOnMap);
                            }}
                            className={styles.deleteBtn}
                            color={'red'}
                        />
                    </div>
                );
            });
        } else {
            return (
                <div className={styles.allSmallDiv}>
                    <div className={styles.variableOnMap}>
                        {t(`${variables[0]}`)}
                        <AiOutlineClose
                            onClick={() => {
                                removeVariable(variables[0]);
                            }}
                            className={styles.deleteBtn}
                            color={'red'}
                        />
                    </div>
                    <div className={styles.variableOnMap}>
                        {t(`${variables[1]}`)}
                        <AiOutlineClose
                            onClick={() => {
                                removeVariable(variables[1]);
                            }}
                            className={styles.deleteBtn}
                            color={'red'}
                        />
                    </div>
                    <div
                        className={styles.imageDefault}
                        onClick={() => setIsDetailsModalOpen(true)}
                    >{`${variables.length - 1}+`}</div>
                </div>
            );
        }
    };

    const removeMarkersProperties = () => {
        mapRef.current.removeMarker(testmarker);
    };

    const onMapLoad = () => {
        setMapLoaded(true);
        addMarkersProperties();
    };

    return (
        <div>
            <Form description={formDescription()} ref={formRef} />
            <GoogleMapComponent
                ref={mapRef}
                apiKey={'AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo'}
                onMapLoaded={() => addMarkersProperties()}
                zoom={7}
                center={{ lat: 39.585312488733365, lng: 23.037010230304432 }}
            ></GoogleMapComponent>
            <div className={styles.variablesDiv}>
                {displayVariables(variablesInMap)}

                <div className={styles.addBtn} onClick={() => setIsDisabledAddModal(true)}>
                    <MdOutlineModeEditOutline />
                    {t('Τιμές Στο Χάρτη')}
                </div>
            </div>
            {renderAddModal()}
            {renderPreviewModal()}
        </div>
    );
};

export default AddPropertyMapVariable;
