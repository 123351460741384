import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineFolderOpen, AiOutlineFormatPainter } from 'react-icons/ai';
import { BiBuildings, BiEuro, BiVideo } from 'react-icons/bi';
import { BiArea } from 'react-icons/bi';
import { BsLamp } from 'react-icons/bs';
import { BsCurrencyDollar, BsFileEarmarkText, BsHouseDoor, BsInfo } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { FaRegLightbulb } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { IoBulbOutline, IoConstructOutline } from 'react-icons/io5';
import {
    MdOutlineFeaturedPlayList,
    MdOutlineImagesearchRoller,
    MdOutlineInsertPhoto,
    MdOutlineStairs
} from 'react-icons/md';
import { RiLandscapeLine } from 'react-icons/ri';
import { RiKey2Line } from 'react-icons/ri';
import { TfiPaintRoller } from 'react-icons/tfi';
import { TiArrowBackOutline } from 'react-icons/ti';

import useOwnersEdit from '../../hooks/useOwnersEdit';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { FormLabel } from '../../components/formLabel/FormLabel';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import OwnersList from '../../components/ownersList/OwnersList';

import requests, { URI } from '../../utils/requests';

import { SidebarPage } from '../generalPages/SidebarPage';
import PropertyDigitalFolderPage from './PropertyDigitalFolderPage';
import PropertyLocationPage from './PropertyLocationPage';
import PropertyPartsPage from './PropertyPartsPage';
import { PropertyViewImagesPage } from './PropertyViewImagesPage';
import { PropertyViewVideosPage } from './PropertyViewVideosPage';
import { PropertyViewSuggestionsPage } from './SuggestionPages/PropertyViewSuggestionsPage';
import { PropertyEstimatesHistoryPage } from './estimatesPages/PropertyEditEstimatesPage';
import { PropertyEstimateViewPage } from './estimatesPages/PropertyEstimateViewPage';
import { PropertyExpensesMainPage } from './expensesPages/PropertyEditExpensesPage';
import { PropertyExpensesViewPage } from './expensesPages/PropertyExpensesViewPage';
import { PropertyLeasesHistoryPage } from './leasesPages/PropertyEditLeasesPage';
import { PropertyLeasesViewPage } from './leasesPages/PropertyLeasesViewPage';
import {
    PropertyRenovationMainPage,
    PropertyViewRenovationPage
} from './renovationPages/PropertyRenovatioPage';
import { PropertyViewSmartFeaturePage } from './smartFeaturePages/PropertySmartFeaturePage';

import imageProperty from '../../assets/images/AlphaLogo.png';
import infoDescription from '../../assets/json/PropertyInfoView';
import landDescription from '../../assets/json/PropertyLand';
import statsDescription from '../../assets/json/PropertyStats';

import styles from './PropertyViewPage.module.css';
import './propertyFormView.css';

const SIZE_ICON = 20;

const BuildingViewPage = ({ property }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { propertyId } = useParams();
    const navigate = useNavigate();
    const [owners, setOwners] = useOwnersEdit();
    const [mainImage, setMainImage] = useState(null);
    const [imageExists, setImageExists] = useState(true);
    const [isPortaitImage, setIsPrortaitImage] = useState(false);
    const [isRemoveModalActive, setIsRemoveModalActive] = useState(false);

    useEffect(() => {
        Promise.all([requests.getPropertyAssetsMain(propertyId)])
            .then(([{ data: _mainImage }]) => {
                setMainImage(_mainImage);
            })
            .catch(console.error);
    }, [propertyId]);

    useEffect(() => {
        if (!property) {
            return;
        }

        requests.getPropertyOwners(property.id).then(({ data }) => {
            if (!data) {
                setOwners(null);
            }
            setOwners(data?.map(elem => ({ ...elem.owner, percentage: elem.percentage })));
        });
    }, [property, setOwners]);

    const getEditPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'property',
            'land',
            'location',
            'parts',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'renovations',
            'smartfeatures',
            'digital-folder'
        ];

        const urlParts = location.pathname.split('/').reverse();
        const urlSuffix = urlParts[0];
        if (validSuffixes.includes(urlSuffix)) {
            return `../properties/edit/${propertyId}/${urlSuffix}`;
        }
        return `../properties/edit/${propertyId}/${urlParts[1]}/${urlSuffix}`;
    }, [location, propertyId]);

    if (!property) {
        return <LoaderWrapper />;
    }

    const removeModal = () => {
        if (!isRemoveModalActive) return null;

        return (
            <Modal
                onClose={() => setIsRemoveModalActive(false)}
                header={t('delete-property')}
                content={<div>{t('Are you sure you want to delete the property')}</div>}
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsRemoveModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-error'>
                            {t('remove')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    const options = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <h1 className={styles.pageTitle}>{t('general')}</h1>
                            <FormLabel readOnly> {t('Ownership')} </FormLabel>
                            <Form
                                description={infoDescription()}
                                readOnly
                                initialValue={property.building}
                            />
                            <OwnersList owners={owners} readOnly />
                            <Form
                                description={statsDescription()}
                                readOnly
                                initialValue={property.building}
                            />
                        </>
                    ),
                    Icon: BsInfo
                },
                {
                    path: 'location',
                    title: t('location'),
                    element: <PropertyLocationPage property={property} readOnly />,
                    Icon: GoLocation
                },
                {
                    path: 'land',
                    title: t('land'),
                    element: (
                        <>
                            <h1 className={styles.pageTitle}>{t('land')}</h1>
                            <Form
                                description={landDescription()}
                                initialValue={property.plot}
                                readOnly
                            />
                        </>
                    ),
                    Icon: RiLandscapeLine
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: <PropertyViewImagesPage />,
                    Icon: MdOutlineInsertPhoto
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyViewVideosPage />,
                    Icon: BiVideo
                },
                property.building.pType === 'buildingMulti' && {
                    path: 'parts',
                    title: t('property-parts'),
                    element: <PropertyPartsPage property={property} readOnly />,
                    Icon: BiBuildings
                },
                {
                    path: 'smartfeatures',
                    title: t('Smart Features'),
                    element: <PropertyViewSmartFeaturePage buildingId={property.building.id} />,
                    Icon: BsLamp
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: <PropertyDigitalFolderPage property={property} />,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    },
                    Icon: AiOutlineFolderOpen
                }
            ]
        },
        {
            title: t('Actions'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage buildingId={property.building.id} />,
                    Icon: BiEuro
                },
                {
                    path: 'expenses/:id',
                    element: <PropertyExpensesViewPage buildingId={property.building.id} />,
                    Icon: BsCurrencyDollar
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    Icon: RiKey2Line,
                    element: <PropertyLeasesHistoryPage buildingId={property.building.id} />,
                    hasNestedRoutes: true
                },
                {
                    path: 'leases/:id',
                    element: <PropertyLeasesViewPage buildingId={property.building.id} />
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: <PropertyEstimatesHistoryPage buildingId={property.building.id} />,
                    Icon: BsFileEarmarkText,
                    hasNestedRoutes: true
                },
                {
                    path: 'estimates/:id',
                    element: <PropertyEstimateViewPage buildingId={property.building.id} />
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: <PropertyViewSuggestionsPage buildingId={property.building.id} />,
                    Icon: FaRegLightbulb
                },
                {
                    path: 'renovations',
                    title: t('renovations'),
                    element: <PropertyRenovationMainPage buildingId={property.building.id} />,
                    Icon: AiOutlineFormatPainter
                },
                {
                    path: 'renovations/:id',
                    element: (
                        <PropertyViewRenovationPage
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                }
            ]
        }
    ];

    const labelFloor = floor => {
        const floorNumber = floor;
        let floorLabel;
        if (floorNumber === null) {
            floorLabel = '-';
        } else if (floorNumber === 0) {
            floorLabel = t('Ground');
        } else {
            const absFloorNumber = Math.abs(floorNumber);
            const lastDigit = absFloorNumber % 10;
            const isTeen = absFloorNumber % 100 >= 11 && absFloorNumber % 100 <= 13;
            if (lastDigit === 1 && !isTeen) {
                floorLabel = `${floorNumber}${t('st')}`;
            } else if (lastDigit === 2 && !isTeen) {
                floorLabel = `${floorNumber}${t('nd')}`;
            } else if (lastDigit === 3 && !isTeen) {
                floorLabel = `${floorNumber}${t('rd')}`;
            } else {
                floorLabel = `${floorNumber}${t('th')}`;
            }
        }
        return floorLabel;
    };
    const getCustomQuickOverviewPerType = type => {
        switch (type) {
            case 'buildingMulti':
                return (
                    <div className={styles.characteristicsDiv}>
                        <div className={styles.characteristics} title={t('floor')}>
                            <MdOutlineStairs className={styles.icon} size={SIZE_ICON} />
                            {labelFloor(property?.building?.floor)}
                        </div>
                        <div className={styles.characteristics} title={t('yearOfConstruction')}>
                            <IoConstructOutline className={styles.icon} size={SIZE_ICON} />
                            {(property?.building?.yearOfConstruction === null && '-') ||
                                property?.building?.yearOfConstruction + ' ' + t('year') ||
                                '-'}
                        </div>
                        <div className={styles.characteristics} title={t('areaSX')}>
                            <BiArea className={styles.icon} size={SIZE_ICON} />
                            <div className={styles.cm2}>{`${
                                ((property?.building?.areaSD === undefined ||
                                    property?.building?.areaSD === null) &&
                                    '- ') ||
                                property?.building?.areaSD + ' ' + `${t('㎡')}`
                            } `}</div>
                        </div>
                    </div>
                );

            default:
                return (
                    <div className={styles.characteristicsDiv}>
                        <div className={styles.characteristics} title={t('floor')}>
                            <MdOutlineStairs className={styles.icon} size={SIZE_ICON} />
                            {labelFloor(property?.building?.floor)}
                        </div>
                        <div className={styles.characteristics} title={t('yearOfConstruction')}>
                            <IoConstructOutline className={styles.icon} size={SIZE_ICON} />
                            {(property?.building?.yearOfConstruction === null && '-') ||
                                property?.building?.yearOfConstruction + ' ' + t('year') ||
                                '-'}
                        </div>
                        <div className={styles.characteristics} title={t('areaSX')}>
                            <BiArea className={styles.icon} size={SIZE_ICON} />
                            <div className={styles.cm2}>{`${
                                ((property?.building?.areaSD === undefined ||
                                    property?.building?.areaSD === null) &&
                                    '- ') ||
                                property?.building?.areaSD + ' ' + `${t('㎡')}`
                            } `}</div>
                        </div>
                    </div>
                );
        }
    };
    const imageResolution = () => {
        const img = new Image();
        img.src = `${URI}/${mainImage.file.path}`;
        img.addEventListener('load', () => {
            if (img.naturalWidth > img.naturalHeight) {
                setIsPrortaitImage(false);
            } else {
                setIsPrortaitImage(true);
            }
        });
        return;
    };

    return (
        <SidebarPage options={options}>
            {imageExists && mainImage && (
                <img
                    src={`${URI}/${mainImage.file.path}`}
                    alt={property?.building?.name}
                    onLoad={imageResolution}
                    className={isPortaitImage ? styles.portraitImage : styles.imageProperty}
                    onError={() => setImageExists(false)}
                />
            )}
            {(!imageExists || mainImage === '') && (
                <img
                    src={imageProperty}
                    style={{ objectFit: 'scale-down' }}
                    className={styles.imageProperty}
                    alt={property?.building?.name}
                />
            )}

            <div className={styles.childrenSideBar}>
                <div className={styles.nameBuilding}>
                    <div className={styles.titleSidebar}>{property.building.name}</div>
                    <div className={styles.actionsContainer}>
                        <Link to='../properties'>
                            <div className={styles.actionBtnContent}>
                                <TiArrowBackOutline size={25} />
                            </div>
                        </Link>
                        <Link
                            className={`${styles.actionBars} ${styles.editBtn}`}
                            to={getEditPage()}
                        >
                            <div className={styles.actionBtnContent}>
                                <FaEdit size={20} />
                            </div>
                        </Link>
                        <AiOutlineDelete
                            className={styles.deleteBtn}
                            color={'red'}
                            size={23}
                            onClick={() => {
                                setIsRemoveModalActive(true);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.street}>{`${property.street} ${property.streetNumber}`}</div>
                <div className={styles.rowDiv}>
                    {getCustomQuickOverviewPerType(property.building.pType)}
                </div>
            </div>
            {removeModal()}
        </SidebarPage>
    );
};

export default BuildingViewPage;
