import React from 'react';
import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';
import { generateNColors } from './helpers/ColorGenerator';
import { useTranslation } from 'react-i18next';
import { PlotLine } from './helpers/PlotLine';
import styles from './StatisticsStyles.module.css';

const processData = (data = []) => {
    //TODO: remove the random numbers
    const result = Array(12).fill(0);
    // const result = Array.from({ length: 12 }, () => 1 + Math.floor(Math.random() * 10));

    data.forEach(item => {
        const { month, count } = item;

        result[month - 1] = count;
    });

    return result;
};

export const PlotByPlotBuildingMonth = ({ data, requestedYear = new Date().getFullYear() }) => {
    const { t } = useTranslation();
    if (data == null) return <LoaderWrapper />;

    const building = data.building;
    const plot = data.plot || [];

    if (plot.length + building.length === 0)
        return <div className={styles.invalidBox}>{t('No data Found!')}</div>;

    const monthNames = [
        t('January'),
        t('February'),
        t('March'),
        t('April'),
        t('May'),
        t('June'),
        t('July'),
        t('August'),
        t('September'),
        t('October'),
        t('November'),
        t('December')
    ];

    const years_plot = {};
    const years_building = {};

    plot.forEach(item => {
        const { year, month, count } = item;
        if (!years_plot[year]) {
            years_plot[year] = [];
        }
        years_plot[year].push({ month, count });
    });

    building.forEach(item => {
        const { year, month, count } = item;
        if (!years_building[year]) {
            years_building[year] = [];
        }
        years_building[year].push({ month, count });
    });

    const proccessedDataPlot = processData(years_plot[requestedYear]);
    const proccessedDataBuilding = processData(years_building[requestedYear]);

    console.log(years_plot, proccessedDataPlot);

    const plotDataset = {
        fill: false,
        label: t('plot'),

        tension: 0.1,
        data: proccessedDataPlot,
        borderColor: generateNColors(1),
        borderWidth: 2,
        backgroundColor: generateNColors(proccessedDataPlot.length)
    };

    const buildingDataset = {
        label: t('building'),

        fill: false,
        tension: 0.1,
        data: proccessedDataBuilding,
        borderColor: generateNColors(1),
        borderWidth: 2,
        backgroundColor: generateNColors(proccessedDataBuilding.length)
    };

    const datasets = [buildingDataset];
    if (data.plot) datasets.push(plotDataset);

    const data_ = {
        labels: monthNames,
        datasets
    };

    return <PlotLine data={data_} />;
};
