import React from 'react';
import { Link } from 'react-router-dom';

import { Loader } from '@googlemaps/js-api-loader';
import ReactDOMServer from 'react-dom/server';
import { MdOutlineModeEditOutline } from 'react-icons/md';

import { URI } from '../utils/requests';

import IMap from './IMap';
import Marker from './Marker';
import MarkerOverlay from './MarkerOverlay';

import styles from './style.css';

const GoogleMapComponent = React.forwardRef((props, ref) => {
    class Map extends IMap {
        constructor(props) {
            super(props);
            this.mapRef = React.createRef();
            this.state = {
                map: null,
                apiKey: props.apiKey,
                zoom: props.zoom,
                center: props.center,
                markersInitialized: false
            };
        }

        componentDidMount() {
            const loader = new Loader({
                apiKey: this.state.apiKey
            });

            loader.load().then(() => {
                if (window.google) {
                    const map = new window.google.maps.Map(this.mapRef.current, {
                        center: this.props.center,
                        zoom: this.props.zoom,
                        streetViewControl: false,
                        fullscreenControl: true,
                        scaleControl: true,
                        mapTypeControl: true,
                        mapTypeControlOptions: {
                            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: window.google.maps.ControlPosition.TOP_CENTER
                        },
                        panControl: true,
                        zoomControl: true,
                        zoomControlOptions: {
                            position: window.google.maps.ControlPosition.RIGHT_CENTER
                        },
                        rotateControl: false,
                        scrollwheel: true,
                        minZoom: 2
                    });

                    this.setState({ map: map, markersInitialized: true }); // Update the markersInitialized flag

                    window.google.maps.event.addListenerOnce(map, 'idle', () => {
                        this.props.onMapLoaded?.();
                    });

                    window.google.maps.event.addListener(map, 'zoom_changed', () => {
                        const newZoom = map.getZoom();
                        if (this.state.zoom !== newZoom) {
                            this.setState({ zoom: newZoom });
                        }
                    });

                    this.initializeDivForVariablesInMap(map);
                }
            });
        }

        initializeDivForVariablesInMap(map) {
            const overlayContainer = document.createElement('div');
            overlayContainer.style.position = 'absolute';
            overlayContainer.style.backgroundColor = 'rgba(255, 255, 255, 0.45)';
            overlayContainer.style.width = '100%';
            overlayContainer.style.height = '50px';
            overlayContainer.style.bottom = '-250px'; // Adjust this value to position the overlay at the desired distance from the bottom
            overlayContainer.style.left = '-500px';
            overlayContainer.style.zIndex = '4';

            overlayContainer.innerHTML = ReactDOMServer.renderToString(
                <div className={styles.variablesDiv}>
                    <div className={styles.addBtn}>
                        <MdOutlineModeEditOutline />
                        {'Τιμές Στο Χάρτη'}
                    </div>
                </div>
            );

            const overlayView = new window.google.maps.OverlayView();
            overlayView.onAdd = () => {
                const panes = overlayView.getPanes();
                panes.overlayLayer.appendChild(overlayContainer);
            };

            overlayView.setMap(map);
        }

        getMap() {
            return this.state.map;
        }

        addMarker(marker) {
            const { map } = this.state;
            var content = '';
            var infoWindow;
            if (map) {
                const markerObj = new window.google.maps.Marker({
                    position: marker.position,
                    map: this.state.map,
                    // title: String(marker.title),
                    icon: marker.icon,
                    // animation: window.google.maps.Animation.DROP,
                    zIndex: 2
                });

                markerObj.addListener('click', () => {
                    content = `<img src='${marker.image}' style="max-width: 100%; max-height: 150px;" alt="fdf"/>`;
                    content += `<div className={styles.infoName}>${marker?.title} </div> `;
                    infoWindow = new window.google.maps.InfoWindow({ content });
                    infoWindow.open(this.state.map, markerObj);
                });

                markerObj.addListener('mouseover', () => {
                    const videoElement = document.createElement('video');
                    videoElement.src =
                        'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
                    videoElement.autoplay = true;
                    videoElement.controls = false;

                    videoElement.style.position = 'absolute';
                    videoElement.style.width = '500px';
                    videoElement.style.height = 'auto';
                    videoElement.style.zIndex = '2';

                    const markerOverlay = new MarkerOverlay(
                        { lat: marker.latitude, lng: marker.longtitude },
                        videoElement,
                        this.state.map,
                        marker
                    );

                    // this.addOverlayView(markerOverlay, videoElement);
                });

                markerObj.addListener('mouseout', () => {
                    const videoElement = document.querySelector('video');
                    if (videoElement) {
                        videoElement.parentNode.removeChild(videoElement);
                    }
                });

                if (this.state.markersInitialized) {
                    markerObj.setMap(this.state.map);
                }

                return markerObj;
            }
            return null;
        }

        removeMarker(marker) {
            marker.setMap(null);
        }

        addOverlayView(customOverlayView, container) {
            const overlay = new window.google.maps.OverlayView();
            const { map } = this.state;
            overlay.setMap(map);

            overlay.onAdd = function () {
                this.getPanes().markerLayer.appendChild(container);
                customOverlayView.container = container;
                updateOverlayVisibility();
            };

            overlay.draw = function () {
                updateOverlayVisibility();
                if (!customOverlayView.visible) return;

                const overlayProjection = this.getProjection();
                if (!overlayProjection) return;

                const position = new window.google.maps.LatLng(
                    customOverlayView.position.lat,
                    customOverlayView.position.lng
                );

                const point = overlayProjection.fromLatLngToDivPixel(position);
                const width = customOverlayView.container.offsetWidth;
                const height = customOverlayView.container.offsetHeight;
                const left = point.x - width / 2 + 'px';
                const top = point.y - height / 2 + 'px';

                customOverlayView.container.style.transform = `translate(${left}, ${top})`;
            };

            overlay.setValues({
                position: customOverlayView.position,
                map: map
            });

            window.google.maps.event.addListener(
                customOverlayView.marker,
                'position_changed',
                function () {
                    overlay.setValues({
                        position: customOverlayView.position,
                        map: map
                    });
                }
            );

            window.google.maps.event.addListener(map, 'center_changed', function () {
                overlay.setValues({
                    position: customOverlayView.position,
                    map: map
                });
            });

            overlay.onRemove = function () {
                if (customOverlayView.container) {
                    customOverlayView.container.parentNode.removeChild(customOverlayView.container);
                    customOverlayView.container = null;
                }
            };

            function updateOverlayVisibility() {
                // Check if zoom level is greater than 10 and update overlay visibility
                if (map.getZoom() < 10) {
                    customOverlayView.visible = false;
                    customOverlayView.container.style.display = 'none';
                } else {
                    customOverlayView.visible = true;
                    customOverlayView.container.style.display = 'block';
                }
            }

            customOverlayView.position = customOverlayView.position;
        }

        removeOverlayView(customOverlayView) {
            if (!customOverlayView) return;

            const overlay = customOverlayView.overlay;
            if (!overlay) return;

            overlay.setMap(null);

            if (customOverlayView.container) {
                customOverlayView.container.parentNode.removeChild(customOverlayView.container);
                customOverlayView.container = null;
            }
        }

        addInfoWindow(infoWindow) {
            const { map } = this.state;
            if (map) {
                const { position, marker, content, onCloseClick } = infoWindow;

                const infowindow = new window.google.maps.InfoWindow({
                    position: position,
                    content: content
                });

                if (onCloseClick) {
                    window.google.maps.event.addListener(infowindow, 'closeclick', onCloseClick);
                }
                infowindow.open(map, marker);

                marker.infoWindow = infowindow;
            }
        }

        render() {
            const { apiKey, zoom, center } = this.props;
            const mapStyle = { height: '500px', width: '1000px' };

            // If apiKey is not provided or zoom and center are not specified, show a default map
            if (!apiKey || (zoom === undefined && center === undefined)) {
                return <div style={mapStyle}>Default Map</div>;
            }

            return <div ref={this.mapRef} style={mapStyle} />;
        }
    }

    return <Map ref={ref} {...props} />;
});

export default GoogleMapComponent;
