import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import Select from 'react-select';

import { Link } from './Link';

import styles from './DropdownLink.module.css';

export const DropdownLink = ({ title, Icon, links = [] }) => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(null);

    const options = links
        .filter(link => link.title)
        .map(link => ({
            label: link.title,
            value: link.path,
            title: link.title,
            icon: link.icon,
            path: link.path
        }));

    useEffect(() => {
        const activeOption = options.find(option => {
            const prefix = option.path[0] !== '/' ? `/${option.path}` : option.path;
            const fullPath = location.pathname;

            return fullPath.startsWith(prefix);
        });

        setActiveLink(activeOption);
    }, [location.key]);

    const customSelectStyles = {
        container: (provided, state) => {
            const styles = {
                ...provided,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                font: 'inherit',
                fontSize: '1rem',
                zIndex: 2,
                backgroundColor: 'transparent'
            };

            return styles;
        },
        control: (provided, state) => ({
            ...provided,
            borderWidth: '0',
            boxShadow: '0 !important',
            paddingLeft: '30px',
            paddingRight: '30px',
            cursor: 'pointer',
            backgroundColor: 'transparent'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none'
        }),
        dropdownIndicator: (provided, state) => {
            if (activeLink)
                return {
                    ...provided,
                    color: 'var(--theme-primary, #002c61)'
                };
            else
                return {
                    ...provided,
                    color: 'var(--theme-icon-bg, #002c61)',
                    ':hover': 'var(--theme-primary-10, #002c61)'
                };
        },
        valueContainer: (provided, state) => ({
            ...provided,
            margin: 0,
            padding: 0
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: 0,
            overflow: 'hidden',
            minWidth: '100%',
            width: 'fit-content',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0'
        }),
        option: (provided, state) => {
            const styles = {
                ...provided,
                textDecoration: 'none !important',
                padding: 0
            };

            return styles;
        },
        menuList: (provided, state) => ({
            ...provided,
            padding: 0
        })
    };

    return (
        <div className={`${styles.container} ${activeLink ? styles.isActive : ''}`}>
            <Select
                options={options}
                styles={customSelectStyles}
                formatOptionLabel={({ title, icon, path }) => (
                    <Link
                        className={styles.link}
                        link={{ title, icon, path }}
                        isActiveClassname={styles.active}
                    />
                )}
                value={null}
                placeholder={
                    <div className={styles.defaultMenuOption}>
                        {Icon && <Icon />}
                        <div className={styles.titleContainer}>
                            <div className={styles.title}> {title} </div>
                        </div>
                    </div>
                }
                isSearchable={false}
            />
        </div>
    );
};
