import styles from './PreviewTable.module.css';

const OverviewList = ({ header, title, more, list = [], onMoreClick, onEmptyMessage }) => {
    return (
        <div className={styles.overviewWrap}>
            <div className={styles.overviewTitle}>
                <div className={styles.title}>{title}</div>
                {!!list.length && (
                    <div className={styles.more} onClick={onMoreClick}>
                        {more}
                    </div>
                )}
            </div>

            <div className={styles.body}>
                {!!list.length && (
                    <div className={styles.header}>
                        {header?.map((head, i) => {
                            return <div key={`header_${i}}`}>{head}</div>;
                        })}
                    </div>
                )}
                {!!list.length && <div className={styles.overviewContent}>{list}</div>}
                {!list.length && <div>{onEmptyMessage}</div>}
            </div>
        </div>
    );
};

export const PreviewTable = ({ header, title, more, list, onMoreClick, onEmptyMessage }) => {
    return (
        <OverviewList
            header={header}
            title={title}
            more={more}
            list={list}
            onMoreClick={onMoreClick}
            onEmptyMessage={onEmptyMessage}
        />
    );
};
