import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';
import { useUser } from '../../context/withUser';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';

import requests, { setAuthHeaders } from '../../utils/requests';

import LogInDescription from '../../assets/json/LogIn.js';

import styles from './LoginPage.module.css';

const navigationMapping = {
    ADMIN: 'a'
};
export const LoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();
    const recaptchaRef = useRef(null);
    const mountedRef = useRef(true);
    const { user, setUser } = useUser();
    const addToast = useToasts();

    useEffect(() => {
        if (user && navigationMapping[user.type]) {
            navigate(`/${navigationMapping[user.type]}`);
        }

        return () => {
            mountedRef.current = false;
        };
    }, [user, navigate]);

    function onSubmit() {
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }

        const data = formRef.current.getData();
        data.email = data.email.trim();

        if (data.email === '') {
            addToast({
                type: 'failure',
                message: t('empty-email'),
                duration: 5000
            });
            formRef.current.clear();
            return false;
        }
        //enable this section of code when remove user 1 Admin
        // const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;

        // if (!validEmail.test(data.email)) {
        //     addToast({
        //         type: 'failure',
        //         message: t('invalid-email'),
        //         duration: 5000
        //     });
        //     return;
        // }
        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        if (!token) {
            addToast({
                type: 'failure',
                message: t('invalid-captcha'),
                duration: 5000
            });
            formRef.current.clear();
            return false;
        }

        return requests
            .login({ ...data, token })
            .then(res => {
                console.log(res);
                const { token, ...user } = res.data;
                setAuthHeaders(token);
                setUser(user);
            })
            .catch(err => {
                addToast({
                    type: 'failure',
                    message: t('Wrong password!'),
                    duration: 5000
                });
            });
    }
    return (
        <div className={styles.mainContainer}>
            <div className={styles.loginPageForm}>
                <div className={styles.prompt}>{t('Login-eproperties')}</div>
                <div className={styles.formContainer}>
                    <Form description={LogInDescription()} ref={formRef} />
                    <div className={styles.linkButton} onClick={() => navigate('/forgot-password')}>
                        {t('forgot-my-password')}
                    </div>
                    <div className={styles.recaptchaContainer}>
                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptchaRef} />
                    </div>
                    <ButtonWithLoading
                        className={`${styles.loginButton} btn btn-primary-0`}
                        onClick={onSubmit}
                    >
                        {t('login')}
                    </ButtonWithLoading>
                    <div className={styles.registerWrap}>
                        {t('dont-have-account')}
                        <div className={styles.linkButton} onClick={() => navigate('../register')}>
                            {t('register')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.advertiseCol}></div>
        </div>
    );
};
