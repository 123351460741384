import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from 'react-icons/ai';

import styles from './QuickViewCard.module.css';

const QuickViewCard = ({ image, title, path, description, advertise = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.container} onClick={() => !advertise && navigate(path)}>
            <img src={image} alt={title} />
            <div className={styles.content}>
                <h2>{title}</h2>
                <p>{description}</p>
                {advertise || (
                    <Link to={path} className={styles.moreBtn}>
                        {t('Find out more')}
                        <AiOutlineArrowRight />
                    </Link>
                )}
            </div>
        </div>
    );
};

export default QuickViewCard;
