import { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { Form } from 'react-form';

import { ToastMessagesWrapper } from './context/withToastMessages/withToastMessages';
import { UserWrapper } from './context/withUser';

import { AdminSubsystem } from './pages/subsystems/AdminSubsystem';
import { DefaultSubsystem } from './pages/subsystems/DefaultSubsystem';

import { FancyColorInput } from './utils/formInputs/FancyColorInput';
import { MultipleChoiceInput } from './utils/formInputs/MultipleChoiceInput';
import { MultipleSelectionInput } from './utils/formInputs/MultipleSelectionInput';
import { ToggleInput } from './utils/formInputs/ToggleInput';
import { UnfoldedDropdownInput } from './utils/formInputs/UnfoldedDropdownInput';
import useFormValidators from './utils/formRestrictions';
import './utils/i18next/i18n';

import './styles/App.css';
import './styles/ColorTheme.css';
import './styles/Form.css';

function App() {
    useFormValidators();

    useEffect(() => {
        installFormInputs();
    }, []);

    const colorTheme = localStorage.getItem('chosen-theme') || 'light';
    document.documentElement.className = colorTheme;

    return (
        <Suspense fallback={null}>
            <UserWrapper>
                <ToastMessagesWrapper>
                    <Router>
                        <Routes>
                            <Route exact path='a/*' element={<AdminSubsystem />} />
                            <Route exact path='/*' element={<DefaultSubsystem />} />
                        </Routes>
                    </Router>
                </ToastMessagesWrapper>
            </UserWrapper>
        </Suspense>
    );
}

function installFormInputs() {
    Form.registerInput('multipleChoice', MultipleChoiceInput);
    Form.registerInput('unfolded-dropdown', UnfoldedDropdownInput);
    Form.registerInput('multiselect', MultipleSelectionInput);
    Form.registerInput('fancy-color', FancyColorInput);
    Form.registerInput('toggle', ToggleInput);
}

export default App;
