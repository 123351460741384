// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagementPage_mainDiv__Kv4jr {\n    width: 100%;\n    /* height: 100vh; */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 50px;\n    /* padding: 50px; */\n    padding-bottom: 100px;\n}\n\n.ManagementPage_cardsDiv__oEwlj {\n    width: 80vw;\n    /* height: 100vh; */\n\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 20px;\n\n    max-width: 1700px;\n    min-width: 300px;\n}\n\n/* tablet */\n@media screen and (max-width: 1200px) {\n    .ManagementPage_cardsDiv__oEwlj {\n        flex-direction: column;\n        width: 60vw;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 700px) {\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalPages/ManagementPage.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,mBAAmB;;IAEnB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;;IAET,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA,WAAW;AACX;IACI;QACI,sBAAsB;QACtB,WAAW;IACf;AACJ;;AAEA,UAAU;AACV;AACA","sourcesContent":[".mainDiv {\n    width: 100%;\n    /* height: 100vh; */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 50px;\n    /* padding: 50px; */\n    padding-bottom: 100px;\n}\n\n.cardsDiv {\n    width: 80vw;\n    /* height: 100vh; */\n\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 20px;\n\n    max-width: 1700px;\n    min-width: 300px;\n}\n\n/* tablet */\n@media screen and (max-width: 1200px) {\n    .cardsDiv {\n        flex-direction: column;\n        width: 60vw;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 700px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": "ManagementPage_mainDiv__Kv4jr",
	"cardsDiv": "ManagementPage_cardsDiv__oEwlj"
};
export default ___CSS_LOADER_EXPORT___;
