import i18next from 'i18next';

const getDescription = () => ({
    questions: [
        {
            name: 'category',
            type: 'multiselect',
            title: ' ',
            showTitle: false,
            dataType: 'array',
            choices: [
                {
                    title: i18next.t('price'),
                    value: 'price'
                },
                {
                    title: i18next.t('bedrooms'),
                    value: 'bedrooms'
                },
                {
                    title: i18next.t('bathrooms'),
                    value: 'bathrooms'
                },
                {
                    title: i18next.t('acreage'),
                    value: 'acreage'
                },
                {
                    title: i18next.t('area'),
                    value: 'area'
                },
                {
                    title: i18next.t('floor'),
                    value: 'floor'
                },

                {
                    title: i18next.t('contructDate'),
                    value: 'contructDate'
                }
            ]
        }
    ]
});

export default getDescription;
