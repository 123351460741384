import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyLocationForm'),
        showTitle: false,
        name: 'propertyLocationForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('general'),
                type: 'label'
            },
            {
                title: i18next.t('Περιφέρεια'),
                name: 'region',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: [
                    { title: i18next.t('attica'), value: 'Attica' },
                    { title: i18next.t('central-greece'), value: 'Central Greece' },
                    { title: i18next.t('central-macedonia'), value: 'Central Macedonia' },
                    { title: i18next.t('crete'), value: 'Crete' },
                    {
                        title: i18next.t('eastern-macedonia-and-thrace'),
                        value: 'Eastern Macedonia and Thrace'
                    },
                    { title: i18next.t('epirus'), value: 'Epirus' },
                    { title: i18next.t('ionian-islands'), value: 'Ionian Islands' },
                    { title: i18next.t('north-aegean'), value: 'North Aegean' },
                    { title: i18next.t('peloponnese'), value: 'Peloponnese' },
                    { title: i18next.t('south-aegean'), value: 'South Aegean' },
                    { title: i18next.t('thessaly'), value: 'Thessaly' },
                    { title: i18next.t('wastern-greece'), value: 'Western Greece' },
                    { title: i18next.t('wastern-macedonia'), value: 'Western Macedonia' }
                    // {
                    //     title: i18next.t('monastic-community-of-mount-athos'),
                    //     value: 'Monastic community of Mount Athos'
                    // }
                ]
            },
            {
                title: i18next.t('Περιφερειακή Ενότητα'),
                name: 'regionalUnit',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: [
                    // { title: i18next.t('North Athens'), value: 'North Athens' },
                    // { title: i18next.t('West Athens'), value: 'West Athens' },
                    // { title: i18next.t('Central Athens'), value: 'Central Athens' },
                    // { title: i18next.t('South Athens'), value: 'South Athens' },
                    // { title: i18next.t('East Attica'), value: 'East Attica' },
                    // { title: i18next.t('Piraeus'), value: 'Piraeus' },
                    // { title: i18next.t('Islands'), value: 'Islands' },
                    // { title: i18next.t('West Attica'), value: 'West Attica' },
                    // { title: i18next.t('Boeotia'), value: 'Boeotia' },
                    // { title: i18next.t('Euboea'), value: 'Euboea' },
                    // { title: i18next.t('Evrytania'), value: 'Evrytania' },
                    // { title: i18next.t('Phocis'), value: 'Phocis' },
                    // { title: i18next.t('Phthiotis'), value: 'Phthiotis' },
                    // { title: i18next.t('Imathia'), value: 'Imathia' },
                    // { title: i18next.t('Thessaloniki'), value: 'Thessaloniki' },
                    // { title: i18next.t('Kilkis'), value: 'Kilkis' },
                    // { title: i18next.t('Pella'), value: 'Pella' },
                    // { title: i18next.t('Pieria'), value: 'Pieria' },
                    // { title: i18next.t('Serres'), value: 'Serres' },
                    // { title: i18next.t('Chalkidiki'), value: 'Chalkidiki' },
                    // { title: i18next.t('Chania'), value: 'Chania' },
                    // { title: i18next.t('Heraklion'), value: 'Heraklion' },
                    // { title: i18next.t('Lasithi'), value: 'Lasithi' },
                    // { title: i18next.t('Rethymno'), value: 'Rethymno' },
                    // { title: i18next.t('Drama'), value: 'Drama' },
                    // { title: i18next.t('Evros'), value: 'Evros' },
                    // { title: i18next.t('Thasos'), value: 'Thasos' },
                    // { title: i18next.t('Kavala'), value: 'Kavala' },
                    // { title: i18next.t('Xanthi'), value: 'Xanthi' },
                    // { title: i18next.t('Rhodope'), value: 'Rhodope' },
                    // { title: i18next.t('Arta'), value: 'Arta' },
                    // { title: i18next.t('Ioannina'), value: 'Ioannina' },
                    // { title: i18next.t('Preveza'), value: 'Preveza' },
                    // { title: i18next.t('Thesprotia'), value: 'Thesprotia' },
                    // { title: i18next.t('Corfu'), value: 'Corfu' },
                    // { title: i18next.t('Ithaca'), value: 'Ithaca' },
                    // { title: i18next.t('Cephalonia'), value: 'Cephalonia' },
                    // { title: i18next.t('Lefkada'), value: 'Lefkada' },
                    // { title: i18next.t('Zakynthos'), value: 'Zakynthos' },
                    // { title: i18next.t('Chios'), value: 'Chios' },
                    // { title: i18next.t('Ikaria'), value: 'Ikaria' },
                    // { title: i18next.t('Lemnos'), value: 'Lemnos' },
                    // { title: i18next.t('Lesbos'), value: 'Lesbos' },
                    // { title: i18next.t('Samos'), value: 'Samos' },
                    // { title: i18next.t('Arcadia'), value: 'Arcadia' },
                    // { title: i18next.t('Argolis'), value: 'Argolis' },
                    // { title: i18next.t('Corinthia'), value: 'Corinthia' },
                    // { title: i18next.t('Laconia'), value: 'Laconia' },
                    // { title: i18next.t('Messenia'), value: 'Messenia' },
                    // { title: i18next.t('Andros'), value: 'Andros' },
                    // { title: i18next.t('Kalymnos'), value: 'Kalymnos' },
                    // { title: i18next.t('Karpathos'), value: 'Karpathos' },
                    // { title: i18next.t('Kea-Kythnos'), value: 'Kea-Kythnos' },
                    // { title: i18next.t('Kos'), value: 'Kos' },
                    // { title: i18next.t('Milos'), value: 'Milos' },
                    // { title: i18next.t('Mykonos'), value: 'Mykonos' },
                    // { title: i18next.t('Naxos'), value: 'Naxos' },
                    // { title: i18next.t('Paros'), value: 'Paros' },
                    // { title: i18next.t('Rhodes'), value: 'Rhodes' },
                    // { title: i18next.t('Syros'), value: 'Syros' },
                    // { title: i18next.t('Santorini'), value: 'Santorini' },
                    // { title: i18next.t('Tinos'), value: 'Tinos' },
                    // { title: i18next.t('Karditsa'), value: 'Karditsa' },
                    // { title: i18next.t('Larissa'), value: 'Larissa' },
                    // { title: i18next.t('Magnesia'), value: 'Magnesia' },
                    // { title: i18next.t('Sporades'), value: 'Sporades' },
                    // { title: i18next.t('Trikala'), value: 'Trikala' },
                    // { title: i18next.t('Achaea'), value: 'Achaea' },
                    // { title: i18next.t('Aetolia-Acarnania'), value: 'Aetolia-Acarnania' },
                    // { title: i18next.t('Elis'), value: 'Elis' },
                    // { title: i18next.t('Florina'), value: 'Florina' },
                    // { title: i18next.t('Grevena'), value: 'Grevena' },
                    // { title: i18next.t('Kastoria'), value: 'Kastoria' },
                    // { title: i18next.t('Kozani'), value: 'Kozani' }
                ]
            },
            {
                title: i18next.t('Δήμος'),
                name: 'propertyLocationMunicipality',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Δημοτική Ενότητα'),
                name: 'propertyLocationCitySection',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Περιοχή/Τοπωνύμιο'),
                name: 'area',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Οικοδομικό Τετράγωνο'),
                name: 'block',
                type: 'text',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Κύρια Οδός'),
                name: 'street',
                type: 'text',
                isRequired: false,
                space: '70%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Αριθμός'),
                name: 'streetNumber',
                type: 'number',
                isRequired: false,
                space: '10%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Πρόσοψη'),
                name: 'inFront',
                type: 'checkbox',
                isRequired: false,
                space: '10%'
            },
            {
                title: i18next.t('Συντεταγμένη X (Longitude)'),
                name: 'longtitude',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Συντεταγμένη Y (Latitude)'),
                name: 'latitude',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('propertyLocationMap'),
                showTitle: false,
                name: 'propertyLocationMap',
                type: 'map',
                restrictions: {
                    maxMarkers: 1
                },
                map: {
                    center: {
                        lat: 38.007,
                        lng: 23.739
                    },
                    zoom: 10,
                    height: 300
                },
                zoomControlStyle: {
                    left: '90%',
                    top: '75%'
                },
                marker: {
                    width: 40,
                    height: 40,
                    color: '#478978'
                }
            },
            {
                title: i18next.t('Οδοί που περικλύουν το ακίνητο'),
                name: 'propertyLocationIncludedStreet',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Οδός'),
                name: 'propertyLocationInludedStreet0',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Πρόσοψη'),
                name: 'propertyLocationInludedFront0',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Οδός'),
                name: 'propertyLocationInludedStreet1',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Πρόσοψη'),
                name: 'propertyLocationInludedFront1',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Οδός'),
                name: 'propertyLocationInludedStreet2',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Πρόσοψη'),
                name: 'propertyLocationInludedFront2',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            }
        ]
    };
};

export default getDescription;
