import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { DropdownFilter, Table } from 'table';

import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import backgroundImg from '../../assets/images/stats.jpg';

import styles from './MyTeamsPage.module.css';

export const MyActivityPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [myActivity, setMyActivity] = useState(null);

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: 'date'
            },
            {
                accessor: 'type',
                Header: t('Type'),
                Filter: DropdownFilter
            },
            {
                accessor: 'description',
                Header: t('Description')
            },
            {
                accessor: 'team',
                Header: t('Team'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../teams/${team.id}/info`)}
                        />
                    );
                }
            }
        ],
        [navigate, t]
    );

    useState(() => {
        fetchAndSetData();
    }, []);

    async function fetchAndSetData() {
        const { data } = await requests.getMyLogs();
        setMyActivity(data.map(transformLog));
    }

    function transformLog(log) {
        return {
            date: `${Utils.timestampToHumanizedDate(
                log.createdAt
            )} - ${Utils.timestampToHumanizedTime(log.createdAt)}`,
            type: t(log.type),
            description: t(log.description),
            team: log.team
        };
    }

    return (
        <div className={styles.pageWrap}>
            <Header title={t('Activity')} backgroundImg={backgroundImg} position='40% 60%' />
            <div style={{ padding: '0px 50px' }}>
                <LoaderWrapper size={50} thickness={8}>
                    {myActivity && (
                        <Table
                            pagination={{ pageSize: 10 }}
                            columns={TABLE_COLUMNS}
                            data={myActivity}
                        />
                    )}
                </LoaderWrapper>
            </div>
        </div>
    );
};
