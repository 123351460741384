import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import BuildingViewPage from './BuildingViewPage';
import LandViewPage from './LandViewPage';

import './propertyFormView.css';

const PropertyViewPage = () => {
    const { propertyId } = useParams();
    const [property, setProperty] = useState(null);

    useEffect(() => {
        if (!propertyId) {
            return;
        }
        requests.getProperty(propertyId).then(({ data }) => setProperty(data));
    }, [propertyId]);

    if (!property) {
        return <LoaderWrapper />;
    }

    const typeSpecificPages = {
        BUILDING: <BuildingViewPage property={property} />,
        LAND: <LandViewPage property={property} />
    };

    return typeSpecificPages[property.type || 'BUILDING'];
};

export default PropertyViewPage;
