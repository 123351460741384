import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Sidebar } from '../../components/sidebar/Sidebar';

// --------------------------------------------------------------------------------------
import styles from './SidebarPage.module.css';

// --------------------------------------------------------------------------------------

export const SidebarPage = ({ options, children }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.lhs}>
                <Sidebar options={options.filter(o => !!o.title)}>{children}</Sidebar>
            </div>
            <div className={styles.rhs}>
                <Routes>
                    {options.flatMap((option, i) => {
                        if (option.options) {
                            return option.options
                                .filter(option => !!option.path)
                                .map((option, j) => (
                                    <Route
                                        key={`${i}_${j}`}
                                        path={option.path + (option.innerRouting ? '/*' : '')}
                                        element={option.element}
                                    />
                                ));
                        } else {
                            return (
                                <Route
                                    key={i}
                                    path={option.path + (option.innerRouting ? '/*' : '')}
                                    element={option.element}
                                />
                            );
                        }
                    })}
                    <Route path='*' element={<div>{t('Error')}</div>} />
                </Routes>
            </div>
        </div>
    );
};
