import React, { useState } from 'react';

import { Form } from 'react-form';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { Modal } from '../../../../components/modal/Modal';
import { PropertyFeatureIndicator } from '../../../../components/propertyFeatureIndicator/PropertyFeatureIndicator';

import { URI } from '../../../../utils/requests';

import { Carousel } from '../../Carousel';

import fullDescription from '../../../../assets/json/PropertyViewGuest.js';

import styles from './SubPage.module.css';

export const InfoSubPage = ({ data }) => {
    const [expandedDescription, setExpandedDescription] = useState(false);
    const [expandedAllFeatures, setExpandedAllFeatures] = useState(false);
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);

    const carouselModal = () => {
        if (!isCarouselOpen) return null;
        const im = isCarouselOpen.src;
        return (
            <Modal
                onClose={() => setIsCarouselOpen(false)}
                header={isCarouselOpen.title}
                content={<Carousel contents={im.map(i => `${URI}/${i.file.path}`)} />}
            />
        );
    };

    function createContent(type, data) {
        switch (type) {
            case 'info':
                return createContentInfo(data);
            case 'assets':
                return createContentAssets(data);
            case 'description':
                return createContentDescription(data);
            case 'features':
                return createContentFeatures(data);
            default:
                return;
        }
    }

    function createContentInfo(data = []) {
        return (
            <div className={styles.infoContainer}>
                {data.map((item, index) => {
                    return (
                        <div
                            className={styles.infoFeatureIndicator}
                            key={`section_info_wrapper_${index}`}
                        >
                            <PropertyFeatureIndicator
                                key={`section_info_${index}`}
                                type={item.type}
                                title={item.content}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    function createContentAssets(data = []) {
        const categories = {};
        data.forEach(d => {
            if (!categories[d.assetType]) {
                categories[d.assetType] = [d];
            } else {
                categories[d.assetType].push(d);
            }
        });

        const catNames = Object.keys(categories);

        return (
            <div className={styles.assetContainer}>
                {catNames.map((n, index) => {
                    return (
                        <div key={`asset_${index}`} className={styles.assetBox}>
                            <img
                                className={styles.assetImage}
                                src={`${URI}/${categories[n][0].file.path}`}
                                onClick={() => setIsCarouselOpen({ src: categories[n], title: n })}
                                alt={`${URI}/${categories[n][0].file.path}`}
                            />
                            <span className={styles.assetType}>{n}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    const createContentDescription = (data = []) => {
        const chars = data.description.split('');
        let expandable = false;
        const numOfnl = chars.filter(c => c === '\n').length;
        if (chars.length > 500 || numOfnl * 100 + chars.length > 500) expandable = true;

        return (
            <div className={`${styles.descriptionWraper} `}>
                <div
                    className={`${styles.descriptionContainer} ${
                        expandedDescription && styles.expanded
                    }`}
                >
                    {data.description}
                </div>
                {expandable && (
                    <div
                        className={`${styles.readMoreContainer} ${
                            expandedDescription && styles.readLessContainer
                        }`}
                    >
                        <div
                            onClick={() => {
                                setExpandedDescription(!expandedDescription);
                            }}
                            className={styles.readMoreButton}
                        >
                            {expandedDescription ? 'Διαβάστε λιγότερα' : 'Διαβάστε περισσότερα'}
                            {expandedDescription ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    function createContentFeatures(data = []) {
        return (
            <div className={styles.allFeaturesFormWraper}>
                <div
                    className={`${styles.allFeaturesFormContainer} ${
                        expandedAllFeatures && styles.expanded
                    }`}
                >
                    <Form readOnly description={fullDescription()} initialValue={data} />
                </div>
                <div
                    className={`${styles.readMoreContainer} ${
                        expandedAllFeatures && styles.readLessContainer
                    }`}
                >
                    <div
                        onClick={() => {
                            setExpandedAllFeatures(!expandedAllFeatures);
                        }}
                        className={styles.readMoreButton}
                    >
                        {expandedAllFeatures ? 'Διαβάστε λιγότερα' : 'Διαβάστε περισσότερα'}
                        {expandedAllFeatures ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.pageWrap}>
            {data?.map((item, index) => {
                return (
                    <div key={`section_info_${index}`} className={styles.section}>
                        <div
                            key={`section_info_${item.title}_title_${index}`}
                            className={styles.sectionTitle}
                        >
                            {item.title}
                        </div>
                        <div
                            key={`section_info_${item.title}_content_${index}`}
                            className={styles.sectionContent}
                        >
                            {createContent(item.type, item.data)}
                        </div>
                    </div>
                );
            })}
            {carouselModal()}
        </div>
    );
};
