import React from 'react';
import { Link } from 'react-router-dom';

import styles from './PreviewCard.module.css';

const PreviewCard = ({ icon, title, link, counter, list }) => {
    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.titleContainer}>
                    {icon}
                    <Link className={styles.title} to={link}>
                        {title}
                    </Link>
                </div>
                <div>({counter})</div>
            </div>
            {list &&
                list.map((elem, i) => {
                    return (
                        <div
                            className={styles.listItemContainer}
                            key={`PreviewCardElem${elem.title + i}`}
                        >
                            <Link className={styles.listItemLink} to={elem.link}>
                                {elem.title}
                            </Link>
                            <div>{elem.counter}</div>
                        </div>
                    );
                })}
        </div>
    );
};

export default PreviewCard;
