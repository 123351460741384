import React, { useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { MdModeEdit } from 'react-icons/md';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests from '../../utils/requests';
import Utils from '../../utils/utils';

import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { ProfileImage } from '../profileImage/ProfileImage';

import description from '../../assets/json/UserInformationUpdate.js';

import styles from './TeamProfile.module.css';

export const MyUserProfile = ({ userData, canEdit = true, updateUserData, showOtherInfo }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [isEdit, setIsEdit] = useState(false);
    const addToast = useToasts();

    const updateData = newData => {
        const d = JSON.parse(JSON.stringify(userData));
        d.name = newData.name;
        d.picture = newData.picture;
        d.email = userData.email;
        updateUserData && updateUserData({ name: newData.name });
    };

    const isDataChanged = newData => {
        const d = JSON.parse(JSON.stringify(userData));
        if (d.name !== newData.name || d.picture !== newData.picture) return true;
        return false;
    };

    const updateUser = async () => {
        const formData = formRef.current.getData();
        if (!isDataChanged(formData)) {
            setIsEdit(false);
            showOtherInfo(!isEdit);
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }
        requests
            .updateUserData(userData.email, { name: formData.name })
            .then(() => {
                updateData(formData);
                setIsEdit(false);
                showOtherInfo(!isEdit);
                addToast({
                    type: 'success',
                    message: t('User {{id}} updated successfully', { id: userData.email }),
                    duration: 3000
                });
            })
            .catch(err => {
                addToast({
                    type: 'failure',
                    message: t('User {{id}} TODO did not updated successfully', {
                        id: userData.email
                    }),
                    duration: 3000
                });
                console.log(err);
            });
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftCol}>
                <ProfileImage
                    accountData={userData}
                    updateRequest={requests.postUserImage}
                    deleteRequest={requests.deleteUserImage}
                    updateData={updateUserData}
                    toastMessages={{
                        onUpdate: {
                            type: 'success',
                            text: t('User {{id}} updated successfully', { id: userData.email })
                        },
                        onDelete: {
                            type: 'success',
                            text: t('User {{id}} updated successfully', { id: userData.email })
                        }
                    }}
                />
            </div>

            {!isEdit && (
                <div className={styles.rightCol}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            <span className={styles.teamName}>{userData.name || '...'}</span>
                            {canEdit && !isEdit && (
                                <MdModeEdit
                                    size='20px'
                                    className={styles.tool}
                                    onClick={() => {
                                        setIsEdit(!isEdit);
                                        showOtherInfo(!isEdit);
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            {t('Created at {{date}}', {
                                date: Utils.timestampToHumanizedDate(userData.createdAt)
                            })}
                        </div>
                    </div>
                    <div className={styles.content}>
                        {t('Email {{email}}', { email: userData.email })}
                    </div>
                </div>
            )}
            {isEdit && (
                <div>
                    <Form description={description()} initialValue={userData} ref={formRef} />
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                showOtherInfo(!isEdit);
                                setIsEdit(!isEdit);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => {
                                await updateUser();
                            }}
                        >
                            {t('save')}
                        </ButtonWithLoading>
                    </div>
                </div>
            )}
        </div>
    );
};
