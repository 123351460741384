import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

export const MyTeamPropertiesPage = props => {
    return (
        <SearchPaginatedPropertiesWithMap
            extraFilters={{ isTeamPropertiesRequest: true }}
            path='../properties/view'
        />
    );
};
