import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { UnderConstructionComponent } from '../../../components/underConstructionComponent/UnderConstructionComponent';

import requests from '../../../utils/requests';

import styles from './UserStatistics.module.css';
import { TeamStatisticLeases } from '../../teamPages/statisticsPages/TeamStatisticLeases';

export const UserStatisticLeasesPage = () => {
    // const [data, setData] = useState(null);
    // const { userId: id } = useParams();
    // const { t } = useTranslation();

    // useEffect(() => {
    //     requests.getUserLeases(id).then(({ data }) => setData(data));
    // }, [id]);

    // if (data === null) return <LoaderWrapper />;
    // console.log(data);
    // return (
    //     <div className={styles.main}>
    //         <div className={styles.header}>
    //             <Header title={t('Leases-Statistic')} />
    //         </div>
    //         <div className={styles.row}>
    //             <div>
    //                 <UnderConstructionComponent />
    //             </div>
    //         </div>
    //     </div>
    // );
    return <TeamStatisticLeases />;
};
