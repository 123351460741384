import React from 'react';

import styles from './ButtonWithLoading.module.css';

class ButtonWithLoading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: !!this.props.loading,
            disabled: !!this.props.disabled
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getClasses = () => {
        let classes = [styles.button, this.props.className];

        if (this.state.loading) classes.push(styles.loading);
        if (this.state.disabled) classes.push(styles.disabled);

        return classes.join(' ');
    };

    onClick = e => {
        if (!this.state.loading && !this.state.disabled) {
            const possiblePromise = this.props.onClick?.(e);

            if (possiblePromise instanceof Promise) {
                this.setLoading(true);

                possiblePromise.finally(() => this._isMounted && this.setLoading(false));
            }
        }
    };

    setLoading = loading => {
        this.setState({ loading: !!loading, disabled: !!loading });
    };

    setDisabled = disabled => {
        this.setState({ disabled: !!disabled });
    };

    isLoading = () => {
        return this.state.loading;
    };

    render() {
        return (
            <button
                onClick={this.onClick}
                className={this.getClasses()}
                style={this.props.style || {}}
            >
                <div className={styles.loader}></div>
                <div className={styles.name}> {this.props.children} </div>
            </button>
        );
    }
}

export default ButtonWithLoading;
