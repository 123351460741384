import React from 'react';

class IMap extends React.Component {
    constructor(props) {
        // Initialize map using apiKey and mapOptions
        super(props);
    }

    setZoom(zoom) {
        // Set the zoom level of the map
    }

    getMap() {
        //get the map obj
    }

    setCenter(lat, lng) {
        // Set the center of the map
    }

    addMarker(marker) {
        // Add a marker to the map with the given latitude, longitude, and options
    }

    removeMarker(marker) {
        // Remove the given marker from the map
    }

    addOverlayView(OverlayView) {
        // Add an overlay view to the map with the given options
    }

    removeOverlayView(OverlayView) {
        // Add an overlay view to the map with the given options
    }

    addInfoWindow(InfoWindow) {
        //add info window to markers
    }

    variablesInMap(map, variables, zoom) {
        //add variables in map to compare the features of each property
    }

    removeVariablesInMap(map, variables) {
        //remove varibles from compared
    }

    closeInfoWindow() {
        // Close the currently open info window (if any)
    }

    onZoomChange(callback) {
        // Call the given callback function whenever the zoom level of the map changes
    }

    onMarkerClick(marker, callback) {
        // Call the given callback function when the given marker is clicked
    }

    render() {
        // Render the map in the current DOM element
        return <div>hello</div>;
    }
}

export default IMap;
