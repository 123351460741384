import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { Table } from 'table';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { PreviewProfileImagesGroup } from '../../components/previewProfileImagesGroup/PreviewProfileImagesGroup';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';

import styles from './TeamRolePage.module.css';

export default function TeamRolePage() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { teamId } = useParams();
    const [allRoles, setAllRoles] = useState(null);

    const RoleCell = ({ role }) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        return (
            <div className={styles.role}>
                <div className={styles.roleLhs} onClick={() => navigate(`${role.id}`)}>
                    <div
                        className={styles.color}
                        style={{ backgroundColor: role.color || '#000000' }}
                    />
                    <span className={styles.name}> {role.name} </span>
                </div>
                <div className={styles.pictures}>
                    <PreviewProfileImagesGroup
                        profiles={role.users}
                        request={async () => ({ data: role.users })}
                        transformDataToRender={UsersPreview}
                        header={t('Users with role', { role: role.name })}
                        inProfile={true}
                    />
                </div>
            </div>
        );
    };

    const UsersPreview = users => {
        if (!users) return null;
        return (
            <div className={styles.users}>
                {users.map((user, index) => (
                    <SmallPreviewNameImage
                        key={index}
                        onClick={() => navigate(`../../../users/${user.id}/info`)}
                        imageSrc={user.image && `${URI}/${user.image.path}`}
                        fullname={user.name}
                    />
                ))}
            </div>
        );
    };
    const TABLE_COLUMNS_ALL = useMemo(
        () => [
            { Header: t('Name'), accessor: 'role', Cell: ({ value }) => <RoleCell role={value} /> }
        ],
        []
    );

    const fetchRoles = useCallback(async () => {
        const { data: roles } = await requests.getAllTeamRoles(teamId);
        setAllRoles(roles);
    }, [teamId]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    const transformRolesToRow = (roles = []) => roles.map(role => ({ role }));

    if (!allRoles) return <LoaderWrapper />;

    return (
        <div>
            <div className={styles.pageWrap}>
                <div className={styles.topBar}>
                    <h1>{t('Roles')}</h1>
                    <button
                        className={`btn btn-primary-0 ${styles.actionBtn}`}
                        onClick={() => navigate('create')}
                    >
                        <IoMdAddCircleOutline color={'var(--theme-bg)'} />
                        {t('Add')}
                    </button>
                </div>

                <Table
                    showColumnNames={false}
                    pagination={{ pageSize: 10 }}
                    columns={TABLE_COLUMNS_ALL}
                    data={transformRolesToRow(allRoles)}
                    exporting={false}
                />
            </div>
        </div>
    );
}
