import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import backgroundImg from '../../../assets/images/stats.jpg';
import styles from './StatisticsPage.module.css';
import { ManageStatisticsPage } from '../../../components/manageStatisticsPage/ManageStatisticsPage';
export const AdminStatisticsPage = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.pageWrap}>
            <Header title={t('Statistics')} backgroundImg={backgroundImg} position='40% 60%' />
            <div className={styles.contentWrap}>
                <LoaderWrapper>
                    <ManageStatisticsPage />
                </LoaderWrapper>
            </div>
        </div>
    );
};
