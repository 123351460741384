import i18next from 'i18next';

const newTaskDescr = () => {
    return {
        title: i18next.t('propertyDigitalFolderForm'),
        showTitle: false,
        name: 'propertyDigitalFolderForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('taskName'),
                name: 'taskname',
                isRequired: true,
                type: 'text'
            }
        ]
    };
};

export default newTaskDescr;
