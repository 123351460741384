import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MdModeEdit } from 'react-icons/md';

import { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import { Avatar } from './Avatar';

import styles from './TeamProfile.module.css';

export const UserProfile = ({ userData, canEdit = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftCol}>
                <Avatar
                    title={userData.name}
                    imageSrc={userData.image && `${URI}/${userData.image.path}`}
                />
            </div>

            <div className={styles.rightCol}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span className={styles.teamName}>{userData.name || '...'}</span>
                        {canEdit && (
                            <MdModeEdit
                                size='20px'
                                className={styles.tool}
                                onClick={() => {
                                    navigate('../options');
                                }}
                            />
                        )}
                    </div>
                    <div>
                        {t('Created at {{date}}', {
                            date: Utils.timestampToHumanizedDate(userData.createdAt)
                        })}
                    </div>
                </div>
                <div className={styles.content}>
                    {t('Email {{email}}', { email: userData.email })}
                </div>
            </div>
        </div>
    );
};
