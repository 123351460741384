import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import i18next from 'i18next';
import { AiOutlineHeart, AiOutlineMessage } from 'react-icons/ai';
import { BiInfoCircle, BiShareAlt } from 'react-icons/bi';
import { BsCardChecklist } from 'react-icons/bs';
import { MdEuroSymbol } from 'react-icons/md';

import { ImageGallery } from '../../../components/imageGallery/ImageGallery';
import { MenuTabs } from '../../../components/menuTabs/MenuTabs';

import requests, { URI } from '../../../utils/requests';

import PropertiesMap from '../../generalPages/PropertiesMap';
import { ContactSubPage } from './subpages/ContactSubPage';
import { ExpensesSubPage } from './subpages/ExpensesSubPage';
import { FeaturesSubPage } from './subpages/FeaturesSubPage';
import { InfoSubPage } from './subpages/InfoSubPage';

import styles from './GuestPropertyView.module.css';

const tab_links = [
    {
        icon: <BiInfoCircle size={25} />,
        name: 'info',
        title: i18next.t('Πληροφορίες')
    },
    {
        icon: <BsCardChecklist size={25} />,
        name: 'features',
        title: i18next.t('Χαρακτηριστικά')
    },
    {
        icon: <MdEuroSymbol size={25} />,
        name: 'expenses',
        title: i18next.t('Έξοδα')
    },
    {
        icon: <AiOutlineMessage size={25} />,
        name: 'contact',
        title: i18next.t('Επικοινωνία')
    }
];

const imageFloatingLeftButtons = [
    {
        icon: <AiOutlineHeart size={25} />,
        title: i18next.t('Αποθήκευση')
    },
    {
        icon: <BiShareAlt size={25} />,
        title: i18next.t('Κοινοποίηση')
    }
];

const mainTitleContent = {
    mainTitle: 'Μονοκατοικία,240τ.μ.',
    mainSubtitle: 'Ηράκλειο Κρήτης,κέντρο',
    price: 65000,
    currentCurrency: '€'
};

const infoData = [
    {
        title: i18next.t('Χαρακτηριστικά'),
        type: 'info',
        data: []
    },
    {
        title: i18next.t('Φωτογραφίες'),
        type: 'assets',
        data: []
    },
    {
        title: i18next.t('Περιγραφή'),
        type: 'description',
        data: {}
    },
    {
        title: i18next.t('Όλα τα χαρακτηριστικά'),
        type: 'features',
        data: []
    }
];

const fullData = {
    info: infoData,
    features: {},
    expenses: {},
    contact: {}
};

export const GuestPropertyView = () => {
    const [subPage, setSubPage] = useState(0);
    const [isFullMap, setIsFullMap] = useState(false);
    const [property, setProperty] = useState(null);
    const mapRef = useRef(null);
    const { propertyId } = useParams();

    useEffect(() => {
        requests.getGuestExample(propertyId).then(({ data }) => setProperty(data));
    }, [propertyId]);

    function setProperties(property) {
        mapRef?.current?.setProperties(property);
        mapRef?.current?.setCenter({
            lng: property?.longtitude,
            lat: property?.latitude
        });
        mapRef?.current?.setZoom(12);
    }

    function selectSubPage(type, data) {
        switch (type) {
            case 'info':
                return <InfoSubPage data={data} />;
            case 'features':
                return <FeaturesSubPage data={data} />;
            case 'expenses':
                return <ExpensesSubPage data={data} />;
            case 'contact':
                return <ContactSubPage data={data} />;

            default:
                console.assert('unknown sub page type');
                return null;
        }
    }

    if (!property) return null;

    infoData[0].data = [
        {
            type: 'floor',
            content: '2ος Όροφος'
        },
        {
            type: 'bed',
            content: '2 Υπνοδωμάτια'
        },
        {
            type: 'bath',
            content: '2 Μπάνια'
        },
        {
            type: 'constructionDate',
            content: 'Έτος Κατασκευής 2010'
        },
        {
            type: 'area',
            content: 'Εμβαδόν 240τ.μ.'
        },
        {
            type: 'parking',
            content: 'Διαθέσιμο Πάρκινγκ'
        }
    ];
    infoData[1].data = property.propertyAssets;
    infoData[2].data = {
        description: `Πωλείται οίκημα 2 επίπεδων στους πρόποδες του Γιούχτα, (οικισμούς Αγ.βλάσση), \
            σε οικόπεδο 270τμ με απίθανη και ανεμπόδιστη θέα το Ηράκλειο και κατασκευή του 2010.\
            Το πρώτο επίπεδο είναι Mεζονέτα 150τμ αποτελούμενο από μεγάλο τζάκι, σαλονοτραπεζαρία, \
            κουζίνας, wc και αποθηκάριο στο ισόγειο, ένα μπαλκόνι μπροστά 25τμ περίπου και μια βεράντα, πίσω.\
            Στον 1ο όροφο υπάρχουν τρία υπνοδωμάτια, με μεγάλες ντουλάπες, ένα μεγάλο μπάνιο με μπανιερά και μεγάλη ντουλάπα, \
            από πλακέ θαλάσσης, ένα πατάρι και μια μεγάλη ξεχωριστή, παπουτσοθήκη. Στον δεύτερο όροφο υπάρχει ένα δώμα 12τμ περίπου που μπορεί \
            να χρησιμοποιηθεί είτε ως υπνοδωμάτιο, είτε σαν εργαστήριο, από το δώμα υπάρχει πρόσβαση στην ταράτσα υπάρχει πλυσταριό και απεριόριστη θέα .\
            η μεζονέτα έχει αυτόνομη θέρμανση υγραέριο κουφώματα με διπλά τζαμιά και ένα μικρό κήπο.\
            Το δεύτερο επίπεδο είναι ημιτελές κτίσμα σε διαδικασία δανειοδότησης \
            για κατοικία 87 τμ με προτεινόμενη κάτοψη από αρχιτέκτονα για ένα τεσσάρι .διαθέτει αυλή 50τμ περίπου δυνατότητα τοποθετημένη ψησταριά και ξυλόφουρνος`
    };

    let mainImage = property.propertyAssets.filter(a => a.isMainImage);

    if (mainImage.length) {
        mainImage = `${URI}/${mainImage[0].file.path}`;
    } else {
        mainImage = property.propertyAssets[0]
            ? `${URI}/${property.propertyAssets[0].file.path}`
            : 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';
    }

    const contents = property.propertyAssets.map(i => `${URI}/${i.file.path}`);

    return (
        <div className={styles.pageWrap}>
            <div className={styles.imageContainer}>
                <ImageGallery images={contents} main={mainImage} />
            </div>
            <div className={styles.pageBody}>
                <div
                    className={`${styles.left} ${isFullMap && styles.hideLeftCol}  ${
                        isFullMap && styles.collapseLeftCol
                    }`}
                >
                    <div className={`${styles.titleWrapper}`}>
                        <div
                            className={`${styles.menuContainer} ${isFullMap && styles.hidden} ${
                                isFullMap && styles.alignLeft
                            }`}
                        ></div>
                        <div className={`${styles.rowTitle} ${isFullMap && styles.none}`}>
                            <div className={styles.titleContainer}>
                                <span className={styles.mainTitle}>
                                    {mainTitleContent.mainTitle}
                                </span>
                                <span className={styles.mainSubtitle}>
                                    {mainTitleContent.mainSubtitle}
                                </span>
                            </div>
                            <div className={styles.titleColRight}>
                                <div className={styles.priceContainer}>
                                    <span>{mainTitleContent.currentCurrency}</span>
                                    <span>{mainTitleContent.price}</span>
                                </div>
                                <div className={styles.leftImageButtons}>
                                    {imageFloatingLeftButtons?.map((b, i) => (
                                        <div key={i} title={b.title}>
                                            {b.icon}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.rowMain} `}>
                        <div className={`${styles.menuContainer} ${isFullMap && styles.alignLeft}`}>
                            <MenuTabs
                                links={tab_links}
                                flexCol
                                init={0}
                                onChange={setSubPage}
                                hideTitles={isFullMap}
                            />
                        </div>
                        <div className={`${styles.subPageContainer} ${isFullMap && styles.none}`}>
                            {selectSubPage(
                                tab_links[subPage].name,
                                fullData[tab_links[subPage].name]
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${styles.right} ${isFullMap && styles.expandedRightCol}`}>
                    {setProperties(property)}
                    <PropertiesMap
                        isFullMap={isFullMap}
                        setIsFullMap={setIsFullMap}
                        ref={mapRef}
                        isActiveMap={false}
                        guestMode={true}
                        height={'1000px'}
                    />

                    {() => {
                        setIsFullMap(!isFullMap);
                    }}
                </div>
            </div>
        </div>
    );
};
