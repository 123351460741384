// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatisticsPage_pageWrap__BRaao {\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n    height: 100%;\n}\n\n@media only screen and (max-width: 1000px) {\n    .StatisticsPage_pageWrap__BRaao {\n        display: flex;\n        flex-direction: column;\n        padding: 0;\n        width: 100%;\n    }\n}\n\n.StatisticsPage_contentWrap__iN7BG {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    padding: 0px 50px;\n}\n\n.StatisticsPage_subPageWrap__DqWdW {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    min-height: 700px;\n}\n\n.StatisticsPage_subPage__qqhGw {\n    display: flex;\n    justify-content: center;\n    max-width: 1500px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/adminPages/statisticsPages/StatisticsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,UAAU;QACV,WAAW;IACf;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n    height: 100%;\n}\n\n@media only screen and (max-width: 1000px) {\n    .pageWrap {\n        display: flex;\n        flex-direction: column;\n        padding: 0;\n        width: 100%;\n    }\n}\n\n.contentWrap {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    padding: 0px 50px;\n}\n\n.subPageWrap {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    min-height: 700px;\n}\n\n.subPage {\n    display: flex;\n    justify-content: center;\n    max-width: 1500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "StatisticsPage_pageWrap__BRaao",
	"contentWrap": "StatisticsPage_contentWrap__iN7BG",
	"subPageWrap": "StatisticsPage_subPageWrap__DqWdW",
	"subPage": "StatisticsPage_subPage__qqhGw"
};
export default ___CSS_LOADER_EXPORT___;
