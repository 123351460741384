import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import BuildingEditPage from './propertyEditPages/BuildingEditPage';
import LandEditPage from './propertyEditPages/LandEditPage';

const PropertyEditPage = ({ permissions = {} }) => {
    const { propertyId } = useParams();
    const [property, setProperty] = useState(null);

    useEffect(() => {
        if (!propertyId) {
            return;
        }

        requests.getProperty(propertyId).then(({ data }) => setProperty(data));
    }, [propertyId]);

    if (!property) {
        return <LoaderWrapper />;
    }

    const typeSpecificPages = {
        BUILDING: <BuildingEditPage property={property} setProperty={setProperty} />,
        LAND: <LandEditPage property={property} setProperty={setProperty} />
    };

    return typeSpecificPages[property.type || 'BUILDING'];
};

export default PropertyEditPage;
