import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { BsSearch, BsX } from 'react-icons/bs';
import { MdPersonRemove } from 'react-icons/md';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { Table } from 'table';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { Tabs } from '../../../components/tabs/Tabs';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../../components/smallPreviewNameImage/SmallPreviewNameImage';
import { Modal } from '../../../components/modal/Modal';

import requests, { URI } from '../../../utils/requests';

import RoleForm from '../../../assets/json/RoleForm';

import styles from './EditRolePage.module.css';

const AddUserModal = ({ role, teamId, onClose, onAdd }) => {
    const { t } = useTranslation();

    const [allUsers, setAllUsers] = useState();
    const [filteredUsers, setFilteredUsers] = useState();
    const [filter, setFilter] = useState();
    const [selectedUser, setSelectedUser] = useState(null);

    const buttonRef = useRef(null);
    const navigate = useNavigate();

    const addToast = useToasts();

    const fetchAllTeamMembers = useCallback(async () => {
        const { data: members } = await requests.getTeamMembers(teamId);

        const users = members.map(member => member.user);

        const allUsers = users.filter(
            user => !role?.users?.some(excludeUser => excludeUser.email === user.email)
        );

        setAllUsers(allUsers);
        setFilteredUsers(allUsers);
    }, [teamId, role]);

    useEffect(() => {
        fetchAllTeamMembers();
    }, [fetchAllTeamMembers]);

    useEffect(() => {
        if (selectedUser === null) buttonRef.current.setDisabled(true);
        else if (!buttonRef.current.isLoading()) buttonRef.current.setDisabled(false);
    }, [selectedUser]);

    const onSearchChange = e => {
        setFilter(e.target.value);

        if (e.target.value.length === 0) setFilteredUsers(allUsers);
        else
            setFilteredUsers(
                allUsers.filter(user =>
                    user.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
    };

    const addRole = async () => {
        try {
            await requests.assignRole(teamId, role.id, selectedUser.email);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t(
                    "Something went wrong with updating the users' roles. Please try again later."
                ),
                duration: 3000
            });
        }

        addToast({
            type: 'success',
            message: t(`You successfully added to the role`, {
                user: selectedUser.name,
                role: role.name
            }),
            duration: 3000
        });

        onAdd?.(selectedUser);
    };

    return (
        <Modal
            onClose={onClose}
            header={t('Add Member to Role')}
            content={
                <div className={styles.modalContentContainer}>
                    <LoaderWrapper>
                        {allUsers &&
                            (selectedUser ? (
                                <div className={`${styles.user} ${styles.selected}`}>
                                    <SmallPreviewNameImage
                                        imageSrc={
                                            selectedUser.image &&
                                            `${URI}/${selectedUser.image.path}`
                                        }
                                        fullname={selectedUser.name}
                                        onClick={() =>
                                            navigate(`../../users/${selectedUser.email}/info`)
                                        }
                                    />
                                    <BsX onClick={() => setSelectedUser(null)} />
                                </div>
                            ) : (
                                <div className={styles.users}>
                                    <div className={styles.prompt}>
                                        {t('Please select a user to add to the role')}
                                    </div>
                                    <div className={styles.search}>
                                        <BsSearch />
                                        <input
                                            type='text'
                                            onChange={onSearchChange}
                                            placeholder={`${t('Serach Team Members')}...`}
                                            value={filter}
                                        />
                                    </div>
                                    {filteredUsers.map((user, i) => (
                                        <div
                                            key={i}
                                            className={styles.user}
                                            onClick={() => setSelectedUser(user)}
                                        >
                                            <SmallPreviewNameImage
                                                imageSrc={user.image && `${URI}/${user.image.path}`}
                                                fullname={user.name}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                    </LoaderWrapper>
                </div>
            }
            footer={
                <>
                    <ButtonWithLoading className='btn btn-cancel' onClick={onClose}>
                        {t('cancel')}
                    </ButtonWithLoading>
                    <ButtonWithLoading
                        className='btn btn-primary-0'
                        onClick={addRole}
                        ref={buttonRef}
                    >
                        {t('Add')}
                    </ButtonWithLoading>
                </>
            }
        />
    );
};

const RemoveUserModal = ({ user, role, onClose, onRemove }) => {
    const { t } = useTranslation();

    return (
        <Modal
            onClose={onClose}
            header={t('Remove Member from Role')}
            content={
                <div>
                    {t('Are you sure you want to remove user from role ?', {
                        user: <b> {user.name} </b>,
                        role: <b> {role.name} </b>
                    })}
                </div>
            }
            footer={
                <>
                    <ButtonWithLoading className='btn btn-cancel' onClick={onClose}>
                        {t('Cancel')}
                    </ButtonWithLoading>
                    <ButtonWithLoading className='btn btn-error' onClick={() => onRemove(role)}>
                        {t('Delete')}
                    </ButtonWithLoading>
                </>
            }
        />
    );
};

const UserCell = ({ user, role, onRemove }) => {
    const { t } = useTranslation();
    const [removeUserModalIsVisible, setRemoveUserModalIsVisible] = useState(false);
    const { teamId } = useParams();

    const navigate = useNavigate();
    const addToast = useToasts();

    const removeUser = async role => {
        try {
            await requests.removeRoleFromUser(teamId, role.id, user.email);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t(
                    'Something went wrong with removing the user from the role. Please try again later.'
                ),
                duration: 3000
            });
        }

        addToast({
            type: 'success',
            message: t('User has been removed successfully'),
            duration: 3000
        });

        setRemoveUserModalIsVisible(false);

        onRemove?.(user);
    };

    return (
        <div className={styles.userRow}>
            <SmallPreviewNameImage
                imageSrc={user.image && `${URI}/${user.image.path}`}
                fullname={user.name}
                onClick={() => navigate(`../../users/${user.email}/info`)}
            />
            <MdPersonRemove
                className={styles.removeBtn}
                onClick={() => setRemoveUserModalIsVisible(true)}
            />

            {removeUserModalIsVisible && (
                <RemoveUserModal
                    user={user}
                    role={role}
                    teamId={teamId}
                    onClose={() => setRemoveUserModalIsVisible(false)}
                    onRemove={removeUser}
                />
            )}
        </div>
    );
};

const RoleUsers = ({ role: role_ }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { teamId } = useParams();

    const [addUserModalIsVisible, setAddUserModalIsVisible] = useState(false);
    const [removeUserModalIsVisible, setRemoveUserModalIsVisible] = useState(false);

    const [role, setRole] = useState(role_);

    const columns = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'user',
                Cell: ({ value: user }) => <UserCell user={user} role={role} onRemove={onRemove} />
            }
        ],
        [role]
    );

    const onAdd = user => {
        role_.users = [...role_.users, user];

        setRole({ ...role_ });

        setAddUserModalIsVisible(false);
    };

    const onRemove = user => {
        role_.users = role_.users.filter(roleUser => roleUser.email !== user.email);

        setRole({ ...role_ });
    };

    const data = useMemo(() => role.users.map(user => ({ user })), [role]);

    return (
        <div className={styles.tabContent}>
            <div className={styles.title}>
                <h2>{t('Users')}</h2>
                <ButtonWithLoading
                    className={`btn btn-primary-0 ${styles.addUserBtn}`}
                    onClick={setAddUserModalIsVisible}
                >
                    {t('Add User')}
                </ButtonWithLoading>
            </div>
            <Table
                showColumnNames={false}
                pagination={{ pageSize: 10 }}
                columns={columns}
                data={data}
                exporting={false}
                filters={false}
            />
            {addUserModalIsVisible && (
                <AddUserModal
                    role={role}
                    teamId={teamId}
                    onClose={() => setAddUserModalIsVisible(false)}
                    onAdd={onAdd}
                />
            )}
        </div>
    );
};

const RoleBasicInfo = ({ role }) => {
    const addToast = useToasts();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { teamId, roleId } = useParams();

    const formRef = useRef();

    const permissions = [
        'canAddNewProperty',
        'hasViewAccess',
        'hasEditAccess',
        'hasDigitalFolderAccess',
        'hasLeaseAccess',
        'hasRenovationAccess',
        'hasExpensesAccess',
        'hasEstimatesAccess',
        'hasSuggestionAccess',
        'hasMaturityActionsAccess',
        'hasDigitalFolderEdit',
        'hasLeaseEdit',
        'hasRenovationEdit',
        'hasExpensesEdit',
        'hasEstimatesEdit',
        'hasSuggestionEdit',
        'hasMaturityActionsEdit'
    ];

    const editRole = async () => {
        const data = formRef.current.getData();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) return null;

        try {
            await requests.updateRoleOnTeam(teamId, roleId, data);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t('There was an error updating the role. Please try again later.'),
                duration: 3000
            });
        }

        addToast({
            type: 'success',
            message: t('Role was updated successfully'),
            duration: 3000
        });

        return navigate('../roles');
    };

    if (!role) return null;

    return (
        <div className={styles.tabContent}>
            <Form ref={formRef} description={RoleForm(permissions)} initialValue={role} />
            <ButtonWithLoading className={`btn btn-primary-0 ${styles.saveBtn}`} onClick={editRole}>
                {t('Save')}
            </ButtonWithLoading>
        </div>
    );
};

export default function EditRolePage() {
    const { t } = useTranslation();
    const { teamId, roleId } = useParams();
    const addToast = useToasts();
    const navigate = useNavigate();
    const formRef = useRef();

    const [role, setRole] = useState(null);

    const fetchRole = async () => {
        try {
            const { data: role } = await requests.getRole(teamId, roleId);

            setRole(role);
        } catch (err) {
            addToast({
                type: 'failure',
                message: t('There was an error retrieving the role. Please try again later.'),
                duration: 3000
            });

            navigate('../roles');
        }
    };

    useEffect(() => {
        fetchRole();
    }, []);

    const tabs = useMemo(() => {
        return [
            {
                title: t('Basic Information & Permissions'),
                content: <RoleBasicInfo role={role} />
            },
            {
                title: t('Users'),
                content: <RoleUsers role={role} />
            }
        ];
    }, [role]);

    return (
        <div className={styles.container}>
            <LoaderWrapper>
                {role && (
                    <div className={styles.container}>
                        <div className={styles.title}>
                            <ButtonWithLoading
                                className={`btn btn-cancel ${styles.backBtn}`}
                                onClick={() => navigate('../roles')}
                            >
                                <IoIosArrowBack />
                                {t('Back')}
                            </ButtonWithLoading>
                            <h1>{t('Edit Role')}</h1>
                        </div>
                        <Tabs tabs={tabs} />
                    </div>
                )}
            </LoaderWrapper>
        </div>
    );
}
