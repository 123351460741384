import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Add-member'),
        promptForSubmit: 'Confirm',
        questions: [
            {
                type: 'dropdown',
                isRequired: true,
                title: i18next.t('Select-user'),
                name: 'selectUser',
                choices: []
            },
            {
                type: 'dropdown',
                title: i18next.t('Role'),
                isRequired: true,
                name: 'userType',
                choices: [
                    {
                        title: i18next.t('Member'),
                        value: 'MEMBER'
                    },
                    {
                        title: i18next.t('Manager'),
                        value: 'MANAGER'
                    }
                ]
            }
        ]
    };
};

export default getDescription;
