import ErrorPrompt from 'react-form/dist/Form/form-inputs/ErrorPrompt';
import { Input } from 'react-form/dist/Form/form-inputs/Input';

// ---------------------------------------------------------------------------------------

export class ToggleInput extends Input {
    constructor(props) {
        super(props);

        this.state.value = !!this.state.value;
    }

    onChange = (e, onFinish) => {
        const value = e.target.checked !== undefined ? !!e.target.checked : e.target.value;

        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    };

    readOnlyRender() {
        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap' style={this.props.style}>
                    <div className='toggle-label-container'>
                        <label>
                            <input type='checkbox' defaultChecked={true} disabled />
                            <span />
                        </label>
                    </div>
                </div>
            </>
        );
    }

    render() {
        if (this.state.isHidden) return null;

        if (this.props.readOnly) return this.readOnlyRender();

        return (
            <label className='toggle-container'>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div className='form-group' style={this.props.style}>
                    <div className='toggle-label-container'>
                        <label>
                            <input
                                type='checkbox'
                                defaultChecked={this.state.value}
                                onChange={this.onChange}
                            />
                            <span />
                        </label>
                    </div>
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </label>
        );
    }
}
