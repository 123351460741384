import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';

import PropertyList from '../../components/PropertyList/PropertyList';
import AddPropertyForm from '../../components/addPropertyForm/AddPropertyForm';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { Modal } from '../../components/modal/Modal';
import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

import requests from '../../utils/requests';

import styles from './TeamPropertiesPage.module.css';

const TeamPropertiesPage = ({ permissions }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { teamId } = useParams();
    const [isDisableAddModal, setIsDisabledAddModal] = useState(true);
    const formRef = useRef(null);
    const [properties, setProperties] = useState(null);

    const onSuccessfulSubmit = data => {
        setIsDisabledAddModal(true);
        navigate(`../../properties/edit/${data.id}/info`);
    };

    useEffect(() => {
        if (!teamId) return;
        requests.getAllPropertiesOfTeam(teamId).then(({ data }) => setProperties(data));
    }, [teamId]);

    const submitProperty = async () => {
        if (!formRef.current) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const formData = formRef.current.getData();

        const submitedData = {
            teamId,
            name: formData.propertyName,
            type: formData.propertyType,
            category: formData.propertyCategory,
            location: formData.propertyLocationMap[0],
            region: formData.locationInfoRegion,
            regionalUnit: formData.locationInfoRegionalUnit,
            street: formData.locationInfoAddress,
            streetNumber: formData.locationInfoAddrNumber
        };

        const { data } = await requests.postProperty(submitedData);
        onSuccessfulSubmit(data);
    };

    function flattenPropertyData(properties) {
        if (!properties) return;

        return properties.map((p, i) => {
            return {
                id: p?.id,
                teamId: p?.teamId,
                propertyId: p?.id,
                name: p?.building?.name || p?.plot?.name,
                street: p?.street,
                streetNumber: p?.streetNumber,
                description: p?.building?.description || p?.plot?.description,
                price: p?.building?.price || p?.plot?.price,
                floor: p?.building?.floor,
                yearOfConstruction: p?.building?.yearOfConstruction,
                areaSqM: p?.building?.areaSD || p?.plot?.totalPlotAreaAytopsy,
                type: p?.type,
                pType: p?.building?.pType || p?.plot?.pType,
                mainImagePath: p?.propertyAssets[0]?.file?.path
            };
        });
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('properties')} />
                {permissions.canAddProperty && (
                    <span
                        className={`btn btn-primary-0 ${styles.addButton}`}
                        onClick={() => setIsDisabledAddModal(false)}
                    >
                        <IoMdAddCircleOutline size={'20px'} />
                        {t('add-property-header')}
                    </span>
                )}
            </div>
            {!isDisableAddModal && (
                <Modal
                    onClose={() => setIsDisabledAddModal(true)}
                    header={t('add-property-header')}
                    content={<AddPropertyForm formRef={formRef} />}
                    footer={
                        <div className={styles.addPropertyFormFooter}>
                            <ButtonWithLoading
                                className='btn'
                                onClick={() => {
                                    setIsDisabledAddModal(true);
                                }}
                            >
                                {t('Cancel')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                                className='btn btn-primary-0'
                                onClick={submitProperty}
                            >
                                {t('Create')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
            {/* <SearchPaginatedPropertiesWithMap
                extraFilters={{ teamId }}
                path='../../property/view'
                background={({ children, className }) => (
                    <div className={className} style={{ boxShadow: 'unset' }}>
                        {children}
                    </div>
                )}
            />
             */}
            {properties ? (
                <PropertyList
                    inTeam={false}
                    properties={flattenPropertyData(properties)}
                    path={'../../properties/view'}
                    guestView={false}
                />
            ) : (
                t('noRecordsFound')
            )}
        </div>
    );
};

export default TeamPropertiesPage;
