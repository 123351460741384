// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyLocationPage_pageWrap__vEYvM {\n    display: flex;\n    flex-direction: column;\n}\n\n.PropertyLocationPage_actionBtnsContainer__kUIk0 {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end;\n}\n.PropertyLocationPage_pageTitle__7Rope {\n    margin-bottom: 20px;\n}\n.PropertyLocationPage_actionBtn__Zkefr {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/PropertyLocationPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;;IAEX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,SAAS;;IAET,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end;\n}\n.pageTitle {\n    margin-bottom: 20px;\n}\n.actionBtn {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "PropertyLocationPage_pageWrap__vEYvM",
	"actionBtnsContainer": "PropertyLocationPage_actionBtnsContainer__kUIk0",
	"pageTitle": "PropertyLocationPage_pageTitle__7Rope",
	"actionBtn": "PropertyLocationPage_actionBtn__Zkefr"
};
export default ___CSS_LOADER_EXPORT___;
