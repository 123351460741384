import React from 'react';

import { createPortal } from 'react-dom';
import { BsX } from 'react-icons/bs';

import { useScroll, withScroll } from '../../context/withScroll';

import styles from './Modal.module.css';

function SimpleHeader({ title, onClose }) {
    return (
        <div className={styles.simpleHeader}>
            {title}
            <BsX onClick={onClose} className={styles.close}></BsX>
        </div>
    );
}

// --------------------------------------------------------------------------------------

function Modal_({ header, content, footer, onClose, className, contentClassName, style }) {
    const { setScrollContainer } = useScroll();

    const header_ =
        typeof header === 'string' ? <SimpleHeader title={header} onClose={onClose} /> : header;

    return createPortal(
        <div className={styles.container}>
            <div className={styles.overlay} />

            <div className={`${styles.modal} ${className}`} style={style}>
                {header && <div className={styles.header}>{header_}</div>}
                {content && (
                    <div
                        ref={setScrollContainer}
                        className={`${styles.content} ${contentClassName || ''}`}
                    >
                        {content}
                    </div>
                )}
                {footer && <div className={styles.footer}>{footer}</div>}
            </div>
        </div>,
        document.getElementById('root')
    );
}

export const Modal = withScroll(Modal_);
