// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LanguagePicker_optionContainer__i1TT3 {\n    display: flex;\n    gap: 8px;\n    padding-left: 5px;\n    padding-right: 5px;\n}\n\n.LanguagePicker_flag__65ZES {\n    width: 24px;\n    border-radius: 4px;\n    flex-shrink: 0;\n}\n\n@media only screen and (max-width: 1000px) {\n    .LanguagePicker_optionContainer__i1TT3 {\n        width: -moz-fit-content;\n        width: fit-content;\n    }\n    .LanguagePicker_languageTitle__0scyd {\n        display: none;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/languagePicker/LanguagePicker.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI;QACI,uBAAkB;QAAlB,kBAAkB;IACtB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".optionContainer {\n    display: flex;\n    gap: 8px;\n    padding-left: 5px;\n    padding-right: 5px;\n}\n\n.flag {\n    width: 24px;\n    border-radius: 4px;\n    flex-shrink: 0;\n}\n\n@media only screen and (max-width: 1000px) {\n    .optionContainer {\n        width: fit-content;\n    }\n    .languageTitle {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": "LanguagePicker_optionContainer__i1TT3",
	"flag": "LanguagePicker_flag__65ZES",
	"languageTitle": "LanguagePicker_languageTitle__0scyd"
};
export default ___CSS_LOADER_EXPORT___;
