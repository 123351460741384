import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyInfoForm'),
        showTitle: false,
        name: 'propertyInfoForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('property-part-name'),
                name: 'propertyPartName',
                type: 'text',
                isRequired: true
            },
            {
                title: i18next.t('property-part-category'),
                name: 'propertyPartsCategory',
                type: 'dropdown',
                isRequired: true
            }
        ]
    };
};

export default getDescription;
