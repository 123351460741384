import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { MdWebAssetOff } from 'react-icons/md';

import styles from '../Error.module.css';

export const Error404Page = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.error}>
                <MdWebAssetOff className={styles.icon} />
                <h1> {`${t('Error')} 404`} </h1>
            </div>
            <div>
                <h2> {t('Oops! The page you were looking for was not found...')} </h2>
                <p>{t('Please contact the administrators or try to reload the page')}</p>
            </div>
            <button className='btn btn-primary-0'>
                <Link to='/'> {t('Back to the home page')} </Link>
            </button>
        </div>
    );
};
