import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { URI } from '../../utils/requests';

import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';
import { Modal } from '../modal/Modal';
import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import styles from './PreviewProfileImagesGroup.module.css';

export const ModalWithRequest = ({ request, renderBasedOnData, onClose, header }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        request?.().then(({ data }) => {
            setData(data);
        });
    }, []);

    return (
        <Modal
            onClose={onClose}
            header={header}
            content={<LoaderWrapper size={30}>{renderBasedOnData(data)}</LoaderWrapper>}
        />
    );
};

export const PreviewProfileImagesGroup = ({
    profiles = [],
    request,
    header,
    inProfile = false,
    redirectToTeam = false,
    transformDataToRender = data => {}
}) => {
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(null);
    const navigate = useNavigate();
    const detailsModal = () => {
        if (!isDetailsModalOpen) return null;

        return (
            <ModalWithRequest
                request={request}
                renderBasedOnData={transformDataToRender}
                onClose={() => setIsDetailsModalOpen(false)}
                header={header}
            />
        );
    };

    const openDetailsModal = () => {
        setIsDetailsModalOpen(true);
    };

    const createProfileData = () => {
        let pJSX = null;

        if (profiles.length <= 3) {
            pJSX = profiles.map((p, i) => {
                return (
                    <SmallPreviewNameImage
                        key={`spni_${i}`}
                        imageSrc={p.image && `${URI}/${p.image.path}`}
                        showName={false}
                        forceShowName={false}
                        fullname={p.name}
                        onClick={() =>
                            navigate(
                                `${inProfile ? '../' : ''}..${
                                    redirectToTeam ? '/teams/' : '/users/'
                                }${redirectToTeam ? p.id : p.email}/info`
                            )
                        }
                    />
                );
            });
        } else {
            pJSX = (
                <>
                    <SmallPreviewNameImage
                        imageSrc={profiles[0].image && `${URI}/${profiles[0].image.path}`}
                        showName={false}
                        fullname={profiles[0].name}
                        forceShowName={false}
                        onClick={() =>
                            navigate(
                                `${inProfile ? '../' : ''}..${
                                    redirectToTeam ? '/teams/' : '/users/'
                                }${redirectToTeam ? profiles[0].id : profiles[0].email}/info`
                            )
                        }
                    ></SmallPreviewNameImage>
                    <SmallPreviewNameImage
                        imageSrc={profiles[1].image && `${URI}/${profiles[1].image.path}`}
                        showName={false}
                        fullname={profiles[1].name}
                        forceShowName={false}
                        onClick={() =>
                            navigate(
                                `${inProfile ? '../' : ''}..${
                                    redirectToTeam ? '/teams/' : '/users/'
                                }${redirectToTeam ? profiles[1].id : profiles[1].email}/info`
                            )
                        }
                    ></SmallPreviewNameImage>
                    <div className={styles.imageDefault} onClick={openDetailsModal}>
                        {`${profiles.length - 2}+`}
                    </div>
                </>
            );
        }

        return pJSX;
    };

    return (
        <div className={styles.container}>
            {createProfileData()}
            {detailsModal()}
        </div>
    );
};
