import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './Popover.module.css';

export const Placement = {
    Left: 'left',
    Right: 'right',
    Top: 'top',
    Bottom: 'bottom'
};

export const Popover = ({
    trigger,
    placement,
    content,
    isVisible: isVisible_,
    onChangeVisibility
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const clickedMe = useRef(false);

    useEffect(() => {
        const hide = () => {
            if (!clickedMe.current) {
                setIsVisible(false);
            } else clickedMe.current = false;
        };

        document.addEventListener('click', hide);

        return () => document.removeEventListener('click', hide);
    }, []);

    useEffect(() => {
        if (isVisible_ !== undefined && isVisible !== isVisible_) {
            clickedMe.current = false;
            setIsVisible(isVisible_);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible_]);

    useEffect(() => {
        onChangeVisibility?.(isVisible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const onClick = useCallback(e => {
        clickedMe.current = true;
        setIsVisible(isVisible => !isVisible);
    }, []);

    const getPlacementClasses = () => {
        return placement
            .split(' ')
            .map(cl => styles[cl])
            .join(' ');
    };

    return (
        <div className={styles.popover}>
            <div className={styles.trigger} onClick={onClick}>
                {trigger}
            </div>
            {isVisible && (
                <div
                    className={`${styles.content} ${getPlacementClasses()}`}
                    onClick={e => e.stopPropagation()}
                >
                    {content}
                </div>
            )}
        </div>
    );
};
