import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';

// --------------------------------------------------------------------------------------
import Utils from '../../utils/utils';

// --------------------------------------------------------------------------------------
import styles from './Sidebar.module.css';
import { Collapse } from 'react-collapse';

import { RiArrowRightSLine } from 'react-icons/ri';

const Category = ({ title, options }) => {
    const [isVisible, setIsVisible] = useState(true);

    const calculateClasses = ({ option, isActive }) => {
        return Utils.classes(
            styles.option,
            isActive && styles.selected,
            option.className && option.className(isActive)
        );
    };

    return (
        <div className={styles.category}>
            <div className={styles.header} onClick={() => setIsVisible(isVisible => !isVisible)}>
                <RiArrowRightSLine
                    className={styles.arrow}
                    style={{
                        transform: isVisible ? 'rotate(90deg)' : 'rotate(0)'
                    }}
                />
                <div className={styles.title}> {title} </div>
            </div>
            <Collapse isOpened={isVisible} theme={{ collapse: styles.collapse }}>
                <div>
                    {options
                        .filter(option => !!option.title)
                        .map((option, j) => {
                            const { Icon, title, path } = option;

                            return (
                                <NavLink
                                    key={j}
                                    to={path}
                                    className={({ isActive }) =>
                                        calculateClasses({ option, isActive })
                                    }
                                >
                                    {Icon && <Icon className={styles.icon} />}
                                    <div className={styles.title}>{title}</div>
                                </NavLink>
                            );
                        })}
                </div>
            </Collapse>
        </div>
    );
};

export const Sidebar = ({ options, className, children }) => {
    const location = useLocation();

    const [toggle, setToggle] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    const mobileNavToggle = () => {
        setToggle(!toggle);
    };

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        setToggle(false);
    }, [location]);

    const className_ = ({ isActive, className }) => {
        return Utils.classes(
            styles.option,
            isActive && styles.selected,
            className && className(isActive)
        );
    };

    return (
        <div className={styles.sideWrapper}>
            <div
                className={styles.sidebar}
                style={{
                    transform:
                        width < 1000 ? (toggle ? 'translateX(0)' : 'translateX(-200px)') : 'none',
                    height: width < 1000 ? (toggle ? '100vh' : '0') : '100%',
                    width: width < 1000 ? (toggle ? 'max-content' : '0') : '100%'
                }}
            >
                <div className={styles.childrenContainer}>{children}</div>
                <div className={styles.options}>
                    {options
                        .filter(option => !!option)
                        .map((option, i) => {
                            if (option.options) {
                                return (
                                    <Category
                                        key={i}
                                        options={option.options}
                                        title={option.title}
                                    />
                                );
                            } else {
                                const { Icon, title, path } = option;

                                return (
                                    <NavLink key={i} to={path} className={className_}>
                                        <Icon className={styles.icon} />
                                        <div className={styles.title}> {title} </div>
                                    </NavLink>
                                );
                            }
                        })}
                </div>
            </div>

            <div onClick={mobileNavToggle} className={styles.mobileToggleButton}>
                {toggle ? (
                    <RxCaretLeft id={styles.icon} size={'25px'} />
                ) : (
                    <RxCaretRight id={styles.icon} size={'25px'} />
                )}
            </div>
        </div>
    );
};
