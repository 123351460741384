// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThemeList_themeList__eiDqb {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 100%;\n    gap: 1.5rem;\n}\n\n@media only screen and (max-width: 1000px) {\n    .ThemeList_themeList__eiDqb {\n        flex-wrap: wrap;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/themeList/ThemeList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,WAAW;IACX,WAAW;AACf;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".themeList {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    width: 100%;\n    gap: 1.5rem;\n}\n\n@media only screen and (max-width: 1000px) {\n    .themeList {\n        flex-wrap: wrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"themeList": "ThemeList_themeList__eiDqb"
};
export default ___CSS_LOADER_EXPORT___;
