import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import optionsDescription from '../../assets/json/PropertyOptions';

import styles from './BuildingOptionsPage.module.css';

const LandOptionsPage = ({ plot }) => {
    const { t } = useTranslation();
    const addToast = useToasts();
    const [plotStatus, setPlotStatus] = useState(null);
    const formRef = useRef(null);

    useEffect(() => {
        requests.getPlotPublicStatus(plot.id).then(({ data }) => {
            setPlotStatus(data);
        });
    }, [plot]);

    if (!plotStatus) return <LoaderWrapper />;

    const submitOptions = async () => {
        if (!formRef.current) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const { exposedUntil, price, exposed } = formRef.current.getData();
        await requests.updatePlotPublicStatus(plot.id, {
            isExposed: exposed === 'isExposed',
            exposedUntil,
            price
        });

        addToast({
            type: 'success',
            message: t('property-publish-prompt'),
            duration: 3000
        });
    };

    return (
        <div className={styles.pageWrap}>
            <h1>{t('options')}</h1>
            <Form
                description={optionsDescription()}
                ref={formRef}
                initialValue={{
                    price: plotStatus.price,
                    exposedUntil: plotStatus.exposedUntil,
                    exposed: plotStatus.isExposed ? 'isExposed' : 'isNotExposed'
                }}
            />
            <div className={styles.actionBtnsContainer}>
                <button className={`btn btn-primary-0 ${styles.actionBtn}`} onClick={submitOptions}>
                    {t('expose')}
                </button>
            </div>
        </div>
    );
};

export default LandOptionsPage;
