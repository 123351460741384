import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { BiArrowBack } from 'react-icons/bi';

import AddPropertyMapVariable from '../../components/addPropertyMapVariable/addPropertyMapVariable.js';
import { Header } from '../../components/header/Header.js';

import requests from '../../utils/requests.js';

import backgroundImg from '../../assets/images/map-edit.jpg';
import description from '../../assets/json/PropertyMapEditFeature.js';

import styles from './PropertiesMapEdit..module.css';

const PropertiesMapEdit = () => {
    const [properties, setProperties] = useState(null);
    const [landProperty, setLandProperty] = useState(null);
    const [buildingProperty, setBuildingProperty] = useState(null);
    const formRef = useRef();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        requests.getAllProperties().then(response => {
            setProperties(response.data);
        });
    }, []);
    useEffect(() => {
        properties?.forEach(p => {
            p.type === 'LAND' ? setLandProperty(p) : setBuildingProperty(p);
        });
    }, [properties]);

    return (
        <div className={styles.mainContainer}>
            <Header title={t('map-feature-edit')} backgroundImg={backgroundImg}>
                <div className={styles.backBtn} onClick={() => navigate(`../properties`)}>
                    <BiArrowBack />
                    {t('map')}
                </div>
            </Header>
            <div className={styles.contentDiv}>
                <AddPropertyMapVariable
                    formRef={formRef}
                    landProperty={landProperty}
                    buildingProperty={buildingProperty}
                />
            </div>
        </div>
    );
};

export default PropertiesMapEdit;
