import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading.js';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import estimateDescription from '../../../assets/json/PropertyEstimate.js';

import styles from './PropertyEstimateViewPage.module.css';

export const PropertyEstimateViewPage = ({ buildingId }) => {
    const formRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id: estimateId } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        requests.getBuildingEstimate(buildingId, estimateId).then(resp => {
            setData(resp.data);
        });
    }, [buildingId, estimateId]);

    if (!data) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <h1 className={styles.pageTitle}>{t('estimateId = {{id}}', { id: estimateId })}</h1>

            <div className={styles.formContainer}>
                <Form
                    description={estimateDescription()}
                    readOnly
                    initialValue={data}
                    ref={formRef}
                ></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={() => navigate('../estimates')}
                >
                    <div className={styles.btnContent}>
                        <IoIosArrowRoundBack />
                        {t('back')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};
