import i18next from 'i18next';

export default (permissions = []) => {
    const questions = [
        {
            type: 'label',
            title: i18next.t('Role Basic Information')
        },
        {
            type: 'text',
            name: 'name',
            title: i18next.t('Role Name'),
            isRequired: true,
            placeholder: i18next.t('Role Name')
        },
        {
            type: 'fancy-color',
            name: 'color',
            title: i18next.t('Role Color'),
            isRequired: true,
            placeholder: i18next.t('Role Color')
        }
    ];

    if (questions.length > 0) {
        questions.push({
            type: 'label',
            title: i18next.t('Role Permissions')
        });

        for (let permission of permissions)
            questions.push({
                type: 'toggle',
                title: i18next.t(permission),
                name: permission
            });
    }

    return { questions };
};
