// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tabs_tabs__nt\\+zU {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n.Tabs_tab__IMJ79 {\n    flex-grow: 1;\n    flex-basis: 0;\n    text-align: center;\n    padding-bottom: 10px;\n    padding-top: 10px;\n    border-bottom: 2px solid var(--theme-bg-20);\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.Tabs_tab__IMJ79.Tabs_selected__5kzJc {\n    border-bottom: 3px solid var(--theme-primary);\n}\n\n.Tabs_container__cI-dz {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    width: 100%;\n    flex-grow: 1;\n}\n\n.Tabs_content__uCSK3 {\n    width: 100%;\n    display: flex;\n    flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,2CAA2C;IAC3C,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,YAAY;AAChB","sourcesContent":[".tabs {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n.tab {\n    flex-grow: 1;\n    flex-basis: 0;\n    text-align: center;\n    padding-bottom: 10px;\n    padding-top: 10px;\n    border-bottom: 2px solid var(--theme-bg-20);\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.tab.selected {\n    border-bottom: 3px solid var(--theme-primary);\n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n    width: 100%;\n    flex-grow: 1;\n}\n\n.content {\n    width: 100%;\n    display: flex;\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "Tabs_tabs__nt+zU",
	"tab": "Tabs_tab__IMJ79",
	"selected": "Tabs_selected__5kzJc",
	"container": "Tabs_container__cI-dz",
	"content": "Tabs_content__uCSK3"
};
export default ___CSS_LOADER_EXPORT___;
