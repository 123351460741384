import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Team-Form'),
        showTitle: false,
        name: 'teamForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Team-name'),
                placeholder: 'Team...',
                name: 'teamName',
                isRequired: true,
                restrictions: {
                    validateFieldsName: 100
                },
                type: 'text'
            },
            {
                title: i18next.t('Description'),
                placeholder: 'This-team...',
                name: 'teamDescription',
                restrictions: {
                    validateFieldsName: 255
                },
                type: 'freetext'
            }
        ]
    };
};

export default getDescription;
