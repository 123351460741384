import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('maps-edit-features'),
        showTitle: false,
        name: 'propertyMapEditFeature',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            // {
            //     title: i18next.t('select-variables'),
            //     name: 'variablesOnMap',
            //     type: 'dropdown',
            //     isRequired: false,
            //     space: '100%',
            //     choices: []
            // },
            {
                title: i18next.t('Icon'),
                name: 'iconLabel',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('type'),
                name: 'typeCustomIcon',
                type: 'dropdown',
                isRequired: false,
                space: '20%',
                choices: [
                    {
                        title: i18next.t('url'),
                        value: 'url',
                        default: true
                    },
                    {
                        title: i18next.t('Font-Awesome'),
                        value: 'fontAwesome'
                    },
                    {
                        title: i18next.t('image'),
                        value: 'ImageIcon'
                    }
                ]
            },
            {
                title: i18next.t('custom-icon-url'),
                name: 'customIcon',
                type: 'text',
                isRequired: false,
                space: '20%'
            },
            {
                title: i18next.t('or-upload-image-marker'),
                name: 'file',
                isRequired: false,
                type: 'file',
                maxFiles: 1,
                space: '100% '
            },
            {
                title: i18next.t('video'),
                name: 'iconLabel',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('on-hover-video'),
                name: 'file',
                isRequired: false,
                type: 'file',
                maxFiles: 1,
                space: '100% '
            }
            //isos valoyme kai preview
        ]
    };
};

export default getDescription;
