import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';

import PropertyCard from '../../components/PropertyCard/PropertyCard';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';

import requests from '../../utils/requests';

import formDescription from '../../assets/json/PropertyParts';

import styles from './PropertyPartsPage.module.css';
import { IoAddCircleOutline } from 'react-icons/io5';

const PropertyPartsPage = ({ property, readOnly }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [buildingParts, setBuildingParts] = useState(null);
    const [isDisabledAddModal, setIsDisabledAddModal] = useState(true);
    const formRef = useRef(null);

    useEffect(() => {
        if (!property || !property.building) {
            return;
        }
        console.log(property);
        requests.getBuildingParts(property.building.id).then(({ data }) =>
            setBuildingParts(
                data.map(({ id, name, description, pType, yearOfConstruction, floor }) => ({
                    id: property.id,
                    name,
                    description,
                    pType,
                    yearOfConstruction,
                    floor,
                    propertyId: id,
                    type: property.type,
                    area: property.area,
                    street: property.street,
                    streetNumber: property.streetNumber,
                    longtitude: property.longtitude,
                    latitude: property.latitude,
                    isPublic: property.isPublic,
                    teamId: property.teamId,
                    mainImagePath: property.propertyAssets.file?.path
                }))
            )
        );
    }, [property]);

    useEffect(() => {
        if (isDisabledAddModal || !formRef.current) {
            return;
        }

        requests.getTypesOfBuildingPart().then(({ data }) => {
            formRef.current.getQuestion('propertyPartsCategory').setOptions(
                data.map(type => ({
                    title: t(type),
                    value: type
                }))
            );
        });
    }, [isDisabledAddModal, t]);

    if (!buildingParts) {
        return <LoaderWrapper />;
    }

    const submitPropertyPart = async () => {
        if (!formRef.current || !property || !property.building) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const propertyPartData = {
            type: formRef.current.getData().propertyPartsCategory,
            name: formRef.current.getData().propertyPartName
        };
        const {
            data: { id }
        } = await requests.createBuildingPart(property.building.id, propertyPartData);

        setIsDisabledAddModal(true);
        navigate(`../part/${id}/info`);
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.pageHeader}>
                <h1 className={styles.pageTitle}>{t('property-parts')}</h1>
                {!readOnly && (
                    <button
                        className='btn btn-primary-0 addBtn'
                        onClick={() => setIsDisabledAddModal(false)}
                    >
                        <IoAddCircleOutline /> {t('property-parts')}
                    </button>
                )}
            </div>

            {buildingParts && buildingParts.length === 0 && <p>{t('no-properties-parts-msg')}</p>}

            {buildingParts &&
                buildingParts.map(buildingPart => (
                    <div key={`propertyPart_${buildingPart.id}_${buildingPart.propertyId}`}>
                        <PropertyCard property={buildingPart} path={`../part`} />
                        <br />
                    </div>
                ))}

            {!readOnly && !isDisabledAddModal && (
                <Modal
                    onClose={() => setIsDisabledAddModal(true)}
                    header={t('add-property-parts-header')}
                    content={<Form description={formDescription()} ref={formRef} />}
                    footer={
                        <div className={styles.addPropertyFormFooter}>
                            <button className='btn' onClick={() => setIsDisabledAddModal(true)}>
                                {t('cancel')}
                            </button>
                            <ButtonWithLoading
                                className='btn btn-primary-0'
                                onClick={submitPropertyPart}
                            >
                                {t('create')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
        </div>
    );
};

export default PropertyPartsPage;
