import React from 'react';
import Utils from '../../utils/utils';

import styles from './Background.module.css';

export const Background = React.forwardRef((props, ref) => {
    return (
        <div className={Utils.classes(styles.background, props.className)} ref={ref}>
            {props.children}
        </div>
    );
});
