import { useEffect, useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';
import { DropdownFilter, Table } from 'table';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { UserProfile } from '../../components/profile/userProfile';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import styles from './UserInfoPage.module.css';

export const UserInfoPage = props => {
    const params = useParams();
    const userId = params.userId;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [info, setInfo] = useState(null);
    const [teams, setTeams] = useState(null);
    const [activity, setActivity] = useState(null);

    const fetchAndSetData = useCallback(async () => {
        const res = await Promise.all([
            requests.getUsersTeams(userId, { limit: 5 }),
            requests.getUser(userId),
            requests.getUserActivity(userId, { limit: 5 }),
            requests.getUserTeamsNumber(userId)
        ]);

        const [teams, user, activity] = res.map(r => r.data);

        setTeams(teams.map(transformTeam));
        setInfo(user);
        setActivity(activity.map(transformActivity));
    }, [userId]);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    const TABLE_COLUMNS_ACTIVITY = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: 'date'
            },
            {
                accessor: 'type',
                Header: t('Type'),
                Filter: DropdownFilter
            },
            {
                accessor: 'description',
                Header: t('Description')
            },
            {
                accessor: 'team',
                Header: t('Team'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../../teams/${team.id}/info`)}
                        />
                    );
                }
            }
        ],
        [navigate, t]
    );

    const TABLE_COLUMNS_TEAMS = useMemo(
        () => [
            {
                accessor: 'team',
                Header: t('Team'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../../teams/${team.id}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Manager'),
                accessor: 'role',
                Cell: ({ value: isManager }) => {
                    if (isManager) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },

            {
                Header: t('Member-since'),
                accessor: 'memberSince'
            },
            {
                accessor: 'hasViewAccess',
                Header: t('View-Permission'),
                Cell: ({ value: hasViewAccess }) => {
                    if (hasViewAccess) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },
            {
                accessor: 'hasEditAccess',
                Header: t('Edit-Permission'),
                Cell: ({ value: hasEditAccess }) => {
                    if (hasEditAccess) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },

            {
                Header: t('Creations'),
                accessor: 'propertiesAdded'
            },
            {
                Header: t('Edits'),
                accessor: 'propertiesEdited'
            }
        ],
        [navigate, t]
    );

    function transformActivity(log) {
        return {
            date: `${Utils.timestampToHumanizedDate(
                log.createdAt
            )} - ${Utils.timestampToHumanizedTime(log.createdAt)}`,
            type: t(log.type),
            description: t(log.description),
            team: log.team
        };
    }

    function transformTeam(team) {
        return {
            team: team.team,
            role: team.isManager,
            memberSince: Utils.timestampToHumanizedDate(team.joinedAt),
            hasViewAccess: team.hasViewAccess,
            hasEditAccess: team.hasEditAccess,
            propertiesAdded: team.propertiesAdded,
            propertiesEdited: team.propertiesEdited
        };
    }

    const routeChange = path => {
        navigate(path);
    };

    return (
        <LoaderWrapper>
            <div className={styles.pageWrap}>
                <>
                    {props.permissions.canPreviewActivity && (
                        <div className={styles.section}>
                            <div className={styles.propertiesTitleDiv}>
                                <div className={styles.categoryHeader}>
                                    {t('Most-Recent-Activity')}
                                </div>
                                <div
                                    className={styles.showMore}
                                    onClick={() => routeChange('../activity')}
                                >
                                    {t('Show More')}
                                </div>
                            </div>{' '}
                            <LoaderWrapper>
                                {activity && (
                                    <Table
                                        resizableColumns={false}
                                        sortableColumns={false}
                                        filters={false}
                                        searchBar={false}
                                        pagination={false}
                                        columns={TABLE_COLUMNS_ACTIVITY}
                                        data={activity}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                    )}

                    {props.permissions.canPreviewTeams && (
                        <div className={styles.section}>
                            <div className={styles.propertiesTitleDiv}>
                                <div className={styles.categoryHeader}>{t('Top-Teams')}</div>
                                <div
                                    className={styles.showMore}
                                    onClick={() => routeChange('../teams')}
                                >
                                    {t('Show More')}
                                </div>
                            </div>

                            <LoaderWrapper>
                                {teams && (
                                    <Table
                                        resizableColumns={false}
                                        sortableColumns={false}
                                        filters={false}
                                        searchBar={false}
                                        pagination={false}
                                        columns={TABLE_COLUMNS_TEAMS}
                                        data={teams}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                    )}
                </>
            </div>
        </LoaderWrapper>
    );
};
