import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { IoAddCircleOutline } from 'react-icons/io5';

import { PropertyLeaseCard } from '../../../components/PropertyLeaseCard/PropertyLeaseCard';
import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests, { URI } from '../../../utils/requests';

import leaseDescription from '../../../assets/json/PropertyLease';

import styles from './PropertyEditLeasesPage.module.css';

async function onSubmitLease(buildingId, plotId, leaseId, propertyId, formRef) {
    if (!formRef.current) {
        return false;
    }

    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();
    if (!!sanityCheckRes.length) {
        return false;
    }

    const data = formRef.current.getData();
    const formData = new FormData();
    for (const field in data) {
        if (field === 'contract') {
            continue;
        }
        formData.append(field, data[field]);
    }
    formData.append('propertyId', propertyId);
    if (data.contract instanceof File || !data.contract) {
        formData.append('contract', data.contract);
    } else {
        // NOTE keep the current file
        const { data: _data } = await axios.get(`${URI}/${data.contract.path}`, {
            responseType: 'blob'
        });
        formData.append('contract', new File([_data], data.contract.name));
    }

    if (buildingId) {
        if (!leaseId) {
            return requests.createBuildingLease(buildingId, formData);
        } else {
            return requests.updateBuildingLease(buildingId, leaseId, formData);
        }
    }
    if (plotId) {
        if (!leaseId) {
            return requests.createPlotLease(plotId, formData);
        } else {
            return requests.updatePlotLease(plotId, leaseId, formData);
        }
    }
}

export const PropertyEditLeasesPage = ({ propertyId, buildingId, plotId, navigateBack }) => {
    const { t } = useTranslation();
    const { id: leaseId } = useParams();
    const [data, setData] = useState(null);
    const formRef = useRef(null);

    // if(!id) do not fetch data...
    useEffect(() => {
        if (leaseId) {
            (buildingId
                ? requests.getBuildingLease(buildingId, leaseId)
                : requests.getPlotLease(plotId, leaseId)
            ).then(({ data }) => {
                setData(data);
            });
        }
    }, [buildingId, plotId, leaseId]);

    if (!data && leaseId) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.pageTitle}>
                {leaseId ? t('Edit lease') : t('Create new lease')}
            </h1>

            <div className={styles.formContainer}>
                <Form
                    description={leaseDescription()}
                    initialValue={
                        data && {
                            ...data,
                            contract: data.contract && {
                                name: data.contract.file.name,
                                path: data.contract.file.path
                            }
                        }
                    }
                    ref={formRef}
                />
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>
                        <AiOutlineClose />
                        {t('cancel')}
                    </div>
                </ButtonWithLoading>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={async () => {
                        (await onSubmitLease(buildingId, plotId, leaseId, propertyId, formRef)) &&
                            navigateBack();
                    }}
                >
                    <div className={styles.btnContent}>
                        <AiOutlineCheck />
                        {t('save')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export const PropertyLeasesHistoryPage = ({ isEdit = false, buildingId, plotId }) => {
    const { t } = useTranslation();

    const formRefFilters = useRef(null);
    const formRefSortBy = useRef(null);
    const [propertyLeases, setPropertyLeases] = useState(null);
    const [filteredLeases, setFilteredLeases] = useState(null);
    const [sort, setSort] = useState('NEW');

    const navigate = useNavigate();
    const dateFilterDesc = {
        title: 'dateFilterForm',
        showTitle: false,
        name: 'dateFilterForm',
        type: 'form',
        questions: [
            {
                title: t('Από'),
                name: 'durationFrom',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: t('Έως'),
                name: 'durationTo',
                type: 'date',
                isRequired: false,
                space: '50%'
            }
        ]
    };
    const sortByDesc = {
        title: 'sortByForm',
        showTitle: false,
        name: 'sortByForm',
        type: 'form',
        questions: [
            {
                title: t('ταξινόμηση'),
                name: 'sortBy',
                type: 'dropdown',
                space: '100%',
                value: 'NEW',
                choices: [
                    {
                        title: t('newest'),
                        value: 'NEW'
                    },
                    {
                        title: t('oldest'),
                        value: 'OLD'
                    }
                ]
            }
        ]
    };

    useEffect(() => {
        (buildingId ? requests.getAllBuildingLeases(buildingId) : requests.getAllPlotLeases(plotId))
            .then(({ data }) => {
                data.forEach(c => {
                    c.durationFrom = new Date(c.durationFrom);
                    c.durationTo = new Date(c.durationTo);
                });
                setPropertyLeases(data);
                setFilteredLeases(data);
            })
            .catch(console.log);
    }, [buildingId, plotId]);

    const applyFilters = () => {
        if (!formRefFilters.current) return;
        const { durationFrom: dateFromData, durationTo: dateToData } =
            formRefFilters.current.getData();

        const durationFrom = new Date(dateFromData);
        const durationTo = new Date(dateToData);

        if (dateFromData && !dateToData) {
            const tempRes = propertyLeases.filter(p => new Date(p.durationTo) >= durationFrom);
            setFilteredLeases(tempRes);
        } else if (!dateFromData && dateToData) {
            const tempRes = propertyLeases.filter(p => new Date(p.durationTo) <= durationTo);
            setFilteredLeases(tempRes);
        } else if (dateFromData && dateToData) {
            const tempRes = propertyLeases.filter(
                p =>
                    new Date(p.durationFrom) >= durationFrom && new Date(p.durationTo) <= durationTo
            );
            setFilteredLeases(tempRes);
        } else {
            setFilteredLeases(propertyLeases);
        }
    };

    const applySort = (items = []) => {
        if (sort === 'NEW') {
            return items.sort(function (a, b) {
                return new Date(b.durationFrom) - new Date(a.durationFrom);
            });
        }
        if (sort === 'OLD') {
            return items.sort(function (a, b) {
                return new Date(a.durationFrom) - new Date(b.durationFrom);
            });
        } else {
            return items;
        }
    };

    const handleSortBy = (...args) => {
        const type = args[args.length - 1];
        if (type === 'NEW') {
            setSort('NEW');
        } else if (type === 'OLD') {
            setSort('OLD');
        }
    };
    const clearFilters = () => {
        if (!formRefFilters.current) return;
        formRefFilters.current.clear();
        setFilteredLeases(propertyLeases);
    };
    if (propertyLeases == null) return <LoaderWrapper />;
    return (
        <div className={styles.pageWrapper}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('leases')}</h1>
                {isEdit && (
                    <span
                        className={`btn btn-primary-0 ${styles.buttonContent}`}
                        onClick={() => navigate('./new')}
                    >
                        <IoAddCircleOutline size={'20px'} />
                        <span className={styles.buttonTitle}>{t('Add')}</span>
                    </span>
                )}
            </div>
            <LoaderWrapper>
                {propertyLeases != null && filteredLeases != null ? (
                    <div className={styles.pageInner}>
                        <div className={styles.filterSection}>
                            <div className={styles.formWrapper}>
                                <Form ref={formRefFilters} description={dateFilterDesc} />
                                <div className={styles.btnFilters}>
                                    <ButtonWithLoading
                                        className='btn btn-primary-0'
                                        onClick={applyFilters}
                                    >
                                        <div className={styles.btnContent}>{t('Apply')}</div>
                                    </ButtonWithLoading>
                                </div>
                                <div className={styles.btnFilters}>
                                    <ButtonWithLoading
                                        className='btn btn-error'
                                        onClick={clearFilters}
                                    >
                                        <div className={styles.btnContent}>{t('Clear')}</div>
                                    </ButtonWithLoading>
                                </div>
                            </div>

                            <div className={styles.sortBy}>
                                <Form
                                    ref={formRefSortBy}
                                    description={sortByDesc}
                                    onChange={(...event) => handleSortBy(...event)}
                                />
                            </div>
                        </div>

                        <span className={styles.countLeases}>
                            <b>{filteredLeases.length}</b> {t('leases')}
                        </span>
                        {applySort(filteredLeases).map((lease, i) => (
                            <div className={styles.cardContainer} key={`lCard_${i}`}>
                                <PropertyLeaseCard
                                    id={lease.id}
                                    dateFrom={
                                        lease.durationFrom.getDate() +
                                        '/' +
                                        (lease.durationFrom.getMonth() + 1) +
                                        '/' +
                                        lease.durationFrom.getFullYear()
                                    }
                                    dateTo={
                                        lease.durationTo.getDate() +
                                        '/' +
                                        (lease.durationTo.getMonth() + 1) +
                                        '/' +
                                        lease.durationTo.getFullYear()
                                    }
                                    leaser={{ name: lease.name, afm: lease.afm }}
                                    values={{
                                        val1: lease.leasedProperty,
                                        val2: lease.leasedPropertyPerSM,
                                        val3: lease.paymentType
                                    }}
                                    author={lease.author}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <LoaderWrapper>{t('leases_not_found')}</LoaderWrapper>
                )}
            </LoaderWrapper>
        </div>
    );
};
