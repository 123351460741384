// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VerificationPage_container__0Upyb {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    align-items: flex-start;\n    margin-left: auto;\n    margin-right: auto;\n    gap: 40px;\n\n    width: 100%;\n    max-width: 1200px;\n    padding: 40px;\n    box-sizing: border-box;\n}\n\n.VerificationPage_messageContainer__VMPcx {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n}\n\n.VerificationPage_panel__XI1Px {\n    padding: 40px;\n    background-color: #ffffff;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/defaultPages/VerificationPage/VerificationPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,SAAS;;IAET,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,2CAA2C;IAC3C,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    align-items: flex-start;\n    margin-left: auto;\n    margin-right: auto;\n    gap: 40px;\n\n    width: 100%;\n    max-width: 1200px;\n    padding: 40px;\n    box-sizing: border-box;\n}\n\n.messageContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n}\n\n.panel {\n    padding: 40px;\n    background-color: #ffffff;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VerificationPage_container__0Upyb",
	"messageContainer": "VerificationPage_messageContainer__VMPcx",
	"panel": "VerificationPage_panel__XI1Px"
};
export default ___CSS_LOADER_EXPORT___;
