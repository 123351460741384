import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BiX } from 'react-icons/bi';
import { BsFillPlayCircleFill } from 'react-icons/bs';

import requests, { URI } from '../../utils/requests';

import styles from './PropertyViewVideosPage.module.css';

export const PropertyViewVideosPage = () => {
    const { t } = useTranslation();
    const { propertyId } = useParams();

    const [currentVideoPlaying, setCurrentVideoPlaying] = useState(null);

    const [videos, setVideos] = useState(null);

    const getAndSetData = useCallback(() => {
        requests.getPropertyVideos(propertyId).then(({ data }) => setVideos(data));
    }, [propertyId]);

    useEffect(() => {
        getAndSetData();
    }, [getAndSetData]);

    if (!videos) return null;

    const getExtendedImages = videoEntries => {
        if (videoEntries.length === 0)
            return <div className={styles.emptyPrompt}>{t('no-videos')}</div>;
        return videoEntries.map((ve, i) => (
            <div className={styles.extendedSingleWrap} key={i}>
                {currentVideoPlaying !== ve.id ? (
                    <>
                        <img
                            className={styles.video}
                            onClick={() => setCurrentVideoPlaying(ve.id)}
                            src={`${URI}/${ve.thumbnail.path}`}
                            alt={`${URI}/${ve.thumbnail.path}`}
                        />
                        <BsFillPlayCircleFill
                            className={styles.playButton}
                            onClick={() => setCurrentVideoPlaying(ve.id)}
                        />
                    </>
                ) : (
                    <>
                        <video
                            autoPlay={true}
                            controls={true}
                            className={styles.video}
                            src={`${URI}/${ve.video.path}`}
                        />
                        <BiX
                            onClick={e => {
                                e.stopPropagation();
                                setCurrentVideoPlaying(null);
                            }}
                            className={styles.closeIcon}
                        />
                    </>
                )}
                <div className={styles.videoTitle}>{ve.title}</div>
            </div>
        ));
    };

    const extendedView = () => {
        return (
            <div className={styles.extendedWrap}>
                <div className={styles.extendedVideoWrap}>{getExtendedImages(videos)}</div>
            </div>
        );
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.topBar}>
                <h1>{t('Videos')}</h1>
            </div>
            {extendedView()}
        </div>
    );
};
