// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyFeatureIndicator_indicationWrap__00-bO {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    height: 60px;\n    padding: 0.6rem;\n    gap: 5px;\n    font-size: var(--font-4) !important;\n    color: var(--theme-text) !important;\n    background: var(--theme-bg-20);\n    border-radius: 10px;\n\n    flex: none;\n    order: 0;\n    flex-grow: 1;\n}\n\n.PropertyFeatureIndicator_icon__Ha-OG {\n    color: var(--theme-primary);\n    display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/propertyFeatureIndicator/PropertyFeatureIndicator.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,mCAAmC;IACnC,mCAAmC;IACnC,8BAA8B;IAC9B,mBAAmB;;IAEnB,UAAU;IACV,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,aAAa;AACjB","sourcesContent":[".indicationWrap {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    height: 60px;\n    padding: 0.6rem;\n    gap: 5px;\n    font-size: var(--font-4) !important;\n    color: var(--theme-text) !important;\n    background: var(--theme-bg-20);\n    border-radius: 10px;\n\n    flex: none;\n    order: 0;\n    flex-grow: 1;\n}\n\n.icon {\n    color: var(--theme-primary);\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicationWrap": "PropertyFeatureIndicator_indicationWrap__00-bO",
	"icon": "PropertyFeatureIndicator_icon__Ha-OG"
};
export default ___CSS_LOADER_EXPORT___;
