import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { MdFilterList } from 'react-icons/md';
import Select from 'react-select';

import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';
import { Modal } from '../modal/Modal';
import Pagination from '../pagination/Pagination';

import styles from './PaginatedList.module.css';

// ---------------------------------------------------------------------------------------

const ROWS_PER_PAGE = 10;

// ---------------------------------------------------------------------------------------

export const PaginatedList = React.memo(props => {
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [pageData, setPageData] = useState({
        currentPageRows: [],
        isLoading: true,
        totalRows: 0
    });
    const [sortBy, setSortBy] = useState('id__DESC');
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({});
    const fetch = props.fetch;

    useEffect(() => {
        setPageData(prevState => ({
            ...prevState,
            currentPageRows: [],
            isLoading: true
        }));
        fetch(currentPage, rowsPerPage, { ...filters, ...props.extraFilters }, sortBy).then(
            ({ data: { count: totalRows, rows: currentPageRows } }) => {
                currentPageRows = currentPageRows.map(props.transformEntry);
                setPageData({
                    isLoading: false,
                    currentPageRows,
                    totalRows
                });
                props.setProperties(currentPageRows);
            }
        );
    }, [fetch, sortBy, currentPage, rowsPerPage, filters, props]);

    useEffect(() => {
        setCurrentPage(1);
    }, [filters, props.extraFilters, sortBy]);

    if (pageData.isLoading) return <LoaderWrapper />;

    return (
        <div className={styles.paginatedList}>
            <div className={styles.optionsContainer}>
                <div className={styles.total}>
                    {pageData.totalRows + ' ' + (props.itemName || props.title)}
                </div>
                <Options
                    formRef={props.formRef}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    filtersDescription={props.filtersDescription}
                    filters={filters}
                    setFilters={setFilters}
                />
                {props.extraOptions}
            </div>
            {props.renderList(pageData)}
            <Pagination
                setCurrentPage={setCurrentPage}
                totalRows={pageData.totalRows}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
            />
        </div>
    );
});

export const Options = props => {
    const { t } = useTranslation();
    const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
    const filtersFormRef = useRef(null);

    const rowsPerPageOptions = [
        { value: 10, label: `10 ${t('per page')}` },
        { value: 20, label: `20 ${t('per page')}` },
        { value: 50, label: `50 ${t('per page')}` },
        { value: 100, label: `100 ${t('per page')}` }
    ];

    const sortOptions = [
        { value: 'id__DESC', label: t('Προστέθηκε πρόσφατα') },
        { value: 'updatedAt__DESC', label: t('Ανανεώθηκε πρόσφατα') },
        { value: 'price__ASC', label: t('Τιμή (Χαμηλή προς υψηλή)') },
        { value: 'price__DESC', label: t('Τιμή (Υψηλή προς Χαμηλή)') },
        { value: 'areaSqM__ASC', label: t('Εμβαδό (Χαμηλό προς υψηλό)') },
        { value: 'areaSqM__DESC', label: t('Εμβαδό (Υψηλό προς Χαμηλό)') }
    ];

    const showFilters = () => setIsFiltersModalVisible(true);
    const hideFilters = () => setIsFiltersModalVisible(false);
    const clearFilters = () => {
        if (!filtersFormRef?.current) return;
        filtersFormRef.current.clear();
    };
    const setFilters = () => {
        console.assert(filtersFormRef.current);
        props.setFilters(filtersFormRef.current.getData());
    };
    function renderFiltersModal() {
        if (!isFiltersModalVisible) {
            return null;
        }
        return (
            <Modal
                className={styles.filtersModal}
                header={t('Filters')}
                content={
                    <Form
                        ref={filtersFormRef}
                        initialValue={props.filters}
                        description={props.filtersDescription}
                    />
                }
                onClose={hideFilters}
                footer={
                    <div className={styles.footer}>
                        <ButtonWithLoading className={`btn`} onClick={clearFilters}>
                            {t('Clear')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className={`btn btn-primary-0`} onClick={setFilters}>
                            {t('Apply')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    return (
        <div className={styles.options}>
            <div className={styles.left}>
                <SortInput options={sortOptions} value={props.sortBy} onChange={props.setSortBy} />
            </div>
            <div className={styles.right}>
                <RowsPerPageInput
                    options={rowsPerPageOptions}
                    value={props.rowsPerPage}
                    onChange={props.setRowsPerPage}
                />
                <button className={styles.filterButton} onClick={showFilters}>
                    <MdFilterList />
                    {t('Filters')}
                </button>
                {renderFiltersModal()}
            </div>
        </div>
    );
};

// ---------------------------------------------------------------------------------------

const selectStyles = {
    menu: styles => ({
        ...styles,
        width: 'max-content',
        minWidth: '100%'
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected
            ? 'var(--theme-primary)'
            : isFocused
            ? 'var(--theme-bg-20)'
            : 'var(--theme-bg)',
        color: isSelected ? 'var(--theme-text-over-primary)' : 'var(--theme-text)',
        cursor: isDisabled ? 'not-allowed' : 'pointer'
    })
};

export const SortInput = props => {
    const value = props.options.find(({ value }) => value === props.value);
    const onChange = ({ value }) => props.onChange(value);

    return (
        <Select
            styles={selectStyles}
            value={value}
            options={props.options}
            isSearchable={false}
            onChange={onChange}
        />
    );
};

export const RowsPerPageInput = props => {
    const value = props.options.find(({ value }) => value === props.value);
    const onChange = ({ value }) => props.onChange(value);
    return (
        <Select
            styles={selectStyles}
            value={value}
            options={props.options}
            isSearchable={false}
            onChange={onChange}
        />
    );
};
