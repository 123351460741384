// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityPage_pageWrap__Jg3Ur {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n@media only screen and (max-width: 1000px) {\n    .ActivityPage_pageWrap__Jg3Ur {\n        display: flex;\n        flex-direction: column;\n        padding: 0;\n        width: 100%;\n        /* padding: 0.6rem; */\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/adminPages/ActivityPage.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,UAAU;QACV,WAAW;QACX,qBAAqB;IACzB;AACJ","sourcesContent":[".pageWrap {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n@media only screen and (max-width: 1000px) {\n    .pageWrap {\n        display: flex;\n        flex-direction: column;\n        padding: 0;\n        width: 100%;\n        /* padding: 0.6rem; */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "ActivityPage_pageWrap__Jg3Ur"
};
export default ___CSS_LOADER_EXPORT___;
