// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubTaskModalPreview_contentModalDiv__cCdBf {\n    display: flex;\n    flex-direction: column;\n\n\n    gap: 20px;\n}\n\n.SubTaskModalPreview_title__w9Ube {\n    font-size: var(--font-2);\n    font-weight: 500;\n\n    border-bottom: 1px solid rgb(225, 225, 248);\n    box-shadow: 1px 1px rgb(225, 225, 248);\n    padding: 5px 0px;\n}\n\n.SubTaskModalPreview_subDiv__YVUrl {\n    display: flex;\n    flex-direction: column;\n\n\n    gap: 10px;\n}\n\n.SubTaskModalPreview_subContentDiv__03L-V {\n    display: flex;\n    flex-direction: column;\n\n    gap: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/TaskManagement/SubTaskModalPreview.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;;IAGtB,SAAS;AACb;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;;IAEhB,2CAA2C;IAC3C,sCAAsC;IACtC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;;;IAGtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;;IAEtB,SAAS;AACb","sourcesContent":[".contentModalDiv {\n    display: flex;\n    flex-direction: column;\n\n\n    gap: 20px;\n}\n\n.title {\n    font-size: var(--font-2);\n    font-weight: 500;\n\n    border-bottom: 1px solid rgb(225, 225, 248);\n    box-shadow: 1px 1px rgb(225, 225, 248);\n    padding: 5px 0px;\n}\n\n.subDiv {\n    display: flex;\n    flex-direction: column;\n\n\n    gap: 10px;\n}\n\n.subContentDiv {\n    display: flex;\n    flex-direction: column;\n\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentModalDiv": "SubTaskModalPreview_contentModalDiv__cCdBf",
	"title": "SubTaskModalPreview_title__w9Ube",
	"subDiv": "SubTaskModalPreview_subDiv__YVUrl",
	"subContentDiv": "SubTaskModalPreview_subContentDiv__03L-V"
};
export default ___CSS_LOADER_EXPORT___;
