import i18next from 'i18next';
import ErrorPrompt from 'react-form/dist/Form/form-inputs/ErrorPrompt';
import { Input } from 'react-form/dist/Form/form-inputs/Input';
import Select from 'react-select';

// ---------------------------------------------------------------------------------------

const styles = {
    placeholder: styles => ({
        ...styles,
        color: '#727272'
    }),
    menu: styles => ({
        ...styles,
        backgroundColor: 'var(--theme-bg)'
    }),
    indicatorsContainer: (styles, options) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg)'
    }),
    dropdownIndicator: (styles, options) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg)',
        color: '#727272',
        '&:hover': {
            color: '#545454'
        }
    }),
    control: styles => ({
        ...styles,
        outline: 'none'
    }),
    container: (styles, options) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg)'
    }),
    valueContainer: (styles, options) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg)',
        color: 'var(--theme-text)',
        position: 'static'
    }),
    multiValueLabel: (styles, options) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg-20)',
        color: 'var(--theme-text)'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        width: 'calc(100% - 8px)',
        marginLeft: '4px',
        marginRight: '4px',
        marginTop: '4px',
        marginBottom: '4px',
        borderRadius: '3px',
        backgroundColor: isSelected
            ? 'var(--primary)'
            : isFocused
            ? 'var(--theme-bg-20)'
            : 'var(--theme-bg)',
        color: isSelected ? 'var(--theme-text-over-primary)' : 'var(--theme-text)',
        cursor: isDisabled ? 'not-allowed' : 'pointer'
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        backgroundColor: 'var(--theme-bg-20)',
        color: 'var(--theme-text)',
        cursor: 'pointer'
    })
};

// ---------------------------------------------------------------------------------------

export class MultipleChoiceInput extends Input {
    constructor(props) {
        super(props);

        this.state.value = this.computeValueToStore(this.state.value);

        this.onChange = this.onChange.bind(this);
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.state.choices = props.description.choices || [];
        this.colourStyles = styles;
    }

    onMenuOpen() {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName('MyDropdown__menu')[0];
            if (selectedEl) {
                selectedEl.scrollIntoView({ block: 'nearest', inline: 'nearest' });
            }
        }, 15);
    }

    computeValueToStore(value) {
        switch (this.props.description.dataType) {
            case 'text':
                return value && value.split(',');
            case 'array':
            default:
                return value;
        }
    }

    computeValueToReturn(value) {
        switch (this.props.description.dataType) {
            case 'text':
                return value && value.join(',');
            case 'array':
            default:
                return value;
        }
    }

    setData(data, onFinish) {
        this.onChange(
            {
                target: {
                    value: this.computeValueToStore(data)
                }
            },
            onFinish
        );
    }

    getData() {
        if (this.state.value === '') return null;
        return this.computeValueToReturn(this.state.value);
    }

    valueUpdate(oldValue, val = super.getData()) {
        this.props.onChange(this.props.description.name, val);

        const bindQuestions = this.props.description.bindQuestions;
        if (!bindQuestions) return;

        const getQuestionsToHide = () => {
            if (!oldValue) {
                return [];
            }
            return Object.entries(bindQuestions).flatMap(([value, questions]) =>
                oldValue.includes(value) ? questions : []
            );
        };

        const getQuestionsToShow = () => {
            if (!val) {
                return [];
            }
            return Object.entries(bindQuestions).flatMap(([value, questions]) =>
                val.includes(value) ? questions : []
            );
        };

        const questionsToHide = getQuestionsToHide();
        const questionsToShow = getQuestionsToShow();

        this.applyToQuestions(questionsToHide, 'hideQuestion');
        this.applyToQuestions(questionsToShow, 'showQuestion');
    }

    /* invoked by Input.SetData */
    onChange(e, onFinish) {
        const value = e.target.value;

        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    /* invoked when the end-user changes the data */
    onChangeSelection(e) {
        let value = e.map(i => i.value);

        if (value.length === 0) value = null;
        this.valueUpdate(this.state.value, value);
        this.setState({ value });
    }

    setOptions(choices) {
        const data = this.getData() || [];
        const selectedChoices = data.map(sc => this.state.choices.find(c => c.value === sc));
        this.setState({ choices: [...selectedChoices, ...choices] });
    }

    onInputChange(input) {
        if (
            this.props.description.minInputLengthToFireOnInputChange &&
            input.length < this.props.description.minInputLengthToFireOnInputChange
        ) {
            return;
        }
        const debounceDuration = this.props.description.debounceDuration;
        if (!!debounceDuration) {
            if (this.debounceHandler) {
                clearTimeout(this.debounceHandler);
            }
            this.debounceHandler = setTimeout(() => {
                this.props.description.onInputChange?.(this, input);
            }, debounceDuration);
        } else {
            this.props.description.onInputChange?.(this, input);
        }
    }

    readOnlyRender() {
        const toShow = [];
        for (const val of this.state.value || []) {
            toShow.push(this.state.choices.find(c => c.value === val).title);
        }

        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>
                    {!!toShow.length ? toShow.join(', ') : '-'}
                </div>
            </>
        );
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        const options = this.state.choices.map(choice => ({
            value: choice.value,
            label: choice.title || choice.name
        }));

        const value =
            this.state.value?.map(value => options.find(option => option.value === value)) || [];

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <label
                    style={{ width: '100%' }}
                    aria-label={this.props.description.title || this.props.description.name}
                >
                    <Select
                        components={this.props.description.components}
                        noOptionsMessage={() => i18next.t('Πληκτρολογείστε για να ξεκινήσετε')}
                        isMulti
                        options={options}
                        value={value}
                        styles={this.colourStyles}
                        placeholder={i18next.t('select')}
                        onChange={this.onChangeSelection}
                        onMenuOpen={this.onMenuOpen}
                        isDisabled={this.props.description.isDisabled}
                        className={'MyDropdown'}
                        classNamePrefix={'MyDropdown'}
                        onInputChange={this.onInputChange}
                        theme={theme => ({
                            ...theme,
                            borderRadius: '0.25rem',
                            colors: {
                                ...theme.colors,
                                primary: 'var(--primary)'
                            }
                        })}
                    />
                </label>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}

// ---------------------------------------------------------------------------------------
