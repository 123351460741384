import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import backgroundImg from '../../../assets/images/stats.jpg';
import styles from './StatisticsPage.module.css';
import { TeamStatisticLeases } from '../../teamPages/statisticsPages/TeamStatisticLeases';

export const AdminStatisticLeases = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.pageWrap}>
            <Header title={t('Statistics')} backgroundImg={backgroundImg} position='40% 60%' />
            <div className={styles.subPageWrap}>
                <div className={styles.subPage}>
                    <LoaderWrapper>
                        <TeamStatisticLeases />;
                    </LoaderWrapper>
                </div>
            </div>
        </div>
    );
};
