// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subsystem_container__A1dxk {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    height: 100vh;\n    overflow-y: scroll;\n}\n\n.Subsystem_navbar__IySXD {\n    z-index: 1000;\n    position: sticky;\n    top: 0;\n}\n\n.Subsystem_content__Vzjfh {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n}\n\n@media only screen and (max-width: 1000px) {\n    .Subsystem_container__A1dxk {\n        overflow-y: scroll;\n    }\n\n    .Subsystem_blur__4Dn5f {\n        background: rgba(255, 255, 255, 0.2);\n        -webkit-backdrop-filter: blur(8px);\n                backdrop-filter: blur(8px);\n        /* height: 100vh;\n        width: 50%; */\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/subsystem/Subsystem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,MAAM;AACV;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,oCAAoC;QACpC,kCAA0B;gBAA1B,0BAA0B;QAC1B;qBACa;IACjB;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    height: 100vh;\n    overflow-y: scroll;\n}\n\n.navbar {\n    z-index: 1000;\n    position: sticky;\n    top: 0;\n}\n\n.content {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n}\n\n@media only screen and (max-width: 1000px) {\n    .container {\n        overflow-y: scroll;\n    }\n\n    .blur {\n        background: rgba(255, 255, 255, 0.2);\n        backdrop-filter: blur(8px);\n        /* height: 100vh;\n        width: 50%; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Subsystem_container__A1dxk",
	"navbar": "Subsystem_navbar__IySXD",
	"content": "Subsystem_content__Vzjfh",
	"blur": "Subsystem_blur__4Dn5f"
};
export default ___CSS_LOADER_EXPORT___;
