import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Change-Password'),
        promptForSubmit: 'Επιβεβαίωση',
        questions: [
            {
                title: i18next.t('Old-Password'),
                name: 'oldPassword',
                isPassword: true,
                type: 'text',
                isRequired: true
            },
            {
                title: i18next.t('New-Password'),
                name: 'newPassword',
                isPassword: true,
                type: 'text',
                isRequired: true
            },
            {
                title: i18next.t('Confirm-New-Password'),
                name: 'newPasswordVerify',
                isPassword: true,
                type: 'text',
                isRequired: true
            }
        ]
    };
};

export default getDescription;
