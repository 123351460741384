import React, { useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import PropertyList from '../../components/PropertyList/PropertyList';
import { PaginatedList } from '../../components/paginatedList/PaginatedList';

import requests from '../../utils/requests';

import getDescription from '../../assets/json/PropertyFilters';

// ---------------------------------------------------------------------------------------

export const PaginatedProperties = React.memo(props => {
    const { t } = useTranslation();
    const propertyFiltersDescription = getDescription();
    const [modalIsVisible, setModalIsVisible] = useState();

    const navigate = useNavigate();

    function renderPropertyList(pageData) {
        return (
            <PropertyList
                functionOnHover={props.functionOnHover}
                inTeam={props.inTeam}
                properties={pageData.currentPageRows}
                path={props.path}
                onMouseLeaveProp={props.onMouseLeaveProp}
                guestView={props.guestView}
            />
        );
    }

    function fetch(pageNo, rowsPerPage, filters, sortBy) {
        return requests.getPropertyPage(
            pageNo,
            rowsPerPage,
            { ...filters, ...props.filters },
            sortBy
        );
    }

    const title = t('titleManagePropertiesPage');

    return (
        <>
            <PaginatedList
                title={title}
                itemName={t('titleManagePropertiesPage')}
                fetch={props.fetch || fetch}
                renderList={renderPropertyList}
                filtersDescription={propertyFiltersDescription}
                extraFilters={props.extraFilters}
                setProperties={props.setProperties}
                transformEntry={transformEntry}
                extraOptions={props.extraOptions}
            />
        </>
    );
});

// ---------------------------------------------------------------------------------------

function transformEntry(property) {
    return unflatten(property, '.');
}

function unflatten(object, keySeparator = '_') {
    const unflattenedObject = {};
    for (const [key, value] of Object.entries(object)) {
        const keyParts = key.split(keySeparator);
        if (keyParts.length > 1) {
            let current = unflattenedObject;
            const lastKeyPart = keyParts[keyParts.length - 1];
            for (const p of keyParts) {
                if (p === lastKeyPart) {
                    break;
                }
                if (!current.hasOwnProperty(p)) {
                    current[p] = {};
                }
                current = current[p];
            }
            current[lastKeyPart] = value;
        } else {
            unflattenedObject[key] = value;
        }
    }
    return unflattenedObject;
}
