import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';
import { Table } from 'table';

import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import styles from './UserTeamsPage.module.css';

export const UserTeamsPage = props => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const userId = params.userId;
    const [teams, setTeams] = useState(null);

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                accessor: 'team',
                Header: t('Team'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../../teams/${team.id}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Manager'),
                accessor: 'role',
                Cell: ({ value: isManager }) => {
                    if (isManager) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },

            {
                Header: t('Member-since'),
                accessor: 'memberSince'
            },
            {
                accessor: 'hasViewAccess',
                Header: t('View-Permission'),
                Cell: ({ value: hasViewAccess }) => {
                    if (hasViewAccess) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },
            {
                accessor: 'hasEditAccess',
                Header: t('Edit-Permission'),
                Cell: ({ value: hasEditAccess }) => {
                    if (hasEditAccess) {
                        return <BsCheck size='30px' color='var(--theme-primary)' />;
                    }
                }
            },

            {
                Header: t('Creations'),
                accessor: 'propertiesAdded'
            },
            {
                Header: t('Edits'),
                accessor: 'propertiesEdited'
            }
        ],
        [navigate, t]
    );

    const fetchAndSetData = useCallback(async () => {
        const { data } = await requests.getUsersTeams(userId);
        setTeams(data);
    }, [userId]);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    function transformData(team) {
        return {
            team: team.team,
            role: team.isManager,
            memberSince: Utils.timestampToHumanizedDate(team.joinedAt),
            hasViewAccess: team.hasViewAccess,
            hasEditAccess: team.hasEditAccess,
            propertiesAdded: team.propertiesAdded,
            propertiesEdited: team.propertiesEdited
        };
    }

    return (
        <div className={styles.pageWrap}>
            <Header title={t('Teams')} />

            <LoaderWrapper>
                {teams && (
                    <Table
                        pagination={{ pageSize: 10 }}
                        columns={TABLE_COLUMNS}
                        data={teams.map(transformData)}
                    />
                )}
            </LoaderWrapper>
        </div>
    );
};
