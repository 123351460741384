import { useTranslation } from 'react-i18next';

import { ActionLog } from '../../components/actionLog/ActionLog';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import backgroundImg from '../../assets/images/stat.jpg';

import styles from './ActivityPage.module.css';

export const ActivityPage = props => {
    const { t } = useTranslation();

    return (
        <div className={styles.pageWrap}>
            <Header title={t('Activities')} backgroundImg={backgroundImg} position='10% 30%' />
            <div style={{ padding: '0px 50px' }}>
                <LoaderWrapper>
                    <ActionLog pagination={{ pageSize: 10 }} limit={500} />
                </LoaderWrapper>
            </div>
        </div>
    );
};
