import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import PropertyList from '../../components/PropertyList/PropertyList';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { MyUserProfile } from '../../components/profile/MyUserProfile';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';
import { PreviewTable } from '../../components/table/PreviewTable';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import styles from './MyInfoPage.module.css';

export const MyInfoPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [info, setInfo] = useState(null);
    const [teams, setTeams] = useState(null);
    const [teamsNumber, setTeamsNumber] = useState(null);
    const [activity, setActivity] = useState(null);

    const [properties, setProperties] = useState(null);
    const [propertiesNumber, setPropertiesNumber] = useState(null);

    const [editMode, setEditMode] = useState(false);

    const fetchAndSetData = useCallback(async () => {
        const res = await Promise.all([
            requests.getMyTeams({ limit: 5 }),
            requests.getMyInfo(),
            requests.getMyLogs({ limit: 5 }),
            requests.getMyTeamsNumber(),
            requests.getMyProperties({ limit: 5 }),
            requests.getMyPropertiesNumber()
        ]);

        const [teams, inf, activity, teamsNumber, prop, propNum] = res.map(r => r.data);

        setTeams(teams);
        setInfo(inf);
        setActivity(activity);
        setTeamsNumber(teamsNumber.number);
        setProperties(prop.flatMap(tm => tm.team.properties));
        setPropertiesNumber(propNum.number);
    }, []);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    const updateData = ({ ...data }) => {
        setInfo({ ...info, ...data });
    };

    const createTeamsData = allteams => {
        const result = [];
        allteams?.forEach((team, i) => {
            result.push(
                <div
                    key={i}
                    className={styles.overviewItemWrap}
                    onclick={() => navigate(`../teams/${team.id}/info`)}
                >
                    <SmallPreviewNameImage
                        fullname={team.name}
                        imageSrc={team.image && `${URI}/${team.image?.path}`}
                    />
                    <div>{team.isManager ? t('Manager') : t('simple-member')}</div>
                    <div>{t('x-properties')}</div>
                </div>
            );
        });

        return result;
    };

    const createActivityData = act => {
        const result = [];
        act?.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
        act?.forEach((a, i) => {
            console.log(a);
            if (a.showToUser)
                result.push(
                    <div key={i} className={styles.overviewItemWrap}>
                        <div>{a.description}</div>
                        {a.team && (
                            <SmallPreviewNameImage
                                fullname={a.team.name}
                                imageSrc={a.team.image && `${URI}/${a.team.image?.path}`}
                                // onclick={() => navigate(`../teams/${team.id}/info`)}
                            />
                        )}
                        <div>
                            {Utils.timestampToHumanizedDate(a.createdAt)} -
                            {Utils.timestampToHumanizedTime(a.createdAt)}
                        </div>
                    </div>
                );
        });

        return result;
    };

    const routeChange = () => {
        navigate('../properties');
    };

    return (
        <LoaderWrapper>
            <div className={styles.pageWrap}>
                <div className={styles.profileRow}>
                    <LoaderWrapper>
                        {info && (
                            <MyUserProfile
                                userData={info}
                                updateUserData={updateData}
                                showOtherInfo={val => setEditMode(val)}
                            />
                        )}
                    </LoaderWrapper>
                </div>
                {!editMode && (
                    <>
                        <div className={styles.section}>
                            <LoaderWrapper>
                                {activity && (
                                    <PreviewTable
                                        list={createActivityData(activity)}
                                        header={[t('Description'), t('Team'), t('Date')]}
                                        title={t('Activity')}
                                        more={t('View-all')}
                                        onMoreClick={() => navigate('../activity')}
                                        onEmptyMessage={t('No-recent-activity-found')}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                        <div className={styles.section}>
                            <LoaderWrapper>
                                {teams && teamsNumber >= 0 && (
                                    <PreviewTable
                                        list={createTeamsData(teams)}
                                        header={[t('Team'), t('Manager'), t('Properties')]}
                                        title={t('Teams({{number}})', {
                                            number: teamsNumber
                                        })}
                                        more={t('View-all')}
                                        onMoreClick={() => navigate('../teams')}
                                        onEmptyMessage={t('No-members-found')}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                        <div className={styles.section}>
                            <LoaderWrapper>
                                {properties && propertiesNumber >= 0 && (
                                    <>
                                        <div className={styles.propertiesTitleDiv}>
                                            <div className={styles.propertiesTitle}>
                                                {t('properties') + '(' + propertiesNumber + ')'}
                                            </div>
                                            <div className={styles.showMore} onClick={routeChange}>
                                                {t('Show More')}
                                            </div>
                                        </div>
                                        <div className={styles.propertyListDiv}>
                                            <PropertyList
                                                properties={properties}
                                                path={'../properties/view'}
                                            />
                                        </div>
                                    </>
                                )}
                            </LoaderWrapper>
                        </div>
                    </>
                )}
            </div>
        </LoaderWrapper>
    );
};
