import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import styles from './TeamStatistics.module.css';
import { PlotRenovationsByAttributeType } from '../../../components/statistics/renovations/PlotRenovationsByAttributeType';
import { PlotByPlotBuildingMonth } from '../../../components/statistics/PlotByPlotBuildingMonth';

export const TeamStatisticRenovations = () => {
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const [buildingData, setBuildingData] = useState(null);
    let { teamId: id } = useParams();
    if (id === undefined) {
        id = 3;
    }
    useEffect(() => {
        requests
            .getRenovationsByMonth(id)
            .then(({ data }) => setData(curr => ({ ...curr, renovationByMonth: data })));
        requests
            .getRenovationsByTypeOfRenovation(id)
            .then(({ data }) => setData(curr => ({ ...curr, renovationByTypeOfRenovation: data })));
        requests.getRenovationByBuilding(id).then(({ data }) => setBuildingData(data));
    }, [id]);

    if (buildingData === null || data === null) return <LoaderWrapper />;
    console.log(data);
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <Header title={t('Renovations-Statistics')} />
            </div>
            <div className={styles.wrap}>
                <div className={styles.chartsRow}>
                    <div className={styles.chartWrap}>
                        <h3>{t('New Renovations per property type, this year')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotByPlotBuildingMonth
                                    data={data.renovationByMonth}
                                    requestedYear={new Date().getFullYear()}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Renovations per Renovation type')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotRenovationsByAttributeType
                                    type='doughnut'
                                    data={data.renovationByTypeOfRenovation}
                                    attributeType={'count'}
                                    labelType={'type'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Building Budget by Renovation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotRenovationsByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.name,
                                        value: bd.budget
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Building Estimated cost by Renovation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotRenovationsByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.name,
                                        value: bd.estimatedCost
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
