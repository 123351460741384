import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyDigitalFolderForm'),
        showTitle: false,
        name: 'propertyDigitalFolderForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('display-name'),
                name: 'displayName',
                isRequired: true,
                type: 'text'
            },
            {
                title: i18next.t('category'),
                name: 'category',
                isRequired: true,
                type: 'dropdown',
                choices: [
                    {
                        title: i18next.t('propertyPhoto'),
                        value: 'propertyPhoto'
                    },
                    {
                        title: i18next.t('buildingTitle'),
                        value: 'buildingTitle'
                    },
                    {
                        title: i18next.t('otherTitle'),
                        value: 'otherTitle'
                    },
                    {
                        title: i18next.t('soi'),
                        value: 'soi'
                    },
                    {
                        title: i18next.t('rateTable'),
                        value: 'rateTable'
                    },
                    {
                        title: i18next.t('titlePlan'),
                        value: 'titlePlan'
                    },
                    {
                        title: i18next.t('oaStem'),
                        value: 'oaStem'
                    },
                    {
                        title: i18next.t('oaReview'),
                        value: 'oaReview'
                    },
                    {
                        title: i18next.t('floorPlan'),
                        value: 'floorPlan'
                    },
                    {
                        title: i18next.t('section'),
                        value: 'section'
                    },
                    {
                        title: i18next.t('facet'),
                        value: 'facet'
                    },
                    {
                        title: i18next.t('topographicChart'),
                        value: 'topographicChart'
                    },
                    {
                        title: i18next.t('coverageChart'),
                        value: 'coverageChart'
                    },
                    {
                        title: i18next.t('floorFormwork'),
                        value: 'floorFormwork'
                    },
                    {
                        title: i18next.t('hmPlan'),
                        value: 'hmPlan'
                    },
                    {
                        title: i18next.t('facilityPlan'),
                        value: 'facilityPlan'
                    },
                    {
                        title: i18next.t('certificateOfLegitimacy'),
                        value: 'certificateOfLegitimacy'
                    },
                    {
                        title: i18next.t('technicalReport'),
                        value: 'technicalReport'
                    },
                    {
                        title: i18next.t('legalControl'),
                        value: 'legalControl'
                    },
                    {
                        title: i18next.t('cadastralExcerpt'),
                        value: 'cadastralExcerpt'
                    },
                    {
                        title: i18next.t('updatedTopographicalChart'),
                        value: 'updatedTopographicalChart'
                    },
                    {
                        title: i18next.t('arbitraryRegulationStatement'),
                        value: 'arbitraryRegulationStatement'
                    },
                    {
                        title: i18next.t('technicalReportOfArbitraryRegulation'),
                        value: 'technicalReportOfArbitraryRegulation'
                    },
                    {
                        title: i18next.t('certificateOfIntegrationEngineer'),
                        value: 'certificateOfIntegrationEngineer'
                    },
                    {
                        title: i18next.t('regulationPlans'),
                        value: 'regulationPlans'
                    },
                    {
                        title: i18next.t('energyEfficiencyCertificate'),
                        value: 'energyEfficiencyCertificate'
                    },
                    {
                        title: i18next.t('extractOfBuildingIdentityCardHTC'),
                        value: 'extractOfBuildingIdentityCardHTC'
                    },
                    {
                        title: i18next.t('buildingOccupancyCertificateHTC'),
                        value: 'buildingOccupancyCertificateHTC'
                    },
                    {
                        title: i18next.t('estimate'),
                        value: 'estimate'
                    },
                    {
                        title: i18next.t('leaseConcessionContract'),
                        value: 'leaseConcessionContract'
                    }
                ]
            },
            {
                title: i18next.t('file'),
                name: 'file',
                isRequired: true,
                type: 'file',
                maxFiles: 1
            }
        ]
    };
};

export default getDescription;
