import { useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { BsBrush, BsShieldLock } from 'react-icons/bs';
import { MdOutlineLanguage } from 'react-icons/md';

import { useToasts } from '../../context/withToastMessages/withToastMessages';
import { useUser } from '../../context/withUser';

import { LanguageList } from '../../components/languagePicker/LanguageList';
import { ProfileInfo } from '../../components/profileInfo/ProfileInfo';
import { ThemeList } from '../../components/themeList/ThemeList';

import requests from '../../utils/requests';
import { themes } from '../../utils/themes';

import changePasswordFormDescription from '../../assets/json/ChangePassword.js';

import styles from './SettingsPage.module.css';

export const SettingsPage = () => {
    const { t } = useTranslation();

    const { user } = useUser();
    const addToast = useToasts();
    const changePasswordFormRef = useRef();
    const changePasswordForm = (
        <Form
            ref={changePasswordFormRef}
            // description={Utils.translateFormDescription(t, changePasswordFormDescription)}
            description={changePasswordFormDescription()}
        />
    );
    const settings = [
        {
            name: t('change-language'),
            icon: <MdOutlineLanguage />,
            content: (
                <div className={styles.language}>
                    <LanguageList />
                </div>
            )
        },
        {
            name: t('appearance'),
            icon: <BsBrush />,
            content: (
                <div>
                    <ThemeList themes={themes} />
                </div>
            )
        },
        {
            name: t('change-password'),
            icon: <BsShieldLock />,
            content: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {changePasswordForm}
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button className='btn btn-primary-0' onClick={changePassword}>
                            {t('save')}
                        </button>
                    </div>
                </div>
            )
        }
    ];

    function renderSettings() {
        return settings.map((setting, i) => (
            <div key={`setting_cont_${i}`} id={`setting_cont_${i}`} className={styles.settingArea}>
                <div className={styles.settingSeperator}>{setting.name}</div>
                {setting.content}
            </div>
        ));
    }

    function renderSettingButtons() {
        return settings.map((setting, i) => (
            <div
                className={styles.settingName}
                key={`setting_name_${i}`}
                onClick={() => scrollToSetting(i)}
            >
                {setting.icon} {setting.name}
            </div>
        ));
    }

    function scrollToSetting(i) {
        document.getElementById(`setting_cont_${i}`).scrollIntoView({ behavior: 'smooth' });
    }

    function changePassword() {
        changePasswordFormRef.current.clearSanityCheck();
        const sanity = changePasswordFormRef.current.sanityCheckForm();
        if (!!sanity.length) return;

        const data = changePasswordFormRef.current.getData();
        requests
            .changePassword(data)
            .then(() => {
                addToast({
                    type: 'success',
                    message: t('TODO SUCCESS'),
                    duration: 5000
                });
            })
            .catch(error => {
                addToast({
                    type: 'failure',
                    message: t('TODO ERROR'),
                    duration: 5000
                });
            });
    }

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.leftSide}>
                <ProfileInfo className={styles.userInfo} name={user.name} email={user.email} />
                {renderSettingButtons()}
            </div>

            <div className={styles.rightSide}>{renderSettings()}</div>
        </div>
    );
};
