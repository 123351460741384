import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileAdd, AiOutlineUpload } from 'react-icons/ai';
import { BiDotsVertical, BiImage, BiX } from 'react-icons/bi';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Modal } from '../../components/modal/Modal';
import { Popover } from '../../components/popover/Popover';
import {IoMdAddCircleOutline} from 'react-icons/io';
import requests, { URI } from '../../utils/requests';

import { Carousel } from './Carousel';

import styles from './PropertyEditImagesPage.module.css';
import { IoAddCircleOutline } from 'react-icons/io5';

export const PropertyEditImagesPage = () => {
    const { t } = useTranslation();
    const { propertyId } = useParams();

    const [isAddModalActive, setIsAddModalActive] = useState(false);
    const [isRemoveModalActive, setIsRemoveModalActive] = useState(false);
    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const addToast = useToasts();
    const [images, setImages] = useState(null);

    const formRef = useRef();

    const getAndSetData = useCallback(() => {
        requests.getPropertyAssets(propertyId).then(({ data }) => {
            const im = {
                interior: data.filter(d => d.assetType === 'INTERIOR'),
                exterior: data.filter(d => d.assetType === 'EXTERIOR'),
                floor_plans: data.filter(d => d.assetType === 'FLOOR_PLANS')
            };
            setImages(im);
        });
    }, [propertyId]);

    useEffect(() => {
        getAndSetData();
    }, [getAndSetData]);

    if (!images) return null;

    const setMainImage = async assetId => {
        function updateMainImage(p) {
            p.isMainImage = p.id === assetId;
            return p;
        }

        console.log(assetId);
        await requests.setPropertyAssetsMain(propertyId, assetId);
        const im = {
            interior: images.interior.map(updateMainImage),
            exterior: images.exterior.map(updateMainImage),
            floor_plans: images.floor_plans.map(updateMainImage)
        };
        setImages(im);
    };

    const getExtendedImages = images => {
        if (images.length === 0) return <div className={styles.emptyPrompt}>{t('no-images')}</div>;
        return images.map((im, i) => (
            <div className={styles.extendedSingleWrap} key={i}>
                <img
                    className={styles.image}
                    src={`${URI}/${im.file.path}`}
                    onClick={() => setIsCarouselOpen(im)}
                    alt={`${URI}/${im.file.path}`}
                />
                {im.isMainImage && <div className={styles.mainImagePrompt}>{t('Main Image')}</div>}
                <div className={styles.popoverPlace}>
                    <Popover
                        isVisible={false}
                        trigger={
                            <div className={styles.popoverOpen}>
                                <BiDotsVertical />
                            </div>
                        }
                        content={
                            <div className={styles.popoverContainer}>
                                <div
                                    className={styles.popoverItem}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setMainImage(im.id);
                                    }}
                                >
                                    <BiImage className={styles.popoverIcon} /> {t('Set Main Image')}
                                </div>
                                <div
                                    className={styles.popoverItem}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setIsRemoveModalActive(im);
                                    }}
                                >
                                    <BiX className={styles.removeButton} />
                                    {t('Remove Image')}
                                </div>
                            </div>
                        }
                        placement='bottom left'
                    />
                </div>
            </div>
        ));
    };

    const extendedView = () => {
        return (
            <div className={styles.extendedWrap}>
                <div>
                    <h2 className={styles.subTitle}>{t('propertyΙnterior')}</h2>
                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.interior)}
                    </div>
                </div>
                <div>
                    <h2 className={styles.subTitle}>{t('propertyExterior')}</h2>
                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.exterior)}
                    </div>
                </div>
                <div>
                    <h2 className={styles.subTitle}>{t('propertyFloorPlans')}</h2>

                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.floor_plans)}
                    </div>
                </div>
            </div>
        );
    };

    const description = {
        questions: [
            {
                type: 'file',
                name: 'file',
                title: t('file'),
                isRequired: true
            },
            {
                type: 'dropdown',
                name: 'assetType',
                title: t('assetType'),
                isRequired: true,
                choices: [
                    {
                        title: t('interior'),
                        value: 'INTERIOR'
                    },
                    {
                        title: t('exterior'),
                        value: 'EXTERIOR'
                    },
                    {
                        title: t('floor plans'),
                        value: 'FLOOR_PLANS'
                    }
                ]
            }
        ]
    };

    const uploadImage = async () => {
        formRef.current.clearSanityCheck();

        const sanity = formRef.current.sanityCheckForm();
        if (!!sanity.length) return;

        const data = formRef.current.getData();

        const files = data.file;

        if (!/^image\//.test(files[0].type)) {
            addToast({
                type: 'failure',
                message: t('Only image files are accepted (jpeg, png, jpg..)'),
                duration: 10000
            });
            return;
        }
        const formData = new FormData();

        files.forEach(file => {
            formData.append('file', file);
        });
        formData.append('assetType', data.assetType);

        await requests.addPropertyAssets(propertyId, formData);
        setImages(null);
        setIsAddModalActive(false);
        getAndSetData();
    };

    const addModal = () => {
        if (!isAddModalActive) return null;

        return (
            <Modal
                onClose={() => setIsAddModalActive(false)}
                header={t('add-image')}
                content={
                    <div>
                        <Form description={description} ref={formRef} />
                    </div>
                }
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsAddModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-primary-0' onClick={uploadImage}>
                            {t('Add')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    const removeModal = () => {
        if (!isRemoveModalActive) return null;

        const removeImage = async () => {
            await requests.removePropertyAsset(
                isRemoveModalActive.propertyId,
                isRemoveModalActive.id
            );
            const im = {
                interior: images.interior.filter(it => it.id !== isRemoveModalActive.id),
                exterior: images.exterior.filter(it => it.id !== isRemoveModalActive.id),
                floor_plans: images.floor_plans.filter(it => it.id !== isRemoveModalActive.id)
            };
            setImages(im);
            setIsRemoveModalActive(false);
        };

        return (
            <Modal
                onClose={() => setIsRemoveModalActive(false)}
                header={t('remove-image')}
                content={<div>{t('Are you sure you want to remove the image')}</div>}
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsRemoveModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-error' onClick={removeImage}>
                            {t('remove')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    const carouselModal = () => {
        if (!isCarouselOpen) return null;

        let imagesToUse = null;
        if (isCarouselOpen.assetType === 'INTERIOR') {
            imagesToUse = images.interior;
        } else if (isCarouselOpen.assetType === 'EXTERIOR') {
            imagesToUse = images.exterior;
        } else {
            imagesToUse = images.floor_plans;
        }
        const typeTranslation = {
            INTERIOR: t(`Interion images`),
            EXTERIOR: t(`exterior images`),
            FLOOR_PLANS: t(`floor plan images`)
        };
        return (
            <Modal
                onClose={() => setIsCarouselOpen(false)}
                header={typeTranslation[isCarouselOpen.assetType]}
                content={
                    <Carousel
                        contents={imagesToUse.map(i => `${URI}/${i.file.path}`)}
                        active={`${URI}/${isCarouselOpen.file.path}`}
                    />
                }
            />
        );
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.topBar}>
                <h1>{t('photos')}</h1>
                <button
                    className={`btn btn-primary-0 ${styles.actionBtn}`}
                    onClick={() => setIsAddModalActive(true)}
                >
                    <IoAddCircleOutline />
                    {t('upload')}
                </button>
            </div>

            {carouselModal()}
            {extendedView()}
            {addModal()}
            {removeModal()}
        </div>
    );
};
