import i18next from 'i18next';

const getDescription = () => ({
    questions: [
        {
            name: 'bedroomsLabel',
            title: i18next.t('bedrooms'),
            type: 'label'
        },
        {
            name: 'bedroomsMin',
            title: i18next.t('from'),
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: 'min',
                    value: null
                },
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: '6',
                    value: 6
                },
                {
                    title: '7',
                    value: 7
                },
                {
                    title: '8',
                    value: 8
                },
                {
                    title: '9',
                    value: 9
                },
                {
                    title: '10',
                    value: 10
                },
                {
                    title: '10+',
                    value: 11
                }
            ],
            space: '50%'
        },
        {
            name: 'bedroomsMax',
            title: i18next.t('to'),
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: '6',
                    value: 6
                },
                {
                    title: '7',
                    value: 7
                },
                {
                    title: '8',
                    value: 8
                },
                {
                    title: '9',
                    value: 9
                },
                {
                    title: '10',
                    value: 10
                },
                {
                    title: 'max',
                    value: null
                }
            ],
            space: '50%'
        },
        {
            name: 'bathroomsLabel',
            title: i18next.t('bathrooms'),
            type: 'label'
        },
        {
            name: 'bathroomsMin',
            title: i18next.t('from'),
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: 'min',
                    value: null
                },
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: '5+',
                    value: 6
                }
            ],
            space: '50%'
        },
        {
            name: 'bathroomsMax',
            title: i18next.t('to'),
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: 'max',
                    value: null
                }
            ],
            space: '50%'
        },
        {
            name: 'floorLabel',
            title: i18next.t('floor'),
            type: 'label'
        },
        {
            name: 'floorMin',
            title: i18next.t('from'),
            isRequired: true,
            type: 'dropdown',
            choices: [
                {
                    title: 'min',
                    value: null
                },
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: '5+',
                    value: 6
                }
            ],
            space: '50%'
        },
        {
            name: 'floorMax',
            title: i18next.t('to'),
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: '1',
                    value: 1
                },
                {
                    title: '2',
                    value: 2
                },
                {
                    title: '3',
                    value: 3
                },
                {
                    title: '4',
                    value: 4
                },
                {
                    title: '5',
                    value: 5
                },
                {
                    title: 'max',
                    value: null
                }
            ],
            space: '50%'
        },
        {
            name: 'yearOfConstructionLabel',
            title: i18next.t('yearOfConstruction'),
            type: 'label'
        },
        {
            name: 'yearOfConstructionMin',
            title: i18next.t('from'),
            type: 'date',
            yearOnly: true,
            space: '50%'
        },
        {
            name: 'yearOfConstructionMax',
            title: i18next.t('to'),
            type: 'date',
            yearOnly: true,
            space: '50%'
        },
        {
            name: 'areaLabel',
            title: i18next.t('areaSX'),
            type: 'label'
        },
        {
            name: 'areaMin',
            title: i18next.t('from'),
            type: 'dropdown',
            isRequired: true,
            space: '50%',
            choices: [
                {
                    title: 'Min',
                    value: null
                },
                {
                    title: '50',
                    value: 50
                },
                {
                    title: '60',
                    value: 60
                },
                {
                    title: '70',
                    value: 70
                },
                {
                    title: '85',
                    value: 85
                },
                {
                    title: '100',
                    value: 100
                },
                {
                    title: '120',
                    value: 120
                },
                {
                    title: '150',
                    value: 150
                },
                {
                    title: '200',
                    value: 200
                },
                {
                    title: '250',
                    value: 250
                },
                {
                    title: '300',
                    value: 300
                },
                {
                    title: '400',
                    value: 400
                },
                {
                    title: '500',
                    value: 500
                }
            ]
        },
        {
            name: 'areaMax',
            title: i18next.t('to'),
            type: 'dropdown',
            isRequired: true,
            space: '50%',
            choices: [
                {
                    title: '50',
                    value: 50
                },
                {
                    title: '60',
                    value: 60
                },
                {
                    title: '70',
                    value: 70
                },
                {
                    title: '85',
                    value: 85
                },
                {
                    title: '100',
                    value: 100
                },
                {
                    title: '120',
                    value: 120
                },
                {
                    title: '150',
                    value: 150
                },
                {
                    title: '200',
                    value: 200
                },
                {
                    title: '250',
                    value: 250
                },
                {
                    title: '300',
                    value: 300
                },
                {
                    title: '400',
                    value: 400
                },
                {
                    title: '500',
                    value: 500
                },
                {
                    title: 'Max',
                    value: null
                }
            ]
        },
        {
            name: 'renovationProgress',
            title: `${i18next.t('renovationProgress')} (%)`,
            type: 'unfolded-dropdown',
            choices: [
                {
                    title: '0',
                    value: '0'
                },
                {
                    title: '25',
                    value: '25'
                },
                {
                    title: '50',
                    value: '50'
                },
                {
                    title: '75',
                    value: '75'
                },
                {
                    title: '100',
                    value: '100'
                }
            ]
        },
        {
            name: 'repairProgress',
            title: `${i18next.t('repairProgress')} (%)`,
            type: 'unfolded-dropdown',
            choices: [
                {
                    title: '0',
                    value: '0'
                },
                {
                    title: '25',
                    value: '25'
                },
                {
                    title: '50',
                    value: '50'
                },
                {
                    title: '75',
                    value: '75'
                },
                {
                    title: '100',
                    value: '100'
                }
            ]
        },
        {
            name: 'availableDocuments',
            title: i18next.t('has'),
            type: 'multiselect',
            dataType: 'array',
            choices: [
                {
                    title: i18next.t('estimation'),
                    value: 'estimation'
                },
                {
                    title: i18next.t('schematics'),
                    value: 'TODO'
                }
            ]
        },
        {
            name: 'income',
            title: `${i18next.t('expenses')} ($)`,
            type: 'unfolded-dropdown',
            choices: [
                {
                    title: '0-50',
                    value: '50'
                },
                {
                    title: '51-100',
                    value: '100'
                },
                {
                    title: '101-150',
                    value: '150'
                },
                {
                    title: '151-200',
                    value: '200'
                },
                {
                    title: '200+',
                    value: '-1'
                }
            ]
        }
    ]
});
export default getDescription;
