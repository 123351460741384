import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import locationDescription from '../../assets/json/PropertyLocation';
import greeceRegionsRegionalUnits from '../../assets/json/greece-regions-regional-units';

import styles from './PropertyLocationPage.module.css';

const PropertyLocationPage = ({ property, updatePropertyToDB, readOnly }) => {
    const { t } = useTranslation();
    const [lng, setLng] = useState(null);
    const [lat, setLat] = useState(null);
    const [marker, setMarker] = useState(null);
    const formRef = useRef(null);

    const onLocationChange = (path, val) => {
        if (readOnly) {
            return;
        }

        if (path.includes('propertyLocationMap')) {
            setMarker(val);
        } else if (path.includes('longtitude')) {
            setLng(parseFloat(val));
        } else {
            setLat(parseFloat(val));
        }
    };

    const onRegionChange = val => {
        if (readOnly) {
            return;
        }

        if (!val || !formRef.current) {
            return;
        }

        greeceRegionsRegionalUnits[val] &&
            formRef.current.getQuestion('regionalUnit').setOptions(
                greeceRegionsRegionalUnits[val].map(regionalUnit => ({
                    title: t(regionalUnit),
                    value: regionalUnit
                }))
            );
    };

    const onFormChange = (path, val) => {
        if (readOnly) {
            return;
        }

        if (
            path.includes('longtitude') ||
            path.includes('latitude') ||
            path.includes('propertyLocationMap')
        ) {
            onLocationChange(path, val);
            return;
        }

        if (path.includes('region')) {
            onRegionChange(val);
        }
    };

    const submitInfos = async () => {
        if (readOnly) {
            return;
        }

        if (!formRef.current || !property) {
            return false;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return false;
        }

        const _property = JSON.parse(JSON.stringify(property));
        _property.region = formRef.current.getData().region;
        _property.regionalUnit = formRef.current.getData().regionalUnit;
        // municipality: formRef.current.getData().propertyLocationMunicipality
        // citySection: formRef.current.getData().propertyLocationCitySection
        _property.area = formRef.current.getData().area;
        _property.block = formRef.current.getData().block;
        _property.street = formRef.current.getData().street;
        _property.streetNumber = formRef.current.getData().streetNumber;
        _property.inFront = formRef.current.getData().inFront;
        _property.longtitude = formRef.current.getData().longtitude;
        _property.latitude = formRef.current.getData().latitude;
        // inludedStreet0: formRef.current.getData().propertyLocationInludedStreet0
        // inludedFront0: formRef.current.getData().propertyLocationInludedFront0
        // inludedStreet1: formRef.current.getData().propertyLocationInludedStreet1
        // inludedFront1: formRef.current.getData().propertyLocationInludedFront1

        if (JSON.stringify(property) !== JSON.stringify(_property)) {
            await updatePropertyToDB(property.id, _property);
        }
    };

    useEffect(() => {
        if (!property || !formRef.current) {
            return;
        }

        const { longtitude, latitude } = property;
        // default center -> Athens
        const mapData = [{ lng: longtitude || 23.7275, lat: latitude || 37.9838 }];
        const _property = JSON.parse(JSON.stringify(property));
        if (!readOnly) {
            delete _property.longtitude;
            delete _property.latitude;
        }
        _property.propertyLocationMap = mapData;

        greeceRegionsRegionalUnits[_property.region] &&
            formRef.current.getQuestion('regionalUnit').setOptions(
                greeceRegionsRegionalUnits[_property.region].map(regionalUnit => ({
                    title: t(regionalUnit),
                    value: regionalUnit
                }))
            );

        formRef.current.setData?.(_property);
    }, [property, readOnly, t]);

    useEffect(() => {
        if (!formRef.current || lng === null || Number.isNaN(lng)) {
            return;
        }

        const mapInput = formRef.current.getQuestion?.('propertyLocationMap');
        if (!!!mapInput) {
            return;
        }

        const mapData = mapInput.getData();
        mapInput.setData([{ lng, lat: !!mapData ? mapData[0].lat : 0 }]);
    }, [lng]);

    useEffect(() => {
        if (!formRef.current || lat === null || Number.isNaN(lat)) {
            return;
        }

        const mapInput = formRef.current.getQuestion?.('propertyLocationMap');
        if (!mapInput) {
            return;
        }

        const mapData = mapInput.getData();
        mapInput.setData([{ lng: !!mapData ? mapData[0].lng : 0, lat }]);
    }, [lat]);

    useEffect(() => {
        if (!formRef.current || !marker) {
            return;
        }

        const lngInput = formRef.current.getQuestion?.('longtitude');
        const latInput = formRef.current.getQuestion?.('latitude');
        if (!lngInput || !latInput) {
            return;
        }

        if (marker.length === 1) {
            lngInput.setData(marker[0].lng);
            latInput.setData(marker[0].lat);
        }
    }, [marker]);

    if (!property) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <h1 className={styles.pageTitle}>{t('location')}</h1>
            <Form
                description={locationDescription()}
                onChange={onFormChange}
                ref={formRef}
                readOnly={readOnly}
            />
            {!readOnly && (
                <div className={styles.actionBtnsContainer}>
                    <ButtonWithLoading
                        className={`btn btn-primary-0 ${styles.actionBtn}`}
                        onClick={submitInfos}
                    >
                        <AiOutlineCheck /> {t('save')}
                    </ButtonWithLoading>
                </div>
            )}
        </div>
    );
};

export default PropertyLocationPage;
