import React from 'react';

// --------------------------------------------------------------------------------------
import styles from './LoaderWrapper.module.css';

// --------------------------------------------------------------------------------------

export const Loader = ({ size, thickness }) => {
    const computeStyle = () => {
        const style = {};

        if (size !== undefined) {
            let num = parseFloat(size);

            if (!Number.isNaN(num)) {
                style.fontSize = style.height = num + 'px';
                style.borderWidth = num / 10;
            } else style.fontSize = size;
        }

        if (thickness !== undefined) {
            style.borderWidth = thickness;
        }

        return style;
    };

    return <div className={styles.loader} style={computeStyle()}></div>;
};

// --------------------------------------------------------------------------------------

export const LoaderWrapper = ({ size = 60, thickness = 6, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.children}>{children}</div>
            <Loader size={size} thickness={thickness} />
        </div>
    );
};
