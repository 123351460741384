import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import i18next from 'i18next';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { GenericChart } from '../../../components/chartComponent/GenericChart';
import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import styles from './TeamStatistics.module.css';
import Utils from './Utils';

export const TeamStatisticExpenses = () => {
    const [data, setData] = useState(null);
    const [filteredData, SetFilteredData] = useState(null);
    let { teamId: id } = useParams();
    const formRef = useRef();
    const { t } = useTranslation();
    if (id === undefined) {
        id = 3;
    }
    useEffect(() => {
        requests.getTeamExpenses(id).then(({ data }) => setData(data));
    }, [id]);

    if (!data) {
        return <LoaderWrapper />;
    }

    const description = filterDescription(data);

    const splitInDays = (data = []) => {
        return data.flatMap(d => {
            const res = [];
            const start = new Date(d.dateFrom);
            const end = new Date(d.dateTo);
            const num_of_days = (end.getTime() - start.getTime()) / (1000 * 3600 * 24);

            for (let i = 0; i < num_of_days; i++) {
                // start.setDate(start.getDate() + i);
                const newDate = new Date(start);
                newDate.setDate(start.getDate() + i);
                res.push({
                    ...d,
                    dateFrom: newDate.toISOString(),
                    dateTo: newDate.toISOString(),
                    valueOfPayment: d.valueOfPayment / num_of_days
                });
            }
            return res;
        });
    };

    const applyGroups = {
        groupBy: (value, data) => {
            if (value === 'BUILDING') {
                return groupByBuilding(data);
            } else {
                return data;
            }
        },
        viewBy: (value, data) => {
            return splitInDays(data);
        }
    };

    const applyFilter = {
        building: (value, data) => data.filter(d => d.buildingId === value),
        category: (value, data) => data.filter(d => d.category === value),
        subCategory: (value, data) => data.filter(d => d.expenseSubCategory === value),
        dateFrom: (value, data) => {
            var newData = [];

            const requested_start_from = new Date(value).getTime();

            for (const d in data) {
                const end_at = new Date(data[d].dateTo).getTime();
                const start_from = new Date(data[d].dateFrom).getTime();

                if (end_at < requested_start_from) continue;
                if (start_from >= requested_start_from) newData.push(data[d]);
                else {
                    const diff_in_days = (end_at - start_from) / (1000 * 3600 * 24);
                    const value_per_day = data[d].valueOfPayment / diff_in_days;

                    const new_duration = (end_at - requested_start_from) / (1000 * 3600 * 24);

                    const new_value = new_duration * value_per_day;
                    newData.push({ ...data[d], dateFrom: value, valueOfPayment: new_value });
                }
            }

            return newData;
        },
        dateTo: (value, data) => {
            var newData = [];
            const requested_end_at = new Date(value).getTime();

            for (const d in data) {
                const end_at = new Date(data[d].dateTo).getTime();
                const start_from = new Date(data[d].dateFrom).getTime();

                if (start_from >= requested_end_at) continue;

                if (end_at <= requested_end_at) newData.push(data[d]);
                else {
                    const diff_in_days = (end_at - start_from) / (1000 * 3600 * 24);
                    const value_per_day = data[d].valueOfPayment / diff_in_days;
                    const new_duration = (requested_end_at - start_from) / (1000 * 3600 * 24);

                    const new_value = new_duration * value_per_day;
                    newData.push({ ...data[d], dateTo: value, valueOfPayment: new_value });
                }
            }

            return newData;
        },
        isPaid: (value, data) => data.filter(d => !!d.isPaid === !!value)
    };

    const applyFilters = () => {
        if (!formRef.current) return SetFilteredData(transformData('doughnut', data, {}));
        const options = formRef.current.getData();

        let toReturnData = data;

        for (const [opt, optValue] of Object.entries(options)) {
            if (!applyFilter[opt] || !optValue) continue;
            toReturnData = applyFilter[opt](optValue, toReturnData);
        }

        //

        for (const [opt, optValue] of Object.entries(options)) {
            if (!applyGroups[opt] || !optValue) continue;
            toReturnData = applyGroups[opt](optValue, toReturnData);
        }

        SetFilteredData(
            transformData(options.chartType || 'doughnut', toReturnData, { viewBy: options.viewBy })
        );
    };

    const onFormChange = (fieldName, value) => {
        if (!formRef.current) return;
        if (fieldName === 'filterForm/category') {
            const set = new Set(
                data.filter(d => d.category === value).map(i => i.expenseSubCategory)
            );
            const question = formRef.current.getQuestion('subCategory');
            if (!question) return;

            question.setOptions(
                Array.from(set).map(type => ({
                    title: t(type),
                    value: type
                }))
            );
            question.setData(null);
        }
    };

    const clearFilters = () => {
        formRef.current.clear();
        SetFilteredData(transformData('doughnut', data, {}));
    };

    if (!filteredData) applyFilters();

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <Header title={t('Expenses-Statistics')} />
            </div>
            <div className={styles.row}>
                <div className={styles.filterCol}>
                    <Form description={description} ref={formRef} onChange={onFormChange} />
                    <div className={styles.filterButtons}>
                        <div
                            className='btn btn-primary-0'
                            id={styles.btnAction}
                            onClick={clearFilters}
                        >
                            {t('Clear')}
                        </div>
                        <div
                            className='btn btn-primary-0'
                            id={styles.btnAction}
                            onClick={applyFilters}
                        >
                            {t('Submit')}
                        </div>
                    </div>
                </div>
                <div className={styles.chartCol}>
                    <div className={styles.bigChartWrap}>
                        {filteredData && (
                            <GenericChart
                                type={filteredData.type}
                                data={filteredData.data}
                                labels={filteredData.labels}
                                moreOptions={filteredData.options}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

function filterDescription(data) {
    const description = {
        name: 'filterForm',
        title: i18next.t('filterForm'),
        type: 'form',
        questions: [
            {
                title: i18next.t('Φίλτρα'),
                name: 'Filters',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Τύπος Γραφήματος'),
                name: 'chartType',
                type: 'dropdown',
                isRequired: true,
                space: '100%',
                value: 'doughnut',
                bindQuestions: {
                    line: ['viewBy'],
                    scatter: ['viewBy']
                },
                choices: [
                    { title: i18next.t('Ντόνατ'), value: 'doughnut' },
                    { title: i18next.t('Πίτα'), value: 'pie' },
                    { title: i18next.t('Ραβδόγραμμα'), value: 'bar' }
                    // { title: i18next.t('Σκατερ'), value: 'scatter' }, //only for view by expeses
                    // { title: i18next.t('Γραμμή'), value: 'line' } //only for view By building
                ]
            },
            {
                title: i18next.t('Προβολή ανά'),
                name: 'viewBy',
                type: 'dropdown',
                isRequired: true,
                space: '100%',
                value: 'YEAR',
                placeholder: i18next.t('Select..'),
                choices: [
                    { title: i18next.t('Έτος'), value: 'YEAR' },
                    { title: i18next.t('Μήνας'), value: 'MONTH' }
                ]
            },
            {
                title: i18next.t('Κατηγοριοποίηση'),
                name: 'groupBy',
                type: 'dropdown',
                isRequired: true,
                space: '100%',
                value: 'EXPENSES',
                placeholder: i18next.t('Select..'),

                bindQuestions: {
                    EXPENSES: [
                        'building',
                        'category',
                        'subCategory',
                        'dateFrom',
                        'dateTo',
                        'isPaid'
                    ],
                    BUILDING: ['dateFrom', 'dateTo']
                },
                choices: [
                    { title: i18next.t('Έξοδο'), value: 'EXPENSES' }, //disable line....
                    { title: i18next.t('Κτίριο'), value: 'BUILDING' } //disable scatter....
                ]
            },
            {
                title: i18next.t('building'),
                name: 'building',
                type: 'dropdown',
                isRequired: false,
                placeholder: i18next.t('Select..'),

                space: '100%',
                choices: Utils.getUniqueBuildingIDs(data).map(d => ({
                    value: d.buildingId,
                    name: d.buildingName || data.building.name //FIXME
                }))
            },
            {
                title: i18next.t('Κατηγορία'),
                name: 'category',
                type: 'dropdown',
                isRequired: false,
                placeholder: i18next.t('Select..'),

                space: '50%',
                choices: [
                    { title: i18next.t('CONTRACTS'), value: 'CONTRACTS' },
                    { title: i18next.t('NON-CONTRACT-EXPENSES'), value: 'NON-CONTRACT-EXPENSES' },
                    { title: i18next.t('MAINTENANCE'), value: 'MAINTENANCE' }
                ]
            },
            {
                title: i18next.t('Υπο-κατηγορία'),
                name: 'subCategory',
                type: 'dropdown',
                placeholder: i18next.t('Select..'),

                isRequired: false,
                space: '50%',
                choices: []
            },

            {
                title: i18next.t('Από'),
                name: 'dateFrom',
                type: 'date',
                placeholder: i18next.t('Select..'),
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Έως'),
                name: 'dateTo',
                type: 'date',
                placeholder: i18next.t('Select..'),
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εξοφλημένο'),
                name: 'isPaid',
                type: 'dropdown',
                isRequired: false,
                choices: [
                    { title: i18next.t('Yes'), value: 1 },
                    { title: i18next.t('No'), value: 0 }
                ],
                space: '100%'
            }
        ]
    };

    return description;
}

function groupByBuilding(data = []) {
    const obj = {};

    for (const datum of data) {
        const key = datum.buildingId;

        if (!obj[key]) obj[key] = [datum];
        else obj[key].push(datum);
    }
    return obj;
}

function sumIfGroupedFunction(data, type) {
    if (Array.isArray(data)) {
        return {
            labels: data.map(e => e.nameOfExpense),
            data: data.map(e => e.valueOfPayment),
            type
        };
    }

    const labels = [];
    const d = [];
    for (const [, val] of Object.entries(data)) {
        labels.push(val[0].buildingName || val[0].building.name); //FIXME
        d.push(val.reduce((c, v) => c + v.valueOfPayment, 0));
    }

    return {
        labels,
        data: d,
        type
    };
}

const sumIfGroupedFunction_Time_Value_plots = (data, type, options) => {
    if (Array.isArray(data)) {
        const dset = {};
        data.forEach(d => {
            if (!dset[d.id]) dset[d.id] = [d];
            else {
                dset[d.id].push(d);
            }
        });

        const datasets = [];
        for (const [, val] of Object.entries(dset)) {
            const d = val.map(e => {
                return { x: e.dateFrom, y: e.valueOfPayment };
            });
            datasets.push({ label: val[0].nameOfExpense, data: d });
        }
        return {
            labels: [],
            data: datasets,
            type,
            options
        };
    } else {
        // gia kathe kthrio , gia kathe mera thelw to sum tou kthriou autou
        const datasets = [];
        for (const [, val] of Object.entries(data)) {
            const d = val.map(e => {
                return { x: e.dateFrom, y: e.valueOfPayment };
            });
            console.log('sum days for ', val[0].buildingName || val[0].building.name, d); //FIXME

            const dates = {};
            for (const dd of d) {
                if (!dates[dd.x]) dates[dd.x] = { x: dd.x, y: dd.y };
                else {
                    dates[dd.x].y += dd.y;
                }
            }

            const summedValues = Object.values(dates);
            summedValues.sort((a, b) => new Date(a.x) - new Date(b.x));
            datasets.push({
                label: val[0].buildingName || val[0].building.name,
                data: summedValues
            }); //FIXME
        }

        return {
            labels: [],
            data: datasets,
            type,
            options
        };
    }
};

function transformData(type, data, options) {
    switch (type) {
        case 'doughnut':
            return sumIfGroupedFunction(data, type);
        case 'pie':
            return sumIfGroupedFunction(data, type);
        case 'bar':
            return sumIfGroupedFunction(data, type);

        case 'line':
            return sumIfGroupedFunction_Time_Value_plots(data, type, options);
        case 'scatter':
            return sumIfGroupedFunction_Time_Value_plots(data, type, options);

        default:
            console.log(type, data);
            break;
    }
}
