import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import ProgressBar from '@ramonak/react-progress-bar';
import { useTranslation } from 'react-i18next';
import { AiOutlineUserAdd, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BiFlag } from 'react-icons/bi';
import { BsFlag, BsFlagFill } from 'react-icons/bs';
import { FaRegCalendarPlus } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { MdOutlineAddHome } from 'react-icons/md';

import requests, { URI } from '../../utils/requests';

import { Modal } from '../modal/Modal';
import { PreviewProfileImagesGroup } from '../previewProfileImagesGroup/PreviewProfileImagesGroup';
import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';
import SubTaskModalPreview from './SubTaskModalPreview';

import styles from './SubTaskComponent.module.css';

const ICON_SIZE = 25;

const SubTaskComponent = ({ data, inTeam, inUser }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getPropertiesList = properties => {
        return properties.map(p => {
            return (
                <>
                    <Link
                        to={`${
                            !inTeam
                                ? `../properties/view/${p.id}/info`
                                : `../../properties/view/${p.id}/info`
                        }`}
                    >
                        {p.id}
                    </Link>
                    &nbsp; &nbsp;
                </>
            );
        });
    };

    const getUsersList = users => {
        return (
            <PreviewProfileImagesGroup
                profiles={users}
                header={t('Preview-of-users')}
                redirectToTeam={false}
                inProfile={inTeam ? true : false}
            />
        );
    };

    const getTeamsList = teams => {
        return (
            <PreviewProfileImagesGroup
                request={() => requests.getModalPreviewTeamsFakeData(teams)}
                profiles={teams}
                inProfile={inTeam ? true : false}
                header={t('Preview-of-team')}
                redirectToTeam={true}
            />
        );
    };
    function renderModal() {
        if (!isModalOpen) return null;

        return (
            <Modal
                onClose={() => {
                    setIsModalOpen(false);
                }}
                header={'Εργασία: ' + data.name}
                content={<SubTaskModalPreview data={data} />}
            />
        );
    }

    return (
        <div className={inTeam || inUser ? styles.subTaskMainTeamDiv : styles.subTaskMainDiv}>
            <div className={styles.taskNameDiv} onClick={() => setIsModalOpen(true)}>
                <div
                    className={styles.circle}
                    style={{ backgroundColor: `${data.taskColor}` }}
                ></div>
                <div className={styles.subTaskName}> {data.name} </div>
            </div>
            <div className={styles.subTaskDiv}>
                {data.user === null ? (
                    <AiOutlineUserAdd size={ICON_SIZE} className={styles.icon} />
                ) : Array.isArray(data.user) ? (
                    getUsersList(data.user)
                ) : (
                    <SmallPreviewNameImage
                        fullname={data.user.name}
                        showName={false}
                        imageSrc={`${URI}/${data.user.image.path}`}
                        onClick={() => navigate(`../users/${data.user.email}/info`)}
                    />
                )}
            </div>
            <div className={styles.subTaskDiv}>
                {data.team === null ? (
                    <AiOutlineUsergroupAdd size={ICON_SIZE} className={styles.icon} />
                ) : Array.isArray(data.team) ? (
                    getTeamsList(data.team)
                ) : (
                    <SmallPreviewNameImage
                        fullname={data.team.name}
                        showName={false}
                        imageSrc={`${URI}/${data.user.image.path}`}
                        onClick={() =>
                            navigate(`${inTeam ? '../' : ''}../teams/${data.team.id}/info`)
                        }
                    />
                )}
            </div>

            <div className={styles.subTaskSmallDiv}>
                {data.priorityColor === null ? (
                    <BsFlag size={ICON_SIZE - 5} className={styles.icon} />
                ) : (
                    <BsFlagFill color={`${data.priorityColor}`} size={ICON_SIZE - 5} />
                )}
            </div>

            <div className={styles.subTaskDiv}>
                {data.property === null ? (
                    <MdOutlineAddHome size={ICON_SIZE} className={styles.icon} />
                ) : Array.isArray(data.property) ? (
                    getPropertiesList(data.property)
                ) : (
                    <Link
                        to={`${
                            !inTeam
                                ? `../properties/view/${data.property.id}/info`
                                : `../../properties/view/${data.property.id}/info`
                        }`}
                    >
                        {data.property.id}
                    </Link>
                )}
            </div>

            <div className={styles.subTaskDiv}>
                {
                    <ProgressBar
                        completed={data.progress}
                        bgColor={'var(--theme-primary)'}
                        width='80%'
                        labelSize='12px'
                        labelAlignment='outside'
                        labelColor='var(--theme-primary)'
                    />
                }
            </div>

            <div className={styles.subTaskSmallDiv}>
                {data.dateFrom === null ? (
                    <FaRegCalendarPlus size={ICON_SIZE - 5} className={styles.icon} />
                ) : (
                    data.dateFrom
                )}
            </div>

            <div className={styles.subTaskSmallDiv}>
                {data.dateTo === null ? (
                    <FaRegCalendarPlus size={ICON_SIZE - 5} className={styles.icon} />
                ) : (
                    data.dateTo
                )}
            </div>
            {renderModal()}
        </div>
    );
};

export default SubTaskComponent;
