import React, { useState } from 'react';

import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSLine } from 'react-icons/ri';

import SubTaskComponent from './SubTaskComponent';

import styles from './TaskComponent.module.css';

const TaskComponent = ({ taskName, subtasks, inTeam, inUser }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation();

    return (
        <div
            className={styles.taskComponentDiv}
            style={{ backgroundColor: isVisible ? 'rgb(242 240 240 / 43%)' : '' }}
        >
            <div
                className={styles.taskNameDiv}
                onClick={() => setIsVisible(isVisible => !isVisible)}
            >
                <RiArrowRightSLine
                    className={styles.arrow}
                    style={{
                        transform: isVisible ? 'rotate(90deg)' : 'rotate(0)'
                    }}
                />
                <div className={styles.title}> {taskName} </div>
            </div>
            <Collapse isOpened={isVisible} theme={{ collapse: styles.collapse }}>
                <div className={inTeam || inUser ? styles.rowInformationDivTeam : styles.rowInformationDiv}>
                    <div className={styles.labelNameDiv}>{t('Name')}</div>
                    <div className={styles.infoLabelDiv}>{t('users')}</div>
                    <div className={styles.infoLabelDiv}>{t('teams')}</div>

                    <div className={styles.infoLabelSmallDiv}>{t('Priority')}</div>

                    <div className={styles.infoLabelDiv}>{t('properties')}</div>

                    <div className={styles.infoLabelDiv}>{t('progress')}</div>

                    <div className={styles.infoLabelSmallDiv}>{t('dateFrom')}</div>

                    <div className={styles.infoLabelSmallDiv}>{t('dateTo')}</div>
                </div>
                {Array.isArray(subtasks) ? (
                    subtasks.map((subtask, i) => {
                        return <SubTaskComponent data={subtask} inTeam={inTeam} inUser={inUser} />;
                    })
                ) : (
                    <SubTaskComponent data={subtasks} inTeam={inTeam} inUser={inUser} />
                )}
            </Collapse>
        </div>
    );
};

export default TaskComponent;
