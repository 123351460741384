import React, { useEffect, useState } from 'react';

import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

export const MyOverviewExpensesPage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        requests.getMyExpenses().then(({ data }) => setData(data));
    }, []);

    if (data === null) return <LoaderWrapper />;
    console.log(data);

    return <div>MyOverviewExpenses</div>;
};
