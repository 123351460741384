// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyList_mainComponent__HOdXc {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 72px;\n    max-width: 1200px;\n    margin: auto;\n}\n\n/* tablet */\n@media screen and (max-width: 1200px) {\n    .PropertyList_mainComponent__HOdXc {\n        width: 100%;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 700px) {\n}\n", "",{"version":3,"sources":["webpack://./src/components/PropertyList/PropertyList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,iBAAiB;IACjB,YAAY;AAChB;;AAEA,WAAW;AACX;IACI;QACI,WAAW;IACf;AACJ;;AAEA,UAAU;AACV;AACA","sourcesContent":[".mainComponent {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    padding: 72px;\n    max-width: 1200px;\n    margin: auto;\n}\n\n/* tablet */\n@media screen and (max-width: 1200px) {\n    .mainComponent {\n        width: 100%;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 700px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainComponent": "PropertyList_mainComponent__HOdXc"
};
export default ___CSS_LOADER_EXPORT___;
