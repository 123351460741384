import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useScroll, withScroll } from '../../context/withScroll';

import { Error404Page } from '../../pages/generalPages/Error/Error404Page/Error404Page';
import { SettingsPage } from '../../pages/settingsPage/SettingsPage';

import { Navbar } from '../navbar/Navbar';

import styles from './Subsystem.module.css';

const Subsystem_ = props => {
    const { setScrollContainer } = useScroll();

    function renderRoutes() {
        const computePath = page => page.path + (page.hasNestedRoutes ? '/*' : '');

        return props.pages.flatMap(page => {
            if (!page.links) {
                return (
                    <Route
                        key={page.path}
                        path={computePath(page)}
                        element={React.createElement(page.component, page.props)}
                    />
                );
            } else {
                return page.links.map(page => {
                    return (
                        <Route
                            key={page.path}
                            path={computePath(page)}
                            element={React.createElement(page.component, page.props)}
                        />
                    );
                });
            }
        });
    }

    const setContainer = ref => {
        setScrollContainer(ref);
    };

    return (
        <div ref={setContainer} className={styles.container}>
            <div className={styles.navbar}>
                <Navbar
                    underlyingName={props.underlyingName}
                    links={props.pages.filter(isNavbarLinkPage).map(page => {
                        const calculatePath = page =>
                            `${props.name ? props.name + '/' : ''}${page.path}`;

                        if (page.links) {
                            return {
                                ...page,
                                links: page.links.map(link => ({
                                    ...link,
                                    path: calculatePath(link)
                                })),
                                path: calculatePath(page)
                            };
                        }

                        return {
                            ...page,
                            path: calculatePath(page)
                        };
                    })}
                />
            </div>
            <div className={styles.content}>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <Navigate to={`${props.pages[0].initialPath || props.pages[0].path}`} />
                        }
                    />
                    <Route path='settings' element={<SettingsPage />} />
                    {renderRoutes()}
                    <Route exact path='*' element={<Error404Page />} />
                </Routes>
            </div>
            {/* <div>
                <Footer />
            </div> */}
        </div>
    );
};

// --------------------------------------------------------------------------------------

export const Subsystem = withScroll(Subsystem_);

function isNavbarLinkPage(page) {
    return !!page.icon;
}
