import React, { useState } from 'react';

import styles from './MenuTabs.module.css';

export const MenuTabs = ({ links = [], init = 0, flexCol, onChange, hideTitles = false }) => {
    const [activated, setActivated] = useState(init);
    return (
        <div className={`${styles.menuContainer} ${flexCol ? styles.col : styles.row}`}>
            {links.map((item, index) => (
                <div
                    className={`${styles.menuItem} ${activated === index ? styles.active : ''} ${
                        hideTitles && styles.bubbleIcon
                    }`}
                    title={item.title}
                    key={`MenuTabItem_${index}`}
                    onClick={() => {
                        setActivated(index);
                        onChange?.(index);
                    }}
                >
                    <div style={{ strokeWidth: '5' }}>{item.icon}</div>
                    {!hideTitles && <div>{item.title}</div>}
                </div>
            ))}
        </div>
    );
};
