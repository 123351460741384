export const data = [
    {
        progressStatus: 'Δεν έχει ξεκινήσει',
        taskColor: '#8B939C',
        name: 'Διεργασία 1',
        user: [
            {
                email: 'theodoros.papakonstantinou@example.com',
                isVerified: true,
                verificationToken: '$2b$10$pBRn1PUR/IzUkqF1.mHSAua6eGr47gsEmWUS8s3U3kAO/L6J9yijO',
                verificationTokenExpiration: '1686907664577',
                resetPasswordToken: null,
                resetPasswordTokenExpiration: null,
                name: 'Θεόδωρος Παπακωνσταντίνου',
                isDisabled: false,
                registeredAt: '2023-06-15T09:27:44.000Z',
                lastLogin: '2023-06-15T13:05:23.000Z',
                type: 'MEMBER',
                createdAt: '2023-06-15T09:27:44.000Z',
                updatedAt: '2023-06-15T13:05:23.000Z',
                imageId: 116,
                teamMaps: [
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T09:33:56.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'theodoros.papakonstantinou@example.com',
                        teamId: 3,
                        createdAt: '2023-06-15T09:33:56.000Z',
                        updatedAt: '2023-06-15T09:33:56.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Πληροφορικής',
                            id: 3,
                            image: {
                                path: 'static/uploaded-files/1686831870397-11847100niclas-illg-FnfGRMXENYg-unsplash-min1.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T15:18:08.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'theodoros.papakonstantinou@example.com',
                        teamId: 7,
                        createdAt: '2023-06-15T15:18:08.000Z',
                        updatedAt: '2023-06-15T15:18:08.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Τελωνείων',
                            id: 7,
                            image: {
                                path: 'static/uploaded-files/1686817093371-993306095imre-tomosvari-FbhuN53_330-unsplash-min.jpg'
                            }
                        }
                    }
                ],
                image: {
                    path: 'static/uploaded-files/1686821413819-267262090meghan-holmes-rLrS7_oMd_Q-unsplash-min.jpg'
                }
            },
            {
                email: 'maria.konstantinidou@example.com',
                isVerified: true,
                verificationToken: '$2b$10$E6UjQbGeEcaUaz30P5P/fOBr/TeBQJXYdhbzSwGhtgmmMLT6MLmq2',
                verificationTokenExpiration: '1686749012713',
                resetPasswordToken: null,
                resetPasswordTokenExpiration: null,
                name: 'Μαρία Κωνσταντινίδου',
                isDisabled: false,
                registeredAt: '2023-06-13T13:23:32.000Z',
                lastLogin: '2023-06-13T13:23:32.000Z',
                type: 'MEMBER',
                createdAt: '2023-06-13T13:23:32.000Z',
                updatedAt: '2023-06-15T08:30:43.000Z',
                imageId: 96,
                teamMaps: [
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T09:53:57.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'maria.konstantinidou@example.com',
                        teamId: 3,
                        createdAt: '2023-06-15T09:53:57.000Z',
                        updatedAt: '2023-06-15T09:53:57.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Πληροφορικής',
                            id: 3,
                            image: {
                                path: 'static/uploaded-files/1686831870397-11847100niclas-illg-FnfGRMXENYg-unsplash-min1.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T08:22:30.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'maria.konstantinidou@example.com',
                        teamId: 11,
                        createdAt: '2023-06-15T08:22:30.000Z',
                        updatedAt: '2023-06-15T08:22:30.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Ηρακλείου',
                            id: 11,
                            image: {
                                path: 'static/uploaded-files/1686818334257-564487305heraklion_crop-min.jpg'
                            }
                        }
                    }
                ],
                image: {
                    path: 'static/uploaded-files/1686817843458-904832584woman-ga80c5a90d_1280-min.jpg'
                }
            },
            {
                email: 'yiannis.papadopoulos@example.com',
                isVerified: true,
                verificationToken: '$2b$10$4RKxtwwLQDbOIQludpMm3.pmdo1FKXS5NhxrPUxhgMVqRfEBPX5UW',
                verificationTokenExpiration: '1686747778999',
                resetPasswordToken: null,
                resetPasswordTokenExpiration: null,
                name: 'Γιάννης Παπαδόπουλος',
                isDisabled: false,
                registeredAt: '2023-06-13T13:02:58.000Z',
                lastLogin: '2023-06-13T13:02:58.000Z',
                type: 'ADMIN',
                createdAt: '2023-06-13T13:02:58.000Z',
                updatedAt: '2023-06-15T08:31:44.000Z',
                imageId: 101,
                teamMaps: [
                    {
                        isManager: true,
                        joinedAt: '2023-06-14T13:21:58.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'yiannis.papadopoulos@example.com',
                        teamId: 1,
                        createdAt: '2023-06-14T13:21:58.000Z',
                        updatedAt: '2023-06-14T13:21:58.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Οικονομικό Τμήμα',
                            id: 1,
                            image: {
                                path: 'static/uploaded-files/1686818786323-489099809markus-winkler-IrRbSND5EUc-unsplash-min.jpg'
                            }
                        }
                    },
                    {
                        isManager: true,
                        joinedAt: '2023-06-15T07:53:52.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'yiannis.papadopoulos@example.com',
                        teamId: 6,
                        createdAt: '2023-06-15T07:53:52.000Z',
                        updatedAt: '2023-06-15T07:53:52.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Φορολογίας',
                            id: 6,
                            image: {
                                path: 'static/uploaded-files/1686817081561-806185481olga-delawrence-5616whx5NdQ-unsplash-min.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T08:23:19.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'yiannis.papadopoulos@example.com',
                        teamId: 13,
                        createdAt: '2023-06-15T08:23:19.000Z',
                        updatedAt: '2023-06-15T08:23:19.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Ελεγκτική Υπηρεσία',
                            id: 13,
                            image: {
                                path: 'static/uploaded-files/1686817201262-671855458agence-olloweb-d9ILr-dbEdg-unsplash.jpg'
                            }
                        }
                    }
                ],
                image: {
                    path: 'static/uploaded-files/1686817904357-700307126photo-1472099645785-5658abf4ff4e-min.jpg'
                }
            },
            {
                email: 'alexandros.andreadis@example.com',
                isVerified: true,
                verificationToken: '$2b$10$irjdel0hhYh6HtHcvTLr/eoyr1jMWR/wVTrLtwiI6Vg.A21NaILmy',
                verificationTokenExpiration: '1686747167337',
                resetPasswordToken: null,
                resetPasswordTokenExpiration: null,
                name: 'Αλέξανδρος Ανδρεάδης ',
                isDisabled: false,
                registeredAt: '2023-06-13T12:52:47.000Z',
                lastLogin: '2023-06-15T12:49:27.000Z',
                type: 'ADMIN',
                createdAt: '2023-06-13T12:52:47.000Z',
                updatedAt: '2023-06-15T12:49:27.000Z',
                imageId: 84,
                teamMaps: [
                    {
                        isManager: false,
                        joinedAt: '2023-06-14T12:57:35.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'alexandros.andreadis@example.com',
                        teamId: 3,
                        createdAt: '2023-06-14T12:57:35.000Z',
                        updatedAt: '2023-06-14T12:57:35.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Πληροφορικής',
                            id: 3,
                            image: {
                                path: 'static/uploaded-files/1686831870397-11847100niclas-illg-FnfGRMXENYg-unsplash-min1.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T08:20:34.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'alexandros.andreadis@example.com',
                        teamId: 8,
                        createdAt: '2023-06-15T08:20:34.000Z',
                        updatedAt: '2023-06-15T08:20:34.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Αθηνών',
                            id: 8,
                            image: {
                                path: 'static/uploaded-files/1686818299052-540364244crop_andrea-leopardi-a-lN07O_ZNI-unsplash-min.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T08:25:34.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'alexandros.andreadis@example.com',
                        teamId: 14,
                        createdAt: '2023-06-15T08:25:34.000Z',
                        updatedAt: '2023-06-15T08:25:34.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Εξυπηρέτησης Πολιτών',
                            id: 14,
                            image: {
                                path: 'static/uploaded-files/1686818678018-176183770charanjeet-dhiman-mHusyBu4bxM-unsplash-min.jpg'
                            }
                        }
                    },
                    {
                        isManager: false,
                        joinedAt: '2023-06-15T15:19:21.000Z',
                        canAddNewProperty: false,
                        propertiesAdded: 0,
                        propertiesEdited: 0,
                        hasViewAccess: false,
                        hasEditAccess: false,
                        hasDigitalFolderAccess: false,
                        hasLeaseAccess: false,
                        hasRenovationAccess: false,
                        hasExpensesAccess: false,
                        hasEstimatesAccess: false,
                        hasSuggestionAccess: false,
                        hasMaturityActionsAccess: false,
                        hasDigitalFolderEdit: false,
                        hasLeaseEdit: false,
                        hasRenovationEdit: false,
                        hasExpensesEdit: false,
                        hasEstimatesEdit: false,
                        hasSuggestionEdit: false,
                        hasMaturityActionsEdit: false,
                        userEmail: 'alexandros.andreadis@example.com',
                        teamId: 16,
                        createdAt: '2023-06-15T15:19:21.000Z',
                        updatedAt: '2023-06-15T15:19:21.000Z',
                        inviterEmail: null,
                        team: {
                            name: 'Τμήμα Ρόδου',
                            id: 16,
                            image: {
                                path: 'static/uploaded-files/1686818740616-293066536rhodes_map.png'
                            }
                        }
                    }
                ],
                image: {
                    path: 'static/uploaded-files/1686817309389-106722175sergio-de-paula-c_GmwfHBDzk-unsplash-min.jpg'
                }
            }
        ],
        team: null,
        priorityColor: 'red',
        property: [
            {
                id: 15,
                name: 'Σύγχρονο διαμέρισμα',
                description:
                    'Αυτό το σύγχρονο διαμέρισμα στο κέντρο της Αθήνας είναι μια εκλεπτυσμένη επιλογή για όσους αναζητούν άνεση, λειτουργικότητα και κεντρική τοποθεσία. Το διαμέρισμα προσφέρει μια μοντέρνα ατμόσφαιρα με ευρύχωρους χώρους και εκλεπτυσμένα φινιρίσματα.\n\nΟ ενιαίος χώρος καθιστικού και τραπεζαρίας διαθέτει μεγάλα παράθυρα που επιτρέπουν τη φυσική φωτεινότητα να διαπερνά τον χώρο. Η κουζίνα είναι πλήρως εξοπλισμένη με σύγχρονες συσκευές και λειτουργικότητα για να πραγματοποιήσετε τις κουζινικές σας επιδόσεις. Το διαμέρισμα διαθέτει επίσης ένα άνετο υπνοδωμάτιο με ενσωματωμένες ντουλάπες και ένα πλήρως ανακαινισμένο μπάνιο.',
                pType: 'buildingPart',
                yearOfConstruction: 2015,
                floor: 4,
                areaSqM: 70,
                propertyId: 12,
                type: 'BUILDING',
                area: null,
                street: 'Αγίου Κωνσταντίνου, Αθήνα',
                streetNumber: 36,
                longtitude: 23.7152,
                latitude: 37.9922,
                isPublic: null,
                teamId: 8,
                mainImagePath:
                    'static/uploaded-files/1686908475942-205431442med-badr-chemmaoui-xtDpXi_a-YQ-unsplash-min.jpg',
                price: 0,
                isExposed: 0,
                buildingPart: {
                    apartment: {
                        id: 1
                    }
                }
            },
            {
                id: 7,
                name: 'Πάρκινγκ Πελατών',
                description:
                    'Ο συγκεκριμένος χώρος διατίθεται για να εξυπηρετήσει στη στάθμευση των οχημάτων των πελατών.',
                pType: 'buildingSingle',
                yearOfConstruction: 2019,
                floor: 0,
                areaSqM: 236,
                propertyId: 4,
                type: 'BUILDING',
                area: null,
                street: 'Θουκιδίδου ',
                streetNumber: 50,
                longtitude: 23.7468,
                latitude: 37.9032,
                isPublic: null,
                teamId: 14,
                mainImagePath:
                    'static/uploaded-files/1686814977625-219677753igor-karimov-e9YSIyHC9Ug-unsplash.jpg',
                price: 0,
                isExposed: 0,
                buildingSingle: {
                    parking: {
                        id: 2
                    }
                }
            },
            {
                id: 21,
                name: 'Μοντέρνα Βίλα',
                description:
                    'Αυτή η εκπληκτική μοντέρνα βίλα προσφέρει πολυτέλεια και άνεση σε μια εντυπωσιακή περιβάλλοντα. Με σχεδιασμό που επιτρέπει άφθονο φως και θέα, η βίλα περιλαμβάνει ευρύχωρους χώρους καθιστικού και τραπεζαρίας, πλήρως εξοπλισμένη κουζίνα, μοντέρνα μπάνια και ευρύχωρα υπνοδωμάτια. Ο κήπος και η πισίνα προσφέρουν την ιδανική ατμόσφαιρα για χαλάρωση και ψυχαγωγία. Απολαύστε την πολυτέλεια και την ομορφιά αυτής της μοντέρνας βίλας σε μια από τις πιο όμορφες τοποθεσίες της Καρπάθου.',
                pType: 'buildingSingle',
                yearOfConstruction: 2018,
                floor: 2,
                areaSqM: 220,
                propertyId: 21,
                type: 'BUILDING',
                area: null,
                street: 'Κάρπαθος',
                streetNumber: 7800,
                longtitude: 27.1243,
                latitude: 35.6364,
                isPublic: null,
                teamId: 1,
                mainImagePath:
                    'static/uploaded-files/1686923410782-434264228avi-werde-hHz4yrvxwlA-unsplash.jpg',
                price: 0,
                isExposed: 0,
                buildingSingle: {
                    house: {
                        id: 1
                    }
                }
            }
        ],
        progress: 0,
        dateTo: '12/12/2022 23:59',
        dateFrom: '28/06/2023'
    },
    {
        progressStatus: 'Σε εξέλιξη',
        taskColor: 'var(--theme-primary)',
        name: 'Διεργασία 2',
        user: {
            email: 'alexandros.andreadis@example.com',
            isVerified: true,
            verificationToken: '$2b$10$irjdel0hhYh6HtHcvTLr/eoyr1jMWR/wVTrLtwiI6Vg.A21NaILmy',
            verificationTokenExpiration: '1686747167337',
            resetPasswordToken: null,
            resetPasswordTokenExpiration: null,
            name: 'Αλέξανδρος Ανδρεάδης ',
            isDisabled: false,
            registeredAt: '2023-06-13T12:52:47.000Z',
            lastLogin: '2023-06-15T12:49:27.000Z',
            type: 'ADMIN',
            createdAt: '2023-06-13T12:52:47.000Z',
            updatedAt: '2023-06-15T12:49:27.000Z',
            imageId: 84,
            teamMaps: [
                {
                    isManager: false,
                    joinedAt: '2023-06-14T12:57:35.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'alexandros.andreadis@example.com',
                    teamId: 3,
                    createdAt: '2023-06-14T12:57:35.000Z',
                    updatedAt: '2023-06-14T12:57:35.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Τμήμα Πληροφορικής',
                        id: 3,
                        image: {
                            path: 'static/uploaded-files/1686831870397-11847100niclas-illg-FnfGRMXENYg-unsplash-min1.jpg'
                        }
                    }
                },
                {
                    isManager: false,
                    joinedAt: '2023-06-15T08:20:34.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'alexandros.andreadis@example.com',
                    teamId: 8,
                    createdAt: '2023-06-15T08:20:34.000Z',
                    updatedAt: '2023-06-15T08:20:34.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Τμήμα Αθηνών',
                        id: 8,
                        image: {
                            path: 'static/uploaded-files/1686818299052-540364244crop_andrea-leopardi-a-lN07O_ZNI-unsplash-min.jpg'
                        }
                    }
                },
                {
                    isManager: false,
                    joinedAt: '2023-06-15T08:25:34.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'alexandros.andreadis@example.com',
                    teamId: 14,
                    createdAt: '2023-06-15T08:25:34.000Z',
                    updatedAt: '2023-06-15T08:25:34.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Τμήμα Εξυπηρέτησης Πολιτών',
                        id: 14,
                        image: {
                            path: 'static/uploaded-files/1686818678018-176183770charanjeet-dhiman-mHusyBu4bxM-unsplash-min.jpg'
                        }
                    }
                },
                {
                    isManager: false,
                    joinedAt: '2023-06-15T15:19:21.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'alexandros.andreadis@example.com',
                    teamId: 16,
                    createdAt: '2023-06-15T15:19:21.000Z',
                    updatedAt: '2023-06-15T15:19:21.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Τμήμα Ρόδου',
                        id: 16,
                        image: {
                            path: 'static/uploaded-files/1686818740616-293066536rhodes_map.png'
                        }
                    }
                }
            ],
            image: {
                path: 'static/uploaded-files/1686817309389-106722175sergio-de-paula-c_GmwfHBDzk-unsplash-min.jpg'
            }
        },
        team: null,
        priorityColor: 'orange',
        property: {
            id: 18,
            name: 'Γκαρσονιέρα',
            description:
                'Αυτή η υπέροχη γκαρσονιέρα βρίσκεται στην καρδιά της Θεσσαλονίκης και προσφέρει μια εξαιρετική εμπειρία διαβίωσης στην πόλη. Το διαμέρισμα διαθέτει έναν ευρύχωρο και φωτεινό χώρο καθιστικού με μοντέρνα επίπλωση και μια μεγάλη τηλεόραση. Η θέα από το μπαλκόνι είναι εκπληκτική, προσφέροντας θέα στην πόλη και στα γραφικά σοκάκια της Θεσσαλονίκης. Η τοποθεσία είναι ιδανική, καθώς βρίσκεται κοντά σε καταστήματα, εστιατόρια, μουσεία και αξιοθέατα της πόλης.',
            pType: 'buildingPart',
            yearOfConstruction: 1999,
            floor: 5,
            areaSqM: 30,
            propertyId: 15,
            type: 'BUILDING',
            area: null,
            street: 'Οδός Ερμού, Θεσσαλονίκη',
            streetNumber: 15,
            longtitude: 22.9484,
            latitude: 40.6491,
            isPublic: null,
            teamId: 9,
            mainImagePath:
                'static/uploaded-files/1686914576948-346106684beazy-ABohRftG_Os-unsplash-min.jpg',
            price: 0,
            isExposed: 0,
            buildingPart: {
                apartment: {
                    id: 4
                }
            }
        },
        progress: 50,
        dateTo: '12/12/2022 23:59',
        dateFrom: '20/05/2023'
    },
    {
        progressStatus: 'Σε εξέλιξη',
        taskColor: '#20BF55',
        name: 'Διεργασία 3',
        user: {
            email: 'yiannis.papadopoulos@example.com',
            isVerified: true,
            verificationToken: '$2b$10$4RKxtwwLQDbOIQludpMm3.pmdo1FKXS5NhxrPUxhgMVqRfEBPX5UW',
            verificationTokenExpiration: '1686747778999',
            resetPasswordToken: null,
            resetPasswordTokenExpiration: null,
            name: 'Γιάννης Παπαδόπουλος',
            isDisabled: false,
            registeredAt: '2023-06-13T13:02:58.000Z',
            lastLogin: '2023-06-13T13:02:58.000Z',
            type: 'ADMIN',
            createdAt: '2023-06-13T13:02:58.000Z',
            updatedAt: '2023-06-15T08:31:44.000Z',
            imageId: 101,
            teamMaps: [
                {
                    isManager: true,
                    joinedAt: '2023-06-14T13:21:58.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'yiannis.papadopoulos@example.com',
                    teamId: 1,
                    createdAt: '2023-06-14T13:21:58.000Z',
                    updatedAt: '2023-06-14T13:21:58.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Οικονομικό Τμήμα',
                        id: 1,
                        image: {
                            path: 'static/uploaded-files/1686818786323-489099809markus-winkler-IrRbSND5EUc-unsplash-min.jpg'
                        }
                    }
                },
                {
                    isManager: true,
                    joinedAt: '2023-06-15T07:53:52.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'yiannis.papadopoulos@example.com',
                    teamId: 6,
                    createdAt: '2023-06-15T07:53:52.000Z',
                    updatedAt: '2023-06-15T07:53:52.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Τμήμα Φορολογίας',
                        id: 6,
                        image: {
                            path: 'static/uploaded-files/1686817081561-806185481olga-delawrence-5616whx5NdQ-unsplash-min.jpg'
                        }
                    }
                },
                {
                    isManager: false,
                    joinedAt: '2023-06-15T08:23:19.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'yiannis.papadopoulos@example.com',
                    teamId: 13,
                    createdAt: '2023-06-15T08:23:19.000Z',
                    updatedAt: '2023-06-15T08:23:19.000Z',
                    inviterEmail: null,
                    team: {
                        name: 'Ελεγκτική Υπηρεσία',
                        id: 13,
                        image: {
                            path: 'static/uploaded-files/1686817201262-671855458agence-olloweb-d9ILr-dbEdg-unsplash.jpg'
                        }
                    }
                }
            ],
            image: {
                path: 'static/uploaded-files/1686817904357-700307126photo-1472099645785-5658abf4ff4e-min.jpg'
            }
        },
        team: {
            id: 17,
            name: 'Τμήμα Αθλητισμού',
            description:
                'Στο Τμήμα Αθλητισμού έχουμε ως όραμα την ένωση του Σώματος, της Ψυχής και του Πνεύματος. Η άσκηση οδηγεί προς την Αυτογνωσία, την Αρμονία και την Ομορφιά.',
            isActive: true,
            activeUntil: null,
            maxMembers: null,
            maxProperties: null,
            maxManagers: null,
            createdAt: '2023-06-16T14:19:24.000Z',
            updatedAt: '2023-06-16T14:39:11.000Z',
            acceptedById: 'test@test.com',
            initiatedBy: null,
            imageId: 175,
            teamMaps: [
                {
                    isManager: true,
                    joinedAt: '2023-06-16T14:21:29.000Z',
                    canAddNewProperty: false,
                    propertiesAdded: 0,
                    propertiesEdited: 0,
                    hasViewAccess: false,
                    hasEditAccess: false,
                    hasDigitalFolderAccess: false,
                    hasLeaseAccess: false,
                    hasRenovationAccess: false,
                    hasExpensesAccess: false,
                    hasEstimatesAccess: false,
                    hasSuggestionAccess: false,
                    hasMaturityActionsAccess: false,
                    hasDigitalFolderEdit: false,
                    hasLeaseEdit: false,
                    hasRenovationEdit: false,
                    hasExpensesEdit: false,
                    hasEstimatesEdit: false,
                    hasSuggestionEdit: false,
                    hasMaturityActionsEdit: false,
                    userEmail: 'panagiotis.karagiorgos@example.com',
                    teamId: 17,
                    createdAt: '2023-06-16T14:21:29.000Z',
                    updatedAt: '2023-06-16T14:21:29.000Z',
                    inviterEmail: null,
                    user: {
                        email: 'panagiotis.karagiorgos@example.com',
                        name: 'Παναγιώτης Καραγιώργος',
                        image: {
                            path: 'static/uploaded-files/1686817882740-686910511angelo-pantazis-_bmROQSJJG0-unsplash-min.jpg'
                        }
                    }
                }
            ],
            image: {
                path: 'static/uploaded-files/1686926350845-145513487milosz-sakowski-Upujzc1TNVA-unsplash.jpg'
            }
        },
        priorityColor: null,
        property: {
            id: 2,
            name: 'Τεχνολογικό Κέντρο',
            description:
                'Ένα σύγχρονο κτίριο εξοπλισμένο με προηγμένες τεχνολογικές υποδομές για την υποστήριξη των πληροφοριακών συστημάτων.\n',
            pType: 'buildingMulti',
            yearOfConstruction: 2004,
            floor: 0,
            areaSqM: 2000,
            propertyId: 2,
            type: 'BUILDING',
            area: null,
            street: 'Ευήνου',
            streetNumber: 9,
            longtitude: 23.5928,
            latitude: 38.1477,
            isPublic: null,
            teamId: 3,
            mainImagePath:
                'static/uploaded-files/1686827568524-886755443omar-flores-xkoegrJfrBU-unsplash-min.jpg',
            price: 0,
            isExposed: 0,
            buildingMulti: {
                blockOfFlat: {
                    id: 1
                }
            }
        },
        progress: 100,
        dateTo: '20/05/2023 13:09',
        dateFrom: '27/05/2023 23:59'
    }
];
