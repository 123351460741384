import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import requests, { setAuthHeaders } from '../utils/requests';

export const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export function UserWrapper({ children }) {
    const [user, setUser] = useState(null);
    const [working, setWorking] = useState(true);
    const timeoutId = useRef();

    const refreshToken = useCallback(async () => {
        try {
            const res = await requests.refreshToken();
            const { token, ...user_ } = res.data;
            setAuthHeaders(token);
            if (!user) {
                setUser(user_);
            }
        } catch (err) {
            // TODO: handle
        }
        setWorking(false);
    }, [user]);
    //To  DO  FUTURE WORK
    const setRefreshTimeout = useCallback(() => {
        if (user) timeoutId.current = setTimeout(refreshToken, 60 * 30 * 1000 - 1000);
    }, [user, refreshToken]);

    useEffect(setRefreshTimeout, [setRefreshTimeout]);

    useEffect(() => {
        refreshToken();

        return () => {
            if (timeoutId.current !== undefined) clearTimeout(timeoutId.current);
        };
    }, [refreshToken]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {working ? null : children}
        </UserContext.Provider>
    );
}
