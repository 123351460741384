import React from 'react';

import styles from './Avatar.module.css';

function cropName(name) {
    const splited = name?.split(/\s+/);

    let result = '';

    for (const w of splited.slice(0, 3)) {
        result += w.charAt(0).toUpperCase() + '.';
    }
    return result;
}

export const Avatar = ({ title, imageSrc }) => {
    return (
        <div className={styles.wrap}>
            {imageSrc && (
                <div
                    className={styles.image}
                    style={{
                        backgroundImage: `url(${imageSrc})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                ></div>
            )}
            {!imageSrc && <div className={styles.imageDefault}>{cropName(title)}</div>}
        </div>
    );
};
