// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserActivityPage_pageWrap__rEBdv {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/userProfilePages/UserActivityPage.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".pageWrap {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "UserActivityPage_pageWrap__rEBdv"
};
export default ___CSS_LOADER_EXPORT___;
