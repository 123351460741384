import { useTranslation } from 'react-i18next';
import { AiOutlineHome, AiOutlinePieChart, AiOutlineTeam } from 'react-icons/ai';
import { IoMdPulse } from 'react-icons/io';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { RiUserSettingsLine } from 'react-icons/ri';

import { useUser } from '../../context/withUser';

import { Subsystem } from '../../components/subsystem/Subsystem';

import { ActivityPage } from '../adminPages/ActivityPage';
import { AdminHomePage } from '../adminPages/AdminHomePage';
import { ManagePropertiesPage } from '../adminPages/ManagePropertiesPage';
import { ManageTeamsPage } from '../adminPages/ManageTeamsPage';
import { ManageUsersPage } from '../adminPages/ManageUsersPage';
import PropertiesMapEdit from '../adminPages/PropertiesMapEdit';
import { Error403Page } from '../generalPages/Error/Error403Page/Error403Page';
import ManagementPage from '../generalPages/ManagementPage';
import { TeamProfilePage } from '../generalPages/TeamProfilePage';
import { UserProfilePage } from '../generalPages/UserProfilePage';
import PropertyEditPage from '../propertyPages/PropertyEditPage';
import PropertyPartViewPage from '../propertyPages/PropertyPartViewPage';
import PropertyPartsEditPage from '../propertyPages/PropertyPartsEditPage';
import PropertyViewPage from '../propertyPages/PropertyViewPage';

import { BiLineChart } from 'react-icons/bi';
import { BsBarChartLine } from 'react-icons/bs';
import { AdminStatisticsPage } from '../adminPages/statisticsPages/AdminStatisticsPage';
import { AdminStatisticExpenses } from '../adminPages/statisticsPages/AdminStatisticExpenses';
import { AdminStatisticEstimates } from '../adminPages/statisticsPages/AdminStatisticEstimates';
import { AdminStatisticLeases } from '../adminPages/statisticsPages/AdminStatisticLeases';
import { AdminStatisticRenovations } from '../adminPages/statisticsPages/AdminStatisticRenovations';
import TaskManagementPage from '../taskManagementPages/TaskManagementPage';


import { BsListTask } from 'react-icons/bs';
// --------------------------------------------------------------------------------------

export const AdminSubsystem = props => {
    const { user } = useUser();
    const { t } = useTranslation();

    /**
     *
     * admin can access all pages and has all the permissions
     *
     */
    const Pages = [
        {
            title: t('Homepage'),
            path: 'home',
            component: () => <AdminHomePage />,
            icon: AiOutlineHome
        },
        {
            title: t('Properties'),
            path: 'properties',
            component: () => <ManagePropertiesPage />,
            icon: MdOutlineMapsHomeWork
        },
        {
            title: t('Structure'),
            icon: RiUserSettingsLine,
            links: [
                {
                    title: t('Users'),
                    path: 'users',
                    component: () => <ManageUsersPage />,
                    icon: RiUserSettingsLine
                },
                {
                    title: t('Teams'),
                    path: 'teams',
                    component: () => <ManageTeamsPage />,
                    icon: AiOutlineTeam
                }
            ]
        },
        {
            title: t('Monitoring'),
            icon: BiLineChart,
            links: [
                {
                    title: t('Activity'),
                    path: 'activity',
                    component: () => <ActivityPage />,
                    icon: IoMdPulse
                },
                {
                    title: t('Statistics'),
                    path: 'statistics',
                    component: () => <AdminStatisticsPage />,
                    icon: BsBarChartLine
                },
                {
                    path: 'statistics/expenses',
                    component: () => <AdminStatisticExpenses />,
                    hasNestedRoutes: true
                },
                {
                    path: 'statistics/estimates',
                    component: () => <AdminStatisticEstimates />,
                    hasNestedRoutes: true
                },
                {
                    path: 'statistics/leases',
                    component: () => <AdminStatisticLeases />,
                    hasNestedRoutes: true
                },
                {
                    path: 'statistics/renovations',
                    component: () => <AdminStatisticRenovations />,
                    hasNestedRoutes: true
                }
            ]
        },

        {
            path: 'users/:userId',
            component: () => <UserProfilePage />,
            hasNestedRoutes: true
        },

        {
            path: 'management',
            component: () => <ManagementPage />
        },
        {
            path: 'teams/:teamId',
            component: () => <TeamProfilePage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/edit/:propertyId',
            component: () => <PropertyEditPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/edit/:propertyId/part/:propertyPartId',
            component: () => <PropertyPartsEditPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/view/:propertyId',
            component: () => <PropertyViewPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/view/:propertyId/part/:propertyPartId',
            component: () => <PropertyPartViewPage />,
            hasNestedRoutes: true
        },
        {
            title: t('task management'),
            path: 'tasks',
            component: () => <TaskManagementPage inTeam={false} />,
            icon: BsListTask
        }
        // {
        //     path: '/properties/map-edit',
        //     component: () => <PropertiesMapEdit />,
        //     hasNestedRoutes: true
        // }
    ];

    if (user?.type !== 'ADMIN') return <Error403Page />;

    return <Subsystem name='a' pages={Pages} />;
};
