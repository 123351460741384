import React from 'react';

import { useTranslation } from 'react-i18next';

import { LoaderWrapper } from '../../loaderWrapper/LoaderWrapper';
import { generateNColors } from '../helpers/ColorGenerator';
import { PlotBar } from '../helpers/PlotBar';
import { PlotDoughnut } from '../helpers/PlotDoughnut';
import { PlotPie } from '../helpers/PlotPie';

import styles from '../StatisticsStyles.module.css';

export const PlotEstimatesByAttributeType = ({ type = 'bar', data, attributeType, labelType }) => {
    const { t } = useTranslation();

    if (data == null) return <LoaderWrapper />;
    if (data.length === 0) return <div className={styles.invalidBox}>{t('No data Found!')}</div>;

    const dataToPlot = data.map(item => item[attributeType]);
    const sum = dataToPlot.reduce((a, b) => a + b, 0);
    if (sum === 0) {
        return <div className={styles.invalidBox}>{t('No data Found!')}</div>;
    }
    const data_ = {
        labels: data.map(item => t(item[labelType]) || item[labelType]),
        datasets: [
            {
                data: dataToPlot,
                backgroundColor: generateNColors(dataToPlot.length)
            }
        ]
    };
    if (type === 'pie') {
        return <PlotPie data={data_} />;
    } else if (type === 'bar') {
        return <PlotBar data={data_} />;
    } else if (type === 'doughnut') {
        return <PlotDoughnut data={data_} />;
    } else {
        return null;
    }
};
