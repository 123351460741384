// ---------------------------------------------------------------------------------------
import { AiFillCheckCircle } from 'react-icons/ai';

import Utilities from '../../utils/utils';

// ---------------------------------------------------------------------------------------
import styles from './ThemePreview.module.css';

// ---------------------------------------------------------------------------------------

export const ThemePreview = props => {
    const activeClassName = props.isActive ? styles.active : '';

    const applyTheme = () => {
        props.setCurrentTheme(props.theme);
    };

    return (
        <div className={Utilities.classes(`.${props.theme}`, styles.themePreview, activeClassName)}>
            <button
                className={Utilities.classes(props.theme, styles.themePreviewButton)}
                onClick={applyTheme}
            >
                <div className={styles.panel}>
                    <span className={styles.text}> Aa </span>
                </div>
                <AiFillCheckCircle className={styles.isActiveIcon} />
            </button>
            <span className={styles.themeTitle}>{props.theme}</span>
        </div>
    );
};
