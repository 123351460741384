import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { Table } from 'table';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { PreviewProfileImagesGroup } from '../../components/previewProfileImagesGroup/PreviewProfileImagesGroup';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import backgroundImg from '../../assets/images/teams-header.jpg';
import input_desc from '../../assets/json/AddTeam.js';

import styles from './ManageUsersPage.module.css';

const postTeam = async (teamName, description, image) => {
    const teamData = {};
    teamData.name = teamName;
    teamData.description = description;
    teamData.image = image;
    const res = await requests.postTeam(teamData);
    return res;
};

const TeamNameCell = ({ row }) => {
    const navigate = useNavigate();

    const { team } = row.original;

    return (
        <SmallPreviewNameImage
            imageSrc={team.image && `${URI}/${team.image.path}`}
            fullname={team.name}
            onClick={() => navigate(`../teams/${team.id}/info`)}
            request={() => requests.getTeam(team.id)}
        />
    );
};

const TeamMembersCell = ({ row }) => {
    const team = row.original.team;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const transformDataForPreviewRender = useCallback(
        data => {
            if (!data) return null;

            const users = data.teamMaps.map(t => t.user);

            return users.map((user, i) => {
                return (
                    <div key={i} className={styles.overviewItemWrap}>
                        <div className={styles.overviewItemStart}>
                            <SmallPreviewNameImage
                                fullname={user.name}
                                imageSrc={user.image && `${URI}/${user.image.path}`}
                                onClick={() => navigate(`../users/${user.email}/info`)}
                            />
                        </div>
                        <div className={styles.overviewItem}>
                            {user.isManager ? t('manager') : t('Member')}
                        </div>
                    </div>
                );
            });
        },
        [navigate, t]
    );

    const getUsers = (users = []) => users.map(u => u.user);

    if (!team) return null;

    return (
        <PreviewProfileImagesGroup
            profiles={getUsers(team.teamMaps)}
            request={() => requests.getTeamFull(team.id)}
            header={t('Preview-of-members')}
            transformDataToRender={transformDataForPreviewRender}
        />
    );
};

export const ManageTeamsPage = props => {
    const navigate = useNavigate();
    const formRef = useRef();
    const { t } = useTranslation();
    const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
    const [teams, setTeams] = useState(null);

    const addToast = useToasts();

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'name',
                Cell: TeamNameCell
            },
            {
                Header: t('ID'),
                accessor: 'id'
            },
            {
                Header: t('Creation-Date'),
                accessor: 'createdAt'
            },
            {
                Header: t('Number-of-Members'),
                accessor: 'numberOfMembers'
            },
            {
                Header: t('Members'),
                accessor: 'members',
                Cell: TeamMembersCell
            },
            {
                Header: t('Status'),
                accessor: 'status'
            }
        ],
        [navigate, t]
    );

    const fetchAndSetData = useCallback(async () => {
        const { data } = await requests.getAllTeams();
        setTeams(data);
    }, []);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    async function onSuccessfulSubmit() {
        fetchAndSetData();
        setIsDisabledAddModal(false);
    }

    async function submitTeam() {
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }
        const teamName = formRef.current.getData().teamName;
        const descr = formRef.current.getData().teamDescription;

        try {
            if (teamName.length >= 100) {
                addToast({
                    type: 'failure',
                    message: t(
                        'The provided team name is too long to be accepted. Please choose a shorter team name and try again.'
                    ),
                    duration: 3000
                });
                return;
            } else if (teamName.description >= 255) {
                addToast({
                    type: 'failure',
                    message: t(
                        'The provided team description is too long to be accepted. Please choose a shorter team description and try again.'
                    ),
                    duration: 3000
                });
                return;
            }

            await postTeam(teamName, descr);

            onSuccessfulSubmit();
            addToast({
                type: 'success',
                message: t('Team {{name}} added successfully', { name: teamName }),
                duration: 3000
            });
        } catch (e) {
            console.log(e);
        }
    }

    function renderAddModal() {
        if (!isDisableAddModal) return null;

        return (
            <Modal
                onClose={() => {
                    setIsDisabledAddModal(false);
                }}
                header={t('Add-new-Team')}
                content={
                    <div>
                        <Form description={input_desc()} ref={formRef} />
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledAddModal(false);
                            }}
                        >
                            {t('Reject', 'Απόρριψη')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => submitTeam()}
                        >
                            {t('Confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const transformData = (data = []) => {
        if (!data || !data.length) return [];

        return data.map(team => {
            return {
                name: team.name,
                id: team.id,
                createdAt: `${Utils.timestampToHumanizedDate(
                    team.createdAt
                )} - ${Utils.timestampToHumanizedTime(team.createdAt)}`,
                numberOfMembers: team.teamMaps.length,
                members: team.teamMaps.map(tm => tm.user.name).join(', '),
                status: team.isActive ? t('Active') : t('Inactive'),
                team: team
            };
        });
    };

    const renderTeams = () => {
        const data = transformData(teams);
        return (
            <div style={{ padding: '0px 50px' }}>
                <Table pagination={{ pageSize: 10 }} columns={TABLE_COLUMNS} data={data} />
            </div>
        );
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('Teams')} backgroundImg={backgroundImg}>
                    {teams && (
                        <span
                            className={`btn btn-primary-0 ${styles.addButton}`}
                            onClick={() => setIsDisabledAddModal(true)}
                        >
                            <AiOutlineUsergroupAdd size={'20px'} />
                            <span className={styles.buttonTitle}>{t('Add-new-team')}</span>
                        </span>
                    )}
                </Header>
            </div>
            <LoaderWrapper size={50} thickness={8}>
                {teams && renderTeams(teams)}
            </LoaderWrapper>
            {renderAddModal()}
        </div>
    );
};
