import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Form } from 'react-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { useNavigate } from 'react-router';
import Toggle from 'react-toggle';
import { Table } from 'table';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { PreviewProfileImagesGroup } from '../../components/previewProfileImagesGroup/PreviewProfileImagesGroup';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';
import { Tabs } from '../../components/tabs/Tabs';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import backgroundImg from '../../assets/images/user-header.jpg';
import register_descr from '../../assets/json/Register.js';

import styles from './ManageUsersPage.module.css';
import 'react-toggle/style.css';

if (register_descr().questions[register_descr().questions.length - 1]?.name !== 'type') {
    register_descr().questions.push({
        type: 'dropdown',
        isRequired: true,
        title: 'User-Type',
        name: 'type',
        choices: [
            {
                value: 'MEMBER',
                title: 'simple-user'
            },
            {
                value: 'ADMIN',
                title: 'Admin'
            }
        ]
    });
}

const postMember = async member => {
    const res = await requests.register(
        member.email,
        member.name,
        member.password,
        member.type,
        member.extraData
    );
    return res;
};

export const ManageUsersPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const addToast = useToasts();
    const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
    const [isDisableUserModalActive, setIsDisabledUserModalActive] = useState(false);
    const [allUsers, setAllUsers] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const formRef = useRef();
    const recaptchaRef = useRef();

    const mapTypeToText = useCallback(
        type => {
            const typeMap = {
                ADMIN: <div className={styles.roleDiv}>{t('Admin')}</div>,
                MEMBER: <div className={`${styles.roleDiv} ${styles.memberDiv}`}>{t('User')}</div>
            };

            return typeMap[type] || 'UNKNOWN TYPE';
        },
        [t]
    );

    useEffect(() => {
        requests.getAllUsers().then(({ data: users }) => {
            setAllUsers(users);
        });
    }, []);

    function rejectChangeActiveness(user) {
        addToast({
            type: 'information',
            message: t('Activeness of {{id}} rejected to change status', { id: user.email }),
            duration: 3000
        });
    }

    async function changeActiveness(user) {
        const res = user.isDisabled
            ? await requests.enableUser(user.email)
            : await requests.disableUser(user.email);

        if (!res) return false;

        user.isDisabled = !user.isDisabled;
        setIsDisabledUserModalActive(false);

        const updatedUsers = [...allUsers];
        updatedUsers[updatedUsers.findIndex(u => u.email === user.email)] = user;
        setAllUsers(updatedUsers);

        addToast({
            type: 'success',
            message: user.isDisabled
                ? t('User {{id}} enabled successfully', { id: user.email })
                : t('User {{id}} disabled successfully', { id: user.email }),
            duration: 3000
        });
    }

    async function onSuccessfulSubmit() {
        requests.getAllUsers().then(({ data: users }) => {
            setAllUsers(users);
        });
        setIsDisabledAddModal(false);
    }
    async function checkIfEmailExist(email) {
        try {
            await requests.getUser(email);
            return true;
        } catch {
            return false;
        }
    }

    async function submitUser() {
        formRef.current.clearSanityCheck();

        const _data = formRef.current.getData();
        const data = { ..._data, email: _data.email.trim() };
        const trimmedEmail = data.email.trim();

        if (await checkIfEmailExist(trimmedEmail)) {
            addToast({
                type: 'failure',
                message: t('this-email-exist-already'),
                duration: 5000
            });
            return;
        }

        Form.registerRestrictionCheck({
            tag: 'validateConfirmationPassword',
            func: () => {
                return data.password === data.passwordVerify;
            },
            explanation: t('passwords-do-not-match')
        });

        const sanityCheck = formRef.current.sanityCheckForm();
        if (!!sanityCheck.length) {
            formRef.current.setData({
                password: null,
                passwordVerify: null
            });
            return;
        }

        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        if (!token) {
            addToast({
                type: 'failure',
                message: t('invalid-captcha'),
                duration: 5000
            });
            formRef.current.clear();
            return;
        }

        postMember({
            ...data,
            type: data.type || 'MEMBER',
            extraData: { ...data.extraData, token }
        })
            .then(() => {
                addToast({
                    type: 'success',
                    message: t('User {{id}} added successfully', { id: trimmedEmail }),
                    duration: 3000
                });
                onSuccessfulSubmit();
            })
            .catch(() => {
                addToast({
                    type: 'failure',
                    message: t('Could not add User {{id}}', { id: trimmedEmail }),
                    duration: 3000
                });
                formRef?.current?.setData({
                    password: null,
                    passwordVerify: null,
                    email: null,
                    name: null,
                    type: null
                });
            });
    }

    function renderAddModal() {
        if (!isDisableAddModal) return null;

        return (
            <Modal
                onClose={() => {
                    setIsDisabledAddModal(false);
                }}
                header={t('add-new-user')}
                content={
                    <div className={styles.recaptchaContainer}>
                        <Form description={register_descr()} ref={formRef} />
                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptchaRef} />
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledAddModal(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => {
                                await submitUser();
                            }}
                        >
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const transformDataForPreviewRender = data => {
        if (!data) return null;

        const teams = data.teamMaps.map(t => t.team);

        return teams.map((team, i) => {
            return (
                <div key={i} className={styles.overviewItemWrap}>
                    <div className={styles.overviewItemStart}>
                        <SmallPreviewNameImage
                            fullname={team.name}
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            onClick={() => navigate(`../teams/${team.id}/info`)}
                        />
                    </div>
                </div>
            );
        });
    };

    const TABLE_COLUMNS_ALL = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'name',
                Cell: ({ value: user }) => {
                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../users/${user.email}/info`)}
                            request={() => requests.getUser(user.email)}
                        />
                    );
                }
                // filter: () => {}
            },

            {
                Header: t('Email'),
                accessor: 'email'
            },
            {
                accessor: 'type',
                Header: t('Type')
            },
            {
                accessor: 'lastLogin',
                Header: t('Last-login')
            },
            {
                accessor: 'teams',
                Header: t('Teams'),
                Cell: ({ value: user }) => {
                    if (!user || user.length === 0) return null;

                    return (
                        <PreviewProfileImagesGroup
                            profiles={user.teamMaps.map(tm => tm.team)}
                            request={() => requests.getUser(user.email)}
                            header={t('Preview-of-teams')}
                            transformDataToRender={transformDataForPreviewRender}
                            redirectToTeam={true}
                        />
                    );
                }
            },
            {
                accessor: 'status',
                Header: t('Status'),
                Cell: ({ value: user }) => {
                    return (
                        <label className={styles.labelActiveness}>
                            <Toggle
                                onChange={() => {
                                    setIsDisabledUserModalActive(user);
                                }}
                                className={styles.customToggle}
                                checked={!user.isDisabled}
                                icons={{
                                    unchecked: null
                                }}
                            />
                            <span>{!user.isDisabled ? t('Active') : t('Inactive')}</span>
                        </label>
                    );
                }
            }
        ],
        [navigate, t]
    );

    const TABLE_COLUMNS_SIMPLE = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'name',
                Cell: ({ value: user }) => {
                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../users/${user.email}/info`)}
                            request={() => requests.getUser(user.email)}
                        />
                    );
                }
                // filter: () => {}
            },

            {
                Header: t('Email'),
                accessor: 'email'
            },

            {
                accessor: 'lastLogin',
                Header: t('Last-login')
            },
            {
                accessor: 'teams',
                Header: t('Teams'),
                Cell: ({ value: user }) => {
                    if (!user || user.length === 0) return null;

                    return (
                        <PreviewProfileImagesGroup
                            profiles={user.teamMaps.map(tm => tm.team)}
                            request={() => requests.getUser(user.email)}
                            header={t('Preview-of-teams')}
                            transformDataToRender={transformDataForPreviewRender}
                        />
                    );
                }
            },
            {
                accessor: 'status',
                Header: t('Status'),
                Cell: ({ value: user }) => {
                    return (
                        <label className={styles.labelActiveness}>
                            <Toggle
                                onChange={() => {
                                    setIsDisabledUserModalActive(user);
                                }}
                                className={styles.customToggle}
                                checked={!user.isDisabled}
                                icons={{
                                    unchecked: null
                                }}
                            />
                            <span>{!user.isDisabled ? t('Active') : t('Inactive')}</span>
                        </label>
                    );
                }
            }
        ],
        [navigate, t]
    );

    const transformDataSimple = (data = []) => {
        if (!data || !data.length) return [];

        return data.map(user => {
            return {
                name: user,
                email: user.email,
                lastLogin: `${Utils.timestampToHumanizedDate(
                    user.lastLogin
                )} - ${Utils.timestampToHumanizedTime(user.lastLogin)}`,
                teams: user,
                status: user
            };
        });
    };

    const transformDataAll = useCallback(
        (data = []) => {
            if (!data || !data.length) return [];
            return data.map(user => {
                return {
                    name: user,
                    email: user.email,
                    type: mapTypeToText(user.type),

                    lastLogin: `${Utils.timestampToHumanizedDate(
                        user.lastLogin
                    )} - ${Utils.timestampToHumanizedTime(user.lastLogin)}`,
                    teams: user,
                    status: user
                };
            });
        },
        [mapTypeToText]
    );

    function renderDisableModal() {
        if (!isDisableUserModalActive) return null;

        const user = isDisableUserModalActive;
        return (
            <Modal
                onClose={() => {
                    setIsDisabledUserModalActive(false);
                    rejectChangeActiveness(user);
                }}
                header={
                    isDisableUserModalActive.isDisabled ? t('disable-account') : t('enable-account')
                }
                content={
                    <div>
                        {t('Are you sure you want to change status of usew with {{email}}', {
                            email: user.email
                        })}
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setDisabled(user.isDisabled);
                                rejectChangeActiveness(user);
                                setIsDisabledAddModal(false);
                                setIsDisabledUserModalActive(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => {
                                await changeActiveness(user);
                                setDisabled(isDisableUserModalActive.isDisabled);
                            }}
                        >
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const tabs = useMemo(
        () => [
            {
                title: t('all-users'),
                content: (
                    <LoaderWrapper size={50} thickness={8}>
                        <Table
                            pagination={{ pageSize: 10 }}
                            columns={TABLE_COLUMNS_ALL}
                            data={transformDataAll(allUsers)}
                        />
                    </LoaderWrapper>
                )
            },
            {
                title: t('admins'),
                content: (
                    <LoaderWrapper size={50} thickness={8}>
                        <Table
                            pagination={{ pageSize: 10 }}
                            columns={TABLE_COLUMNS_SIMPLE}
                            data={transformDataSimple(allUsers?.filter(u => u.type === 'ADMIN'))}
                        />
                    </LoaderWrapper>
                )
            },
            {
                title: t('users'),
                content: (
                    <LoaderWrapper size={50} thickness={8}>
                        <Table
                            pagination={{ pageSize: 10 }}
                            columns={TABLE_COLUMNS_SIMPLE}
                            data={transformDataSimple(allUsers?.filter(u => u.type === 'MEMBER'))}
                        />
                    </LoaderWrapper>
                )
            }
        ],
        [t, TABLE_COLUMNS_ALL, TABLE_COLUMNS_SIMPLE, allUsers, transformDataAll]
    );

    const renderUsers = useMemo(() => {
        return <Tabs tabs={tabs} />;
    }, [tabs]);

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('users')} backgroundImg={backgroundImg} position={'50% 60%'}>
                    {allUsers && (
                        <span
                            className={`btn btn-primary-0 ${styles.addButton}`}
                            onClick={() => setIsDisabledAddModal(true)}
                        >
                            <AiOutlineUsergroupAdd size={'20px'} />
                            <span className={styles.buttonTitle}>{t('add-user')}</span>
                        </span>
                    )}
                </Header>
            </div>
            <div style={{ padding: '0px 50px' }}>
                <LoaderWrapper>{allUsers && renderUsers}</LoaderWrapper>
            </div>
            {renderDisableModal()}
            {renderAddModal()}
        </div>
    );
};
