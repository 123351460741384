import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { BsFillHouseDoorFill, BsFillPeopleFill } from 'react-icons/bs';

import { useUser } from '../../context/withUser';

import ManagePropertiesCardList from '../../components/ManagePropertiesCardList/ManagePropertiesCardList';
import PreviewCard from '../../components/PreviewCard/PreviewCard';
import { HighlightMap } from '../../components/highlightMap/HighlightMap';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import styles from './HomePage.module.css';

import propertyCollage from '../../assets/images/propertyCollage.jpg';

/**
 *
 * get my permissions for the homepage...
 */
export const HomePage = () => {
    const { t } = useTranslation();
    const { user } = useUser();

    const [properties, setProperties] = useState(null);
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        requests.getAllProperties().then(({ data: teamMap }) => {
            setProperties(teamMap.flatMap(tm => tm.team.properties));
        });
    }, []);

    useEffect(() => {
        requests.getMyTeams().then(({ data }) => {
            setTeams(data);
        });
    }, []);

    if (!properties) return <LoaderWrapper />;

    const getPropertyCategories = () => [
        {
            title: t('private-properties'),
            counter: properties.filter(p => p.owners?.find(o => o.id === user.email)).length,
            link: '/my-properties?type=private'
        },
        {
            title: t('My-listings'),
            counter: properties.filter(p => p.isExposed).length,
            link: '/my-properties?type=listings'
        },
        {
            title: t('My-ownings'),
            counter: properties.filter(p => p.isSaved).length,
            link: '/my-properties?type=ownings'
        }
    ];

    const getTeamCategories = () => [
        {
            title: t('managing'),
            counter: teams.filter(p => {
                const me = p.teamMaps.find(m => m.userEmail === user.email);
                return me && me.isManager;
            }).length,
            link: '/teams?type=managing'
        },
        {
            title: t('part-of-teams'),
            counter: teams.filter(p => p.isActive === false).length,
            link: '/teams?type=...' //TODO
        },
        {
            title: t('total-active-teams'),
            counter: teams.filter(p => p.isActive === true).length,
            link: '/teams?type=...' //TODO
        }
    ];

    return (
        <div className={styles.pageWrap}>
            <div className={styles.welcome}>
                <div className={`${styles.welcomeBg} welcomeBG`}>
                    {/* <HighlightMap properties={properties} /> */}
                    <img src={propertyCollage} className={styles.propertyImg} />
                </div>
                <div className={styles.welcomeBg} />
                <div className={styles.textDiv}>
                    {/* <img src={logo} className={styles.icon} /> */}
                    {t(`welcome {{name}} at e-Properties`, {
                        name: user.name
                    })}
                </div>
            </div>
            <div className={styles.category}>
                <div className={styles.categoryHeader}>{t('general-info')}</div>
            </div>
            <div className={styles.info}>
                <PreviewCard
                    icon={<BsFillHouseDoorFill />}
                    title={t('properties')}
                    link={'/my-properties'}
                    counter={properties.length}
                    list={getPropertyCategories()}
                ></PreviewCard>

                <PreviewCard
                    icon={<BsFillPeopleFill />}
                    title={t('my-teams')}
                    link={'/teams'}
                    counter={teams.length}
                    list={getTeamCategories()}
                ></PreviewCard>
            </div>

            <div className={styles.category}>
                <div className={styles.categoryHeader}>{t('Services-header')}</div>
            </div>
            <div className={styles.servicesDiv}>
                <LoaderWrapper>
                    <ManagePropertiesCardList></ManagePropertiesCardList>
                </LoaderWrapper>
            </div>
        </div>
    );
};
