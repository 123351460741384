import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { DropdownFilter, Table } from 'table';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

export const ActionLog = ({ pagination, limit }) => {
    const [logs, setLogs] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const filterBasedOnName = useCallback(columnAccessor => {
        return (rows, columnIds, filterValue) =>
            rows.filter(row => {
                const cellValue = row.values[columnAccessor];

                if (!cellValue) return filterValue === '-';

                return cellValue.name.includes(filterValue);
            });
    }, []);

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: t('User'),
                accessor: 'user',
                Cell: ({ value: user }) => (
                    <SmallPreviewNameImage
                        imageSrc={user.image && `${URI}/${user.image.path}`}
                        fullname={user.name}
                        onClick={() => navigate(`../users/${user.email}/info`)}
                        request={() => requests.getUser(user.email)}
                    />
                ),
                filter: filterBasedOnName('user'),
                width: 50
            },
            {
                Header: t('Date'),
                accessor: 'date',
                width: 50
            },
            {
                accessor: 'type',
                Header: t('Type'),
                Filter: DropdownFilter,
                width: 50
            },
            {
                accessor: 'description',
                Header: t('Description'),
                Cell: ({ value: description }) => {
                    return <div dangerouslySetInnerHTML={{ __html: description }} />;
                }
            },
            {
                accessor: 'team',
                Header: t('Team'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../teams/${team.id}/info`)}
                        />
                    );
                },
                filter: filterBasedOnName('team')
            }
        ],
        [filterBasedOnName, navigate, t]
    );

    const fetchAndSetData = useCallback(async () => {
        const qData = {};

        if (limit) qData.limit = limit;

        const { data } = await requests.getAllLogs(qData);

        setLogs(data.map(transformLog).filter(log => log.user !== null));
    }, [limit]);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    function transformLog(log) {
        return {
            user: log.user,
            date: `${Utils.timestampToHumanizedDate(
                log.createdAt
            )} - ${Utils.timestampToHumanizedTime(log.createdAt)}`,
            type: t(log.type),
            // description: t(log.description, { email: log.user.email, propertyId }),
            description: t(log.description),
            team: log.team
        };
    }

    return (
        <div>
            <LoaderWrapper size={50} thickness={8}>
                {logs && (
                    <Table
                        searchBar={false}
                        filters={false}
                        exporting={false}
                        pagination={pagination}
                        columns={TABLE_COLUMNS}
                        data={logs}
                    />
                )}
            </LoaderWrapper>
        </div>
    );
};
