import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Team-Information'),
        promptForSubmit: 'Confirm',
        questions: [
            {
                title: i18next.t('Team-name'),
                name: 'name',
                type: 'text',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Description'),
                name: 'description',
                type: 'freetext',
                restrictions: {
                    validateFieldsName: 255
                }
            }
        ]
    };
};

export default getDescription;
