import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import { useToasts } from '../../../context/withToastMessages/withToastMessages.js';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading.js';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests, { URI } from '../../../utils/requests';

import suggestionsDescription from '../../../assets/json/PropertySuggestions';

import styles from './PropertyEditSuggestionsPage.module.css';

async function onSubmitSuggestion(formRef, buildingId, plotId, propertyId) {
    if (!formRef.current) {
        return false;
    }
    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();
    if (!!sanityCheckRes.length) {
        return false;
    }

    const data = formRef.current.getData();
    const formData = new FormData();
    for (const field in data) {
        if (field === 'images') {
            continue;
        }
        formData.append(field, data[field]);
    }
    if (!data.images) {
        formData.append('images', data.images);
    } else {
        await Promise.all(
            data.images.map(async image => {
                if (image instanceof File) {
                    formData.append('images', image);
                    return;
                }
                const { data } = await axios.get(`${URI}/${image.path}`, {
                    responseType: 'blob'
                });
                formData.append('images', new File([data], image.name));
            })
        );
    }
    formData.append('propertyId', propertyId);

    return buildingId
        ? await requests.updateBuildingSuggestion(buildingId, formData)
        : await requests.updatePlotSuggestion(plotId, formData);
}

export const PropertyEditSuggestionsPage = ({ buildingId, plotId, propertyId }) => {
    const { t } = useTranslation();
    const addToast = useToasts();
    const [data, setData] = useState(null);
    const formRef = useRef(null);

    useEffect(() => {
        if (!buildingId && !plotId) {
            return;
        }

        (buildingId
            ? requests.getBuildingSuggestion(buildingId)
            : requests.getPlotSuggestion(plotId)
        ).then(({ data }) => setData(data));
    }, [buildingId, plotId]);

    if (!data) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.pageTitle}>{t('suggestions title')}</h1>

            <div className={styles.formContainer}>
                <Form
                    description={suggestionsDescription()}
                    initialValue={{
                        ...data,
                        images: data.images.map(image => ({
                            name: image.file.name,
                            path: image.file.path
                        }))
                    }}
                    ref={formRef}
                />
            </div>
            <div className={styles.footerButtons}>
                <div className={styles.actionBtnsContainer}>
                    <ButtonWithLoading
                        className={`btn btn-primary-0 ${styles.actionBtn}`}
                        onClick={async () => {
                            await onSubmitSuggestion(formRef, buildingId, plotId, propertyId);
                            addToast({
                                type: 'success',
                                message: t('success-property-update'),
                                duration: 3000
                            });
                        }}
                    >
                        <AiOutlineCheck /> {t('save')}
                    </ButtonWithLoading>
                </div>
            </div>
        </div>
    );
};
