import React, { useCallback, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
    AiFillFolderOpen,
    AiOutlineFileText,
    AiOutlineFolderOpen,
    AiOutlineRight
} from 'react-icons/ai';
import { BiEuro, BiImage, BiVideo } from 'react-icons/bi';
import { BsCurrencyDollar, BsFileEarmarkText, BsInfo, BsInfoCircle } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { IoOptions } from 'react-icons/io5';
import { MdOutlineFeaturedPlayList, MdOutlineInsertPhoto } from 'react-icons/md';
import { PiPencilSlashDuotone } from 'react-icons/pi';
import { RiKey2Line, RiLandscapeLine, RiMap2Line } from 'react-icons/ri';
import { TfiMapAlt } from 'react-icons/tfi';
import { TiArrowBackOutline } from 'react-icons/ti';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import AddPropertyMapVariable from '../../../components/addPropertyMapVariable/addPropertyMapVariable';

import requests from '../../../utils/requests';

import { SidebarPage } from '../../generalPages/SidebarPage';
import LandOptionsPage from '../LandOptionsPage';
import MapFeaturesEdit from '../MapFeaturesEdit';
import PropertyDigitalFolderPage from '../PropertyDigitalFolderPage';
import { PropertyEditImagesPage } from '../PropertyEditImagesPage';
import { PropertyEditVideosPage } from '../PropertyEditVideosPage';
import PropertyLocationPage from '../PropertyLocationPage';
import { PropertyEditSuggestionsPage } from '../SuggestionPages/PropertyEditSuggestionsPage';
import {
    PropertyEditEstimatesPage,
    PropertyEstimatesHistoryPage
} from '../estimatesPages/PropertyEditEstimatesPage';
import {
    PropertyEditExpensesPage,
    PropertyExpensesMainPage
} from '../expensesPages/PropertyEditExpensesPage';
import {
    PropertyEditLeasesPage,
    PropertyLeasesHistoryPage
} from '../leasesPages/PropertyEditLeasesPage';
import PropertyEditInfoPage from './PropertyEditInfoPage';
import PropertyEditLandPage from './PropertyEditLandPage';

import styles from './LandEditPage.module.css';

const LandEditPage = ({ property, setProperty }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const addToast = useToasts();
    const formRef = useRef();
    const getBackPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'property',
            'land',
            'location',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'digital-folder'
        ];

        const urlSuffix = location.pathname.split('/').reverse()[0];
        const urlParts = location.pathname.split('/').reverse();
        if (validSuffixes.includes(urlSuffix)) {
            return `../properties/view/${property.id}/${urlParts[0]}`;
        }
        return `../properties/view/${property.id}/${urlParts[1]}/${urlParts[0]}`;
    }, [location, property]);

    const updatePropertyToDB = async (propertyId, data) => {
        const res = await requests.updateProperty(propertyId, data);
        addToast({
            type: 'success',
            message: t('success-property-update'),
            duration: 3000
        });
        return res;
    };

    const options = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <PropertyEditInfoPage
                                property={property}
                                updateProperty={setProperty}
                                updatePropertyToDB={updatePropertyToDB}
                                getOwners={async propertyId => {
                                    const { data } = await requests.getPropertyOwners(propertyId);
                                    if (!data) {
                                        return null;
                                    }

                                    return data.map(elem => ({
                                        ...elem.owner,
                                        percentage: elem.percentage
                                    }));
                                }}
                                updateOwners={requests.updatePropertyOwners}
                            />
                            <PropertyEditLandPage
                                property={property}
                                updateProperty={setProperty}
                                updatePropertyToDB={updatePropertyToDB}
                            />
                        </>
                    ),
                    Icon: BsInfo
                },
                {
                    path: 'location',
                    title: t('location'),
                    element: (
                        <PropertyLocationPage
                            property={property}
                            updateProperty={setProperty}
                            updatePropertyToDB={updatePropertyToDB}
                        />
                    ),
                    Icon: GoLocation
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: <PropertyEditImagesPage />,
                    Icon: MdOutlineInsertPhoto
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyEditVideosPage />,
                    Icon: BiVideo
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: (
                        <PropertyDigitalFolderPage
                            canEdit
                            property={property}
                            updateProperty={setProperty}
                            updatePropertyToDB={updatePropertyToDB}
                        />
                    ),
                    Icon: AiOutlineFolderOpen,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    }
                },
                {
                    path: 'options',
                    title: t('options'),
                    element: <LandOptionsPage plot={property.plot} />,
                    Icon: IoOptions
                }
            ]
        },
        {
            title: t('Actions'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage isEdit plotId={property.plot.id} />,
                    Icon: BiEuro
                },
                {
                    path: 'expenses/new',
                    element: (
                        <PropertyEditExpensesPage
                            propertyId={property.id}
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'expenses/:id',
                    element: (
                        <PropertyEditExpensesPage
                            propertyId={property.id}
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    element: <PropertyLeasesHistoryPage isEdit plotId={property.plot.id} />,
                    Icon: RiKey2Line
                },
                {
                    path: 'leases/new',
                    element: (
                        <PropertyEditLeasesPage
                            propertyId={property.id}
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases/:id',
                    element: (
                        <PropertyEditLeasesPage
                            propertyId={property.id}
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: (
                        <PropertyEstimatesHistoryPage
                            isEdit
                            plotId={property.plot.id}
                            btnContent={
                                <>
                                    {t('continue')} <AiOutlineRight />
                                </>
                            }
                            nextPageClick={() =>
                                navigate(
                                    location.pathname.split('/').slice(0, -1).join('/') +
                                        '/suggestions'
                                )
                            }
                        />
                    ),
                    Icon: BsFileEarmarkText
                },
                {
                    path: 'estimates/new',
                    element: (
                        <PropertyEditEstimatesPage
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates/:id',
                    element: (
                        <PropertyEditEstimatesPage
                            plotId={property.plot.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: (
                        <PropertyEditSuggestionsPage
                            plotId={property.plot.id}
                            propertyId={property.id}
                        />
                    ),
                    Icon: MdOutlineFeaturedPlayList
                }
            ]
        }
    ];

    return (
        <div className={styles.pageWrap}>
            <SidebarPage options={options}>
                <div className={styles.propertyInfoContainer}>
                    <div className={styles.firstRow}>
                        <h2 className={styles.propertyInfoSubcategory}>{t(property.plot.pType)}</h2>
                        <Link className={`${styles.returnBtn}`} to={getBackPage()}>
                            <PiPencilSlashDuotone size={30} className={styles.icon} />
                        </Link>
                    </div>
                    <h3 className={styles.propertyInfoCategory}>{t('plot')}</h3>
                </div>
            </SidebarPage>
        </div>
    );
};

export default LandEditPage;
