import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Smart-Features-Form'),
        promptForSubmit: 'Confirm',
        questions: [
            {
                title: i18next.t('energyManagement'),
                name: 'energyManagement',
                type: 'checkbox'
            },
            {
                title: i18next.t('consumptionMonitoring'),
                name: 'consumptionMonitoring',
                type: 'checkbox'
            },

            {
                title: i18next.t('bms'),
                name: 'bms',
                type: 'checkbox'
            },

            {
                title: i18next.t('remoteManagement'),
                name: 'remoteManagement',
                type: 'checkbox'
            },

            {
                title: i18next.t('managementAndAutomationsHM'),
                name: 'managementAndAutomationsHM',
                type: 'checkbox'
            },

            {
                title: i18next.t('lightManagement'),
                name: 'lightManagement',
                type: 'checkbox'
            },

            {
                title: i18next.t('airConditioningManagement'),
                name: 'airConditioningManagement',
                type: 'checkbox'
            },
            {
                title: i18next.t('autoFaultDetection'),
                name: 'autoFaultDetection',
                type: 'checkbox'
            },
            {
                title: i18next.t('airQualityControl'),
                name: 'airQualityControl',
                type: 'checkbox'
            },
            {
                title: i18next.t('smartParking'),
                name: 'smartParking',
                type: 'checkbox'
            }
        ]
    };
};

export default getDescription;
