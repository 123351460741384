import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import ResetPassword from '../../../assets/json/ResetPassword';

import styles from './ResetPasswordPage.module.css';

export const ResetPasswordPage = () => {
    const navigate = useNavigate();

    const addToast = useToasts();
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    const [validationIsComplete, setValidationIsComplete] = useState(false);

    const formRef = useRef();

    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const validateUserTokenCombination = useCallback(async () => {
        try {
            await requests.validateResetPasswordToken(email, token);
        } catch (err) {
            addToast({
                type: 'failure',
                message: t('The reset password link is not correct or it has expired'),
                duration: 5000
            });

            return navigate('/home');
        }

        setValidationIsComplete(true);
    }, [email, token, addToast, navigate, t]);

    useEffect(() => {
        validateUserTokenCombination();
    }, [validateUserTokenCombination]);

    const onSubmit = async () => {
        const { password, passwordValidation } = formRef.current.getData();

        if (password !== passwordValidation)
            return addToast({
                type: 'failure',
                message: t("The password and the password validation don't match"),
                duration: 5000
            });

        try {
            await requests.resetPassword(email, token, password);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t('The reset password link has expired'),
                duration: 5000
            });
        }

        addToast({
            type: 'success',
            message: t('You have successfully reset your password'),
            duration: 5000
        });

        navigate('/login');
    };

    return (
        <LoaderWrapper>
            {validationIsComplete && (
                <div className={styles.pageWrap}>
                    <div className={styles.formWrap}>
                        <div className={styles.title}>{t('Reset Password')}</div>

                        <Form description={ResetPassword()} ref={formRef} />

                        <ButtonWithLoading
                            className={`${styles.btn} btn btn-primary-0`}
                            onClick={onSubmit}
                        >
                            {t('Send')}
                        </ButtonWithLoading>
                    </div>
                </div>
            )}
        </LoaderWrapper>
    );
};
