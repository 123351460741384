import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

const MAX_STR_LENGTH = 255;
const MAX_iNTEGER_LENGTH = 2147483646;
const MAX_FLOAT_LENGTH = 3.402823466e38;
const MIN_FLOAT_LENGTH = 1.175494351e-38;

const useFormValidators = () => {
    const { t } = useTranslation();

    function minChars(value, arg) {
        if (typeof value !== 'string' && value.length < MAX_STR_LENGTH && typeof arg === 'number')
            return false;

        return value.length >= arg;
    }

    function maxChars(value, arg) {
        if (typeof value !== 'string' && value.length < MAX_STR_LENGTH && typeof arg === 'number')
            return false;

        return value.length <= arg;
    }

    function hasChars(value, arg) {
        if (typeof value !== 'string' && value.length < MAX_STR_LENGTH && typeof arg === 'number')
            return false;

        return value.length === arg;
    }

    function validateEmail(v) {
        return (
            typeof v === 'string' &&
            v.length < MAX_STR_LENGTH &&
            /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/.test(v)
        );
    }

    function validatePassword(v) {
        return (
            typeof v === 'string' &&
            v.length < MAX_STR_LENGTH &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,100}$/.test(v)
        );
    }
    function validatePasswordHasSpaces(v) {
        return (
            typeof v === 'string' &&
            v.length < MAX_STR_LENGTH &&
            /^(?! )[A-Za-z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*?(?<! )$/.test(v)
        );
    }

    function validateFieldsInteger(v) {
        return v >= 0 && v < MAX_iNTEGER_LENGTH;
    }
    function validateFieldsFloat(v) {
        return v >= 0 && MIN_FLOAT_LENGTH && v < MAX_FLOAT_LENGTH;
    }
    function validateFieldsName(v, arg) {
        console.log(v, arg);
        return v.length > 0 && v.length < arg;
    }
    Form.registerRestrictionCheck({
        tag: 'minChars',
        func: minChars,
        explanation: t(`Το πεδίο $fieldname πρέπει να είναι $res ή περισσότερους χαρακτήρες`)
    });

    Form.registerRestrictionCheck({
        tag: 'maxChars',
        func: maxChars,
        explanation: t(`Το πεδίο $fieldname πρέπει να είναι $res ή λιγότερους χαρακτήρες`)
    });

    Form.registerRestrictionCheck({
        tag: 'hasChars',
        func: hasChars,
        explanation: t(`Το πεδίο $fieldname πρέπει να είναι ακριβώς $res χαρακτήρες`)
    });

    Form.registerRestrictionCheck({
        tag: 'validateEmail',
        func: validateEmail,
        explanation: t(
            'Το email που πληκτρολογήσατε δεν έχει την αναμενόμενη μορφή. Παράδειγμα example@domain.com'
        )
    });

    Form.registerRestrictionCheck({
        tag: 'validatePassword',
        func: validatePassword,
        explanation: t(
            'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα, ένα μικρό γράμμα, έναν αριθμό και ένα σύμβολο από τα !@#$%^&*_=+-'
        )
    });

    Form.registerRestrictionCheck({
        tag: 'maxMarkers',
        func: (value, maxMarkers) => Array.isArray(value) && value.length <= maxMarkers,
        explanation: t('Μπορείτε να ορίσεται στον χάρτη το πολύ $res σημείο/α')
    });

    Form.registerRestrictionCheck({
        tag: 'validatePasswordHasSpaces',
        func: validatePasswordHasSpaces,
        explanation: t('password-containts-blank')
    });

    Form.registerRestrictionCheck({
        tag: 'validateFieldsInteger',
        func: validateFieldsInteger,
        explanation: t('Invalid $fieldname value')
    });

    Form.registerRestrictionCheck({
        tag: 'validateFieldsFloat',
        func: validateFieldsFloat,
        explanation: t('Invalid $fieldname value')
    });

    Form.registerRestrictionCheck({
        tag: 'validateFieldsName',
        func: validateFieldsName,
        explanation: t(' $fieldname is too big')
    });
};

export default useFormValidators;
