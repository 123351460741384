import { useRef } from 'react';
import PropertiesMap from '../../pages/generalPages/PropertiesMap';

export const HighlightMap = ({ properties, height }) => {
    const mapRef = useRef(null);

    return (
        <>
            <PropertiesMap
                isFullMap={true}
                ref={mapRef}
                isActiveMap={true}
                guestMode={true}
                height={height}
                initialProperties={properties}
            />
            {mapRef?.current?.setZoom(7)}
        </>
    );
};
