// const colorsPallete = [
//     '#4158A9',
//     '#D8315B',
//     '#00635D',
//     '#B3FFB3',
//     '#BFDBF7',
//     '#D4C2FC',
//     '#78E0DC',
//     '#8EEDF7',
//     '#D7C0D0',
//     '#D33F49'
// ];

// const colorsPallete = [
//     '#CFB3CD',
//     '#BCEBCB',

//     '#588B8B',
//     '#A3C9A8',
//     '#DFC2F2',
//     '#F1FFC4',
//     '#68EDC6 ',
//     '#C2B97F',
//     '#72DDF7',
//     '#79C99E',
//     '#95D9DA',
//     '#6DD6DA',
//     '#A2ABB5',
//     '#027BCE',
//     '#FED99B'
// ];

const colorsPallete = [
    '#7E9ABD',
    '#BFD7FF',
    '#B3C1D2',
    '#9FA0C3',
    '#E6B89C',
    '#A8D4AD',
    '#FEC0CE',
    '#EBCFB2',
    '#99AEC8',
    '#FED99B',
    '#AD6A6C',
    '#FEDC97',
    '#BEA2C2',
    '#D0FFCE',
    '#E7DECD',
    '#9BB1FF',
    '#DCE2AA',
    '#E09891',
    '#496F5D',
    '#90AA86',
    '#588B8B',
    '#A3C9A8',
    '#DFC2F2',
    '#F1FFC4',
    '#68EDC6 ',
    '#C2B97F',
    '#72DDF7',
    '#79C99E',
    '#95D9DA',
    '#6DD6DA',
    '#A2ABB5',
    '#027BCE',
    '#FED99B'
];

export function generateNColors(n) {
    const colors = [];

    for (let i = 0; i < n; ++i) {
        colors.push(colorsPallete[i % colorsPallete.length]);
    }
    return colors;
}
