import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyInfoForm'),
        showTitle: false,
        name: 'propertyInfoForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('general-info'),
                name: 'propertyLandConstractionRules',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('ΚΑΕΚ Γεωτεμαχίου'),
                name: 'kaekPlot',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 20
                }
            },
            {
                title: i18next.t('Ονομασία Ακινήτου'),
                name: 'name',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Αριθμός Γηπέδου'),
                name: 'plotNumber',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('ΑΤΑΚ'),
                name: 'atak',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Αναλογία Οικοπέδου / Γηπέδου'),
                name: 'ratio',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Εμβαδόν Αναλογίας Οικοπέδου / Γηπέδου'),
                name: 'ratioArea',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Χρήσεις Γης'),
                name: 'plotUse',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Νομικό Καθεστώς'),
                name: 'legalRegime',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Ποσοστό Γηπέδου'),
                name: 'plotPercentage',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Κτηματολογικό Γραφείο'),
                name: 'cadastralOffice',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Όροι Δόμησης'),
                name: 'propertyLandConstractionRules',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Αρτιότητα'),
                name: 'hasExcellence',
                type: 'checkbox',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Συντελεστής Κάλυψης'),
                name: 'coverageFactor',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Συντελεστής Δόμησης'),
                name: 'constractionFactor',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Θέση Δόμησης Κτηρίου'),
                name: 'constructionPosition',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Μέγιστο Ύψος'),
                name: 'maxHeight',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                },
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Συνολικό Εμβαδόν Γηπέδου'),
                name: 'propertyLandTotalArea',
                type: 'label',
                isRequired: false,
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Βάσει Συμβολαίου'),
                name: 'totalPlotAreaContract',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                },
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Βάσει Ο-Α'),
                name: 'totalPlotAreaOA',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                },
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Βάσει Αποτύπωσης'),
                name: 'totalPlotAreaDepiction',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Βάσει Αυτοψίας'),
                name: 'totalPlotAreaAytopsy',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Λεπτομέρειες Οικοπέδου'),
                name: 'propertyLandDetails',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Εντός Σχεδίου'),
                name: 'inPlan',
                type: 'checkbox',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Εντός Ζώνης'),
                name: 'inZone',
                type: 'checkbox',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Εντός Οικισμού'),
                name: 'inSettlement',
                type: 'checkbox',
                isRequired: false,
                space: '100%'
            }
        ]
    };
};

export default getDescription;
