// ---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------
import styles from './LanguagePreview.module.css';

// ---------------------------------------------------------------------------------------

export const LanguagePreview = ({ options = [], defaultValue, imgs, onChange }) => {
    return (
        <div className={styles.container}>
            {options.map((opt, i) => {
                const isActive = opt.value === defaultValue.value ? styles.selected : '';

                return (
                    <button
                        key={`lang_flag_${i}`}
                        className={`${styles.button} ${isActive}`}
                        onClick={() => {
                            onChange({ value: opt.value });
                        }}
                    >
                        <div className={styles.panel}>
                            <div className={styles.flag}>{imgs[opt.value]}</div>
                        </div>
                    </button>
                );
            })}
        </div>
    );
};
