import React from 'react';

import { useTranslation } from 'react-i18next';

import QuickViewCard from '../QuickViewCard/QuickViewCard';

import actions from '../../assets/images/actions.png';
import digitalFolder from '../../assets/images/digitalFolder.png';
import estimates from '../../assets/images/estimates.png';
import expenses from '../../assets/images/expenses.jpg';
import leases from '../../assets/images/leases.png';
import renovations from '../../assets/images/renovations.png';
import smartBuilding from '../../assets/images/smartBuilding.png';
import statistics from '../../assets/images/stat.jpg';
import suggestions from '../../assets/images/suggestions.png';

import styles from './ManagePropertiesCardList.module.css';

const ManagePropertiesCardList = ({ advertise = false, path = null }) => {
    const { t } = useTranslation();
    const services = [
        {
            image: expenses,
            title: t('expenses'),
            path: `${path ? `${path}/` : ''}expenses`,
            description: t(
                'With the help of our platform, you can record the monthly expenses of your properties and see their statistics'
            )
        },
        {
            image: estimates,
            title: t('estimates'),
            path: `${path ? `${path}/` : ''}estimates`,
            description: t(
                'With the help of our platform, you can easily create and manage estimates for your properties, providing accurate and detailed cost projections to help you make informed decisions'
            )
        },
        {
            image: leases,
            title: t('leases'),
            path: `${path ? `${path}/` : ''}leases`,
            description: t(
                'Our platform allows you to easily manage leases and concessions for your properties, providing a comprehensive view of all agreements and their associated details'
            )
        },
        {
            image: digitalFolder,
            title: t('digital-folder'),
            path: `${path ? `${path}/` : ''}digital-folder`,
            description: t(
                'With our platform, you can create and manage digital folders for each property, allowing for easy and organized access to all relevant documents and information'
            )
        },
        {
            image: suggestions,
            title: t('suggestions'),
            path: `${path ? `${path}/` : ''}suggestions`,
            description: t(
                'Our platform offers a streamlined way to track and manage your property suggestions and schematics, allowing you to easily organize and access them whenever you need'
            )
        },
        {
            image: renovations,
            title: t('renovations'),
            path: `${path ? `${path}/` : ''}renovations`,
            description: t(
                'With our platform, you can easily manage and track renovation and repair projects for your properties, ensuring they are completed efficiently and on budget'
            )
        },
        {
            image: actions,
            title: t('actions'),
            path: `${path ? `${path}/` : ''}ripening-actions`,
            description: t(
                'Our platform offers a comprehensive solution for property management, which includes regular technical checks to ensure the optimal functionality of your properties'
            )
        },
        {
            image: smartBuilding,
            title: t('smart-building'),
            path: `${path ? `${path}/` : ''}smart-building`,
            description: t(
                'Keep up with the latest smart technology for your properties by using our platform to manage and optimize their features'
            )
        },
        {
            image: statistics,
            title: t('statistics'),
            path: `${path ? `${path}/` : ''}statistics`,
            description: t(
                "Our platform provides you with real-time statistics on your properties' performance, giving you valuable insights to make informed decisions"
            )
        }
    ];

    return (
        <div className={styles.servicesList}>
            {services.map((s, i) => (
                <div key={`Card_${i}`}>
                    <QuickViewCard {...s} advertise={advertise} />
                    <br></br>
                </div>
            ))}
        </div>
    );
};

export default ManagePropertiesCardList;
