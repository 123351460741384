// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserProfilePage_userInfo__HPZ8z {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 0.6rem;\n    margin-bottom: 20px;\n}\n\n.UserProfilePage_userName__5FK0k {\n    font-size: 1rem;\n}\n\n.UserProfilePage_userEmail__rx3Uv {\n    font-size: 0.875rem;\n}\n\n.UserProfilePage_actionBar__x1uFh {\n    display: flex;\n    gap: 0.2rem;\n}\n\n.UserProfilePage_actionBar__x1uFh button {\n    background-color: inherit;\n    border: none;\n}\n\n.UserProfilePage_actionBar__x1uFh button svg {\n    fill: var(--theme-primary-10);\n    color: var(--theme-primary-10);\n    width: 1.25rem;\n    height: 1.25rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalPages/UserProfilePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,8BAA8B;IAC9B,cAAc;IACd,eAAe;AACnB","sourcesContent":[".userInfo {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 0.6rem;\n    margin-bottom: 20px;\n}\n\n.userName {\n    font-size: 1rem;\n}\n\n.userEmail {\n    font-size: 0.875rem;\n}\n\n.actionBar {\n    display: flex;\n    gap: 0.2rem;\n}\n\n.actionBar button {\n    background-color: inherit;\n    border: none;\n}\n\n.actionBar button svg {\n    fill: var(--theme-primary-10);\n    color: var(--theme-primary-10);\n    width: 1.25rem;\n    height: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfo": "UserProfilePage_userInfo__HPZ8z",
	"userName": "UserProfilePage_userName__5FK0k",
	"userEmail": "UserProfilePage_userEmail__rx3Uv",
	"actionBar": "UserProfilePage_actionBar__x1uFh"
};
export default ___CSS_LOADER_EXPORT___;
