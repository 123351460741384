import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Forgot Password'),
        showTitle: false,
        name: 'ForgotPassword',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Password'),
                placeholder: '********',
                name: 'password',
                type: 'text',
                isRequired: true,
                isPassword: true
            },
            {
                title: i18next.t('Password Validation'),
                placeholder: '********',
                name: 'passwordValidation',
                type: 'text',
                isRequired: true,
                isPassword: true
            }
        ]
    };
};

export default getDescription;
