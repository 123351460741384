// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserStatisticsPage_mainDiv__cdcQ9 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.UserStatisticsPage_headerDiv__N1doq {\n    margin-bottom: 30px;\n}\n.UserStatisticsPage_cardsDiv__PJ9P4 {\n    width: 100%;\n    align-self: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/userProfilePages/UserStatisticsPage.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".mainDiv {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.headerDiv {\n    margin-bottom: 30px;\n}\n.cardsDiv {\n    width: 100%;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": "UserStatisticsPage_mainDiv__cdcQ9",
	"headerDiv": "UserStatisticsPage_headerDiv__N1doq",
	"cardsDiv": "UserStatisticsPage_cardsDiv__PJ9P4"
};
export default ___CSS_LOADER_EXPORT___;
