import React from 'react';

import { FaRegImages } from 'react-icons/fa';

import styles from './ImageGallery.module.css';

export const ImageGallery = ({ images = [], main }) => {
    return <div className={styles.container}>{renderImageGallery(images, main)}</div>;
};
function renderImageGallery(images, main) {
    if (images.length === 0) return renderDefualt();
    else if (images.length === 1) return renderOne(images);
    else if (images.length === 2) return renderTwo(images, main);
    else if (images.length === 3) return renderThree(images, main);
    else if (images.length === 4) return renderFour(images, main);
    else if (images.length === 5) return renderFive(images, main);
    else if (images.length > 5) return renderMore(images, main);
}

const renderDefualt = () => {
    return (
        <div className={styles.columnMainContainer} style={{ width: '100%', background: 'red' }}>
            main for one default...
        </div>
    );
};

const renderOne = images => {
    return (
        <div className={styles.columnMainContainer} style={{ width: '100%' }}>
            <img className={styles.image} src={images[0]} alt={images[0]} />
        </div>
    );
};

const renderTwo = (images, main) => {
    return (
        <>
            <div className={styles.columnMainContainer}>
                <img className={styles.image} src={images[0]} alt={images[0]} />
            </div>
            <div className={styles.columnSecContainer}>
                <img className={styles.image} src={images[1]} alt={images[1]} />
            </div>
        </>
    );
};

const renderThree = (images, main) => {
    return (
        <>
            <div className={styles.columnMainContainer}>
                <img className={styles.image} src={images[0]} alt={images[0]} />
            </div>
            <div className={styles.columnSecContainer}>
                <img className={styles.secImage} src={images[1]} alt={images[1]} />
                <img className={styles.secImage} src={images[2]} alt={images[2]} />
            </div>
        </>
    );
};

const renderFour = (images, main) => {
    return (
        <>
            <div className={styles.columnMainContainer}>
                <img className={styles.image} src={images[0]} alt={images[0]} />
            </div>
            <div className={styles.columnSecContainer}>
                <div className={styles.rowSecContainer}>
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[1]}
                        alt={images[1]}
                    />
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[2]}
                        alt={images[2]}
                    />
                </div>
                <img className={styles.secImage} src={images[3]} alt={images[3]} />
            </div>
        </>
    );
};

const renderFive = (images, main) => {
    return (
        <>
            <div className={styles.columnMainContainer}>
                <img className={styles.image} src={images[0]} alt={images[0]} />
            </div>
            <div className={styles.columnSecContainer}>
                <div className={styles.rowSecContainer}>
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[1]}
                        alt={images[1]}
                    />
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[2]}
                        alt={images[2]}
                    />
                </div>
                <div className={styles.rowSecContainer}>
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[3]}
                        alt={images[3]}
                    />
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[4]}
                        alt={images[4]}
                    />
                </div>
            </div>
        </>
    );
};

const renderMore = (images, main) => {
    return (
        <>
            <div className={styles.columnMainContainer}>
                <img className={styles.image} src={images[0]} alt={images[0]} />
            </div>
            <div className={styles.columnSecContainer}>
                <div className={styles.rowSecContainer}>
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[1]}
                        alt={images[1]}
                    />
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[2]}
                        alt={images[2]}
                    />
                </div>
                <div className={styles.rowSecContainer}>
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[3]}
                        alt={images[3]}
                    />
                    <img
                        className={`${styles.secImage} ${styles.quaterImage}`}
                        src={images[4]}
                        alt={images[4]}
                    />
                    <span className={styles.moreImagesIcon}>
                        <FaRegImages size={25} />
                        {images.length}
                    </span>
                </div>
            </div>
        </>
    );
};
