import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FaThList } from 'react-icons/fa';

import { Modal } from '../../components/modal/Modal';

import requests, { URI } from '../../utils/requests';

import { Carousel } from './Carousel';

import styles from './PropertyViewImagesPage.module.css';
import { FormLabel } from '../../components/formLabel/FormLabel';

export const PropertyViewImagesPage = () => {
    const { t } = useTranslation();
    const { propertyId } = useParams();

    const [isCarouselOpen, setIsCarouselOpen] = useState(false);
    const [images, setImages] = useState(null);

    const [viewMode, setViewMode] = useState('extended');

    useEffect(() => {
        requests.getPropertyAssets(propertyId).then(({ data }) => {
            const im = {
                interior: data.filter(d => d.assetType === 'INTERIOR'),
                exterior: data.filter(d => d.assetType === 'EXTERIOR'),
                floor_plans: data.filter(d => d.assetType === 'FLOOR_PLANS')
            };
            setImages(im);
        });
    }, [propertyId]);

    if (!images) return null;

    const compactView = () => {
        const previewImage = images => {
            if (images.length > 4) images.length = 4;

            return (
                <div className={styles.catImageWrap}>
                    {images.map((im, i) => (
                        <img
                            key={i}
                            className={styles.catImageSmall}
                            src={`${URI}/${im.file.path}`}
                            onClick={() => setIsCarouselOpen(im)}
                            alt={`${URI}/${im.file.path}`}
                        />
                    ))}
                </div>
            );
        };

        return (
            <div className={styles.catWrapper}>
                <div className={styles.catWrap}>
                    {previewImage(images.interior)}
                    <div className={styles.catTitle}>{t('propertyΙnterior')}</div>
                </div>
                <div className={styles.catWrap}>
                    {previewImage(images.exterior)}
                    <div className={styles.catTitle}>{t('propertyExterior')}</div>
                </div>
                <div className={styles.catWrap}>
                    {previewImage(images.floor_plans)}
                    <div className={styles.catTitle}>{t('propertyFloorPlans')}</div>
                </div>
            </div>
        );
    };

    const extendedView = () => {
        const getExtendedImages = images => {
            if (images.length === 0)
                return <div className={styles.emptyPrompt}>{t('no-images')}</div>;
            return images.map((im, i) => (
                <img
                    key={i}
                    className={styles.image}
                    src={`${URI}/${im.file.path}`}
                    onClick={() => setIsCarouselOpen(im)}
                    alt={`${URI}/${im.file.path}`}
                />
            ));
        };

        return (
            <div className={styles.extendedWrap}>
                <div>
                    <FormLabel readOnly>{t('propertyΙnterior')}</FormLabel>
                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.interior)}
                    </div>
                </div>
                <div>
                    <FormLabel>{t('propertyExterior')}</FormLabel>
                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.exterior)}
                    </div>
                </div>
                <div>
                    <FormLabel>{t('propertyFloorPlans')}</FormLabel>

                    <div className={styles.extendedImageWrap}>
                        {getExtendedImages(images.floor_plans)}
                    </div>
                </div>
            </div>
        );
    };

    const viewMap = {
        compact: compactView,
        extended: extendedView
    };

    const changeViewMode = () => {
        viewMode === 'compact' ? setViewMode('extended') : setViewMode('compact');
    };

    const carouselModal = () => {
        if (!isCarouselOpen) return null;

        let imagesToUse = null;
        if (isCarouselOpen.assetType === 'INTERIOR') {
            imagesToUse = images.interior;
        } else if (isCarouselOpen.assetType === 'EXTERIOR') {
            imagesToUse = images.exterior;
        } else {
            imagesToUse = images.floor_plans;
        }
        const typeTranslation = [
            t(`Interion images`),
            t(`exterior images`),
            t(`floor plan images`)
        ];
        return (
            <Modal
                onClose={() => setIsCarouselOpen(false)}
                header={
                    (isCarouselOpen.assetType === 'INTERIOR' && typeTranslation[0]) ||
                    (isCarouselOpen.assetType === 'EXTERIOR' && typeTranslation[1]) ||
                    typeTranslation[2]
                }
                content={
                    <Carousel
                        contents={imagesToUse.map(i => `${URI}/${i.file.path}`)}
                        active={`${URI}/${isCarouselOpen.file.path}`}
                    />
                }
            />
        );
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.topBar}>
                <h1>{t('photos')}</h1>
                {/* There are bugs with the compat view. Later fix it */}
                {/* <FaThList className={styles.changeMode} onClick={changeViewMode} /> */}
            </div>
            {viewMap[viewMode]() || viewMode['compact']()}
            {carouselModal()}
        </div>
    );
};
