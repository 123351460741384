import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { BsMap } from 'react-icons/bs';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { useScroll } from '../../context/withScroll';

import { PaginatedProperties } from '../../components/paginatedProperties/PaginatedProperties';
import { SearchPropertyForm } from '../../components/searchPropertyForm/SearchPropertyForm';

import PropertiesMap from '../../pages/generalPages/PropertiesMap';

import requests from '../../utils/requests';
import Utils from '../../utils/utils';

import AddPropertyForm from '../addPropertyForm/AddPropertyForm';
import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { Header } from '../header/Header';
import { Modal } from '../modal/Modal';
import { Background } from './Background';

import backgroundImg from '../../assets/images/properties-bg.jpg';

import styles from './SearchPaginatedPropertiesWithMap.module.css';

// ---------------------------------------------------------------------------------------

const DefaultBg = ({ children, onChangeHeight }) => {
    const { t } = useTranslation();
    const { getScrollContainer } = useScroll();
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const backgroundRef = useRef();
    const [mapHeight, setMapHeight] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        onChangeHeight?.(mapHeight);
    }, [mapHeight]);

    useEffect(() => {
        const scrollContainer = getScrollContainer();

        const onScroll = e => {
            const boundingRect = backgroundRef.current?.getBoundingClientRect();

            setMapHeight(`calc(100vh - ${boundingRect.top}px - ${boundingRect.height}px - 40px)`);
        };

        onScroll();

        scrollContainer.addEventListener('scroll', onScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <>
            <Header
                title={
                    <div className={styles.title}>
                        {t('Properties')}
                        {/* todo: render this conditionally */}
                        <span
                            className={`btn btn-primary-0 ${styles.addPropertyBtn}`}
                            onClick={() => setModalIsVisible(true)}
                        >
                            <IoMdAddCircleOutline size={'20px'} />
                            {t('add-property-header')}
                        </span>
                    </div>
                }
                className={styles.header}
                backgroundImg={backgroundImg}
            ></Header>
            <Background ref={backgroundRef} className={`${styles.headerDiv} ${styles.default}`}>
                {children}
            </Background>
            {modalIsVisible && (
                <AddPropertyModal
                    onClose={() => setModalIsVisible(false)}
                    onSuccessfulSubmit={data => navigate(`edit/${data.id}/info`)}
                />
            )}
        </>
    );
};

const LayoutSeleciton = ({ onChange }) => {
    const [isActiveMap, setIsActiveMap] = useState();
    const { t } = useTranslation();

    useEffect(() => {
        onChange?.(isActiveMap);
    }, [isActiveMap]);

    return (
        <div className={styles.rowDiv}>
            <div className={styles.toggleDiv}>
                <div
                    className={`${styles.mapProperties} ${
                        !isActiveMap && styles.mapPropertiesActive
                    }`}
                    onClick={() => {
                        setIsActiveMap(false);
                    }}
                >
                    <AiOutlineUnorderedList />
                    {t('list')}
                </div>
                <div
                    className={`${styles.mapProperties} ${
                        isActiveMap ? styles.mapPropertiesActive : ''
                    }`}
                    onClick={() => {
                        setIsActiveMap(true);
                    }}
                >
                    <BsMap />
                    {t('map')}
                </div>
            </div>
        </div>
    );
};

export const SearchPaginatedPropertiesWithMap = ({
    mainFilters: mainFilters_,
    extraFilters,
    setSearchIsActive,
    path,
    fetch,
    guestView,
    Background
}) => {
    const [mainFilters, setMainFilters] = useState(mainFilters_ || null);
    const mapRef = useRef(null);
    const [isActiveMap, setIsActiveMap] = useState(false);
    const [isFullMap, setIsFullMap] = useState(false);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [mapHeight, setMaxHeight] = useState();

    const onChangeHeight = height => {
        setMaxHeight(height);
    };

    let timeout = null;

    const setProperties = useCallback(properties => {
        mapRef.current?.setProperties(properties);
    }, []);

    const onPropertyHover = ({ latitude, longtitude }) => {
        isActiveMap &&
            (timeout = setTimeout(() => {
                mapRef.current.setCenter({
                    lat: latitude,
                    lng: longtitude
                });
                mapRef.current.setZoom(14);
                mapRef?.current?.setHighlightedIcon({
                    latitude: latitude,
                    longtitude: longtitude,
                    color: 'yellow'
                });
            }, 1000));
    };

    const onMouseLeave = () => {
        clearTimeout(timeout);
        mapRef?.current?.setHighlightedIcon({
            color: 'black'
        });
    };

    function renderBackground(children) {
        if (Background) {
            return <Background className={`${styles.headerDiv}`}>{children}</Background>;
        } else {
            return <DefaultBg onChangeHeight={onChangeHeight}>{children}</DefaultBg>;
        }
    }

    function renderSearchPropertyForm() {
        return (
            <SearchPropertyForm
                key='searchPropertyForm'
                className={styles.searchPropertyForm}
                onSubmit={filters => {
                    setSearchIsActive?.(true);
                    return setMainFilters(filters);
                }}
            />
        );
    }

    const onChangeLayout = isActiveMap => {
        if (!isActiveMap) setIsFullMap(false);

        setIsActiveMap(isActiveMap);
    };

    const properties = useMemo(() => {
        return (
            <PaginatedProperties
                key='paginatedProperties'
                extraFilters={{ ...mainFilters, ...extraFilters }}
                setProperties={setProperties}
                path={path || '../properties/view'}
                fetch={fetch}
                guestView={guestView}
                canAddProperty
                extraOptions={<LayoutSeleciton onChange={onChangeLayout} />}
            />
        );
    }, [mainFilters, extraFilters]);

    return (
        <div className={Utils.classes(styles.mainContainer, !!mainFilters && 'searched')}>
            {renderBackground(renderSearchPropertyForm())}
            {mainFilters && (
                <div className={`${styles.mainContainer} ${isActiveMap ? styles.activeMap : ''}`}>
                    <div className={styles.propertiesContainer}>
                        {!isFullMap && <div className={styles.propertyListDiv}>{properties}</div>}
                        <div className={`${styles.mapDiv} ${isFullMap ? styles.fullMap : ''}`}>
                            <PropertiesMap
                                ref={mapRef}
                                isActiveMap={isActiveMap}
                                isFullMap={isFullMap}
                                setIsFullMap={setIsFullMap}
                                height={mapHeight}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

/* ---------------------------------------------------------------- */

const AddPropertyModal = ({ onClose, onSuccessfulSubmit }) => {
    const formRef = useRef();
    const { t } = useTranslation();

    const submitProperty = async () => {
        if (!formRef.current) return;

        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();

        if (!!sanityCheckRes.length) return;

        const formData = formRef.current.getData();

        const submitedData = {
            teamId: formData.teamId,
            name: formData.propertyName,
            type: formData.propertyType,
            category: formData.propertyCategory,
            location: formData.propertyLocationMap[0],
            region: formData.locationInfoRegion,
            regionalUnit: formData.locationInfoRegionalUnit,
            street: formData.locationInfoAddress,
            streetNumber: formData.locationInfoAddrNumber
        };

        const { data } = await requests.postProperty(submitedData);

        onSuccessfulSubmit?.(data);
    };

    return (
        <Modal
            onClose={onClose}
            header={t('add-property-header')}
            content={<AddPropertyForm formRef={formRef} includeTeam />}
            className={styles.addPropertyModal}
            footer={
                <>
                    <ButtonWithLoading className='btn' onClick={onClose}>
                        {t('Cancel')}
                    </ButtonWithLoading>
                    <ButtonWithLoading className='btn btn-primary-0' onClick={submitProperty}>
                        {t('Create')}
                    </ButtonWithLoading>
                </>
            }
        />
    );
};
