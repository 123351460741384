import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import requests, { URI } from '../../utils/requests';

import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import styles from './PropertyLeaseCard.module.css';

export const PropertyLeaseCard = ({ id, dateFrom, dateTo, values, leaser, author }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [authorData, setAuthorData] = useState(null);
    const [name, setName] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        requests
            .getUser(author)
            .then(({ data }) => {
                setName(data.name);
                setImage(data.image.path);
            })
            .catch(console.error);
    }, []);

    //request to get the leaser if user
    return (
        <div className={styles.mainDiv}>
            <div className={styles.title} onClick={() => navigate(`./${id}`)}>
                {dateFrom} - {dateTo}
            </div>

            <div className={styles.leaserData}>
                <div>
                    {t('Leaser')} : {leaser.name ? leaser.name : '-'}
                </div>
                <div>
                    {t('AFM')} : {leaser.afm ? leaser.afm : '-'}
                </div>
            </div>

            <div className={styles.values}>
                <div>
                    {t('Μίσθιο')}: {values.val1 ? values.val1 + ' €' : '-'}
                </div>
                <div>
                    {t('Μίσθιο/τμ')}: {values.val2 ? values.val2 + ' €' : '-'}
                </div>
                <div>
                    {t('Τρόπος Πληρωμής')}: {values.val3 ? values.val3 : '-'}
                </div>
                <div className={styles.authorDiv}>
                    {t('author')}
                    <SmallPreviewNameImage
                        fullname={name}
                        imageSrc={`${URI}/${image}`}
                        showName={true}
                        onClick={() => navigate(`../../users/${author}/info`)}
                    />
                </div>
            </div>
        </div>
    );
};
