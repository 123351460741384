import React from 'react';

import { Form } from 'react-form';

export const FormLabel = ({ children, readOnly }) => {
    return (
        <Form
            description={{
                questions: [
                    {
                        title: children,
                        name: 'mambersLabel',
                        type: 'label'
                    }
                ]
            }}
            readOnly={readOnly}
        />
    );
};
