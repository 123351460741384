import React, { useEffect, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import suggestionsDescription from '../../../assets/json/PropertySuggestions';

import styles from './PropertyViewSuggestionsPage.module.css';

export const PropertyViewSuggestionsPage = ({ buildingId, plotId }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!buildingId && !plotId) {
            return;
        }

        (buildingId
            ? requests.getBuildingSuggestion(buildingId)
            : requests.getPlotSuggestion(plotId)
        ).then(({ data }) => {
            setData(data);
        });
    }, [buildingId, plotId]);

    if (!data) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('suggestions title')}</h1>
            </div>

            <div className={styles.formContainer}>
                <Form
                    description={suggestionsDescription()}
                    initialValue={{
                        ...data,
                        images: data.images.map(({ file: { name } }) => ({
                            name
                        }))
                    }}
                    readOnly
                />
            </div>
        </div>
    );
};
