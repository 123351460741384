import React from 'react';

import { useTranslation } from 'react-i18next';

import ManagePropertiesCardList from '../../components/ManagePropertiesCardList/ManagePropertiesCardList';
import { Header } from '../../components/header/Header';

import styles from './UserStatisticsPage.module.css';
import { ManageStatisticsPage } from '../../components/manageStatisticsPage/ManageStatisticsPage';

const UserStatisticsPage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.mainDiv}>
            <div className={styles.headerDiv}>
                <Header title={t('overview')} />
            </div>
            <div className={styles.cardsDiv}>
                <ManageStatisticsPage />
            </div>
        </div>
    );
};

export default UserStatisticsPage;
