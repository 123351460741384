import { useEffect, useMemo, useState } from 'react';

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Table } from 'table';

import { useUser } from '../../context/withUser';

import { ActionLog } from '../../components/actionLog/ActionLog';
import { FormLabel } from '../../components/formLabel/FormLabel';
import { Header } from '../../components/header/Header';
import { HighlightMap } from '../../components/highlightMap/HighlightMap';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';
import { PlotEstimatesByAttributeType } from '../../components/statistics/estimates/PlotEstimatesByAttributeType';
import { PlotLeasesByAttributeType } from '../../components/statistics/leases/PlotLeasesByAttributeType';
import { PlotRenovationsByAttributeType } from '../../components/statistics/renovations/PlotRenovationsByAttributeType';

import requests, { URI } from '../../utils/requests';

import propertyCollage from '../../assets/images/propertyCollage.jpg';

import styles from './AdminHomePage.module.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const options = {
    // responsive: true, // Enable responsiveness
    maintainAspectRatio: true,
    plugins: {
        legend: {
            // position: 'bottom'
            display: false
        },
        title: {
            display: true,
            text: '',
            position: 'bottom',
            font: {
                weight: 'bold',
                size: 14
            }
        }
    }
};

function getNewData(labels, min, max) {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);

    const data = {
        labels,
        datasets: [
            {
                // label: 'Dataset 1',
                data: labels.map(() => Math.floor(Math.random() * (max - min + 1)) + min),
                borderColor: `rgb(${r}, ${g}, ${b})`,
                backgroundColor: `rgba(${r}, ${g}, ${b}, 0.5)`
            }
        ]
    };

    return data;
}

export const AdminHomePage = props => {
    const { user } = useUser();

    const [teams, setTeams] = useState([]);
    const [numOfTeams, setNumOfTeams] = useState(null);
    const [members, setMembers] = useState([]);
    const [numOfMembers, setNumOfMembers] = useState(null);
    const [properties, setProperties] = useState(null);
    const [numOfProperties, setNumOfProperties] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const months = [
        t('January'),
        t('February'),
        t('March'),
        t('April'),
        t('May'),
        t('June'),
        t('July'),
        t('August'),
        t('September'),
        t('October'),
        t('November'),
        t('December')
    ];

    useEffect(() => {
        fetchAndSetData();
        fetchAndSetPropertyStats();
        fetchAndSetTeamStats();
        // eslint-disable-next-line
    }, []);

    const fetchAndSetData = async () => {
        const res = await Promise.all([
            requests.getNumberOfTeams(),
            requests.getNumberOfMembers(),
            requests.getTopTeams(5),
            requests.getTopMembers(5),
            requests.getTopProperties()
            // requests.getNumberOfProperties
        ]).catch(console.log);

        setNumOfTeams(res[0].data.numberOfTeams);
        setNumOfMembers(res[1].data.numberOfUsers);
        setTeams(res[2].data.map(transformTeam));
        setMembers(res[3].data.map(transformMembers));
        setNumOfProperties(res[4].data.length);
        console.log(res);
        setProperties(res[4].data);
    };

    const fetchAndSetPropertyStats = async () => {
        let id = 3; //TODO: this is fake Id to fetch team's stats
        requests
            .getEstimationsByTypeOfProperty(id)
            .then(({ data }) =>
                setStatsData(curr => ({ ...curr, EstimationsByTypeOfProperty: data }))
            );
        requests
            .getLeasesByTypeOfProperty(id)
            .then(({ data }) =>
                setStatsData(curr => ({ ...curr, getLeasesByTypeOfProperty: data }))
            );
        requests
            .getRenovationsByTypeOfRenovation(id)
            .then(({ data }) =>
                setStatsData(curr => ({ ...curr, renovationByTypeOfRenovation: data }))
            );
        requests
            .getLeasesByPaymentType(id)
            .then(({ data }) => setStatsData(curr => ({ ...curr, leasesByPaymentType: data })));
    };
    const fetchAndSetTeamStats = async () => {
        const { data } = await requests.getAllTeams();
        setStatsData(curr => ({ ...curr, teams: data }));
    };

    const TABLE_COLUMNS_TEAMS = useMemo(
        () => [
            {
                accessor: 'team',
                Header: t('Teams'),
                Cell: ({ value: team }) => {
                    if (!team) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            forceShowName
                            onClick={() => navigate(`../teams/${team.id}/info`)}
                        />
                    );
                }
            }
        ],
        // eslint-disable-next-line
        []
    );
    const TABLE_COLUMNS_MEMBERS = useMemo(
        () => [
            {
                accessor: 'user',
                Header: t('Name'),
                Cell: ({ value: user }) => {
                    if (!user) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            forceShowName
                            onClick={() => navigate(`../users/${user.email}/info`)}
                        />
                    );
                }
            }
        ],
        // eslint-disable-next-line
        []
    );
    const TABLE_COLUMNS_PROPERTIES = useMemo(
        () => [
            {
                accessor: 'id',
                Header: t('Id'),
                width: 20,
                Cell: ({ value: property, row }) => {
                    if (!property) return null;

                    return (
                        <div
                            className={styles.topPropertiesTable}
                            onClick={() => navigate(`../properties/view/${row.original.id}/info`)}
                        >
                            {'#' + row.original.id}
                        </div>
                    );
                }
            },
            {
                accessor: 'street',
                Header: t('Name of property'),
                Cell: ({ value: property, row }) => {
                    if (!property) return null;

                    return (
                        <div
                            className={styles.topPropertiesTable}
                            onClick={() => navigate(`../properties/view/${row.original.id}/info`)}
                        >
                            {row.original?.building?.name}
                        </div>
                    );
                }
            }
        ],
        // eslint-disable-next-line
        []
    );
    function transformMembers(member) {
        return {
            user: member
        };
    }
    function transformTeam(team) {
        return {
            team: team
        };
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.welcome}>
                <div className={`${styles.welcomeBg} welcomeBG`}>
                    {/* {properties && <HighlightMap properties={properties} />} */}
                    <img src={propertyCollage} className={styles.propertyImg} />
                </div>
                <div className={styles.welcomeBg} />
                <div className={styles.textDiv}>
                    {t(`welcome admin {{adminName}} at e-Properties`, {
                        adminName: user.name
                    })}
                </div>
            </div>

            <div className={styles.category}>
                <Header
                    onClick={() => navigate('../properties')}
                    title={t('Properties ({{numOfProperties}})', { numOfProperties })}
                />
                <div className={styles.categoryContent}>
                    <div className={styles.categoryRow}>
                        <div className={styles.chartWrap}>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <Line
                                        options={(() => {
                                            const opts = JSON.parse(JSON.stringify(options));
                                            opts.plugins.title.text = t('New properties this year');
                                            return opts;
                                        })()}
                                        data={getNewData(months, 1, 5)}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.categoryPart}>
                            <LoaderWrapper>
                                {properties !== null && (
                                    <Table
                                        resizableColumns={false}
                                        sortableColumns={false}
                                        filters={false}
                                        searchBar={false}
                                        pagination={false}
                                        columns={TABLE_COLUMNS_PROPERTIES}
                                        data={properties.slice(0, 5)}
                                        exporting={false}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>

                <Header
                    onClick={() => navigate('../teams')}
                    title={t('Teams ({{numOfTeams}})', { numOfTeams })}
                />

                <div className={styles.categoryContent}>
                    <div className={styles.categoryRow}>
                        <div className={styles.chartWrap}>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <Line
                                        options={(() => {
                                            const opts = JSON.parse(JSON.stringify(options));
                                            opts.plugins.title.text = t('New Teams this year');
                                            return opts;
                                        })()}
                                        data={getNewData(months, 5, 10)}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.categoryPart}>
                            <LoaderWrapper>
                                <Table
                                    resizableColumns={false}
                                    sortableColumns={false}
                                    filters={false}
                                    searchBar={false}
                                    pagination={false}
                                    columns={TABLE_COLUMNS_TEAMS}
                                    data={teams}
                                    exporting={false}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>

                <Header
                    onClick={() => navigate('../users')}
                    title={t('Members ({{numOfMembers}})', { numOfMembers })}
                />

                <div className={styles.categoryContent}>
                    <div className={styles.categoryRow}>
                        <div className={styles.chartWrap}>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <Line
                                        options={(() => {
                                            const opts = JSON.parse(JSON.stringify(options));
                                            opts.plugins.title.text = t('New users this year');
                                            return opts;
                                        })()}
                                        data={getNewData(months, 50, 100)}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.categoryPart}>
                            <LoaderWrapper size={50} thickness={8}>
                                {numOfMembers && members && (
                                    <Table
                                        resizableColumns={false}
                                        sortableColumns={false}
                                        filters={false}
                                        searchBar={false}
                                        pagination={false}
                                        columns={TABLE_COLUMNS_MEMBERS}
                                        data={members}
                                        exporting={false}
                                    />
                                )}
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>

                <Header onClick={() => navigate('../statistics')} title={t('Statistics')} />

                <div className={styles.categoryContent}>
                    <div className={styles.categoryRow}>
                        <div className={styles.chartWrap}>
                            <h3>{t('Number of Properties per team')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotLeasesByAttributeType
                                        type='bar'
                                        data={teams?.map(({ team }) => ({
                                            name: team.name,
                                            count: team.properties?.length
                                        }))}
                                        attributeType={'count'}
                                        labelType={'name'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.chartWrap}>
                            <h3>{t('Members per team')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotEstimatesByAttributeType
                                        type='bar'
                                        data={statsData?.teams?.map(team => {
                                            const data = {
                                                name: team.name,
                                                count: team.teamMaps?.length
                                            };
                                            return data;
                                        })}
                                        attributeType={'count'}
                                        labelType={'name'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.chartWrap}>
                            <h3>{t('Leases per Payment type')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotLeasesByAttributeType
                                        type='doughnut'
                                        data={statsData?.leasesByPaymentType}
                                        attributeType={'count'}
                                        labelType={'paymentType'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                    </div>
                    <div className={styles.categoryRow}>
                        <div className={styles.chartWrap}>
                            <h3>{t('Estimations by property type')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotEstimatesByAttributeType
                                        type='pie'
                                        data={statsData?.EstimationsByTypeOfProperty}
                                        attributeType={'count'}
                                        labelType={'type'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.chartWrap}>
                            <h3>{t('Leases per Property type')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotLeasesByAttributeType
                                        type='pie'
                                        data={statsData?.getLeasesByTypeOfProperty}
                                        attributeType={'count'}
                                        labelType={'type'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                        <div className={styles.chartWrap}>
                            <h3>{t('Renovations per Renovation type')}</h3>
                            <div className={styles.standardContainer}>
                                <LoaderWrapper>
                                    <PlotRenovationsByAttributeType
                                        type='bar'
                                        data={statsData?.renovationByTypeOfRenovation}
                                        attributeType={'count'}
                                        labelType={'type'}
                                    />
                                </LoaderWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.category}>
                <div className={styles.categoryHeader}>{t('Open-last-activity')}</div>
                <div>
                    <ActionLog pagination={false} limit={10} />
                </div>
            </div>
        </div>
    );
};
