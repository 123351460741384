import React, { useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages.js';

import AddPropertyMapVariable from '../../components/addPropertyMapVariable/addPropertyMapVariable.js';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading.js';

import requests from '../../utils/requests.js';

import description from '../../assets/json/PropertyMapEditFeature.js';

import styles from './MapFeaturesEdit.module.css';

const MapFeaturesEdit = ({ property }) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const addToast = useToasts();

    const postMapInfo = async (propertyId, data) => {
        const ifExist = await requests.getMapInfoProperty(propertyId);
        let res;

        if (!ifExist) {
            res = await requests.createMapInfoToProperty(propertyId, data);
        } else {
            res = await requests.updateMapInfoProperty(propertyId, data);
        }
        return res;
    };

    async function submitFeatures() {
        formRef.current.clearSanityCheck();
        const data = formRef.current.getData();

        postMapInfo(property.id, data)
            .then(response => {
                addToast({
                    type: 'success',
                    message: t('User {{id}} added successfully', { id: property.id }),
                    duration: 5000
                });
            })
            .catch(() => {
                addToast({
                    type: 'failure',
                    message: t('User {{id}} added successfully', { id: property.id }),
                    duration: 5000
                });
            });
    }
    return (
        <div>
            <h1 className={styles.title}>{t('map-feature-edit')}</h1>
            <Form description={description()} ref={formRef} />
            <ButtonWithLoading
                className='btn btn-primary-0'
                onClick={async () => {
                    await submitFeatures();
                }}
            >
                {t('save')}
            </ButtonWithLoading>
            {/* <AddPropertyMapVariable formRef={formRef} property={property} /> */}
        </div>
    );
};

export default MapFeaturesEdit;
