import React, { useEffect, useState } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import styles from './SmallPreviewNameImage.module.css';

function cropName (name) {
    const splited = name?.split(/\s+/);

    let result = '';

    for (const w of splited.slice(0, 3)) {
        result += w.charAt(0).toUpperCase();
    }
    return result;
}

function shortName (name) {
    let n = name.slice(0, 19);
    return n + '...';
}

export const SmallPreviewNameImage = ({
    imageSrc,
    fullname,
    showName = true,
    forceShowName = false,
    onClick,
    width = '40px',
    height = '40px'
}) => {
    const [_showName, setShowName] = useState(forceShowName || showName);
    const { width: windowWidth } = useWindowDimensions();

    useEffect(() => {
        if (!showName) {
            return;
        }

        setShowName(forceShowName || windowWidth > 123);
    }, [windowWidth]);

    return (
        <div className={`${styles.wrap} ${onClick ? styles.clickable : ''}`} onClick={onClick}>
            <div className={styles.container} title={_showName ? '' : fullname}>
                {imageSrc ? (
                    <div
                        className={styles.image}
                        style={{
                            backgroundImage: `url(${imageSrc})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    ></div>
                ) : (
                    <div className={`${styles.imageDefault} `} style={{ width, height }}>
                        {cropName(fullname)}
                    </div>
                )}
                {_showName && (
                    <div className={styles.name}>
                        {fullname?.length > 50 ? shortName(fullname) : fullname}
                    </div>
                )}
            </div>
        </div>
    );
};
