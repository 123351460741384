import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Table } from 'table';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import addFileDescription from '../../assets/json/PropertyDigitalFolder';

import styles from './PropertyDigitalFolderPage.module.css';

const PropertyDigitalFolderPage = ({ canEdit, property, returnBackBtn }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const addToast = useToasts();
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);
    const [editModalState, setEditModalState] = useState(null);
    const [deleteModalState, setDeleteModalState] = useState(null);
    const [digitalFolder, setDigitalFolder] = useState(null);
    const formRef = useRef(null);

    useEffect(() => {
        if (!property) {
            return;
        }

        requests.getPropertyDigitalFolder(property.id).then(({ data }) => {
            console.log(data);
            setDigitalFolder(data);
        });
    }, [property]);

    const uploadFile = useCallback(async () => {
        if (!canEdit) {
            return;
        }

        if (!property || !formRef.current) {
            return;
        }

        formRef.current.clearSanityCheck();
        const sanity = formRef.current.sanityCheckForm();
        if (!!sanity.length) {
            return;
        }

        const { displayName, category, file } = formRef.current.getData();
        const formData = new FormData();
        formData.append('displayName', displayName);
        formData.append('category', category);
        formData.append('file', file);

        await requests.uploadPropertyDigitalFile(property.id, formData);
        setDigitalFolder((await requests.getPropertyDigitalFolder(property.id)).data);
        setUploadModalIsOpen(false);

        addToast({
            type: 'success',
            message: t('success-archive-upload'),
            duration: 3000
        });
    }, [canEdit, property, addToast, t]);

    const editFile = async originalData => {
        if (!formRef.current) {
            return;
        }

        formRef.current.clearSanityCheck();
        const sanity = formRef.current.sanityCheckForm();
        if (!!sanity.length) {
            return;
        }

        const formData = new FormData();
        const { displayName, category, file } = formRef.current.getData();
        formData.append('displayName', displayName);
        formData.append('category', category);
        if (file instanceof File) {
            // NOTE new file has been uploaded
            formData.append('file', file);
            formData.append('fileId', originalData.fileName.id);
        } else {
            // NOTE keep the current file
            const { data } = await axios.get(`${URI}/${originalData.fileName.path}`, {
                responseType: 'blob'
            });
            formData.append('file', new File([data], file.name));
        }

        await requests.editPropertyDigitalFile(property.id, originalData.id, formData);
        setDigitalFolder((await requests.getPropertyDigitalFolder(property.id)).data);
        setEditModalState(null);

        addToast({
            type: 'success',
            message: t('success-archive-edit'),
            duration: 3000
        });
    };

    const deleteFile = async id => {
        await requests.removePropertyDigitalFile(property.id, id);
        setDigitalFolder((await requests.getPropertyDigitalFolder(property.id)).data);
        setDeleteModalState(null);

        addToast({
            type: 'success',
            message: t('success-archive-delete'),
            duration: 3000
        });
    };

    const TABLE_COLUMNS = useMemo(() => {
        const columns = [
            {
                Header: t('display-name'),
                accessor: 'displayName',
                sticky: 'left'
            },
            {
                accessor: 'tCategory',
                Header: t('category')
            },
            {
                accessor: 'createdAt',
                Header: t('createdAt')
            },
            {
                accessor: 'updatedAt',
                Header: t('updatedAt')
            },
            {
                accessor: 'fileName',
                Header: t('file-name'),
                Cell: ({ value: file }) => {
                    if (!file) return null;

                    return (
                        <a
                            className={styles.fileLink}
                            href={`${URI}/${file.path}`}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {file.name}
                        </a>
                    );
                }
            },
            {
                accessor: 'uploader',
                Header: t('updated by'),
                Cell: ({ value: uploader }) => {
                    return (
                        <SmallPreviewNameImage
                            imageSrc={uploader.image && `${URI}/${uploader.image.path}`}
                            fullname={uploader.name}
                            onClick={() => navigate(`../../users/${uploader.email}/info`)}
                            request={() => requests.getUser(uploader.email)}
                        />
                    );
                }
            }
        ];
        if (canEdit) {
            columns.push({
                accessor: 'actions',
                Header: t('actions-edit-delete'),
                Cell: ({ row: { original } }) => (
                    <div className={styles.actionsDiv}>
                        <button
                            className={styles.actionBtn}
                            onClick={() => setEditModalState(original)}
                        >
                            <FaEdit color={'var(--theme-primary)'} size={20} />
                        </button>
                        <button
                            className={styles.actionBtn}
                            onClick={() => setDeleteModalState({ id: original.id })}
                        >
                            <AiFillDelete color={'var(--theme-error-10)'} size={20} />
                        </button>
                    </div>
                ),
                sticky: 'right'
            });
        }
        return columns;
    }, [navigate, t, canEdit]);

    function transformData(digitalFile) {
        const { id, displayName, category, createdAt, updatedAt } = digitalFile;

        const file =
            digitalFile.file || digitalFile.paymentNotifFile || digitalFile.paymentReceiptFile;

        return {
            id,
            displayName,
            category,
            updatedAt: `${Utils.timestampToHumanizedDate(updatedAt)}`,
            createdAt: `${Utils.timestampToHumanizedDate(createdAt)}`,
            tCategory: t(category),
            fileName: file,
            uploader: file.user,
            uploadDate: `${Utils.timestampToHumanizedDate(
                file.uploadDate
            )}, ${Utils.timestampToHumanizedTime(file.uploadDate)}`
        };
    }

    if (!property || !digitalFolder) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title}>{t('digital-folder')}</h1>
                {canEdit && (
                    <ButtonWithLoading
                        className='btn btn-primary-0'
                        onClick={() => setUploadModalIsOpen(true)}
                    >
                        <div className={styles.addBtn}>
                            <IoAddCircleOutline /> {t('add-archive')}
                        </div>
                    </ButtonWithLoading>
                )}
            </div>
            <LoaderWrapper>
                <Table
                    pagination={{ pageSize: 10 }}
                    columns={TABLE_COLUMNS}
                    data={digitalFolder.map(transformData)}
                />
            </LoaderWrapper>
            <div
                className={styles.actionBtnsContainer}
                style={{
                    justifyContent: !!!property.building ? 'space-between' : 'flex-end'
                }}
            >
                {returnBackBtn}
            </div>

            {canEdit && uploadModalIsOpen && (
                <Modal
                    onClose={() => setUploadModalIsOpen(false)}
                    header={t('add-archive')}
                    contentClassName={styles.overflowVisible}
                    content={<Form description={addFileDescription()} ref={formRef} />}
                    footer={
                        <div className={styles.modalFooter}>
                            <ButtonWithLoading
                                className='btn'
                                onClick={() => setUploadModalIsOpen(false)}
                            >
                                {t('cancel')}
                            </ButtonWithLoading>
                            <ButtonWithLoading className='btn btn-primary-0' onClick={uploadFile}>
                                {t('Add')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
            {canEdit && editModalState && (
                <Modal
                    onClose={() => setEditModalState(null)}
                    header={t('edit-file')}
                    contentClassName={styles.overflowVisible}
                    content={
                        <Form
                            description={addFileDescription()}
                            initialValue={{
                                ...editModalState,
                                file: { name: editModalState.fileName.name }
                            }}
                            ref={formRef}
                        />
                    }
                    footer={
                        <div className={styles.modalFooter}>
                            <ButtonWithLoading
                                className='btn'
                                onClick={() => setEditModalState(null)}
                            >
                                {t('cancel')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                                className='btn btn-primary-0'
                                onClick={() => editFile(editModalState)}
                            >
                                {t('save')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
            {canEdit && deleteModalState && (
                <Modal
                    onClose={() => setDeleteModalState(null)}
                    header={t('delete-archive')}
                    content={t('delete-archive-prompt')}
                    footer={
                        <div className={styles.modalFooter}>
                            <ButtonWithLoading
                                className='btn'
                                onClick={() => setDeleteModalState(null)}
                            >
                                {t('cancel')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                                className='btn btn-error'
                                onClick={() => deleteFile(deleteModalState.id)}
                            >
                                {t('delete')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
        </div>
    );
};

export default PropertyDigitalFolderPage;
