// Import Swiper React components
import { A11y, EffectFade, Navigation, Pagination, Scrollbar } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Carousel.css';

export const Carousel = ({ contents, active }) => {
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
            spaceBetween={20}
            navigation
            scrollbar={{ draggable: true }}
            // onSlideChange={() => console.log('slide change')}
            slidesPerView='1'
            paginationclickable='true'
            pagination={{
                el: '.swiper-pagination',
                clickable: 'true'
            }}
            initialSlide={contents.findIndex(c => c === active)}
        >
            {contents.map((c, i) => (
                <SwiperSlide key={i}>
                    <div
                        style={{
                            height: '80vh',
                            display: 'flex',
                            userSelect: 'none'
                        }}
                    >
                        <img
                            key={c}
                            src={c}
                            style={{
                                maxHeight: '80vh',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                            alt={c}
                        />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
