// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateRolePage_container__GLb4x {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-items: flex-start;\n    max-width: 1200px;\n    margin: auto;\n}\n\n.CreateRolePage_container__GLb4x > .CreateRolePage_saveBtn__53e1H {\n    margin-top: 40px;\n}\n\n.CreateRolePage_title__D-\\+wv {\n    display: flex;\n    gap: 40px;\n    align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/teamPages/createRolePage/CreateRolePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-items: flex-start;\n    max-width: 1200px;\n    margin: auto;\n}\n\n.container > .saveBtn {\n    margin-top: 40px;\n}\n\n.title {\n    display: flex;\n    gap: 40px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateRolePage_container__GLb4x",
	"saveBtn": "CreateRolePage_saveBtn__53e1H",
	"title": "CreateRolePage_title__D-+wv"
};
export default ___CSS_LOADER_EXPORT___;
