import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

import propertyCollage from '../../assets/images/propertyCollage.jpg';
import Utils from '../../utils/utils';

import styles from './NewPropertiesPage.module.css';

const Background = props => {
    return (
        <div className={Utils.classes(styles.welcome, props.className)}>
            <div className={`${styles.welcomeBg} welcomeBG`}>
                {/* <HighlightMap properties={properties} height={'100%'} /> */}
                <img src={propertyCollage} className={styles.propertyImg} />
            </div>
            <div className={styles.overlay} />
            <div className={styles.content}>{props.children}</div>
        </div>
    );
};

export const NewPropertiesPage = props => {
    return (
        <SearchPaginatedPropertiesWithMap
            extraFilters={{ isPublic: true }}
            Background={Background}
            path='../properties/view'
        />
    );
};
