import React from 'react';

import { Pie } from 'react-chartjs-2';

export const PlotPie = ({ data }) => {
    const options = {
        aspectRatio: 1.5, // Adjust the value to change the aspect ratio of the chart,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                    boxWidth: 10
                }
            }
        }
    };

    return <Pie data={data} options={options} />;
};
