const Utils = {};

// --------------------------------------------------------------------------------------

Utils.classes = function () {
    const classNames = Array.prototype.slice.call(arguments);
    return classNames.filter(Utils.isString).join(' ');
};

Utils.isString = value => value && (typeof value === 'string' || value instanceof String);

// --------------------------------------------------------------------------------------

/**
 * Get from localStorage the value of chosen-theme
 *
 * @returns returns true if the user has enabled dark mode
 * @deprecated use getCurrentTheme() instead
 */
Utils.isDarkMode = () => {
    const theme = localStorage.getItem('chosen-theme');
    return theme === 'dark' || theme === null;
};

/**
 * Get from localStorage the value of chosen-theme
 *
 * @returns {String} the active theme
 */
Utils.getCurrentTheme = () => {
    return localStorage.getItem('chosen-theme');
};

/**
 * Set the localStorage on chosen-theme based on param
 *
 * @param {Boolean} toShow
 */
Utils.changeTheme = themeName => {
    const transitionDuration = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--theme-transition-duration');
    localStorage.setItem('chosen-theme', themeName);
    document.documentElement.className = Utils.classes('theme-transition', themeName);
    setTimeout(() => {
        document.documentElement.classList.remove('theme-transition');
    }, Number(transitionDuration.slice(0, transitionDuration.length - 2)));
};

// --------------------------------------------------------------------------------------

/**
 * Translate the timestamp into a readable form for a human
 *
 * @param {Number} [timestamp = Date.now()]
 * @returns a different format for the date
 */
Utils.timestampToHumanizedDate = (timestamp = Date.now()) => {
    const date = new Date(timestamp);
    const data = [];
    data.push(
        Utils.addZeroIfSingleDigit(date.getDate()),
        Utils.addZeroIfSingleDigit(date.getMonth() + 1),
        Utils.addZeroIfSingleDigit(date.getFullYear())
    );
    return data.join('/');
};

Utils.timestampToHumanizedTime = (timestamp = Date.now()) => {
    const date = new Date(timestamp);
    const data = [
        Utils.addZeroIfSingleDigit(date.getHours()),
        Utils.addZeroIfSingleDigit(date.getMinutes())
    ];
    return data.join(':');
};

Utils.getDayName = (timestamp = Date.now()) => {
    const date = new Date(timestamp);
    return Utils.dayNames[date.getDay()];
};

Utils.dayNames = ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο'];

Utils.bytesToKilobytes = bytes => (bytes === 0 ? 0 : bytes / 1000);
Utils.kilobytesToMegabytes = kilobytes => (kilobytes === 0 ? 0 : kilobytes / 1000);
Utils.bytesToMegabytes = bytes => Utils.kilobytesToMegabytes(Utils.bytesToKilobytes(bytes));

// --------------------------------------------------------------------------------------

Utils.getOrganizationEmail = (params, user) => params.id || user.email;

// --------------------------------------------------------------------------------------

Utils.forEachQuestion = (question, cb) => {
    if (question.hasOwnProperty('questions')) {
        question.questions.forEach(question => Utils.forEachQuestion(question, cb));
    } else {
        cb(question);
    }
};

Utils.getAllQuestionNames = form => {
    const questionNames = [];
    Utils.forEachQuestion(form, question => {
        questionNames.push(question.name);
    });
    return questionNames;
};

Utils.disableQuestions = (description, questionMap) => {
    description = JSON.parse(JSON.stringify(description));

    function disableQuestionsInternal(description, questionMap) {
        if (questionMap?.includes?.(description.name)) {
            description.isDisabled = true;
        }
        if (!description.questions) {
            return;
        }
        for (const question of description.questions) {
            disableQuestionsInternal(question, questionMap?.[description.name]);
        }
    }
    disableQuestionsInternal(description, questionMap);

    return description;
};

// --------------------------------------------------------------------------------------

Utils.addZeroIfSingleDigit = n => (n < 10 ? `0${n}` : n);

Utils.getHumanizedMonth = month => {
    const months = [
        'Ιανουάριος',
        'Φεβρουάαριος',
        'Μάρτιος',
        'Απρίλιος',
        'Μάιος',
        'Ιούνιος',
        'Ιούλιος',
        'Αύγουστος',
        'Σεπτέμβριος',
        'Οκτώβριος',
        'Νοέμβριος',
        'Δεκέμβριος'
    ];

    return months[month - 1];
};

// --------------------------------------------------------------------------------------

/**
 * Change the given string based on params
 *
 * @param {String} message A string with {[X]} labels for parameterized inputs
 * @param {Array<String>} params the replacable values
 * @returns A String with replaced values based on params
 */
Utils.createParameterizedMessage = (message, params) => {
    for (let i = 0; i < params.length; ++i) {
        message = message.replace(new RegExp(`{[${i}]}`), params[i]);
    }
    return message;
};

Utils.interpolateText = (message, params) => {
    for (const [paramName, paramValue] of Object.entries(params)) {
        message = message.replace(new RegExp(`{${paramName}}`), paramValue);
    }
    return message;
};

// --------------------------------------------------------------------------------------

Utils.traslateTargetGroupMap = {
    FAMILY_AND_YOUTH: 'Οικογένεια, παιδί & νεότητα',
    AMEA: 'Α.μεΑ. (σωματική – ψυχική – νοητική υγεία)',
    ELDERS: 'Ηλικιωμένοι',
    VULNERABLE_GROUPS: 'Ευπαθείς ομάδες & ομάδες που τελούν σε κατάσταση έκτακτης ανάγκης'
};

Utils.translateTargetGroup = v => {
    if (!Utils.traslateTargetGroupMap.hasOwnProperty(v)) {
        console.assert(false, `No such target population gender ${v}`);
    }
    return Utils.traslateTargetGroupMap[v];
};

Utils.translateTargetPopulationAgeMap = {
    UNDER_5: 'Κάτω των 5 ετών',
    AGE_6_12: 'Μεταξύ 6 - 12 ετών',
    AGE_13_17: 'Μεταξύ 13 - 17 ετών',
    AGE_18_25: 'Μεταξύ 18 - 25 ετών',
    AGE_26_45: 'Μεταξύ 26 - 45 ετών',
    AGE_46_65: 'Μεταξύ 46 - 65 ετών',
    AGE_66_80: 'Μεταξύ 66 - 80 ετών',
    OVER_81: 'Άνω των 81 ετών'
};

Utils.translateTargetPopulationAge = v => {
    if (!Utils.translateTargetPopulationAgeMap.hasOwnProperty(v)) {
        console.assert(false, `No such target population age ${v}`);
    }
    return Utils.translateTargetPopulationAgeMap[v];
};

Utils.translateExistingStructureMap = {
    GUESTHOUSE: 'Ξενώνας',
    NURSERY_SCHOOL: 'Βρεφονηπιακός σταθμός',
    DAY_CENTER: 'Κέντρο ημέρας',
    NIGHTCLUB: 'Κέντρο διανυκτέρευσης',
    LAB: 'Εργαστήριο',
    PROTECTED_COMPARTMENT: 'Προστατευμένο διαμέρισμα',
    PHONE_LINE: 'Τηλεφωνική γραμμή',
    KINDERGARTEN: 'Παιδικός σταθμός',
    MFH: 'Μονάδα Φροντίδας Ηλικιωμένων (ΜΦΗ)',
    BOARDING_SCHOOL: 'Οικοτροφείο',
    SOCIAL_GROCERY: 'Κοινωνικό παντοπωλείο',
    PREVENTION_CENTER: 'Κέντρο πρόληψης',
    CAMP: 'Κατασκήνωση',
    CLINIC: 'Ιατρείο',
    PHARMACY: 'Φαρμακείο',
    KDAP: 'Κέντρο Δημιουργικής Απασχόλησης Α.μεΑ. (Κ.Δ.Α.Π. - Α.μεΑ.)',
    FITNESS_TRAINING_CENTER: 'Γυμναστήριο-προπονητήριο',
    CONSULTING_UNIT: 'Συμβουλευτικός σταθμός',
    CHRONIC_DISEASE_TREATMENT_CLINIC: 'Θεραπευτήριο Χρονίων Παθήσεων',
    CHILD_PROTECTION_UNIT: 'Μονάδα Προστασίας και Φροντίδας (ΜοΠΠ)',
    KDHF: 'Κέντρο Διημέρευσης και Ημερήσιας Φροντίδας (Κ.Δ.Η.Φ.)',
    KAA: 'Κέντρο Αποθεραπείας και Αποκατάστασης',
    PSYCHOLOGICAL_HEALTH_PROMOTION_CENTER:
        'Κέντρο πρόληψης και προαγωγής της ψυχοκοινωνικής υγείας',
    SEMI_INDEPENDENT_APARTMENT: 'Διαμέρισμα Ημιαυτόνομης Διαβίωσης',
    DORMITORY: 'Υπνωτήριο',
    AKHA: 'Ανοικτό Κέντρο Ημέρας Αστέγων (ΑΚΗΑ)',
    KHFH: 'Κέντρο Φροντίδας Ηλικιωμένων (ΚΗΦΗ)',
    MOBILE_UNIT: 'Κινητή Μονάδα',
    SYD: 'Στέγη Υποστηριζόμενης Διαβίωσης',
    OTHER: 'Άλλο'
};

Utils.translateExistingStructure = v => {
    if (!Utils.translateExistingStructureMap.hasOwnProperty(v)) {
        console.assert(false, `Invalid structure type ${v}`);
    }
    return Utils.translateExistingStructureMap[v];
};

Utils.translateProvidedServicesMap = {
    BLOOD_DONATION: 'Αιμοδοσία',
    OVERNIGHT_STAY: 'Διανυκτέρευση',
    INTERCULTURAL_MEDIATION: 'Διαπολιτισμική διαμεσολάβηση',
    INFORMATION_MATERIAL_PUBLICATION:
        'Έκδοση ενημερωτικού/επιστημονικού υλικού (βιβλία, αφίσες, φυλλάδια, περιοδικά, κ.α.)',
    VOLUNTEER_TRAINING: 'Εκπαίδευση εθελοντών',
    PROFESSINOAL_TRAINING: 'Εκπαίδευση επαγγελματιών',
    INFORMATION: 'Ενημέρωση/πληροφόρηση',
    VOCATIONAL_TRAINING: 'Επαγγελματική κατάρτιση',
    VOCATIONAL_GUIDANCE: 'Επαγγελματικός προσανατολισμός',
    ERGOTHERAPY: 'Εργοθεραπεία',
    CAMPING: 'Κατασκήνωση',
    SPEECH_THERAPY: 'Λογοθεραπεία',
    LEGAL_AID: 'Νομική βοήθεια',
    PEDAGOGICAL_ACTIVITIES: 'Παιδαγωγικές δραστηριότητες',
    DEFENDING_RIGHTS: 'Προάσπιση δικαιωμάτων και διεκδίκηση θεσμικών αλλαγών',
    PREVENTIVE_MEDICAL_MONITORING: 'Προληπτική ιατρική παρακολούθηση',
    PRIMARY_NURSING_CARE: 'Πρωτοβάθμια νοσηλευτική φροντίδα (πρώτες βοήθειες)',
    FEEDING: 'Σίτιση',
    CONSULTING_SUPPORT: 'Συμβουλευτική στήριξη',
    ACCOMPANYING_PATIENTS: 'Συνοδεία ασθενών',
    PLACEMENT_IN_JOBS: 'Τοποθέτηση σε θέσεις εργασίας',
    MATERIAL_ASSISTANCE: 'Υλική βοήθεια (είδος και χρήμα)',
    PHARMACEUTICAL_CARE: 'Φαρμακευτική φροντίδα',
    HOSPITALITY: 'Φιλοξενία',
    CARE_CREATIVE_TIME:
        'Φροντίδα – δημιουργική απασχόληση (Κ.Δ.Α.Π. Α.μεΑ., βρεφονηπιακοί σταθμοί, κ.α.)',
    PERSONAL_HYGIENE_CARE: 'Φροντίδα ατομικής υγιεινής',
    HOME_CARE: 'Φροντίδα στο σπίτι',
    PHYSICAL_THERAPY: 'Φυσικοθεραπεία',
    PSYCHIATRIC_MONITORING: 'Ψυχιατρική παρακολούθηση',
    PSYCHOLOGICAL_SUPPORT: 'Ψυχολογική υποστήριξη'
};

Utils.translateProvidedServices = v => {
    if (!Utils.translateProvidedServicesMap.hasOwnProperty(v)) {
        console.assert(false, `No such provided service ${v}`);
    }
    return Utils.translateProvidedServicesMap[v];
};

// --------------------------------------------------------------------------------------

Utils.group = (items, groupFunctions) => {
    console.assert(items?.length && groupFunctions?.length);
    const restGroup = [];
    const groups = [...groupFunctions.map(f => []), restGroup];

    groups.push(restGroup);
    for (const item of items) {
        let hasGroup = false;
        for (let i = 0; i < groupFunctions.length; ++i) {
            if (groupFunctions[i](item)) {
                groups[i].push(item);
                hasGroup = true;
            }
        }
        if (!hasGroup) {
            restGroup.push(item);
        }
    }
    return groups;
};

// --------------------------------------------------------------------------------------

Utils.createStructureDropdown = (title, structures) => {
    return {
        title,
        name: 'existingStructureId',
        isRequired: true,
        type: 'dropdown',
        choices: structures.map(s => ({
            title: s.guesthouseTitle,
            value: s.id
        }))
    };
};

// --------------------------------------------------------------------------------------

Utils.listToText = (
    list,
    translationMap,
    postfix = '',
    separator = ', ',
    lastLinkingWord = 'και'
) => {
    console.assert(list);
    const translate = t => translationMap[t] || t;
    let text;
    if (list.length === 1) {
        text = translate(list[0]);
    } else {
        const lastText = translate(list.pop());
        text = list.map(translate).join(separator) + ` ${lastLinkingWord} ${lastText}`;
    }
    return text + postfix;
};

Utils.certificationTranslationMap = {
    FIRST_DEGREE: 'Πρωτοβάθμια',
    SECOND_DEGREE: 'Δευτεροβάθμια',
    THIRD_DEGREE: 'Τρίτοβάθμια'
};

Utils.certificationShortTranslationMap = {
    FIRST_DEGREE: 'Πρώτου',
    SECOND_DEGREE: 'Δευτέρου',
    THIRD_DEGREE: 'Τρίτου'
};

Utils.getCertificationText = (degrees, short = false) => {
    return Utils.listToText(
        degrees.split(','),
        short ? Utils.certificationShortTranslationMap : Utils.certificationTranslationMap,
        short ? ' βαθμού' : ' κοινωνική φροντίδα'
    );
};

// --------------------------------------------------------------------------------------

Utils.interpolateTitles = (description, params) => {
    description.title = description.title && Utils.interpolateText(description.title, params);
    description?.questions?.forEach(q => Utils.interpolateTitles(q, params));
};

// --------------------------------------------------------------------------------------

Utils.translateFormDescription = (t, description) => {
    if (!t) return description;
    const translated = JSON.parse(JSON.stringify(description));
    for (let [key, value] of Object.entries(translated)) {
        if (key === 'title' && (translated.showTitle === undefined || !!translated.showTitle)) {
            translated[key] = t(value);
        }
        if (key === 'questions' || key === 'choices') {
            translated[key] = value.map(v => Utils.translateFormDescription(t, v));
        }
        if (key === 'placeholder') {
            translated[key] = t(value);
        }
    }
    return translated;
};

Utils.generateFormYearOptions = (yearsToShow, valueAsNumber = true) => {
    const d = new Date();
    const years = [];

    for (let i = 0; i < yearsToShow; ++i) {
        const currYear = d.getFullYear();
        years.push({
            title: currYear,
            value: valueAsNumber ? currYear : `${currYear}`
        });
        d.setFullYear(currYear - 1);
    }

    return years;
};

// --------------------------------------------------------------------------------------

Utils.hexToRgb = hexColor => {
    hexColor = hexColor.replace('#', '');

    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    return { r, g, b };
};

Utils.isColorLight = hexColor => {
    const rgbColor = Utils.hexToRgb(hexColor);

    const perceivedBrightness = Math.sqrt(
        0.299 * Math.pow(rgbColor.r, 2) +
            0.587 * Math.pow(rgbColor.g, 2) +
            0.114 * Math.pow(rgbColor.b, 2)
    );

    return perceivedBrightness >= 150;
};

export default Utils;
