import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests from '../../utils/requests';

import optionsDescription from '../../assets/json/PropertyOptions';

import styles from './BuildingOptionsPage.module.css';

const BuildingOptionsPage = ({ building }) => {
    const { t } = useTranslation();
    const addToast = useToasts();
    const [buildingStatus, setBuildingStatus] = useState(null);
    const formRef = useRef(null);

    useEffect(() => {
        requests.getBuildingPublicStatus(building.id).then(({ data }) => {
            setBuildingStatus(data);
        });
    }, [building]);

    if (!buildingStatus) return null;

    const submitOptions = async () => {
        if (!formRef.current) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const { exposedUntil, price, exposed } = formRef.current.getData();
        await requests.updateBuildingPublicStatus(building.id, {
            isExposed: exposed === 'isExposed',
            exposedUntil,
            price
        });

        addToast({
            type: 'success',
            message: t('property-publish-prompt'),
            duration: 3000
        });
    };

    return (
        <div className={styles.pageWrap}>
            <h1>{t('options')}</h1>
            <Form
                description={optionsDescription()}
                ref={formRef}
                initialValue={{
                    price: buildingStatus.price,
                    exposedUntil: buildingStatus.exposedUntil,
                    exposed: buildingStatus.isExposed ? 'isExposed' : 'isNotExposed'
                }}
            />
            <div className={styles.actionBtnsContainer}>
                <button className={`btn btn-primary-0 ${styles.actionBtn}`} onClick={submitOptions}>
                    <AiOutlineCheck /> {t('save')}
                </button>
            </div>
        </div>
    );
};

export default BuildingOptionsPage;
