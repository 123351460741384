import GB from 'country-flag-icons/react/3x2/GB';
import GR from 'country-flag-icons/react/3x2/GR';
import i18next from 'i18next';

import { LanguagePreview } from './LanguagePreview';

import styles from './LanguagePicker.module.css';

export const LanguageList = () => {
    const options = [
        { value: 'el', label: 'Ελληνικά' },
        { value: 'en', label: 'English' }
    ];

    const imgs = {
        en: <GB className={styles.flag} />,
        el: <GR className={styles.flag} />
    };

    const onChange = ({ value }) => {
        i18next.changeLanguage(value);
    };

    return (
        <LanguagePreview
            options={options}
            defaultValue={options.find(o => o.value === i18next.language.split('-')[0])}
            imgs={imgs}
            onChange={onChange}
        />
    );
};
