import i18next from 'i18next';

import Utils from '../../utils/utils';

const getDescription = () => {
    return {
        title: 'propertyEstimateForm',
        showTitle: false,
        name: 'propertyEstimateForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Γενικές Πληροφορίες'),
                name: 'propertyEstimatesGeneralInfo',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Όνομα Εκτίμησης'),
                name: 'estimation',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Ημερομηνία Εκτίμησης'),
                name: 'date',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εκτιμητής'),
                name: 'Estimator',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Ονοματεπώνυμο Εκτιμητή'),
                name: 'name',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('ΑΦΜ Εκτιμητή'),
                name: 'afm',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 10
                }
            },
            {
                title: i18next.t('Αυτοψία'),
                name: 'propertySuggestionsAutopsy',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Τύπος Θέρμανσης'),
                name: 'propertySuggestionsHeatingType',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Τύπος Ανελκυστήρα'),
                name: 'propertySuggestionsElevatorType',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Επίπεδο Συντήρησης'),
                name: 'propertySuggestionsMaintenanceLevel',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Έτος Ανακαίνισης'),
                name: 'yearOfRenovation',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                placeholder: i18next.t('Select..'),
                choices: Utils.generateFormYearOptions(80, false)
            },
            {
                title: i18next.t('Φάση Κατασκευής'),
                name: 'propertySuggestionsConstractionPhase',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Ποιότητα Κατασκευής'),
                name: 'propertySuggestionsConstractionQuality',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Εξωτερική Κατασκευή'),
                name: 'propertySuggestionsOutConstraction',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Εσωτερική Κατασκευή'),
                name: 'propertySuggestionsInConstraction',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Κατοικείται'),
                name: 'propertySuggestionsIsInhabited',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Αξιοποιείται'),
                name: 'propertySuggestionsIsUsed',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Ηλεκτροδοτείται'),
                name: 'propertySuggestionsIsElectrified',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Υδροδοτείται'),
                name: 'propertySuggestionsHydrated',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Περιγραφή Κτιρίου'),
                name: 'propertySuggestionsDescription',
                type: 'freetext',
                isRequired: false,
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Συγκριτικά Στοιχεία'),
                name: 'marketComparison',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Ανάλυση Κτηματαγοράς'),
                name: 'marketAnalisisAndComparison',
                type: 'freetext',
                isRequired: false,
                space: '100%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Κόστος Αντικατάστασης'),
                name: 'ReplacementCost',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Κόστος Αντικατάστασης Νεοδόμητου'),
                name: 'newConstructionReplacementCost',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Κόστος Αντικατάστασης με παλαιότητα'),
                name: 'oldReplacementCost',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Αξίες'),
                name: 'Values',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Μισθωτική Αξία'),
                name: 'rentalValue',
                type: 'float',
                isRequired: false,
                space: '100%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Υλική Αξία'),
                name: 'materialValue',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Ασφαλιστέα Αξία'),
                name: 'insurableValue',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Εμπορική Αξία'),
                name: 'marketValue',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Τελική Αγοράια Αξία'),
                name: 'finalMarketValue',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'MoreDetails',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'notes',
                type: 'freetext',
                isRequired: false,
                space: '100%',
                restrictions: {
                    validateFieldsName: 1000
                }
            }
        ]
    };
};

export default getDescription;
