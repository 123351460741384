import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiFillLock } from 'react-icons/ai';

import styles from '../Error.module.css';

export const Error403Page = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.error}>
                <AiFillLock className={styles.icon} />
                <h1> {`${t('Error')} 403`} </h1>
            </div>
            <div>
                <h2> {t("You don't have access to this page")} </h2>
                <p>{t('Please contact the administrators or try to reload the page')}</p>
            </div>
            <button className='btn btn-primary-0'>
                <Link to='/'> {t('Back to the home page')} </Link>
            </button>
        </div>
    );
};
