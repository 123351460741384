import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

import Utils from '../../utils/utils';

import styles from './Pagination.module.css';

const maxVisiblePageButtons = 9;
const halfMaxVisiblePageButtons = Math.floor(maxVisiblePageButtons / 2);

const Pagination = ({ setCurrentPage, totalRows, rowsPerPage, currentPage, className }) => {
    const { t } = useTranslation();
    const totalPages = Math.ceil(totalRows / rowsPerPage);

    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onLastPage = () => setCurrentPage(totalPages);
    const onFirstPage = () => setCurrentPage(1);
    const onPageSelect = pageNo => setCurrentPage(pageNo);

    useEffect(() => {
        setCanGoNext(totalPages !== currentPage);
        setCanGoBack(currentPage !== 1);
    }, [totalPages, currentPage]);

    function renderPageButtons() {
        const buttons = [];
        const minPage = Math.max(0, currentPage - 1 - halfMaxVisiblePageButtons);
        const maxPage = Math.min(totalPages, currentPage + halfMaxVisiblePageButtons);
        for (let i = minPage; i < maxPage; ++i) {
            buttons.push(
                <button
                    key={`page-button-${i}`}
                    onClick={() => onPageSelect(i + 1)}
                    className={`${styles.pageBtn}  ${
                        i + 1 === currentPage ? styles.activeBtn : ''
                    }`}
                >
                    {i + 1}
                </button>
            );
        }
        return buttons;
    }

    return (
        <div className={Utils.classes(styles.pagination, className)}>
            {/* {renderPaginationInfo()} */}
            <div className={styles.leftSideButtonContainer}>
                <button
                    className={Utils.classes(styles.sideBtn, styles.previousBtn)}
                    onClick={onFirstPage}
                    disabled={!canGoBack}
                    title={t('First Page')}
                >
                    <FiChevronsLeft className={styles.sideButtonIcon} />
                </button>
                <button
                    className={Utils.classes(styles.sideBtn, styles.previousBtn)}
                    onClick={onPrevPage}
                    disabled={!canGoBack}
                    title={t('Previous Page')}
                >
                    <FiChevronLeft className={styles.sideButtonIcon} />
                </button>
            </div>
            <div>{renderPageButtons()}</div>
            <div className={styles.rightSideButtonContainer}>
                <button
                    className={Utils.classes(styles.sideBtn, styles.nextBtn)}
                    onClick={onNextPage}
                    disabled={!canGoNext}
                    title={t('Next Page')}
                >
                    <FiChevronRight />
                </button>
                <button
                    className={Utils.classes(styles.sideBtn, styles.nextBtn)}
                    onClick={onLastPage}
                    disabled={!canGoNext}
                    title={`${t('Last Page')} (${totalPages})`}
                >
                    <FiChevronsRight />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
