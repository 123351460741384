// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnderConstructionComponent_pageWrap__4fPQ- {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 60vh;\n}\n", "",{"version":3,"sources":["webpack://./src/components/underConstructionComponent/UnderConstructionComponent.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".pageWrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 60vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "UnderConstructionComponent_pageWrap__4fPQ-"
};
export default ___CSS_LOADER_EXPORT___;
