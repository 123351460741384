// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyEditPropertyPage_pageWrap__pQDEY {\n    display: flex;\n    flex-direction: column;\n}\n\n.PropertyEditPropertyPage_actionBtnsContainer__amvte {\n    width: 100%;\n\n    margin-top: 10px;\n    display: flex;\n    justify-content: flex-end !important;\n}\n\n.PropertyEditPropertyPage_actionBtn__xbgtj {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/propertyEditPages/PropertyEditPropertyPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;;IAEX,gBAAgB;IAChB,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,SAAS;;IAET,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    margin-top: 10px;\n    display: flex;\n    justify-content: flex-end !important;\n}\n\n.actionBtn {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "PropertyEditPropertyPage_pageWrap__pQDEY",
	"actionBtnsContainer": "PropertyEditPropertyPage_actionBtnsContainer__amvte",
	"actionBtn": "PropertyEditPropertyPage_actionBtn__xbgtj"
};
export default ___CSS_LOADER_EXPORT___;
