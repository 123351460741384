import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { IoAddCircleOutline } from 'react-icons/io5';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { PropertyEstimateCard } from '../../../components/propertyEstimateCard/PropertyEstimateCard';

import requests from '../../../utils/requests';

import estimateDescription from '../../../assets/json/PropertyEstimate.js';

import styles from './PropertyEditEstimatesPage.module.css';

async function onSubmitEstimate(buildingId, plotId, estimateId, formRef) {
    if (!formRef.current) {
        return false;
    }
    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();
    if (!!sanityCheckRes.length) {
        return false;
    }

    if (buildingId) {
        if (!estimateId) {
            //do post request...
            return requests.createBuildingEstimate(buildingId, formRef.current.getData());
        } else {
            return requests.updateBuildingEstimate(
                buildingId,
                estimateId,
                formRef.current.getData()
            );
        }
    }

    if (plotId)
        if (!estimateId) {
            //do post request...
            return requests.createPlotEstimate(plotId, formRef.current.getData());
        } else {
            return requests.updatePlotEstimate(plotId, estimateId, formRef.current.getData());
        }
}

export const PropertyEditEstimatesPage = ({ buildingId, plotId, navigateBack }) => {
    const { t } = useTranslation();
    const { id: estimateId } = useParams();
    const [data, setData] = useState(null);
    const formRef = useRef(null);

    // if(!id) do not fetch data...
    useEffect(() => {
        if (!estimateId) {
            return;
        }
        (buildingId
            ? requests.getBuildingEstimate(buildingId, estimateId)
            : requests.getPlotEstimate(plotId, estimateId)
        ).then(({ data }) => setData(data));
    }, [buildingId, plotId, estimateId]);

    if (data === null && estimateId) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.pageTitle}>
                {estimateId ? t('Edit estimation') : t('Create new estimation')}
            </h1>

            <div className={styles.formContainer}>
                <Form description={estimateDescription()} initialValue={data} ref={formRef}></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>
                        <AiOutlineClose />
                        {t('cancel')}
                    </div>
                </ButtonWithLoading>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={async () => {
                        (await onSubmitEstimate(buildingId, plotId, estimateId, formRef)) &&
                            navigateBack();
                    }}
                >
                    <div className={styles.btnContent}>
                        <AiOutlineCheck />
                        {t('save')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export const PropertyEstimatesHistoryPage = ({ isEdit = false, buildingId, plotId }) => {
    const { t } = useTranslation();
    const [propertyEstimates, setPropertyEstimates] = useState(null);
    const [filteredEstimates, setFilteredEstimates] = useState(null);

    const [sort, setSort] = useState('NEW');
    const navigate = useNavigate();
    const formRefSortBy = useRef(null);
    const formRefFilters = useRef(null);

    const dateFilterDesc = {
        title: 'dateFilterForm',
        showTitle: false,
        name: 'dateFilterForm',
        type: 'form',
        questions: [
            {
                title: t('Από'),
                name: 'dateFrom',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: t('Έως'),
                name: 'dateTo',
                type: 'date',
                isRequired: false,
                space: '50%'
            }
        ]
    };

    const sortByDesc = {
        title: 'sortByForm',
        showTitle: false,
        name: 'sortByForm',
        type: 'form',
        questions: [
            {
                title: t('ταξινόμηση'),
                name: 'sortBy',
                type: 'dropdown',
                space: '100%',
                value: 'NEW',
                choices: [
                    {
                        title: t('newest'),
                        value: 'NEW'
                    },
                    {
                        title: t('oldest'),
                        value: 'OLD'
                    }
                ]
            }
        ]
    };

    useEffect(() => {
        (buildingId
            ? requests.getAllBuildingEstimates(buildingId)
            : requests.getAllPlotEstimates(plotId)
        ).then(({ data }) => {
            data.forEach(c => (c.date = new Date(c.date)));
            setPropertyEstimates(data);
            setFilteredEstimates(data);
        });
    }, [buildingId, plotId]);

    const clearFilters = () => {
        if (!formRefFilters.current) return;
        formRefFilters.current.clear();
        setFilteredEstimates(propertyEstimates);
    };

    const applyFilters = () => {
        if (!formRefFilters.current) return;
        const { dateFrom: dateFromData, dateTo: dateToData } = formRefFilters.current.getData();

        const dateFrom = new Date(dateFromData);
        const dateTo = new Date(dateToData);

        if (dateFromData && !dateToData) {
            const tempRes = propertyEstimates.filter(p => new Date(p.date) >= dateFrom);
            setFilteredEstimates(tempRes);
        } else if (!dateFromData && dateToData) {
            const tempRes = propertyEstimates.filter(p => new Date(p.date) <= dateTo);
            setFilteredEstimates(tempRes);
        } else if (dateFromData && dateToData) {
            const tempRes = propertyEstimates.filter(
                p => new Date(p.date) >= dateFrom && new Date(p.date) <= dateTo
            );
            setFilteredEstimates(tempRes);
        } else {
            setFilteredEstimates(propertyEstimates);
        }
    };

    const applySort = (items = []) => {
        if (sort === 'NEW')
            return items.sort(function (a, b) {
                return new Date(b.date) - new Date(a.date);
            });
        if (sort === 'OLD')
            return items.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
        else {
            return items;
        }
    };

    const handleSortBy = (...args) => {
        const type = args[args.length - 1];
        if (type === 'NEW') {
            setSort('NEW');
        } else if (type === 'OLD') {
            setSort('OLD');
        }
    };

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('estimates')}</h1>
                {isEdit && (
                    <span
                        className={`btn btn-primary-0 ${styles.buttonContent}`}
                        onClick={() => navigate('./new')}
                    >
                        <IoAddCircleOutline size={'20px'} />
                        <span className={styles.buttonTitle}>{t('Add')}</span>
                    </span>
                )}
            </div>

            {propertyEstimates ? (
                <div className={styles.pageInner}>
                    <div className={styles.filterSection}>
                        <div className={styles.formWrapper}>
                            <Form ref={formRefFilters} description={dateFilterDesc} />
                            <div className={styles.btnFilters}>
                                <ButtonWithLoading
                                    className='btn btn-primary-0'
                                    onClick={applyFilters}
                                >
                                    <div className={styles.btnContent}>{t('Apply')}</div>
                                </ButtonWithLoading>
                            </div>
                            <div className={styles.btnFilters}>
                                <ButtonWithLoading className='btn btn-error' onClick={clearFilters}>
                                    <div className={styles.btnContent}>{t('Clear')}</div>
                                </ButtonWithLoading>
                            </div>
                        </div>

                        <div className={styles.sortBy}>
                            <Form
                                ref={formRefSortBy}
                                description={sortByDesc}
                                onChange={(...event) => handleSortBy(...event)}
                            />
                        </div>
                    </div>

                    <span className={styles.countEstimates}>
                        <b>{filteredEstimates.length}</b> {t('estimates')}
                    </span>
                    {applySort(filteredEstimates).map((est, i) => (
                        <div className={styles.cardContainer} key={`eCard_${i}`}>
                            <PropertyEstimateCard
                                id={est.id}
                                name={est.estimation}
                                date={
                                    est.date.getDate() +
                                    '/' +
                                    (est.date.getMonth() + 1) +
                                    '/' +
                                    est.date.getFullYear()
                                }
                                estimator={{ name: est.name, afm: est.afm }}
                                values={{
                                    val1: est.rentalValue,
                                    val2: est.materialValue,
                                    val3: est.insurableValue,
                                    val4: est.marketValue,
                                    val5: est.finalMarketValue
                                }}
                                author={est.author}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <LoaderWrapper>{t('estimates_not_found')}</LoaderWrapper>
            )}
        </div>
    );
};
