// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Background_background__9LzoQ {\n    background-size: cover;\n    background-position: 10% 25%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/searchPaginatedPropertiesWithMap/Background.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4BAA4B;AAChC","sourcesContent":[".background {\n    background-size: cover;\n    background-position: 10% 25%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "Background_background__9LzoQ"
};
export default ___CSS_LOADER_EXPORT___;
