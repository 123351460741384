import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import i18next from 'i18next';

import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { UnderConstructionComponent } from '../../../components/underConstructionComponent/UnderConstructionComponent';

import requests from '../../../utils/requests';

import styles from './UserStatistics.module.css';
import { TeamStatisticExpenses } from '../../teamPages/statisticsPages/TeamStatisticExpenses';

export const UserStatisticExpensesPage = () => {
    // const [data, setData] = useState(null);
    // const { userId: id } = useParams();

    // useEffect(() => {
    //     requests.getUserExpenses(id).then(({ data }) => setData(data));
    // }, [id]);

    // if (!data) {
    //     return <LoaderWrapper />;
    // }

    // return (
    //     <div className={styles.main}>
    //         <div className={styles.header}>
    //             <Header title={i18next.t('Expenses-Statistic')} />
    //         </div>
    //         <div className={styles.row}>
    //             <div>
    //                 <UnderConstructionComponent />
    //             </div>
    //         </div>
    //     </div>
    // );
    return <TeamStatisticExpenses />;
};
