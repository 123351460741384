import { useState } from 'react';

import Utilities from '../../utils/utils';

// ---------------------------------------------------------------------------------------
import { ThemePreview } from './ThemePreview';

// ---------------------------------------------------------------------------------------
import styles from './ThemeList.module.css';

// ---------------------------------------------------------------------------------------

export const ThemeList = props => {
    const [currentTheme, setCurrentTheme] = useState(Utilities.getCurrentTheme());

    function applyTheme(theme) {
        setCurrentTheme(theme);
        Utilities.changeTheme(theme);
    }

    function renderThemePreview(theme) {
        return (
            <ThemePreview
                key={theme}
                theme={theme}
                isActive={currentTheme === theme}
                setCurrentTheme={applyTheme}
            />
        );
    }

    return <div className={styles.themeList}>{props.themes.map(renderThemePreview)}</div>;
};
