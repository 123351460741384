// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatisticsStyles_invalidBox__0\\+pZ0 {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 240px;\n    background-color: var(--theme-bg-10); /* Set the background color to a lighter gray */\n    background-image: linear-gradient(\n        45deg,\n        rgba(0, 0, 0, 0.05) 25%,\n        transparent 25%,\n        transparent 75%,\n        rgba(0, 0, 0, 0.05) 75%\n    ); /* Add diagonal lines using linear gradients */\n    background-size: 10px 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/statistics/StatisticsStyles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,oCAAoC,EAAE,+CAA+C;IACrF;;;;;;KAMC,EAAE,8CAA8C;IACjD,0BAA0B;AAC9B","sourcesContent":[".invalidBox {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 240px;\n    background-color: var(--theme-bg-10); /* Set the background color to a lighter gray */\n    background-image: linear-gradient(\n        45deg,\n        rgba(0, 0, 0, 0.05) 25%,\n        transparent 25%,\n        transparent 75%,\n        rgba(0, 0, 0, 0.05) 75%\n    ); /* Add diagonal lines using linear gradients */\n    background-size: 10px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invalidBox": "StatisticsStyles_invalidBox__0+pZ0"
};
export default ___CSS_LOADER_EXPORT___;
