// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagePropertiesCardList_servicesList__hF9Xu {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    max-width: 1400px;\n    gap: 20px;\n}\n\n/* huge screen  */\n@media screen and (min-width: 1600px) and (max-width: 10000px) {\n    .ManagePropertiesCardList_servicesList__hF9Xu {\n        gap: 16px;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 767px) {\n}\n", "",{"version":3,"sources":["webpack://./src/components/ManagePropertiesCardList/ManagePropertiesCardList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,iBAAiB;IACjB,SAAS;AACb;;AAEA,iBAAiB;AACjB;IACI;QACI,SAAS;IACb;AACJ;;AAEA,UAAU;AACV;AACA","sourcesContent":[".servicesList {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    max-width: 1400px;\n    gap: 20px;\n}\n\n/* huge screen  */\n@media screen and (min-width: 1600px) and (max-width: 10000px) {\n    .servicesList {\n        gap: 16px;\n    }\n}\n\n/* phone */\n@media screen and (max-width: 767px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicesList": "ManagePropertiesCardList_servicesList__hF9Xu"
};
export default ___CSS_LOADER_EXPORT___;
