// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreviewProfileImagesGroup_container__gf\\+zF {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    gap: 10px;\n}\n.PreviewProfileImagesGroup_imageDefault__QpKCF {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #86bbd3;\n    width: 40px;\n    height: 40px;\n    flex-shrink: 0;\n    color: var(--theme-bg);\n    font-size: var(--font-3);\n    border-radius: 100%;\n    -webkit-user-select: none;\n            user-select: none;\n    cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/previewProfileImagesGroup/PreviewProfileImagesGroup.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;AACb;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,cAAc;IACd,sBAAsB;IACtB,wBAAwB;IACxB,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    gap: 10px;\n}\n.imageDefault {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #86bbd3;\n    width: 40px;\n    height: 40px;\n    flex-shrink: 0;\n    color: var(--theme-bg);\n    font-size: var(--font-3);\n    border-radius: 100%;\n    user-select: none;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PreviewProfileImagesGroup_container__gf+zF",
	"imageDefault": "PreviewProfileImagesGroup_imageDefault__QpKCF"
};
export default ___CSS_LOADER_EXPORT___;
