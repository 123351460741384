import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyInfoView'),
        showTitle: false,
        name: 'propertyInfoView',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Description'),
                name: 'description',
                type: 'freetext',
                isRequired: false,
                space: '100%'
            }
        ]
    };
};

export default getDescription;
