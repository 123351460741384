import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyInfoForm'),
        showTitle: false,
        name: 'propertyInfoForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Ownsership'),
                type: 'label'
            },
            {
                title: i18next.t('Description'),
                name: 'description',
                type: 'freetext',
                isRequired: false,
                space: '100%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('add-property-owner'),
                name: 'owners',
                isRequired: false,
                type: 'dropdown'
            }
        ]
    };
};

export default getDescription;
