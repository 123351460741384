import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import { SuccessMessage } from '../../../components/SuccessMessage/SuccessMessage';
import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';

import requests from '../../../utils/requests';

import ForgotPasswordDescription from '../../../assets/json/ForgotPassword.js';

import styles from '../LoginPage.module.css';

export const ForgotPasswordPage = () => {
    const addToast = useToasts();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();

    const [done, setDone] = useState(false);

    const onSubmit = async () => {
        const { email } = formRef.current.getData();

        try {
            await requests.sendResetPasswordToken(email);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t(
                    'There was an error in the process, possibly the email you entered is not registered in the system. Please try again later.'
                ),
                duration: 5000
            });
        }

        setDone(true);
    };

    if (done) {
        return (
            <SuccessMessage>
                <span className={styles.prompt}>
                    {t('An email with a link for resetting your password has been sent to you')}
                </span>
            </SuccessMessage>
        );
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.loginPageForm}>
                <div className={styles.formContainer}>
                    <div className={styles.prompt}>{t('Reset Password')}</div>
                    <Form description={ForgotPasswordDescription()} ref={formRef} />

                    <ButtonWithLoading
                        className={`${styles.btn} btn btn-primary-0`}
                        onClick={onSubmit}
                    >
                        {t('Send')}
                    </ButtonWithLoading>
                    <ButtonWithLoading
                        className={`${styles.retutnToLogin} btn `}
                        onClick={() => navigate(`../login`)}
                    >
                        <div className={styles.backRowDiv}>
                            <IoIosArrowBack className={styles.backBtn} />
                            {t('Return to login')}
                        </div>
                    </ButtonWithLoading>
                    <div className={styles.registerWrap}>
                        {t('dont-have-account')}
                        <div className={styles.linkButton} onClick={() => navigate('../register')}>
                            {t('register')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.advertiseCol}></div>
        </div>
    );
};
