import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import i18next from 'i18next';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { GrFormView } from 'react-icons/gr';
import { IoMdAdd } from 'react-icons/io';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Table } from 'table';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../../components/modal/Modal';
import { SmallPreviewNameImage } from '../../../components/smallPreviewNameImage/SmallPreviewNameImage';
import { Tabs } from '../../../components/tabs/Tabs';

import requests, { URI } from '../../../utils/requests';
import Utils from '../../../utils/utils';

import budgetSection from '../../../assets/json/Budget.js';
import improvementSection from '../../../assets/json/Improvements.js';
import renovationSection from '../../../assets/json/Renovations.js';
import repairSection from '../../../assets/json/Repair.js';

import styles from './PropertyRenovationPage.module.css';

export const PropertyRenovationMainPage = ({ buildingId, isEdit = false }) => {
    const { t } = useTranslation();
    const addToast = useToasts();
    const [renovations, setRenovations] = useState(null);
    const [deleteModal, setDeleteModal] = useState(null);
    const navigate = useNavigate();

    const [creationModalIsVisible, setCreationModalIsVisible] = useState();

    useEffect(() => {
        if (!buildingId) {
            return;
        }

        requests
            .getAllBuildingRenovations(buildingId)
            // .then(({ data }) => setRenovations(data));
            .then(({ data }) => {
                Promise.all(
                    data.map(async ren => ({
                        ...ren,
                        author: (await requests.getUser(ren.author)).data
                    }))
                ).then(setRenovations);
            });
    }, [buildingId]);

    const deleteRenovation = useCallback(
        async id => {
            if (!buildingId) {
                return;
            }

            await requests.deleteBuildingRenovation(buildingId, id);
            const { data } = await requests.getAllBuildingRenovations(buildingId);
            setRenovations(data);
            setDeleteModal(null);
            addToast({
                type: 'success',
                message: t('delete-renovation-success'),
                duration: 3000
            });
        },
        [buildingId, addToast, t]
    );

    function getDataTable(renovations, type) {
        const data = renovations.filter(r => r.type === type);
        return (
            <LoaderWrapper size={50} thickness={8}>
                <Table
                    key={'table_' + type}
                    columns={TABLE_COLUMNS_SIMPLE}
                    data={transformDataToTable(data)}
                />
            </LoaderWrapper>
        );
    }

    const transformDataToTable = (data = []) => {
        if (!data || !data.length) return [];
        console.log(data);
        return data.map(ren => {
            return {
                name: ren.name,
                budget: ren.budget,
                estimatedCost: ren.estimatedCost,
                percentage: ren.percentage || 'TODO',
                nameResponsiblePerson: ren.nameResponsiblePerson,
                actions: ren.id,
                author: ren.author,
                createdAt: `${Utils.timestampToHumanizedDate(ren.createdAt)}`,
                updatedAt: `${Utils.timestampToHumanizedDate(ren.updatedAt)}`
            };
        });
    };

    const TABLE_COLUMNS_SIMPLE = useMemo(() => {
        const columns = [
            {
                Header: t('Name'),
                accessor: 'name'
            },

            {
                Header: t('budget'),
                accessor: 'budget'
            },
            {
                Header: t('estimated cost'),
                accessor: 'estimatedCost'
            },
            // {
            //     Header: t('percentage of complete'),
            //     accessor: 'percentageOfComplete'
            // },
            {
                Header: t('createdAt'),
                accessor: 'createdAt'
            },
            {
                Header: t('updatedAt'),
                accessor: 'updatedAt'
            },
            {
                accessor: 'nameResponsiblePerson',
                Header: t('name of responsible')
            },
            {
                accessor: 'author',
                Header: t('author'),
                Cell: ({ value }) => (
                    <>
                        <SmallPreviewNameImage
                            imageSrc={value.image && `${URI}/${value.image.path}`}
                            fullname={value.name}
                            onClick={() => navigate(`../../users/${value.email}/info`)}
                        />
                    </>
                )
            }
        ];
        if (isEdit) {
            columns.push({
                accessor: 'actions',
                Header: t('actions-edit-delete'),
                Cell: ({ value: id }) => {
                    return (
                        <div className={styles.actionsDiv}>
                            <Link className={styles.editBtn} to={`./${id}`}>
                                <FaEdit color='var(--theme-primary)' size={20} />
                            </Link>
                            <button
                                className={styles.deleteBtn}
                                onClick={() => setDeleteModal({ id })}
                            >
                                <AiFillDelete color='var(--theme-error-10)' size={20} />
                            </button>
                        </div>
                    );
                },
                sticky: 'right'
            });
        } else {
            columns.push({
                accessor: 'actions',
                Header: t('actions-edit-delete'),
                Cell: ({ value: id }) => {
                    return (
                        <div className={styles.actionsDiv}>
                            <Link className={styles.editBtn} to={`./${id}`}>
                                <GrFormView color={'var(--theme-primary)'} size={20} />
                            </Link>
                        </div>
                    );
                },
                sticky: 'right'
            });
        }
        return columns;
    }, [isEdit, t]);

    function transformBudgetDataToTable(data = []) {
        if (!data) return [];

        return [
            {
                repairsBudget: data.repairsBudget,
                estimatedRepairsCost: data.estimatedRepairsCost,
                improvementsBudget: data.improvementsBudget,
                estimatedImprovementsCost: data.estimatedImprovementsCost,
                renovationsBudget: data.renovationsBudget,
                estimatedRenovationsCost: data.estimatedRenovationsCost
            }
        ];
    }

    const TABLE_COLUMNS_BUDGET = useMemo(
        () => [
            {
                Header: t('repairsBudget'),
                accessor: 'repairsBudget'
            },
            {
                Header: t('estimatedRepairsCost'),
                accessor: 'estimatedRepairsCost'
            },
            {
                Header: t('improvementsBudget'),
                accessor: 'improvementsBudget'
            },

            {
                accessor: 'estimatedImprovementsCost',
                Header: t('estimatedImprovementsCost')
            },
            {
                accessor: 'renovationsBudget',
                Header: t('renovationsBudget')
            },
            {
                accessor: 'estimatedRenovationsCost',
                Header: t('estimatedRenovationsCost')
            }
        ],
        [t]
    );

    function getBudgetTable(renovations) {
        const data = getBudgets(renovations);
        return (
            <LoaderWrapper size={50} thickness={8}>
                <Table
                    key={'table_total_budgets'}
                    pagination={{ pageSize: 10 }}
                    columns={TABLE_COLUMNS_BUDGET}
                    data={transformBudgetDataToTable(data)}
                />
            </LoaderWrapper>
        );
    }

    if (!renovations) {
        return <LoaderWrapper />;
    }

    const tabs = [
        {
            title: <div className={styles.rowDiv}>{t('Repair')}</div>,
            content: <>{getDataTable(renovations, 'REPAIR')}</>
        },
        {
            title: <div className={styles.rowDiv}>{t('Improvement')}</div>,
            content: <>{getDataTable(renovations, 'IMPROVEMENT')}</>
        },
        {
            title: <div className={styles.rowDiv}>{t('Renovation')}</div>,
            content: <>{getDataTable(renovations, 'RENOVATION')}</>
        }
    ];

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.titleContainer}>
                <Header title={t('Improvement/Renovations')} />
                <span
                    className={`btn btn-primary-0 ${styles.buttonContent}`}
                    onClick={() => setCreationModalIsVisible(true)}
                >
                    <IoAddCircleOutline size={'20px'} />
                    <span className={styles.buttonTitle}>{t('Add')}</span>
                </span>
            </div>

            <div className={styles.tabsWrapper}>
                <Tabs tabs={tabs} />
            </div>

            <br />
            <br />
            <div className={styles.headerLabel}>
                <h2 className={styles.subTitle}>{t('Total Budget')}</h2>
            </div>
            {getBudgetTable(renovations) || t('no_budget_found')}
            {creationModalIsVisible && (
                <CreationModal onClose={() => setCreationModalIsVisible(false)} />
            )}
            {deleteModal && (
                <Modal
                    onClose={() => setDeleteModal(null)}
                    header={t('delete-renovation')}
                    content={t('confirm-delete-renovation')}
                    footer={
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                className='btn'
                                onClick={() => {
                                    setDeleteModal(null);
                                }}
                            >
                                {t('cancel')}
                            </button>
                            <ButtonWithLoading
                                className={`btn btn-error`}
                                onClick={() => deleteRenovation(deleteModal.id)}
                            >
                                {t('delete')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
        </div>
    );
};

function getBudgets(data = []) {
    if (!data) return null;

    const formData = {
        repairsBudget: 0,
        estimatedRepairsCost: 0,
        improvementsBudget: 0,
        estimatedImprovementsCost: 0,
        renovationsBudget: 0,
        estimatedRenovationsCost: 0
    };

    data.forEach(r => {
        if (r.type === 'REPAIR') {
            formData.repairsBudget += r.budget;
            formData.estimatedRepairsCost += r.estimatedCost;
        } else if (r.type === 'IMPROVEMENT') {
            formData.improvementsBudget += r.budget;
            formData.estimatedImprovementsCost += r.estimatedCost;
        } else if (r.type === 'RENOVATION') {
            formData.renovationsBudget += r.budget;
            formData.estimatedRenovationsCost += r.estimatedCost;
        } else {
            console.assert();
        }
    });
    return formData;
}

function chooseDescription(type) {
    switch (type) {
        case 'REPAIR':
            return repairSection();
        case 'IMPROVEMENT':
            return improvementSection();
        case 'RENOVATION':
            return renovationSection();
        default:
            console.assert();
    }
}

export const PropertyViewRenovationPage = ({ buildingId, navigateBack }) => {
    const formRef = useRef(null);
    const { id } = useParams();
    const { t } = useTranslation();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!buildingId) {
            return;
        }
        requests.getBuildingRenovation(buildingId, id).then(({ data }) => setData(data));
    }, [buildingId, id]);

    if (data === null) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>
                    {t('renovation for property = {{id}}', { id: buildingId })}
                </h1>
            </div>

            <div className={styles.formContainer}>
                <Form
                    description={chooseDescription(data.type)}
                    initialValue={data}
                    readOnly
                    ref={formRef}
                ></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>
                        <IoIosArrowRoundBack />
                        {t('back')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

async function onSubmit(buildingId, type, renovationId, formRef) {
    if (!formRef.current) {
        return false;
    }

    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();

    if (!!sanityCheckRes.length) {
        return false;
    }

    if (!renovationId) {
        return requests.createBuildingRenovation(buildingId, {
            ...formRef.current.getData(),
            type: type
        });
    } else {
        return requests.updateBuildingRenovation(
            buildingId,
            renovationId,
            formRef.current.getData()
        );
    }
}

export const PropertyEditRenovationPage = ({ buildingId, navigateBack }) => {
    const { t } = useTranslation();
    const { id: renovationId } = useParams();
    const [data, setData] = useState(null);
    const formRef = useRef(null);
    const searchParams = new URLSearchParams(document.location.search);
    const type = searchParams.get('type');

    useEffect(() => {
        if (!renovationId) {
            return;
        }
        requests.getBuildingRenovation(buildingId, renovationId).then(({ data }) => setData(data));
    }, [renovationId, buildingId]);

    if (!data && renovationId) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <Header
                title={
                    renovationId
                        ? t('Edit improvement/renovation')
                        : t('Create new improvement/renovation')
                }
            />

            <div className={styles.formContainer}>
                <Form
                    description={
                        renovationId ? chooseDescription(data.type) : chooseDescription(type)
                    }
                    initialValue={data}
                    ref={formRef}
                ></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>{t('cancel')}</div>
                </ButtonWithLoading>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={async () => {
                        (await onSubmit(buildingId, type, renovationId, formRef)) && navigateBack();
                    }}
                >
                    <div className={styles.btnContent}>{t('save')}</div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export const PropertyViewRenovationBudgetsPage = ({ buildingId, navigateBack }) => {
    const formRef = useRef(null);
    const { t } = useTranslation();
    const [data, setData] = useState(null);

    useEffect(() => {
        try {
            requests.getAllBuildingRenovations(buildingId).then(({ data }) => setData(data));
        } catch (err) {
            console.log(err);
        }
    }, [buildingId]);

    const getBudgets = (data = []) => {
        if (!data) return null;

        const formData = {
            repairsBudget: 0,
            estimatedRepairsCost: 0,
            improvementsBudget: 0,
            estimatedImprovementsCost: 0,
            renovationsBudget: 0,
            estimatedRenovationsCost: 0
        };

        data.forEach(r => {
            if (r.type === 'REPAIR') {
                formData.repairsBudget += r.budget;
                formData.estimatedRepairsCost += r.estimatedCost;
            } else if (r.type === 'IMPROVEMENT') {
                formData.improvementsBudget += r.budget;
                formData.estimatedImprovementsCost += r.estimatedCost;
            } else if (r.type === 'RENOVATION') {
                formData.renovationsBudget += r.budget;
                formData.estimatedRenovationsCost += r.estimatedCost;
            } else {
                console.assert();
            }
        });
        return formData;
    };

    if (data === null) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>
                    {t('Total budget for building = {{id}}', { id: buildingId })}
                </h1>
            </div>

            <div className={styles.formContainer}>
                <Form
                    description={budgetSection()}
                    initialValue={getBudgets()}
                    readOnly
                    ref={formRef}
                ></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>{t('back')}</div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

const getTypeChoiceFormDescription = () => ({
    questions: [
        {
            title: i18next.t('type'),
            name: 'type',
            type: 'dropdown',
            isRequired: true,
            choices: [
                {
                    title: i18next.t('Repair'),
                    value: 'REPAIR'
                },
                {
                    title: i18next.t('Improvement'),
                    value: 'IMPROVEMENT'
                },
                {
                    title: i18next.t('Renovation'),
                    value: 'RENOVATION'
                }
            ]
        }
    ]
});

function CreationModal({ onClose }) {
    const formRef = useRef(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSubmit = () => {
        formRef.current.clearSanityCheck();

        const errors = formRef.current.sanityCheckForm();

        if (!!errors.length) return;

        const { type } = formRef.current.getData();

        navigate(`new?type=${type}`);
    };

    return (
        <Modal
            onClose={onClose}
            header={t('Create new improvement/renovation')}
            content={<Form ref={formRef} description={getTypeChoiceFormDescription()} />}
            footer={
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button className='btn' onClick={onClose}>
                        {t('cancel')}
                    </button>
                    <ButtonWithLoading className={`btn btn-primary-0`} onClick={onSubmit}>
                        {t('continue')}
                    </ButtonWithLoading>
                </div>
            }
        />
    );
}
