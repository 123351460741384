import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsPlusSquareFill, BsX } from 'react-icons/bs';
import { HiOutlineUserRemove } from 'react-icons/hi';
import { Table } from 'table';

import { ToastMessage, useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import description from '../../assets/json/AddMemberToTeam.js';

import styles from './TeamMembersPage.module.css';

const AddRoleModal = ({ user, teamId, onAdd, onClose }) => {
    const { t } = useTranslation();
    const [selectedRole, setSelectedRole] = useState(null);
    const [allRoles, setAllRoles] = useState();
    const buttonRef = useRef(null);

    const fetchAllRoles = useCallback(async () => {
        const { data: roles } = await requests.getAllTeamRoles(teamId);

        const allRoles = roles.filter(
            role => !user.roles?.some(excludeRole => excludeRole.id === role.id)
        );

        setAllRoles(allRoles);
    }, [teamId, user]);

    useEffect(() => {
        fetchAllRoles();
    }, [fetchAllRoles]);

    useEffect(() => {
        if (selectedRole === null) buttonRef.current.setDisabled(true);
        else if (!buttonRef.current.isLoading()) buttonRef.current.setDisabled(false);
    }, [selectedRole]);

    return (
        <Modal
            onClose={onClose}
            header={t('Add role')}
            content={
                <div className={styles.modalContentContainer}>
                    <LoaderWrapper>
                        {allRoles &&
                            (selectedRole !== null ? (
                                <div
                                    className={`${styles.role} ${styles.selected}`}
                                    style={{
                                        borderLeft: `10px solid ${
                                            allRoles[selectedRole].color || '#000000'
                                        }`
                                    }}
                                >
                                    {allRoles[selectedRole].name}
                                    <BsX onClick={() => setSelectedRole(null)} />
                                </div>
                            ) : (
                                <div className={styles.roles}>
                                    {allRoles.map((role, i) => (
                                        <div
                                            key={i}
                                            className={styles.role}
                                            onClick={() => setSelectedRole(i)}
                                            style={{
                                                borderLeft: `10px solid ${role.color || '#000000'}`
                                            }}
                                        >
                                            {role.name}
                                        </div>
                                    ))}
                                </div>
                            ))}
                    </LoaderWrapper>
                </div>
            }
            footer={
                <>
                    <ButtonWithLoading className='btn btn-cancel' onClick={onClose}>
                        {t('cancel')}
                    </ButtonWithLoading>
                    <ButtonWithLoading
                        className='btn btn-primary-0'
                        onClick={() => onAdd(allRoles[selectedRole])}
                        ref={buttonRef}
                    >
                        {t('Add')}
                    </ButtonWithLoading>
                </>
            }
        />
    );
};

const RemoveRoleModal = ({ user, role, onClose, onRemove }) => {
    const { t } = useTranslation();

    return (
        <Modal
            onClose={onClose}
            header={t('Remove role')}
            content={
                <div>
                    {t('Are you sure you want to remove role {{role}} from user {{user}}?', {
                        user: user.name,
                        role: role.name
                    })}
                </div>
            }
            footer={
                <>
                    <ButtonWithLoading className='btn btn-cancel' onClick={onClose}>
                        {t('Cancel')}
                    </ButtonWithLoading>
                    <ButtonWithLoading className='btn btn-error' onClick={() => onRemove(role)}>
                        {t('remove')}
                    </ButtonWithLoading>
                </>
            }
        />
    );
};

const RolesCell = ({ teamId, member, row }) => {
    const { t } = useTranslation();

    const [addRoleModalIsVisible, setAddRoleModalIsVisible] = useState(false);
    const [roleToRemove, setRoleToRemove] = useState(null);

    const addToast = useToasts();

    const addRole = async role => {
        try {
            await requests.assignRole(teamId, role.id, member.user.email);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t(
                    "Something went wrong with updating the users' roles. Please try again later."
                ),
                duration: 3000
            });
        }

        member.user.roles.push(role);

        addToast({
            type: 'success',
            message: t("The user's roles have been updated successfully"),
            duration: 3000
        });

        setAddRoleModalIsVisible(false);
    };

    const removeRole = async role => {
        try {
            await requests.removeRoleFromUser(teamId, role.id, member.user.email);
        } catch (err) {
            addToast({
                type: 'failure',
                message: t('Something went wrong with removing the role. Please try again later.'),
                duration: 3000
            });

            return setRoleToRemove(null);
        }

        member.user.roles = member.user.roles.filter(role_ => role_.id !== role.id);
        row.setState({ roles: member.user.roles });

        addToast({
            type: 'success',
            message: t('Role has been removed successfully'),
            duration: 3000
        });

        setRoleToRemove(null);
    };

    if (member.isManager) return <div className={styles.roleDiv}>{t('Manager')}</div>;

    return (
        <>
            <div className={styles.userRoles}>
                <div className={styles.roleTags}>
                    {!!member.user.roles &&
                        member.user.roles.map((role, i) => {
                            const color = role.color || '#ffffff';
                            const isLight = Utils.isColorLight(color);

                            return (
                                <div
                                    key={i}
                                    className={styles.roleTag}
                                    style={{
                                        backgroundColor: color,
                                        color: isLight ? '#000000' : '#ffffff'
                                    }}
                                >
                                    {role.name}
                                    <BsX onClick={() => setRoleToRemove(role)} />
                                </div>
                            );
                        })}
                    <BsPlusSquareFill
                        className={styles.addRoleBtn}
                        onClick={() => setAddRoleModalIsVisible(true)}
                    />
                </div>
            </div>
            {addRoleModalIsVisible && (
                <AddRoleModal
                    teamId={teamId}
                    user={member.user}
                    onClose={() => setAddRoleModalIsVisible(false)}
                    onAdd={addRole}
                />
            )}
            {roleToRemove && (
                <RemoveRoleModal
                    teamId={teamId}
                    user={member.user}
                    role={roleToRemove}
                    onRemove={removeRole}
                    onClose={() => setRoleToRemove(null)}
                />
            )}
        </>
    );
};

export const TeamMembersPage = ({ permissions }) => {
    const navigate = useNavigate();
    const params = useParams();
    const teamId = params.teamId;
    const formRef = useRef();
    const formDelRef = useRef();
    const [allTeamMembers, setAllTeamMembers] = useState(null);
    const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
    const [isDisableDeleteModal, setIsDisabledDeleteModal] = useState(false);
    const [canRemove, SetCanRemove] = useState(true);
    const [allUsers, setAllUsers] = useState(null);
    const { t } = useTranslation();
    const addToast = useToasts();

    const createDescriptionForDelete = name => {
        return {
            title: t('Do-you-wanna-remove {{name}}', { name: name }),
            promptForSubmit: t('Confirm'),
            questions: [
                {
                    title: t('Verify-your-password'),
                    type: 'text',
                    name: 'password',
                    placeholder: '********',
                    isPassword: true,
                    isRequired: true
                }
            ]
        };
    };

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                accessor: 'user',
                Header: t('Name'),
                Cell: ({ value: user }) => {
                    if (!user) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../../users/${user.email}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Email'),
                accessor: 'email'
            },
            {
                Header: t('Role'),
                accessor: 'role',
                Cell: ({ value: member, ...props }) => (
                    <RolesCell member={member} teamId={teamId} {...props} />
                )
            },
            {
                Header: '',
                accessor: 'canRemove',
                width: '40',
                Cell: ({ value: data }) => {
                    if (data.can)
                        return (
                            <div className={styles.redirectButton}>
                                <HiOutlineUserRemove
                                    color='var(--theme-primary)'
                                    size='24'
                                    cursor='pointer'
                                    onClick={async () => {
                                        setIsDisabledDeleteModal(data.who.user);
                                    }}
                                />
                            </div>
                        );
                }
            }
        ],
        [navigate, t, teamId]
    );

    const fetchAndSetData = useCallback(async () => {
        const { data } = await requests.getTeamMembers(teamId);
        setAllTeamMembers(data);
    }, [teamId]);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    const fetchAllUsers = async () => {
        const { data } = await requests.getAllUsers();
        return data;
    };

    function renderDeleteModal() {
        if (!isDisableDeleteModal) return null;

        return (
            <Modal
                onClose={() => {
                    setIsDisabledDeleteModal(false);
                }}
                header={t('Remove-User')}
                content={
                    <div
                        style={{
                            minHeight: '500px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        {
                            <Form
                                description={createDescriptionForDelete(isDisableDeleteModal.name)}
                                ref={formDelRef}
                            />
                        }
                        {!canRemove && (
                            <ToastMessage type={'failure'} message={t('Error-Try-again-later')} />
                        )}
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledDeleteModal(false);
                            }}
                        >
                            {t('Reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            style={{
                                opacity: canRemove ? 1 : 0.1,
                                cursor: canRemove ? 'pointer' : 'default'
                            }}
                            onClick={async () =>
                                canRemove ? handleRemove(isDisableDeleteModal.email) : ''
                            }
                        >
                            {t('Confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const transformData = useCallback(
        member => ({
            user: member.user,
            email: member.user.email,
            memberSince: Utils.timestampToHumanizedDate(member.joinedAt),
            propertiesAdded: member.propertiesAdded,
            propertiesEdited: member.propertiesEdited,
            role: { user: member.user, isManager: member.isManager },
            canRemove: { can: permissions.canRemoveMemberFromTeam, who: member }
        }),
        [permissions]
    );

    async function handleRemove(userEmail) {
        formDelRef.current.clearSanityCheck();

        const sanityCheck = formDelRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }
        const { password } = formDelRef.current.getData();
        requests
            .validatePassword(password)
            .then(() => {
                requests
                    .deleteTeamMember(teamId, userEmail)
                    .then(() => {
                        const others = allTeamMembers.filter(m => {
                            if (m.user.email === userEmail) {
                                const usersCP = allUsers ? [...allUsers] : [];
                                usersCP.push(m.user);
                                setAllUsers(usersCP);
                            }
                            return m.user.email !== userEmail;
                        });
                        setAllTeamMembers(others);
                        setIsDisabledDeleteModal(false);
                        addToast({
                            type: 'success',
                            message: t('User {{user}} removed from team {{team}} successfully', {
                                user: userEmail,
                                team: teamId
                            }),
                            duration: 3000
                        });
                    })
                    .catch(console.log);
            })
            .catch(() => {
                SetCanRemove(false);
                setTimeout(() => SetCanRemove(true), 20000);
            });
    }

    async function submit() {
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }
        const { selectUser, ...restData } = formRef.current.getData();

        const isManager = restData.userType === 'MANAGER';
        return requests.postTeamMember(teamId, selectUser, { isManager }).then(() => {
            const others = allUsers.filter(m => m.email !== selectUser);
            setAllUsers(others);
            setIsDisabledAddModal(false);
            fetchAndSetData();
            addToast({
                type: 'success',
                message: t('User {{user}} added on team {{team}} successfully', {
                    user: selectUser,
                    team: teamId
                }),
                duration: 3000
            });
        });
    }

    function createDescription() {
        if (allUsers) return;
        fetchAllUsers()
            .then(result => {
                const users = result.filter(
                    u => !allTeamMembers.find(m => m.user.email === u.email)
                );
                setAllUsers(users);
            })
            .catch(console.log);
    }

    function fillDescription(description) {
        if (!allUsers) return description;

        const newDesc = JSON.parse(JSON.stringify(description));

        allUsers.forEach(u => {
            newDesc.questions[0].choices.push({ name: `${u.email} (${u.name})`, value: u.email });
        });
        return newDesc;
    }

    function renderAddModal() {
        if (!isDisableAddModal) return null;
        createDescription();
        return (
            <Modal
                onClose={() => {
                    setIsDisabledAddModal(false);
                }}
                header={t('add-new-member')}
                content={
                    <div style={{ minHeight: '500px' }}>
                        {allUsers && (
                            <Form description={fillDescription(description())} ref={formRef} />
                        )}
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledAddModal(false);
                            }}
                        >
                            {t('Reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => submit()}
                        >
                            {t('Confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const renderMembers = useMemo(() => {
        return (
            <LoaderWrapper>
                {allTeamMembers && (
                    <Table
                        pagination={{ pageSize: 10 }}
                        columns={TABLE_COLUMNS}
                        data={allTeamMembers.map(transformData)}
                    />
                )}
            </LoaderWrapper>
        );
    }, [allTeamMembers, TABLE_COLUMNS, transformData]);

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('Members')} />

                {permissions.canAddMemberToTeam && allTeamMembers && (
                    <div
                        className={`btn btn-primary-0 ${styles.addButton}`}
                        onClick={() => setIsDisabledAddModal(true)}
                    >
                        <AiOutlineUsergroupAdd size={'20px'} />

                        <div className={styles.buttonTitle}>{t('add-member')}</div>
                    </div>
                )}
            </div>

            {renderMembers}
            {renderAddModal()}
            {renderDeleteModal()}
        </div>
    );
};
