import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyOptionsForm'),
        showTitle: false,
        name: 'propertyOptionsForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('expose'),
                name: 'expose',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('price'),
                name: 'price',
                type: 'float',
                isRequired: false,
                space: '100%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('endDateExpose'),
                name: 'exposedUntil',
                type: 'date',
                isRequired: true,
                space: '50%'
            },
            {
                title: i18next.t('Status'),
                name: 'exposed',
                type: 'unfolded-dropdown',
                isRequired: true,
                choices: [
                    {
                        title: i18next.t('exposed'),
                        value: 'isExposed'
                    },
                    {
                        title: i18next.t('notExposed'),
                        value: 'isNotExposed'
                    }
                ],

                space: '50%'
            }
            // {
            //     title: '€',
            //     name: 'currency',
            //     type: 'label',
            //     space: '10%'
            // },
            // {
            //     title: i18next.t('startDateExpose'),
            //     name: 'startDate',
            //     type: 'date',
            //     isRequired: true,
            //     space: '50%'
            // },
        ]
    };
};

export default getDescription;
