// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ErrorMessage_container__eDobt {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n    padding: 40px;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\n    background-color: #ffffff;\n\n    margin: auto;\n    transform: translateY(-50%);\n    border-top: 1px solid #eeeeee;\n}\n\n.ErrorMessage_message__7qeFy {\n    font-size: 1.5rem;\n    width: 100%;\n    text-align: center;\n    color: #444444;\n}\n\n.ErrorMessage_icon__OiLQv {\n    fill: var(--theme-error);\n    width: 8rem;\n    height: 8rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,aAAa;IACb,2CAA2C;IAC3C,yBAAyB;;IAEzB,YAAY;IACZ,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n    padding: 40px;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);\n    background-color: #ffffff;\n\n    margin: auto;\n    transform: translateY(-50%);\n    border-top: 1px solid #eeeeee;\n}\n\n.message {\n    font-size: 1.5rem;\n    width: 100%;\n    text-align: center;\n    color: #444444;\n}\n\n.icon {\n    fill: var(--theme-error);\n    width: 8rem;\n    height: 8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ErrorMessage_container__eDobt",
	"message": "ErrorMessage_message__7qeFy",
	"icon": "ErrorMessage_icon__OiLQv"
};
export default ___CSS_LOADER_EXPORT___;
