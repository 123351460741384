import stylesModule from './Header.module.css';

export const Header = ({
    icon,
    title,
    children,
    onClick,
    backgroundImg = null,
    isAdvertise = false,
    position = '35% 50%',
    className
}) => {
    const styles = { color: backgroundImg ? 'white' : 'var(--theme-primary)' };

    if (onClick) styles.cursor = 'pointer';

    return (
        <div
            className={`${stylesModule.backgroundHeader} ${
                backgroundImg ? stylesModule.hasBackgroundImg : stylesModule.withoutBackgroundImg
            } ${className || ''}`}
            style={{
                backgroundImage: backgroundImg ? `url(${backgroundImg})` : 'none',
                width: backgroundImg ? '100%' : isAdvertise ? '100%' : '70%',
                backgroundPosition: position
            }}
        >
            <h1 className={stylesModule.header} onClick={onClick} style={styles}>
                <div className={stylesModule.titleContainer}>
                    {icon && <span className={styles.icon}>{icon}</span>}
                    {title}
                </div>
                {children}
            </h1>
        </div>
    );
};
