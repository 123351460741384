import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('maps-edit-features'),
        showTitle: false,
        name: 'propertyMapEdit',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('building'),
                name: 'buildingLabel',
                type: 'label',
                isRequired: false
            },
            {
                name: 'maxVariablesBuildings',
                title: i18next.t('max-User-Variables-Buildings'),
                type: 'dropdown',
                isRequired: true,
                choices: [
                    {
                        title: '1',
                        value: 1
                    },
                    {
                        title: '2',
                        value: 2
                    },
                    {
                        title: '3',
                        value: 3
                    },
                    {
                        title: '4',
                        value: 4
                    },
                    {
                        title: '5',
                        value: 5
                    },
                    {
                        title: '6',
                        value: 6
                    },
                    {
                        title: '7',
                        value: 7
                    },
                    {
                        title: '8',
                        value: 8
                    }
                ],
                space: '50%'
            },
            {
                title: i18next.t('select-variables-buildings'),
                name: 'variablesOnMapBuildings',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('land'),
                name: 'landLabel',
                type: 'label',
                isRequired: false
            },
            {
                name: 'maxVariablesLand',
                title: i18next.t('max-User-Variables-Land'),
                type: 'dropdown',
                isRequired: true,
                choices: [
                    {
                        title: '1',
                        value: 1
                    },
                    {
                        title: '2',
                        value: 2
                    },
                    {
                        title: '3',
                        value: 3
                    },
                    {
                        title: '4',
                        value: 4
                    },
                    {
                        title: '5',
                        value: 5
                    },
                    {
                        title: '6',
                        value: 6
                    },
                    {
                        title: '7',
                        value: 7
                    },
                    {
                        title: '8',
                        value: 8
                    }
                ],
                space: '50%'
            },
            {
                title: i18next.t('select-variables-land'),
                name: 'variablesOnMapLand',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            }
        ]
    };
};

export default getDescription;
