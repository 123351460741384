import React from 'react';

import styles from './UnderConstructionComponent.module.css';

export const UnderConstructionComponent = () => {
    return (
        <div className={styles.pageWrap}>
            <img
                src={
                    'https://iconsplace.com/wp-content/uploads/_icons/2d99ff/256/png/under-construction-icon-2-256.png'
                }
                alt='Unden Contrustion'
            />
        </div>
    );
};
