import React from 'react';

class Marker {
    constructor(position, propertyId, title, icon, image, onClick) {
        this.position = position;
        this.propertyId = propertyId;
        this.title = title;
        this.icon = icon;
        this.image = image;
        this.onClick = onClick;
    }

    getPosition() {
        return this.position;
    }

    getIcon() {
        return this.icon;
    }

    getImage() {
        return this.image;
    }

    setIcon(icon) {
        this.icon = icon;
    }

    setPosition(position) {
        this.position = position;
    }

    setImage(image) {
        this.image = image;
    }
}

export default Marker;
