import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';
import { HiLogout } from 'react-icons/hi';
import { IoSettingsOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router';

import { useUser } from '../../context/withUser';

import requests from '../../utils/requests';
import { URI } from '../../utils/requests';

import { Popover } from '../popover/Popover';
import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import styles from './UserPopover.module.css';

export const UserPopover = () => {
    const { t } = useTranslation();
    const [popoverIsVisible, setPopoverIsVisible] = useState(false);
    const navigate = useNavigate();

    const { user } = useUser();
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        requests
            .getUser(user.email)
            .then(({ data }) => setUserInfo(data))
            .catch(console.error);
    }, [user]);

    const logout = () => {
        requests.logout().then(res => {
            window.location.href = '/';
        });
    };
    if (!userInfo) return null;

    return (
        <Popover
            isVisible={popoverIsVisible}
            onChangeVisibility={setPopoverIsVisible}
            trigger={
                <div
                    className={styles.dropdownToggleBtn}
                    title={t('user-and-settings')}
                    aria-label={t('user-and-settings')}
                >
                    <FaUser />
                </div>
            }
            content={
                <div className={styles.menuContainer}>
                    <div className={styles.userCardWrap}>
                        <SmallPreviewNameImage
                            imageSrc={userInfo.image && `${URI}/${userInfo.image.path}`}
                            fullname={userInfo.name}
                            showName={false}
                            width='70px'
                            height='70px'
                            onClick={() => navigate(`users/${userInfo.email}/options`)}
                            request={() => requests.getUser(userInfo.email)}
                        />
                        <div className={styles.userCardName}>{`${user.name}`}</div>
                        <div className={styles.userCardEmail}>{user.email}</div>
                    </div>
                    <div
                        className={`${styles.dropdownItem} ${styles.settings}`}
                        onClick={() => {
                            navigate(`settings`);
                            setPopoverIsVisible(false);
                        }}
                    >
                        <IoSettingsOutline />
                        {t('settings')}
                    </div>
                    <div className={`${styles.dropdownItem} ${styles.signOut}`} onClick={logout}>
                        <HiLogout />
                        {t('logout')}
                    </div>
                </div>
            }
            placement='bottom left'
        />
    );
};
