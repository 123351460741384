import React, { useEffect, useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import landDescription from '../../../assets/json/PropertyLand';

import styles from './PropertyEditLandPage.module.css';

const PropertyEditLandPage = ({ property, updatePropertyToDB }) => {
    const { t } = useTranslation();
    const formRef = useRef(null);

    const submitInfos = async () => {
        if (!formRef.current || !property) {
            return false;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return false;
        }

        const _property = JSON.parse(JSON.stringify(property));
        _property.plot.kaekPlot = formRef.current.getData().kaekPlot;
        _property.plot.name = formRef.current.getData().name;
        _property.plot.plotNumber = formRef.current.getData().plotNumber;
        _property.plot.atak = formRef.current.getData().atak;
        _property.plot.ratio = formRef.current.getData().ratio;
        _property.plot.ratioArea = formRef.current.getData().ratioArea;
        _property.plot.plotUse = formRef.current.getData().plotUse;
        _property.plot.legalRegime = formRef.current.getData().legalRegime;
        _property.plot.plotPercentage = formRef.current.getData().plotPercentage;
        _property.plot.cadastralOffice = formRef.current.getData().cadastralOffice;
        _property.plot.hasExcellence = formRef.current.getData().hasExcellence;
        _property.plot.coverageFactor = formRef.current.getData().coverageFactor;
        _property.plot.constractionFactor = formRef.current.getData().constractionFactor;
        _property.plot.constructionPosition = formRef.current.getData().constructionPosition;
        _property.plot.maxHeight = formRef.current.getData().maxHeight;
        _property.plot.totalPlotAreaContract = formRef.current.getData().totalPlotAreaContract;
        _property.plot.totalPlotAreaOA = formRef.current.getData().totalPlotAreaOA;
        _property.plot.totalPlotAreaDepiction = formRef.current.getData().totalPlotAreaDepiction;
        _property.plot.totalPlotAreaAytopsy = formRef.current.getData().totalPlotAreaAytopsy;
        _property.plot.inPlan = formRef.current.getData().inPlan;
        _property.plot.inZone = formRef.current.getData().inZone;
        _property.plot.inSettlement = formRef.current.getData().inSettlement;

        if (JSON.stringify(property) !== JSON.stringify(_property)) {
            await updatePropertyToDB(property.id, _property);
        }
    };

    useEffect(() => {
        if (!property || !formRef.current) {
            return;
        }

        formRef.current.setData?.(property.plot);
    }, [property]);

    if (!property) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <h1 className={styles.pageTitle}>{t('land')}</h1>
            <Form description={landDescription()} ref={formRef} />
            <div className={styles.actionBtnsContainer}>
                <ButtonWithLoading
                    className={`btn btn-primary-0 ${styles.actionBtn}`}
                    onClick={submitInfos}
                >
                    <div className={styles.actionBtnContent}>
                        <AiOutlineCheck /> {t('save')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export default PropertyEditLandPage;
