import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { EditTeamProfile } from '../../components/profile/EditTeamProfile';

import requests from '../../utils/requests';

import styles from './TeamOptionsPage.module.css';
import Toggle from 'react-toggle';

export const TeamOptionsPage = ({ team, permissions, updateTeamData }) => {
    const { t } = useTranslation();
    const [isDisableUserModalActive, setIsDisabledUserModalActive] = useState(false);
    const addToast = useToasts();

    async function changeActiveness() {
        const res = (await team.isActive)
            ? requests.enableTeam(team.id)
            : requests.disableTeam(team.id);

        if (!res) return false;

        team.isActive = !team.isActive;
        setIsDisabledUserModalActive(false);
        updateTeamData({ isActive: team.isActive });
        addToast({
            type: 'success',
            message: t('Team {{id}} updated successfully', { id: team.id }),
            duration: 3000
        });
    }

    function renderDisableModal() {
        if (!isDisableUserModalActive) return null;

        const team = isDisableUserModalActive;
        return (
            <Modal
                onClose={() => setIsDisabledUserModalActive(false)}
                header={isDisableUserModalActive.isActive ? t('disable-team') : t('enable-team')}
                content={
                    <div>
                        {t('are you sure you want to change status of')} {`${team.name}`}
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledUserModalActive(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>

                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => changeActiveness()}
                        >
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }
    return (
        <div className={styles.pageWrap}>
            <Header title={t('Information')} />
            <div className={styles.section}>
                <LoaderWrapper>
                    {team && <EditTeamProfile team={team} updateTeamData={updateTeamData} />}
                </LoaderWrapper>
            </div>
            {permissions.canDisable && (
                <div className={styles.section2}>
                    <div className={styles.sectionTitle}>{t('Status')}</div>
                    <Toggle
                        onChange={() => {
                            setIsDisabledUserModalActive(team);
                        }}
                        className={styles.customToggle}
                        checked={team.isActive}
                        icons={{
                            unchecked: null
                        }}
                    />
                    {!team.isActive ? t('inactive') : t('active')}
                </div>
            )}
            {renderDisableModal()}
        </div>
    );
};
