import { useState } from 'react';

import requests from '../utils/requests';

const useOwnersEdit = () => {
    const [owners, setOwners] = useState(null);

    const clearOwners = () => {
        setOwners(null);
    };

    const addOwner = owner => {
        if (!owners) {
            setOwners([{ ...owner, percentage: 0 }]);
            return;
        }

        if (owners.find(_owner => _owner.email === owner.email)) {
            return;
        }

        setOwners([...owners, { ...owner, percentage: 0 }]);
    };

    const removeOwner = owner => {
        if (!owners || !owners.find(_owner => _owner.email === owner.email)) {
            return;
        }

        const _owners = owners.filter(_owner => _owner.email !== owner.email);
        setOwners(_owners.length !== 0 ? _owners : null);
    };

    const updatePercentage = (owner, percentage) => {
        const _owners = JSON.parse(JSON.stringify(owners));
        const _owner = _owners.find(_owner => _owner.email === owner.email);
        if (!_owner) {
            return;
        }
        _owner.percentage = percentage;
        setOwners(_owners);
    };

    const searchForOwners = async subname => {
        return (
            await requests.getAllUsers({
                limit: 15,
                nameSubstring: subname
            })
        ).data.filter(user => !!!owners || owners.find(owner => user.email !== owner.email));
    };

    return [
        owners,
        setOwners,
        clearOwners,
        addOwner,
        removeOwner,
        updatePercentage,
        searchForOwners
    ];
};

export default useOwnersEdit;
