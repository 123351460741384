// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/statistics.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MyOverviewPage_mainDiv__MuDnE {\n    display: flex;\n    flex-direction: column;\n    /* padding: 50px; */\n    gap: 50px;\n}\n.MyOverviewPage_title__azDBK {\n    font-size: 2rem;\n    font-weight: bold;\n    margin-top: 80px;\n    margin-right: 1500px;\n}\n.MyOverviewPage_headerDiv__jZZK0 {\n    width: 100%;\n    height: 300px;\n\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-position: 50% 55%;\n    box-shadow: inset 0 0 0 1000px #000000ad;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    color: aliceblue;\n    object-fit: contain;\n    margin-bottom: 30px;\n}\n.MyOverviewPage_cardsDiv__EoqZ3 {\n    width: 80%;\n    align-self: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/memberPages/myOverviewPages/MyOverviewPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,aAAa;;IAEb,yDAA8D;IAC9D,sBAAsB;IACtB,4BAA4B;IAC5B,wCAAwC;;IAExC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".mainDiv {\n    display: flex;\n    flex-direction: column;\n    /* padding: 50px; */\n    gap: 50px;\n}\n.title {\n    font-size: 2rem;\n    font-weight: bold;\n    margin-top: 80px;\n    margin-right: 1500px;\n}\n.headerDiv {\n    width: 100%;\n    height: 300px;\n\n    background-image: url('../../../assets/images/statistics.jpg');\n    background-size: cover;\n    background-position: 50% 55%;\n    box-shadow: inset 0 0 0 1000px #000000ad;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    color: aliceblue;\n    object-fit: contain;\n    margin-bottom: 30px;\n}\n.cardsDiv {\n    width: 80%;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": "MyOverviewPage_mainDiv__MuDnE",
	"title": "MyOverviewPage_title__azDBK",
	"headerDiv": "MyOverviewPage_headerDiv__jZZK0",
	"cardsDiv": "MyOverviewPage_cardsDiv__EoqZ3"
};
export default ___CSS_LOADER_EXPORT___;
