// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyEstimateViewPage_pageWrap__bsRo6 {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 6px 0px;\n}\n\n.PropertyEstimateViewPage_formContainer__BJX0v {\n    display: flex;\n    width: 100%;\n}\n\n.PropertyEstimateViewPage_footerButtons__3EyCY {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.PropertyEstimateViewPage_btnContent__QbUSC {\n    display: flex;\n    gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/estimatesPages/PropertyEstimateViewPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 6px 0px;\n}\n\n.formContainer {\n    display: flex;\n    width: 100%;\n}\n\n.footerButtons {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.btnContent {\n    display: flex;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "PropertyEstimateViewPage_pageWrap__bsRo6",
	"formContainer": "PropertyEstimateViewPage_formContainer__BJX0v",
	"footerButtons": "PropertyEstimateViewPage_footerButtons__3EyCY",
	"btnContent": "PropertyEstimateViewPage_btnContent__QbUSC"
};
export default ___CSS_LOADER_EXPORT___;
