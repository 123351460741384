import React, { useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests from '../../utils/requests';

import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { ProfileImage } from '../profileImage/ProfileImage';

import description from '../../assets/json/UserInformationUpdate.js';

import styles from './EditUserProfile.module.css';

const GetShortInfoFormDescription = () => {
    const { t } = useTranslation();

    return {
        questions: [
            {
                type: 'text',
                name: 'email',
                title: t('Email')
            },
            {
                type: 'dropdown',
                name: 'type',
                title: t('Type'),
                choices: [
                    {
                        title: t('Admin'),
                        value: 'ADMIN'
                    },
                    {
                        title: t('Member'),
                        value: 'MEMBER'
                    }
                ]
            },
            {
                type: 'date',
                name: 'registeredAt',
                title: t('Registered At'),
                withTime: true
            },
            {
                type: 'date',
                name: 'lastLogin',
                title: t('Last Login'),
                withTime: true
            }
        ]
    };
};

export const EditUserProfile = ({ user, updateUserData }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const addToast = useToasts();

    const shortInfoForm = GetShortInfoFormDescription();

    const updateData = newData => {
        updateUserData && updateUserData({ ...user, name: newData.name });
    };

    const isDataChanged = newData => {
        const d = JSON.parse(JSON.stringify(user));
        if (d.name !== newData.name) return true;
        return false;
    };

    const updateUser = async () => {
        const formData = formRef.current.getData();
        if (!isDataChanged(formData)) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }

        await requests.updateUserData(user.email, { name: formData.name }).catch(console.log);
        updateData(formData);
        addToast({
            type: 'success',
            message: t('User {{id}} updated successfully', { id: user.email }),
            duration: 3000
        });
    };

    return (
        <div className={styles.mainContainer}>
            <ProfileImage
                accountData={user}
                updateRequest={requests.postUserImage}
                deleteRequest={requests.deleteUserImage}
                updateData={updateUserData}
                toastMessages={{
                    onUpdate: {
                        type: 'success',
                        text: t('User {{id}} updated successfully', { id: user.email })
                    },
                    onDelete: {
                        type: 'success',
                        text: t('User {{id}} updated successfully', { id: user.email })
                    }
                }}
            />
            <div className={styles.shortInfoFormContainer}>
                <Form description={shortInfoForm} initialValue={user} readOnly />
            </div>
            {/* <div className={styles.sectionSplitter}> {t('Αλλαγή Ονόματος')} </div> */}
            <div className={styles.changeNameContainer}>
                <Form description={description()} initialValue={user} ref={formRef} />
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        width: '100%',
                        justifyContent: 'flex-end'
                    }}
                >
                    <ButtonWithLoading
                        className={`btn btn-primary-0`}
                        onClick={async () => {
                            await updateUser();
                        }}
                    >
                        {t('change')}
                    </ButtonWithLoading>
                </div>
            </div>
        </div>
    );
};
