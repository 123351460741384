import Utilities from '../../utils/utils';

import styles from './ProfileInfo.module.css';

export const ProfileInfo = ({ name, subName, className, imageSrc }) => {
    return (
        <div className={Utilities.classes(styles.container, className)}>
            {imageSrc ? (
                <div className={`${styles.iconContainer}`}>
                    <img className={styles.icon} src={imageSrc} alt={imageSrc} />
                </div>
            ) : (
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>{name ? name[0].toUpperCase() : ''}</div>
                </div>
            )}
            <div className={styles.infoRow}> {name} </div>
            <span className={`${styles.subName} ${styles.email}`}> {subName} </span>
        </div>
    );
};
