import Axios from 'axios';

function queryBuilding(obj) {
    const result = [];
    if (!obj) return '';
    for (const [key, value] of Object.entries(obj)) {
        if (value === undefined) continue;
        result.push(`${key}=${value}`);
    }
    if (result.length === 0) return '';

    return '?' + result.join('&');
}

const clientConfig = {
    port: 5000
};
// --------------------------------------------------------------------------------------

// 34.244.133.177 <- for frontend development ONLY
// ${window.location.hostname} <- for frontend & backend development
// export const URI = `${window.location.protocol}//${window.location.hostname}:${clientConfig.port}`;
export const URI = `${window.location.protocol}//${window.location.hostname}/api`;

export const axios = Axios.create({
    withCredentials: true,
    baseURL: URI
});

// --------------------------------------------------------------------------------------

export const setAuthHeaders = value => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${value}`;
};

const requests = {};

// --------------------------------------------------------------------------------------

//#region user
requests.refreshToken = () => axios.post(`${URI}/user/refresh-token`);
requests.validatePassword = password => axios.post(`${URI}/user/validate-password`, { password });
requests.login = ({ email, password, token }) =>
    axios.post(`${URI}/user/login`, { email, password, token });
requests.logout = () => axios.post(`${URI}/user/logout`);
requests.disableUser = email => axios.put(`${URI}/user/set-disabled`, { email, disabled: '1' });
requests.enableUser = email => axios.put(`${URI}/user/set-disabled`, { email, disabled: '0' });
requests.verify = (email, token) => axios.post(`user/verify`, { email, token });
requests.register = (email, name, password, type, extraData = {}) =>
    axios.post('/user/register', {
        email,
        name,
        password,
        type,
        ...extraData
    });
requests.sendVerificationEmail = email => axios.post('user/send-verification-email', { email });
requests.sendResetPasswordToken = email => axios.post('user/send-reset-password-token', { email });
requests.resetPassword = (email, token, password) =>
    axios.post('user/reset-password', { email, token, password });
requests.validateResetPasswordToken = (email, token) =>
    axios.post('user/validate-reset-password-token', { email, token });

// My info
requests.putMyInfo = info => axios.put(`${URI}/user/general/my-info`, info);

requests.getMyInfo = () => axios.get(`${URI}/user/general/my-info`);

requests.postUserImage = (email, file) =>
    axios.post(`${URI}/user/info/${email}/image`, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.deleteUserImage = email => axios.delete(`${URI}/user/info/${email}/image`);

//user's owning properties...
requests.getUsersOwningProperties = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/my-properties${queryBuilding(options)}`);

// user's overview
requests.getUserEstimates = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/estimates${queryBuilding(options)}`);
requests.getUserExpenses = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/expenses${queryBuilding(options)}`);
requests.getUserLeases = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/leases${queryBuilding(options)}`);
requests.getUserSuggestions = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/suggestions${queryBuilding(options)}`);
requests.getUserRenovations = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/renovations${queryBuilding(options)}`);
requests.getUserSmartFeatures = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/smart-features${queryBuilding(options)}`);

// MY overview
requests.getMyEstimates = (options = {}) =>
    axios.get(`${URI}/user/general/estimates${queryBuilding(options)}`);
requests.getMyExpenses = (options = {}) =>
    axios.get(`${URI}/user/general/expenses${queryBuilding(options)}`);
requests.getMyLeases = (options = {}) =>
    axios.get(`${URI}/user/general/leases${queryBuilding(options)}`);
requests.getMySuggestions = (options = {}) =>
    axios.get(`${URI}/user/general/suggestions${queryBuilding(options)}`);
requests.getMyRenovations = (options = {}) =>
    axios.get(`${URI}/user/general/renovations${queryBuilding(options)}`);
requests.getMySmartFeatures = (options = {}) =>
    axios.get(`${URI}/user/general/smart-features${queryBuilding(options)}`);

// team's overview
requests.getTeamEstimates = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/estimates${queryBuilding(options)}`);
requests.getTeamExpenses = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/expenses${queryBuilding(options)}`);
requests.getTeamLeases = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/leases${queryBuilding(options)}`);
requests.getTeamSuggestions = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/suggestions${queryBuilding(options)}`);
requests.getTeamRenovations = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/renovations${queryBuilding(options)}`);
requests.getTeamSmartFeatures = (id, options = {}) =>
    axios.get(`${URI}/team/${id}/smart-features${queryBuilding(options)}`);

requests.assignRole = (teamId, roleId, userEmail) =>
    axios.post(`${URI}/team/${teamId}/role/${roleId}/user/${userEmail}`);
requests.removeRoleFromUser = (teamId, roleId, userEmail) =>
    axios.delete(`${URI}/team/${teamId}/role/${roleId}/user/${userEmail}`);

requests.getAllTeamRoles = teamId => axios.get(`${URI}/team/${teamId}/role`);
requests.createRoleOnTeam = (teamId, data) => axios.post(`${URI}/team/${teamId}/role`, data);
requests.updateRoleOnTeam = (teamId, roleId, data) =>
    axios.put(`${URI}/team/${teamId}/role/${roleId}`, data);

requests.updateRole = (attributes, roleId) => axios.put(`${URI}/role/${roleId}`, attributes);

requests.getRole = (teamId, roleId) => axios.get(`${URI}/team/${teamId}/role/${roleId}`);

//User info
requests.getUser = email => axios.get(`${URI}/user/info/${email}`);

//User's listing properties
requests.getUsersProperties = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/properties${queryBuilding(options)}`);
requests.getUserOwningProperties = email => axios.get(`${URI}/user/info/${email}/my-properties`);

requests.getUsersTeams = (email, options = {}) =>
    axios.get(`${URI}/user/info/${email}/teams${queryBuilding(options)}`);

requests.getUserTeamsNumber = email => axios.get(`${URI}/user/info/${email}/teams/number`);

requests.getUserActivity = (email, options = {}) =>
    axios.get(`${URI}/log/user/${email}${queryBuilding(options)}`);

requests.getUserPermissionsForUserProfile = (visitedEmail, userEmail) =>
    axios.get(`${URI}/user/${userEmail}/visited/${visitedEmail}/permissions`);

requests.updateUserData = (email, userData) => axios.put(`${URI}/user/info/${email}`, userData);

//General info
requests.getAllUsers = queries => axios.get(`${URI}/user/info${queryBuilding(queries)}`);
requests.getAllAdminsUsers = () => axios.get(`${URI}/user/info?type=ADMIN`);
requests.getAllSimpleUsers = () => axios.get(`${URI}/user/info?type=MEMBER`);

requests.getTopMembers = top => axios.get(`${URI}/user/general/top-users${queryBuilding({ top })}`);
requests.getNumberOfMembers = () => axios.get(`${URI}/user/general/number-of-users`);

//#endregion

//#region logs

//My logs
requests.getMyLogs = limit => axios.get(`${URI}/log/mine${queryBuilding(limit)}`);

//General logs
requests.getAllLogs = data => axios.get(`${URI}/log/all${queryBuilding(data)}`);

//#endregion

//#region team
requests.postTeam = teamData => axios.post(`${URI}/team`, teamData);
requests.postTeamImage = (teamId, file) =>
    axios.post(`${URI}/team/${teamId}/image`, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.deleteTeamImage = teamId => axios.delete(`${URI}/team/${teamId}/image`);
requests.postTeamMember = (teamId, userEmail, data = {}) =>
    axios.post(`${URI}/team/${teamId}/user/${userEmail}`, data);

requests.deleteTeamMember = (teamId, userEmail) =>
    axios.delete(`${URI}/team/${teamId}/user/${userEmail}`);

requests.disableTeam = (team, body) => axios.put(`${URI}/team/${team}/activate`, body); //body = { activeUntil ,maxMembers ,maxProperties }
requests.enableTeam = team => axios.put(`${URI}/team/${team}/deactivate`, {});
requests.updateTeamManager = (team, user) =>
    axios.put(`${URI}/team/team/${team}/user/${user}/manager-status`);
//My team
requests.getMyTeams = limit => axios.get(`${URI}/team/mine${queryBuilding({ limit })}`);
requests.getMyTeamsNumber = () => axios.get(`${URI}/team/mine/number`);

//Team info
requests.getTeam = teamId => axios.get(`${URI}/team/${teamId}`);
requests.getTeamFull = teamId => axios.get(`${URI}/team/${teamId}/full`);
requests.getTeamMembers = (teamId, limit) =>
    axios.get(`${URI}/team/${teamId}/members${queryBuilding({ limit })}`);
requests.getTeamMembersNumber = teamId => axios.get(`${URI}/team/${teamId}/members/number`);

requests.getTeamActivity = (teamId, limit) =>
    axios.get(`${URI}/log/team/${teamId}${queryBuilding({ limit })}`);

requests.getUserPermissionsForTeam = (teamId, userEmail) =>
    axios.get(`${URI}/team/${teamId}/user/${userEmail}/permissions`);

requests.updateTeamData = (teamId, teamData) => axios.put(`${URI}/team/${teamId}/info`, teamData); //teamData = {name,description}

//General info
requests.getAllTeams = () => axios.get(`${URI}/team/full`);
requests.getTopTeams = top => axios.get(`${URI}/team/general/top-teams?top=${top}`);
requests.getNumberOfTeams = () => axios.get(`${URI}/team/general/number-of-teams`);

//#endregion

//#region properties

//My properties

requests.getMyProperties = limit => axios.get(`${URI}/property/mine${queryBuilding(limit)}`);
requests.getMyPropertiesNumber = () => axios.get(`${URI}/property/mine/number`);

//Properties of team
requests.postProperty = propertyData => axios.post(`${URI}/property`, propertyData);
requests.getProperty = propertyId => axios.get(`${URI}/property/${propertyId}`);
requests.getPropertyBuildings = propertyId => axios.get(`${URI}/property/${propertyId}/buildings`);
requests.updateProperty = (propertyId, propertyData) =>
    axios.put(`${URI}/property/${propertyId}`, propertyData);

requests.getAllProperties = () => axios.get(`${URI}/property`);
requests.getAllPublicProperties = () => axios.get(`${URI}/property/public`);

requests.getTopProperties = top => axios.get(`${URI}/property/simplified${queryBuilding({ top })}`);

requests.getPropertyPage = (pageNo, rowsPerPage, filters = {}, sortBy) => {
    const query = Object.entries(filters)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => `${name}=${value}`)
        .join('&');
    const [by, direction] = sortBy.split('__');
    return axios.get(`${URI}/property/page/${pageNo}/${rowsPerPage}/${by}/${direction}/?${query}`);
};
requests.getAllPropertiesOfTeam = teamdId => axios.get(`${URI}/team/${teamdId}/properties`);
//Buildings
requests.createBuildingPart = (buildingId, partData) =>
    axios.post(`${URI}/building/${buildingId}/buildingPart`, partData);
requests.getBuildingParts = buildingId => axios.get(`${URI}/building/${buildingId}/buildingPart`);
requests.getBuildingPart = (buildingId, buildingPartId) =>
    axios.get(`${URI}/building/${buildingId}/buildingPart/${buildingPartId}`);
requests.getBuilding = buildingId => axios.get(`${URI}/building/${buildingId}`);
requests.updateBuilding = (buildingId, buildingData) =>
    axios.put(`${URI}/building/${buildingId}`, buildingData);

requests.getTypesOfProperties = () => axios.get(`${URI}/property/types`);
requests.getTypesOfBuildingPart = () => axios.get(`${URI}/building/buildingPartTypes`);

requests.getPropertyAssets = (propertyId, options) =>
    axios.get(`${URI}/property/${propertyId}/assets${queryBuilding(options)}`);
requests.getPropertyAssetsMain = propertyId =>
    axios.get(`${URI}/property/${propertyId}/assets/main`);
requests.setPropertyAssetsMain = (propertyId, assetId) =>
    axios.put(`${URI}/property/${propertyId}/assets/main/${assetId}`);
requests.addPropertyAssets = (propertyId, data) =>
    axios.post(`${URI}/property/${propertyId}/assets`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.removePropertyAsset = (propertyId, assetId) =>
    axios.delete(`${URI}/property/${propertyId}/assets/${assetId}`);

requests.getPropertyVideos = (propertyId, options) =>
    axios.get(`${URI}/property/${propertyId}/videos${queryBuilding(options)}`);
requests.addPropertyVideos = (propertyId, data) =>
    axios.post(`${URI}/property/${propertyId}/videos`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.removePropertyVideo = (propertyId, assetId) =>
    axios.delete(`${URI}/property/${propertyId}/videos/${assetId}`);

requests.getPropertyDigitalFolder = (propertyId, options) =>
    axios.get(`${URI}/property/${propertyId}/digital-folder${queryBuilding(options)}`);
requests.uploadPropertyDigitalFile = (propertyId, data) =>
    axios.post(`${URI}/property/${propertyId}/digital-folder`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.editPropertyDigitalFile = (propertyId, digitalFileId, data) =>
    axios.put(`${URI}/property/${propertyId}/digital-folder/${digitalFileId}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.removePropertyDigitalFile = (propertyId, digitalFileId) =>
    axios.delete(`${URI}/property/${propertyId}/digital-folder/${digitalFileId}`);

requests.getPropertyOwners = propertyId => axios.get(`${URI}/property/${propertyId}/owners`);
requests.updatePropertyOwners = (propertyId, owners) =>
    axios.put(`${URI}/property/${propertyId}/owners`, { owners });

//#endregion
// #region building estimates, leases, expenses, suggestions

requests.getAllBuildingEstimates = buildingId =>
    axios.get(`${URI}/building/${buildingId}/estimates`);
requests.getBuildingEstimate = (buildingId, id) =>
    axios.get(`${URI}/building/${buildingId}/estimates/${id}`);
requests.createBuildingEstimate = (buildingId, data) =>
    axios.post(`${URI}/building/${buildingId}/estimates`, data);
requests.updateBuildingEstimate = (buildingId, id, data) =>
    axios.put(`${URI}/building/${buildingId}/estimates/${id}`, data);

requests.getAllBuildingLeases = buildingId => axios.get(`${URI}/building/${buildingId}/leases`);
requests.getBuildingLease = (buildingId, id) =>
    axios.get(`${URI}/building/${buildingId}/leases/${id}`);
requests.createBuildingLease = (buildingId, data) =>
    axios.post(`${URI}/building/${buildingId}/leases`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.updateBuildingLease = (buildingId, id, data) =>
    axios.put(`${URI}/building/${buildingId}/leases/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.getAllBuildingExpenses = buildingId => axios.get(`${URI}/building/${buildingId}/expenses`);
requests.getBuildingExpense = (buildingId, id) =>
    axios.get(`${URI}/building/${buildingId}/expenses/${id}`);
requests.createBuildingExpense = (buildingId, data) =>
    axios.post(`${URI}/building/${buildingId}/expenses`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.updateBuildingExpense = (buildingId, id, data) =>
    axios.put(`${URI}/building/${buildingId}/expenses/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.deleteBuildingExpense = (buildingId, id) =>
    axios.delete(`${URI}/building/${buildingId}/expenses/${id}`);

requests.getBuildingPublicStatus = buildingId =>
    axios.get(`${URI}/building/${buildingId}/options/public`);
requests.updateBuildingPublicStatus = (buildingId, data) =>
    axios.put(`${URI}/building/${buildingId}/options/public`, data);

requests.getBuildingSuggestion = buildingId =>
    axios.get(`${URI}/building/${buildingId}/suggestions`);
requests.updateBuildingSuggestion = (buildingId, data) =>
    axios.put(`${URI}/building/${buildingId}/suggestions`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.getAllBuildingRenovations = buildingId =>
    axios.get(`${URI}/building/${buildingId}/renovations`);
requests.getBuildingRenovation = (buildingId, id) =>
    axios.get(`${URI}/building/${buildingId}/renovations/${id}`);
requests.createBuildingRenovation = (buildingId, data) =>
    axios.post(`${URI}/building/${buildingId}/renovations`, data);
requests.updateBuildingRenovation = (buildingId, id, data) =>
    axios.put(`${URI}/building/${buildingId}/renovations/${id}`, data);
requests.deleteBuildingRenovation = (buildingId, id) =>
    axios.delete(`${URI}/building/${buildingId}/renovations/${id}`);

requests.getBuildingSmartFeatures = buildingId =>
    axios.get(`${URI}/building/${buildingId}/smartfeatures`);
requests.updateBuildingSmartFeatures = (buildingId, smartFeatureId, data) =>
    axios.put(`${URI}/building/${buildingId}/smartfeatures/${smartFeatureId}`, data);
requests.createBuildingSmartFeatures = (buildingId, data) =>
    axios.post(`${URI}/building/${buildingId}/smartfeatures`, data);
// #endregion
// #region plot/land estimates, leases, expenses, suggestions

requests.getAllPlotEstimates = plotId => axios.get(`${URI}/plot/${plotId}/estimates`);
requests.getPlotEstimate = (plotId, id) => axios.get(`${URI}/plot/${plotId}/estimates/${id}`);
requests.createPlotEstimate = (plotId, data) => axios.post(`${URI}/plot/${plotId}/estimates`, data);
requests.updatePlotEstimate = (plotId, id, data) =>
    axios.put(`${URI}/plot/${plotId}/estimates/${id}`, data);

requests.getAllPlotLeases = plotId => axios.get(`${URI}/plot/${plotId}/leases`);
requests.getPlotLease = (plotId, id) => axios.get(`${URI}/plot/${plotId}/leases/${id}`);
requests.createPlotLease = (plotId, data) =>
    axios.post(`${URI}/plot/${plotId}/leases`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.updatePlotLease = (plotId, id, data) =>
    axios.put(`${URI}/plot/${plotId}/leases/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.getAllPlotExpenses = plotId => axios.get(`${URI}/plot/${plotId}/expenses`);
requests.getPlotExpense = (plotId, id) => axios.get(`${URI}/plot/${plotId}/expenses/${id}`);
requests.createPlotExpense = (plotId, data) =>
    axios.post(`${URI}/plot/${plotId}/expenses`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.updatePlotExpense = (plotId, id, data) =>
    axios.put(`${URI}/plot/${plotId}/expenses/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
requests.deletePlotExpense = (plotId, id) => axios.delete(`${URI}/plot/${plotId}/expenses/${id}`);

requests.getPlotSuggestion = plotId => axios.get(`${URI}/plot/${plotId}/suggestions`);
requests.updatePlotSuggestion = (plotId, data) =>
    axios.put(`${URI}/plot/${plotId}/suggestions`, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

requests.getPlotPublicStatus = plotId => axios.get(`${URI}/plot/${plotId}/options/public`);
requests.updatePlotPublicStatus = (plotId, data) =>
    axios.put(`${URI}/plot/${plotId}/options/public`, data);

// #endregion
requests.getPlaces = input => axios.get(`${URI}/places/${input}`);

requests.getGuestExample = propertyId =>
    axios.get(`${URI}/property/example-property-guest/${propertyId}`);

requests.createMapInfoToProperty = (propertyId, data) =>
    axios.post(`${URI}/property/${propertyId}/map-info`, data);

requests.getMapInfoProperty = propertyId => axios.get(`${URI}/property/${propertyId}/map-info`);
requests.updateMapInfoProperty = (propertyId, data) =>
    axios.put(`${URI}/property/${propertyId}/map-info`, data);

//statistics

// leases
requests.getLeasesByMonth = teamId => axios.get(`${URI}/team-statistics/${teamId}/leases-by-month`);

requests.getLeasesByPaymentType = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/leases-by-payment-type`);

requests.getLeasesByTypeOfProperty = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/leases-by-property-type`);

requests.getLeaseByPlot = teamId => axios.get(`${URI}/team-statistics/${teamId}/lease-by-plot`);
requests.getLeaseByBuilding = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/lease-by-building`);

// estimations
requests.getEstimationsByMonth = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/estimations-by-month`);

requests.getEstimationsByTypeOfProperty = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/estimations-by-property-type`);

requests.getEstimationByPlot = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/estimation-plot`);

requests.getEstimationByBuilding = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/estimation-building`);

// renovations
requests.getRenovationsByMonth = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/renovation-by-month`);

requests.getRenovationsByTypeOfRenovation = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/renovation-by-type-of-renovation`);

requests.getRenovationsByTypeOfProperty = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/renovation-by-type-of-property`);

requests.getRenovationByPlot = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/renovation-plot`);

requests.getRenovationByBuilding = teamId =>
    axios.get(`${URI}/team-statistics/${teamId}/renovation-building`);

// Fake requests

requests.getModalPreviewUsersFakeData = users =>
    new Promise(resolve =>
        setTimeout(() => {
            resolve(users);
        }, 500)
    );

requests.getModalPreviewTeamsFakeData = teams =>
    new Promise(resolve =>
        setTimeout(() => {
            resolve({ data: teams });
        }, 500)
    );

export default requests;
