import React from 'react';

import ErrorPrompt from 'react-form/dist/Form/form-inputs/ErrorPrompt';
import { Input } from 'react-form/dist/Form/form-inputs/Input';

// ---------------------------------------------------------------------------------------

export class MultipleSelectionInput extends Input {
    constructor(props) {
        super(props);

        this.state.value = this.computeValueToStore(this.state.value);
        this.onChange = this.onChange.bind(this);
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.state.choices = props.description.choices || [];
    }

    computeValueToStore(value) {
        switch (this.props.description.dataType) {
            case 'text':
                return value && value.split(',');
            case 'array':
            default:
                return value;
        }
    }

    computeValueToReturn(value) {
        switch (this.props.description.dataType) {
            case 'text':
                return value && value.join(',');
            case 'array':
            default:
                return value;
        }
    }

    setData(data, onFinish) {
        this.onChange(
            {
                target: {
                    value: this.computeValueToStore(data)
                }
            },
            onFinish
        );
    }

    getData() {
        if (this.state.value === '') return null;
        return this.computeValueToReturn(this.state.value);
    }

    valueUpdate(oldValue, val = super.getData()) {
        this.props.onChange(this.props.description.name, val);

        const bindQuestions = this.props.description.bindQuestions;
        if (!bindQuestions) return;

        const getQuestionsToHide = () => {
            if (!oldValue) {
                return [];
            }
            return Object.entries(bindQuestions).flatMap(([value, questions]) =>
                oldValue.includes(value) ? questions : []
            );
        };

        const getQuestionsToShow = () => {
            if (!val) {
                return [];
            }
            return Object.entries(bindQuestions).flatMap(([value, questions]) =>
                val.includes(value) ? questions : []
            );
        };

        const questionsToHide = getQuestionsToHide();
        const questionsToShow = getQuestionsToShow();

        this.applyToQuestions(questionsToHide, 'hideQuestion');
        this.applyToQuestions(questionsToShow, 'showQuestion');
    }

    /* invoked by Input.SetData */
    onChange(e, onFinish) {
        const value = e.target.value;

        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    /* invoked when the end-user changes the data */
    onChangeSelection(e) {
        var value;
        if (!this.state.value) value = [];
        else value = this.state.value.slice();
        if (e.target.checked) {
            value.push(e.target.value);
        } else {
            var index = value.indexOf(e.target.value);
            value.splice(index, 1);
        }

        if (value.length === 0) value = null;

        this.valueUpdate(this.state.value, value);
        this.setState({ value });
    }

    setOptions(choices) {
        this.setState({ choices });
    }

    readOnlyRender() {
        const toShow = [];
        for (const val of this.state.value || []) {
            toShow.push(this.state.choices.find(c => c.value === val).title);
        }

        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>
                    {!!toShow.length ? toShow.join(', ') : '-'}
                </div>
            </>
        );
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        const options = this.state.choices.map(choice => ({
            value: choice.value,
            label: choice.title || choice.name
        }));

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div className='form-group' style={this.props.style}>
                    <label
                        style={{ width: '100%' }}
                        aria-label={this.props.description.title || this.props.description.name}
                    ></label>
                    <div className='multiselection-container'>
                        {options &&
                            options.map((elem, index) => (
                                <div
                                    key={`${
                                        this.props.description.title || this.props.description.name
                                    }_option_${index}`}
                                    className='multiselection-item'
                                >
                                    <input
                                        id={elem.label}
                                        className='multiselection-input'
                                        type='checkbox'
                                        name={elem.label}
                                        value={elem.value}
                                        onChange={this.onChangeSelection}
                                        checked={
                                            this.state.value &&
                                            this.state.value.find(element => element === elem.value)
                                                ? true
                                                : false
                                        }
                                    />
                                    <label className='multiselection-label' htmlFor={elem.label}>
                                        {elem.label}
                                    </label>
                                </div>
                            ))}
                    </div>
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}

// ---------------------------------------------------------------------------------------
