import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IoMdAddCircleOutline } from 'react-icons/io';

import { useUser } from '../../context/withUser';

import AddPropertyForm from '../../components/addPropertyForm/AddPropertyForm';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { Modal } from '../../components/modal/Modal';
import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

import requests from '../../utils/requests';

import styles from './UserPropertyPage.module.css';

export const UserPropertyPage = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { userId } = params;
    const navigate = useNavigate();
    const { user } = useUser();
    const [isDisableAddModal, setIsDisabledAddModal] = useState(true);
    const formRef = useRef(null);
    const [mainFilters, setMainFilters] = useState(null);
    const [properties, setProperties] = useState(null);

    useEffect(() => {
        requests
            .getUsersOwningProperties(user.email)
            .then(response => setProperties(response.data));
    }, []);

    const onSuccessfulSubmit = data => {
        requests.updatePropertyOwners(data.id, [{ ...user, percentage: 100 }]).then(() => {
            setIsDisabledAddModal(true);
            navigate(`../../properties/edit/${data.id}/info`);
        });
    };

    const submitProperty = async () => {
        if (!formRef.current) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const formData = formRef.current.getData();

        const submitedData = {
            teamId: null,
            name: formData.propertyName,
            type: formData.propertyType,
            category: formData.propertyCategory,
            location: formData.propertyLocationMap[0],
            region: formData.locationInfoRegion,
            regionalUnit: formData.locationInfoRegionalUnit,
            street: formData.locationInfoAddress,
            streetNumber: formData.locationInfoAddrNumber
        };

        const { data } = await requests.postProperty(submitedData);
        onSuccessfulSubmit(data);
    };
    // async function fetch() {
    //     const properties = (await requests.getUsersOwningProperties(user.email)).data;
    //     return { data: { count: properties.length, rows: properties } };
    // }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('Properties')} />

                <span
                    className={`btn btn-primary-0 ${styles.addButton}`}
                    onClick={() => setIsDisabledAddModal(false)}
                >
                    <IoMdAddCircleOutline size={'20px'} />
                    {t('add-property-header')}
                </span>
            </div>
            {!isDisableAddModal && (
                <Modal
                    onClose={() => setIsDisabledAddModal(true)}
                    header={t('add-property-header')}
                    content={<AddPropertyForm formRef={formRef} />}
                    footer={
                        <div className={styles.addPropertyFormFooter}>
                            <ButtonWithLoading
                                className='btn'
                                onClick={() => {
                                    setIsDisabledAddModal(true);
                                }}
                            >
                                {t('Cancel')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                                className='btn btn-primary-0'
                                onClick={submitProperty}
                            >
                                {t('Create')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
            <SearchPaginatedPropertiesWithMap
                extraFilters={{ ownerEmail: userId }}
                path='../../properties/view'
            />
        </div>
    );
};
