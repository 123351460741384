import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { HighlightMap } from '../../components/highlightMap/HighlightMap';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import registerDescription from '../../assets/json/Register.js';

import styles from './LoginPage.module.css';

import propertyCollage from '../../assets/images/propertyCollage.jpg';

export const RegisterPage = props => {
    const { t } = useTranslation();
    const addToast = useToasts();

    const formRef = useRef();
    const recaptchaRef = useRef(null);
    const [success, setSuccess] = useState();
    const emailRef = useRef();
    const navigate = useNavigate();
    const [properties, setProperties] = useState(null);

    useEffect(() => {
        requests.getAllPublicProperties().then(({ data }) => setProperties(data));
    }, []);

    if (!properties) {
        return <LoaderWrapper />;
    }

    async function checkIfEmailExist(email) {
        try {
            await requests.getUser(email);
            return true;
        } catch {
            return false;
        }
    }

    async function register() {
        formRef.current.clearSanityCheck();
        const { email, name, password, passwordVerify } = formRef.current.getData();
        const trimmedEmail = email.trim();

        Form.registerRestrictionCheck({
            tag: 'validateConfirmationPassword',
            func: () => {
                return password === passwordVerify;
            },
            explanation: t('passwords-dont-mach-TODO')
        });

        formRef.current.setData({ ...formRef.current.getData(), email: trimmedEmail });

        // if (await checkIfEmailExist(trimmedEmail)) {
        //     addToast({
        //         type: 'failure',
        //         message: t('this-email-exist-already'),
        //         duration: 5000
        //     });
        //     return;
        // }

        const sanityCheck = formRef.current.sanityCheckForm();
        if (!!sanityCheck.length) return null;

        const token = recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        if (!token) {
            addToast({
                type: 'failure',
                message: t('invalid-captcha'),
                duration: 5000
            });
            formRef.current.clear();
            return false;
        }

        try {
            const response = await requests.register(trimmedEmail, name, password, 'MEMBER', {
                token
            });

            if (response.status === 200) {
                emailRef.current = trimmedEmail;
                setSuccess(true);
            } else if (response.data && response.data.type === 'user-already-exists') {
                addToast({
                    type: 'failure',
                    message: t('this-email-exist-already'),
                    duration: 5000
                });
                setSuccess(false);
            } else {
                addToast({
                    type: 'failure',
                    message: t('register fail - unknown error'),
                    duration: 5000
                });
                setSuccess(false);
            }
        } catch (err) {
            if (
                err.response &&
                err.response.data &&
                err.response.data.type === 'user-already-exists'
            ) {
                addToast({
                    type: 'failure',
                    message: t('this-email-exist-already'),
                    duration: 5000
                });
            } else {
                addToast({
                    type: 'failure',
                    message: t('fail-register-user'),
                    duration: 5000
                });
                setSuccess(false);
            }
        }
    }

    return (
        <div className={styles.mainContainer}>
            {success && (
                <div className={styles.verificationDiv}>
                    <SuccessMessage>
                        <div className={styles.rowDiv}>
                            {t('An email with a verification link has been sent to')} &nbsp;
                            <b> {` ${emailRef.current}`}</b>.
                        </div>
                        {t('Please verify your account in order to log in')}
                        {/* <div
                            className={styles.backToLoginVerificationPageButton}
                            onClick={() => navigate(`../login`)}
                        >
                            <AiOutlineArrowLeft />
                            {t('Login')}
                        </div> */}
                    </SuccessMessage>
                </div>
            )}
            {!success && (
                <div className={styles.logInWrap}>
                    <div className={styles.prompt}>{t('register-eproperties')}</div>
                    <div className={styles.formContainer}>
                        <Form ref={formRef} description={registerDescription()} />
                        <div className={styles.recaptchaContainer}>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={recaptchaRef}
                            />
                        </div>

                        <ButtonWithLoading
                            className={`${styles.loginButton} btn btn-primary-0`}
                            onClick={async () => register()}
                        >
                            {t('register')}
                        </ButtonWithLoading>

                        <div className={styles.registerWrap}>
                            {t('have-account')}
                            <div className={styles.linkButton} onClick={() => navigate('../login')}>
                                {t('login')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.advertiseColRegister}>
                        {/* <HighlightMap
                            properties={properties}
                            height={'calc(100vh - var(--navbar-height)'}
                        /> */}
                        <img src={propertyCollage} className={styles.propertyImg} />
                        <div className={styles.advertiseColRegisterOverlay} />
                    </div>
                </div>
            )}
        </div>
    );
};
