import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, InfoWindow, LoadScript, Marker, OverlayView } from '@react-google-maps/api';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlineEuroCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { BiArea, BiBath } from 'react-icons/bi';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { IoConstructOutline } from 'react-icons/io5';
import { MdOutlineKingBed, MdOutlinePlace, MdOutlineStairs, MdStairs } from 'react-icons/md';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Modal } from '../../components/modal/Modal';

import { URI } from '../../utils/requests';

import propertyImage from '../../assets/images/AlphaLogo.png';
import VariableMaps from '../../assets/json/VariableMaps';

import styles from '../generalPages/PropertiesMap.module.css';

const containerStyle = {
    width: '100%',
    borderRadius: '10px'
};

const PropertiesMap = forwardRef(
    (
        { isFullMap, setIsFullMap, guestMode = false, initialZoom = 6, initialProperties, height },
        ref
    ) => {
        const [info, setInfo] = useState('');
        const [center, setCenter] = useState({
            lng: 23.767788666805874,
            lat: 37.98582231675635
        });
        const { t } = useTranslation();
        const [activeMarker, setActiveMarker] = useState(null);
        const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
        const formRef = useRef();
        const [variable, setVariable] = useState(null);
        const [activeOverlayView, setActiveOverlayView] = useState(false);
        const [highlightedIcon, setHighlightedIcon] = useState(null);
        const [properties, setProperties] = useState(initialProperties);
        const [zoom, setZoom] = useState(initialZoom);
        const videoUrl =
            'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
        const navigate = useNavigate();
        const addToast = useToasts();

        useImperativeHandle(
            ref,
            () => ({
                setCenter,
                setHighlightedIcon,
                setProperties,
                setZoom,
                setInfoWindowVisible
            }),
            // eslint-disable-next-line
            []
        );

        function setInfoWindowVisible(property) {
            if (!property) return setActiveMarker(null);
            onMarkerClick(property);
        }

        function iconBuildingStyle(m) {
            return {
                path: faHome.icon[4],
                fillColor:
                    m?.latitude === highlightedIcon?.latitude &&
                    m?.longtitude === highlightedIcon?.longtitude
                        ? highlightedIcon.color
                        : 'black',
                fillOpacity: 1,

                strokeWeight: 1,
                strokeColor: '#ffffff',
                scale: 0.04
            };
        }

        function iconPlotStyle(m) {
            return {
                path: faLayerGroup.icon[4],
                fillColor:
                    m?.latitude === highlightedIcon?.latitude ? highlightedIcon.color : 'black',
                fillOpacity: 1,

                strokeWeight: 1,
                strokeColor: '#ffffff',
                scale: 0.04
            };
        }
        const labelFloor = floor => {
            const floorNumber = floor;
            let floorLabel;
            if (floorNumber === null) {
                floorLabel = '-';
            } else if (floorNumber === 0) {
                floorLabel = t('Ground');
            } else {
                const absFloorNumber = Math.abs(floorNumber);
                const lastDigit = absFloorNumber % 10;
                const isTeen = absFloorNumber % 100 >= 11 && absFloorNumber % 100 <= 13;
                if (lastDigit === 1 && !isTeen) {
                    floorLabel = `${floorNumber}${t('st')}`;
                } else if (lastDigit === 2 && !isTeen) {
                    floorLabel = `${floorNumber}${t('nd')}`;
                } else if (lastDigit === 3 && !isTeen) {
                    floorLabel = `${floorNumber}${t('rd')}`;
                } else {
                    floorLabel = `${floorNumber}${t('th')}`;
                }
            }
            return floorLabel;
        };
        function displayVariablesOnMap(variable, property) {
            return variable?.map(v => {
                return (
                    <div className={styles.placeForVariableMaps}>
                        {v === 'price' && (
                            <div className={styles.var}>
                                <AiOutlineEuroCircle /> {property?.price + '$' || '-'}
                            </div>
                        )}
                        {v === 'bedrooms' && (
                            <div className={styles.var}>
                                <MdOutlineKingBed /> 3 beds
                            </div>
                        )}
                        {v === 'bathrooms' && (
                            <div className={styles.var}>
                                <BiBath /> 5 baths
                            </div>
                        )}
                        {v === 'acreage' && (
                            <div className={styles.var}>
                                <BiArea />
                                {`${
                                    ((property?.areaSqM === undefined ||
                                        property?.areaSqM === null) &&
                                        '- ' + t('㎡')) ||
                                    property?.areaSqM + ' ' + `${t('㎡')}`
                                } `}
                            </div>
                        )}
                        {v === 'area' && (
                            <div className={styles.var}>
                                <MdOutlinePlace />
                                {((property?.area === undefined || property?.area === null) &&
                                    '-') ||
                                    property?.area}
                            </div>
                        )}
                        {v === 'floor' && (
                            <div className={styles.var}>
                                <MdOutlineStairs />
                                {labelFloor(property?.floor)}
                            </div>
                        )}

                        {v === 'contructDate' && (
                            <div className={styles.var}>
                                <IoConstructOutline />
                                {(property?.yearOfConstruction === null && '-') ||
                                    property?.yearOfConstruction + ' ' + t('year') ||
                                    '-'}
                            </div>
                        )}
                    </div>
                );
            });
        }

        async function checkIfImageExists(property) {
            const img = new Image();
            img.src = `${URI}/${property.mainImagePath}`;
            try {
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
                return true;
            } catch (error) {
                return false;
            }
        }

        const onMarkerClick = useCallback(async marker => {
            setActiveMarker({
                marker,
                lon: marker?.building?.longtitude || marker?.longtitude,
                lat: marker?.building?.latitude || marker?.latitude
            });

            setInfo(
                <div className={styles.InfoWindowClass}>
                    {(await checkIfImageExists(marker)) ? (
                        <img
                            src={`${URI}/${marker.mainImagePath}`}
                            alt={`${marker.name}_${marker.id}`}
                            className={styles.imageInfoWindow}
                        />
                    ) : (
                        <img
                            src={propertyImage}
                            style={{ objectFit: 'scale-down' }}
                            className={styles.imageInfoWindow}
                            alt={
                                marker.name ||
                                (marker.building && marker.building.name) ||
                                (marker.plot && marker.plot.name)
                            }
                        />
                    )}

                    <div>
                        <Link
                            className={styles.infoName}
                            to={`../properties/view/${marker.propertyId || marker.id}/info`}
                        >
                            {marker?.name}
                        </Link>
                    </div>
                </div>
            );
        }, []);

        function mapExpand() {
            setIsFullMap(!isFullMap);
        }
        function variableMapsSubmit() {
            if (!formRef.current) return;
            formRef.current.clearSanityCheck();

            const sanityCheckRes = formRef.current.sanityCheckForm();
            if (!!sanityCheckRes.length) {
                return;
            }

            setVariable(formRef.current.getData().category);
            setActiveOverlayView(true);
            setIsDisabledAddModal(false);
        }

        function renderAddModal() {
            if (!isDisableAddModal) return null;
            return (
                <Modal
                    onClose={() => {
                        setIsDisabledAddModal(false);
                    }}
                    header={t('Variables')}
                    content={
                        <div>
                            <Form
                                description={VariableMaps()}
                                ref={formRef}
                                initialValue={{ category: variable }}
                            />
                        </div>
                    }
                    footer={
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <ButtonWithLoading
                                className={`btn`}
                                onClick={() => {
                                    setIsDisabledAddModal(false);
                                }}
                            >
                                {t('Reject', 'Απόρριψη')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                                className={`btn btn-primary-0`}
                                onClick={async () => variableMapsSubmit()}
                            >
                                {t('Confirm')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            );
        }

        function handleZoomChanged() {
            if (this.getZoom() > 7 && variable) {
                setActiveOverlayView(true);
            } else {
                setActiveOverlayView(false);
            }
            setZoom(this.getZoom());
        }

        function removeVariable(varMap) {
            setVariable(oldValues => {
                return oldValues.filter(vars => vars !== varMap);
            });
        }

        const options = {
            streetViewControl: false,
            fullscreenControl: false,
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            rotateControl: false,
            scrollwheel: true,
            gestureHandling: guestMode ? 'none' : ''
        };

        return (
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_API_KEY}
                className={styles.totalMapView}
            >
                <div className={`${styles.mainDiv}`}>
                    <div
                        className={`${styles.mapDiv} ${isFullMap ? styles.fullMap : ''}`}
                        style={{
                            height
                        }}
                    >
                        {!guestMode && (
                            <div className={styles.buttonListProperties} onClick={mapExpand}>
                                {!isFullMap && <GrPrevious color='white'></GrPrevious>}
                                {isFullMap && <GrNext color='white'></GrNext>}
                            </div>
                        )}

                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={zoom}
                            onZoomChanged={handleZoomChanged}
                            panTo={{ center }}
                            options={guestMode && options}
                        >
                            {properties &&
                                (Array.isArray(properties) ? properties : [properties]).map(
                                    (m, i) => (
                                        <Marker
                                            key={`marker_${i}`}
                                            name={m.name}
                                            onClick={() => !guestMode && onMarkerClick(m)}
                                            position={{
                                                lng: m?.building?.longtitude || m?.longtitude,
                                                lat: m?.building?.latitude || m?.latitude
                                            }}
                                            icon={
                                                m.type === 'BUILDING'
                                                    ? iconBuildingStyle(m)
                                                    : iconPlotStyle(m)
                                            }
                                            zIndex={1000}
                                        >
                                            {activeOverlayView && variable && (
                                                <OverlayView
                                                    key='mwl'
                                                    position={{
                                                        lng:
                                                            (m?.building?.longtitude ||
                                                                m?.longtitude) + 0.0005,
                                                        lat:
                                                            (m?.building?.latitude || m?.latitude) +
                                                            0.0001
                                                    }}
                                                    mapPaneName={OverlayView.MARKER_LAYER}
                                                >
                                                    <div
                                                        className={`${styles.overlayViewDiv} ${
                                                            variable.length === 0
                                                                ? styles.hideOverlayViewDiv
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className={styles.totalWindowMap}>
                                                            {displayVariablesOnMap(variable, m)}
                                                        </div>
                                                    </div>
                                                </OverlayView>
                                            )}
                                        </Marker>
                                    )
                                )}

                            {activeMarker && (
                                <InfoWindow
                                    position={{
                                        lng: activeMarker.lon,
                                        lat: activeMarker.lat
                                    }}
                                    marker={activeMarker}
                                    onCloseClick={() => setActiveMarker(null)}
                                >
                                    <div className={styles.InfoWindowDiv}>{info}</div>
                                </InfoWindow>
                            )}
                        </GoogleMap>

                        {/* {!guestMode && (
                            <div className={styles.editMap} onClick={() => navigate(`map-edit`)}>
                                <FiSettings size={20} />
                            </div>
                        )} */}
                        {!guestMode && (
                            <div className={`${styles.variableList}`}>
                                <div className={styles.varsDiv}>
                                    {variable &&
                                        variable.map((vars, i) => {
                                            return (
                                                <div
                                                    className={`${styles.selectedBtn} ${
                                                        vars ? styles.selectedBtnActive : ''
                                                    }`}
                                                >
                                                    {t(`${vars}`)}
                                                    <AiOutlineClose
                                                        onClick={() => {
                                                            removeVariable(vars);
                                                        }}
                                                        color={'red'}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                                <div
                                    className={styles.variableListButton}
                                    onClick={() => setIsDisabledAddModal(true)}
                                >
                                    <AiOutlinePlusCircle></AiOutlinePlusCircle>
                                    {t('Τιμές Στο Χάρτη')}
                                </div>
                            </div>
                        )}

                        {renderAddModal()}
                    </div>
                </div>
            </LoadScript>
        );
    }
);

export default PropertiesMap;
