import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AiOutlineHome, AiOutlinePieChart } from 'react-icons/ai';
import { BiBuildings } from 'react-icons/bi';
import { IoMdPulse } from 'react-icons/io';
import { RiListSettingsLine } from 'react-icons/ri';
import { Navigate, useNavigate } from 'react-router';

import { useUser } from '../../context/withUser';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Subsystem } from '../../components/subsystem/Subsystem';
import { UnderConstructionComponent } from '../../components/underConstructionComponent/UnderConstructionComponent';

import { AdvertisePage } from '../defaultPages/AdvertisePage';
import { ForgotPasswordPage } from '../defaultPages/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from '../defaultPages/LoginPage';
import { RegisterPage } from '../defaultPages/RegisterPage';
import { ResetPasswordPage } from '../defaultPages/ResetPasswordPage/ResetPasswordPage';
import { VerificationPage } from '../defaultPages/VerificationPage/VerificationPage';
import ManagementPage from '../generalPages/ManagementPage';
import { TeamProfilePage } from '../generalPages/TeamProfilePage';
import { UserProfilePage } from '../generalPages/UserProfilePage';
import { HomePage } from '../memberPages/HomePage';
import { MyActivityPage } from '../memberPages/MyActivityPage';
import { MyInfoPage } from '../memberPages/MyInfoPage';
import { MyPropertyPage } from '../memberPages/MyPropertyPage';
import { MyTeamPropertiesPage } from '../memberPages/MyTeamPropertiesPage';
import { MyTeamPage } from '../memberPages/MyTeamsPage';
import { MyOverviewEstimatesPage } from '../memberPages/myOverviewPages/MyOverviewEstimates';
import { MyOverviewExpensesPage } from '../memberPages/myOverviewPages/MyOverviewExpenses';
import { MyOverviewLeasesPage } from '../memberPages/myOverviewPages/MyOverviewLeases';
import { MyOverviewPage } from '../memberPages/myOverviewPages/MyOverviewPage';
import { MyOverviewRenovationsPage } from '../memberPages/myOverviewPages/MyOverviewRenovations';
import { MyOverviewSmartFeaturesPage } from '../memberPages/myOverviewPages/MyOverviewSmartFeatures';
import { MyOverviewStatisticsPage } from '../memberPages/myOverviewPages/MyOverviewStatistics';
import { MyOverviewSuggestionsPage } from '../memberPages/myOverviewPages/MyOverviewSuggestions';
import { NewPropertiesPage } from '../memberPages/ΝewPropertiesPage';
import PropertyEditPage from '../propertyPages/PropertyEditPage';
import PropertyPartViewPage from '../propertyPages/PropertyPartViewPage';
import PropertyViewPage from '../propertyPages/PropertyViewPage';
import { GuestPropertyView } from '../propertyPages/guestPages/GuestPropertyView';

const navigationMapping = {
    ADMIN: 'a'
};

export const DefaultSubsystem = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { t } = useTranslation();
    const [permissions, setPermissions] = useState(null);

    const fetchAndSet = useCallback(() => {
        const permissions = {
            hasTeams: true
        };

        setPermissions(permissions);
    }, []);

    useEffect(() => {
        fetchAndSet();
    }, [fetchAndSet]);

    useEffect(() => {
        if (user && navigationMapping[user.type]) {
            navigate(`/${navigationMapping[user.type]}`);
        }
    }, [user, navigate]);

    if (!permissions) return <LoaderWrapper />;

    const defaultPages = [
        {
            title: t('Homepage'),
            path: 'home',
            component: AdvertisePage,
            icon: AiOutlineHome
        },

        {
            path: 'properties/:propertyId',
            component: () => <GuestPropertyView />
        },
        {
            path: 'login',
            component: LoginPage
        },
        {
            path: 'register',
            component: RegisterPage
        },
        {
            path: 'verify',
            component: VerificationPage
        },
        {
            path: 'forgot-password',
            component: ForgotPasswordPage
        },
        {
            path: 'reset-password',
            component: ResetPasswordPage
        }
    ];

    let memberPages = [
        {
            title: t('Homepage'),
            path: 'home',
            component: () => <HomePage />,
            icon: AiOutlineHome
        },
        permissions.hasTeams && {
            title: t('Management'),
            path: 'management',
            component: () => <ManagementPage />,
            icon: RiListSettingsLine
        },
        permissions.hasTeams && {
            path: 'teams',
            component: () => <MyTeamPage />
        },
        {
            title: t('New-Properties'),
            path: 'new-properties',
            component: () => <NewPropertiesPage />,
            icon: BiBuildings
        },
        {
            path: 'team-properties',
            component: () => <MyTeamPropertiesPage />
        },
        {
            path: 'my-properties',
            component: () => <MyPropertyPage />
        },
        {
            title: t('Activity'),
            path: 'activity',
            component: () => <MyActivityPage />,
            icon: IoMdPulse
        },
        {
            title: t('Overview'),
            path: 'overview',
            component: () => <MyOverviewPage />,
            icon: AiOutlinePieChart
        },
        {
            path: 'info',
            component: () => <MyInfoPage />
        },
        {
            path: 'login',
            component: () => <Navigate to={`/`} />
        }
    ];

    //pages that all users can have access to, depending on their permissions
    const additionalPages = [
        {
            path: 'activity',
            component: () => <MyActivityPage permissions={permissions} />
        },
        {
            path: 'teams/:teamId',
            component: () => <TeamProfilePage permissions={permissions} />,
            hasNestedRoutes: true
        },
        {
            path: 'users/:userId',
            component: () => <UserProfilePage permissions={permissions} />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/view/:propertyId',
            component: () => <PropertyViewPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/view/:propertyId/part/:propertyPartId',
            component: () => <PropertyPartViewPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/edit/:propertyId',
            component: () => <PropertyEditPage />,
            hasNestedRoutes: true
        },
        {
            path: 'properties/edit/:propertyId/part/:propertyPartId',
            component: () => <PropertyEditPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Expenses'),
            path: 'overview/expenses',
            component: () => <MyOverviewExpensesPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Estimates'),
            path: 'overview/estimates',
            component: () => <MyOverviewEstimatesPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Leases'),
            path: 'overview/leases',
            component: () => <MyOverviewLeasesPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Folder'),
            path: 'overview/digital-folder',
            component: () => <UnderConstructionComponent />,
            hasNestedRoutes: true
        },
        {
            title: t('renovations'),
            path: 'overview/renovations',
            component: () => <MyOverviewRenovationsPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Suggestions'),
            path: 'overview/suggestions',
            component: () => <MyOverviewSuggestionsPage />,
            hasNestedRoutes: true
        },
        {
            title: t('RipeningActions'),
            path: 'overview/ripening-actions',
            component: () => <UnderConstructionComponent />,
            hasNestedRoutes: true
        },
        {
            title: t('SmartBuilding'),
            path: 'overview/smart-building',
            component: () => <MyOverviewSmartFeaturesPage />,
            hasNestedRoutes: true
        },
        {
            title: t('Statistics'),
            path: 'overview/statistics',
            component: () => <MyOverviewStatisticsPage />,
            hasNestedRoutes: true
        }
    ];

    memberPages = [...memberPages, ...additionalPages];

    const Pages = user ? memberPages : defaultPages;
    return <Subsystem name='' pages={Pages} />;
};
