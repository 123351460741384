import React from 'react';
import { NavLink } from 'react-router-dom';

import Utils from '../../utils/utils';

import styles from './Link.module.css';

export const Link = props => {
    const link = props.link;

    const icon =
        link.icon && React.createElement(link.icon, { size: '1.5rem', style: { flexShrink: 0 } });

    const className = ({ isActive }) =>
        Utils.classes(
            styles.link,
            isActive && styles.active,
            isActive && props.isActiveClassname,
            props.className || ''
        );

    return (
        <NavLink to={link.initialPath || '/' + link.path} className={className}>
            {icon ? (
                <div className={styles.icon} title={link.title || ''}>
                    {icon}
                </div>
            ) : null}
            {link.title ? <div className={styles.title}>{link.title}</div> : null}
        </NavLink>
    );
};
