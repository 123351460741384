import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('User-Information'),
        promptForSubmit: 'Confirm',
        questions: [
            {
                title: i18next.t('Name'),
                name: 'name',
                type: 'text'
            }
        ]
    };
};

export default getDescription;
