import React, { useRef } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests from '../../utils/requests';

import ButtonWithLoading from '../buttonWithLoading/ButtonWithLoading';
import { ProfileImage } from '../profileImage/ProfileImage';

import description from '../../assets/json/TeamInformationUpdate.js';

import styles from './EditTeamProfile.module.css';

export const EditTeamProfile = ({ team, updateTeamData }) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const addToast = useToasts();

    const updateData = newData => {
        const d = JSON.parse(JSON.stringify(team));
        d.name = newData.name;
        d.description = newData.description;
        updateTeamData &&
            updateTeamData({ ...team, name: newData.name, description: newData.description });
    };
    const isDataChanged = newData => {
        const d = JSON.parse(JSON.stringify(team));
        if (d.name !== newData.name || d.description !== newData.description) return true;
        return false;
    };

    const updateTeam = async () => {
        const formData = formRef.current.getData();
        if (!isDataChanged(formData)) {
            return;
        }
        formRef.current.clearSanityCheck();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) {
            return false;
        }

        requests
            .updateTeamData(team.id, { name: formData.name, description: formData.description })
            .then(() => {
                updateData(formData);
                addToast({
                    type: 'success',
                    message: t('Team {{id}} updated successfully', { id: team.id }),
                    duration: 3000
                });
            })
            .catch(console.log);
    };

    return (
        <div className={styles.mainContainer}>
            <ProfileImage
                accountData={team}
                updateRequest={requests.postTeamImage}
                deleteRequest={requests.deleteTeamImage}
                updateData={updateTeamData}
                toastMessages={{
                    onUpdate: {
                        type: 'success',
                        text: t('Team {{id}} updated successfully', { id: team.id })
                    },
                    onDelete: {
                        type: 'success',
                        text: t('Team {{id}} updated successfully', { id: team.id })
                    }
                }}
            />
            <Form description={description()} initialValue={team} ref={formRef} />
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'flex-end'
                }}
            >
                <ButtonWithLoading
                    className={`btn btn-primary-0`}
                    onClick={async () => {
                        await updateTeam();
                    }}
                >
                    {t('save')}
                </ButtonWithLoading>
            </div>
        </div>
    );
};
