import React from 'react';

import { Line } from 'react-chartjs-2';

export const PlotLine = ({ data }) => {
    const options = {
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    borderWidth: 0
                }
            } // Hide legend,
        },
        aspectRatio: 1.5 // Adjust the value to change the aspect ratio of the chart
    };

    return <Line data={data} options={options} />;
};
