import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Add-expenses'),
        promptForSubmit: 'Confirm',
        questions: [
            {
                type: 'dropdown',
                title: i18next.t('Category'),
                name: 'expenseCategory',
                choices: [
                    {
                        title: i18next.t('Contracts'),
                        value: 'CONTRACTS'
                    },
                    {
                        title: i18next.t('Non-contract Expenses'),
                        value: 'NON-CONTRACT-EXPENSES'
                    },
                    {
                        title: i18next.t('Maintenance'),
                        value: 'MAINTENANCE'
                    }
                ]
            },
            {
                type: 'dropdown',
                title: i18next.t('Sub-Category'),
                name: 'expenseSubCategory',
                choices: [
                    {
                        title: i18next.t('Cleaning'),
                        value: 'CLEANING'
                    },
                    {
                        title: i18next.t('Water'),
                        value: 'WATER'
                    },
                    {
                        title: i18next.t('Power'),
                        value: 'POWER'
                    },
                    {
                        title: i18next.t('Alarm'),
                        value: 'ALARM'
                    }
                ]
            }
        ]
    };
};

export default getDescription;
