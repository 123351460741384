import React from 'react';

class InfoWindow {
    constructor(position, marker, content, onCloseClick, onClick) {
        this.position = position;
        this.marker = marker;
        this.content = content;
    }
    getMarker() {
        return this.marker;
    }
}

export default InfoWindow;
