import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import { useTranslation } from 'react-i18next';

import { URI } from '../../utils/requests';

import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import styles from './SubTaskModalPreview.module.css';

const SubTaskModalPreview = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.contentModalDiv}>
            <div className={styles.title}>{t('Status')} </div>
            <div className={styles.status} style={{ color: `${data.taskColor}` }}>
                {data.progressStatus}
            </div>

            <div className={styles.subDiv}>
                <div className={styles.title}>{t('users')} </div>

                {data.user === null ? (
                    '-'
                ) : Array.isArray(data.user) ? (
                    <div className={styles.subContentDiv}>
                        {data.user.map(u => {
                            return (
                                <SmallPreviewNameImage
                                    fullname={u.name}
                                    showName={true}
                                    imageSrc={`${URI}/${u.image.path}`}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <SmallPreviewNameImage
                        fullname={data.user.name}
                        showName={true}
                        imageSrc={`${URI}/${data.user.image.path}`}
                    />
                )}
            </div>
            <div className={styles.subDiv}>
                <div className={styles.title}>{t('teams')} </div>
                <div>
                    {data.team === null ? (
                        '-'
                    ) : Array.isArray(data.team) ? (
                        <div className={styles.subContentDiv}>
                            {data.team.map(tm => {
                                return (
                                    <SmallPreviewNameImage
                                        fullname={tm.name}
                                        showName={true}
                                        imageSrc={`${URI}/${tm.image.path}`}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <SmallPreviewNameImage
                            fullname={data.team.name}
                            showName={true}
                            imageSrc={`${URI}/${data.team.image.path}`}
                        />
                    )}
                </div>
            </div>
            <div className={styles.subDiv}>
                <div className={styles.title}>{t('properties')} </div>
                <div>
                    {data.property === null ? (
                        '-'
                    ) : Array.isArray(data.property) ? (
                        <div className={styles.subContentDiv}>
                            {data.property.map(p => {
                                return (
                                    <div className={styles.property}>
                                        {'#' + '' + p.id + ' ' + p.name}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className={styles.property}>
                            {'#' + '' + data.property.id + ' ' + data.property.name}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.subDiv}>
                <div className={styles.title}>{t('progress')} </div>
                <ProgressBar
                    completed={data.progress}
                    bgColor={'var(--theme-primary)'}
                    width='80%'
                    labelSize='10px'
                    labelAlignment='outside'
                    labelColor='var(--theme-primary)'
                />
            </div>
            <div className={styles.subDiv}>
                <div className={styles.title}>{t('dateTo')} </div>
                {data.dateTo}
            </div>
            <div className={styles.subDiv}>
                <div className={styles.title}>{t('dateFrom')} </div>
                {data.dateFrom}
            </div>
        </div>
    );
};

export default SubTaskModalPreview;
