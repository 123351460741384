import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Header } from '../../../components/header/Header';
import { useTranslation } from 'react-i18next';

import styles from './TeamStatistics.module.css';
import requests from '../../../utils/requests';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { PlotEstimatesByAttributeType } from '../../../components/statistics/estimates/PlotEstimatesByAttributeType';
import { PlotByPlotBuildingMonth } from '../../../components/statistics/PlotByPlotBuildingMonth';

export const TeamStatisticEstimates = () => {
    const [data, setData] = useState(null);
    const [buildingData, setBuildingData] = useState(null);
    const [plotData, setPlotData] = useState(null);
    let { teamId: id } = useParams();
    if (id === undefined) {
        id = 3;
    }
    const { t } = useTranslation();

    useEffect(() => {
        requests
            .getEstimationsByMonth(id)
            .then(({ data }) => setData(curr => ({ ...curr, EstimationsByMonth: data })));
        requests
            .getEstimationsByTypeOfProperty(id)
            .then(({ data }) => setData(curr => ({ ...curr, EstimationsByTypeOfProperty: data })));
        requests.getEstimationByPlot(id).then(({ data }) => setPlotData(data));
        requests.getEstimationByBuilding(id).then(({ data }) => setBuildingData(data));
    }, [id]);

    if (!data || !buildingData || !plotData) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <Header title={t('Estimations Statistics')} />
            </div>
            <div className={styles.wrap}>
                <div className={styles.chartsRow}>
                    <div className={styles.chartWrap}>
                        <h3>{t('New Estimations per property type, this year')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotByPlotBuildingMonth
                                    data={data.EstimationsByMonth}
                                    requestedYear={new Date().getFullYear()}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Estimations by property type')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='doughnut'
                                    data={data.EstimationsByTypeOfProperty}
                                    attributeType={'count'}
                                    labelType={'type'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Building Insurable value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.insurableValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Building Rental value by Estimations')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.rentalValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Building Market value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.marketValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Building Final market value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.finalMarketValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Building Material value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={buildingData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.materialValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Plot  Insurable value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={plotData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.insurableValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Plot Rental value by Estimations')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={plotData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.rentalValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Plot Market value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={plotData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.marketValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Plot Final market value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={plotData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.finalMarketValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Plot Material value by estimation')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotEstimatesByAttributeType
                                    type='bar'
                                    data={plotData.map(bd => ({
                                        name: bd.estimation,
                                        value: bd.materialValue
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
