import React, { createContext, useCallback, useContext, useRef } from 'react';

import {
    BsCheckCircleFill,
    BsFillExclamationTriangleFill,
    BsInfoCircleFill,
    BsX,
    BsXCircleFill
} from 'react-icons/bs';

import styles from './ToastMessages.module.css';

const icons = {
    success: BsCheckCircleFill,
    failure: BsXCircleFill,
    warning: BsFillExclamationTriangleFill,
    info: BsInfoCircleFill
};

export const ToastMessage = ({ type, message, deleteMe, className, iconClass }) => {
    const Icon = icons[type] || icons['info'];
    const type_ = icons[type] ? type : 'info';

    return (
        <div className={`${styles.toastMessage} ${styles[type_]} ${className || ''}`}>
            <div className={styles.leftPart}>
                <Icon className={`${styles.icon} ${iconClass || ''}`} />
                <div className={styles.message}> {message} </div>
            </div>
            {deleteMe && <BsX className={styles.close} onClick={deleteMe} />}
        </div>
    );
};

class ToastMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = { toasts: [] };
    }

    addToastMessage = ({ type, message, duration = 5000 }) => {
        const toast = { type, message, duration };

        setTimeout(() => this.deleteToastMessage(toast), duration);

        this.setState(state => ({
            toasts: [...state.toasts, toast]
        }));
    };

    deleteToastMessage = toast => {
        this.setState(state => ({
            toasts: state.toasts.filter(t => t !== toast)
        }));
    };

    render() {
        return (
            <div
                className={
                    this.props.className
                        ? `${styles.toastMessages} ${this.props.className}`
                        : styles.toastMessages
                }
            >
                {this.state.toasts.map((toast, i) => (
                    <ToastMessage
                        key={i}
                        type={toast.type}
                        message={toast.message}
                        deleteMe={() => this.deleteToastMessage(toast)}
                    />
                ))}
            </div>
        );
    }
}

export const ToastContext = createContext();

export const useToasts = () => useContext(ToastContext);

export function ToastMessagesWrapper({ className, children }) {
    const toastsRef = useRef(null);

    const addToastMessage = useCallback(
        toast => toastsRef.current?.addToastMessage(toast),
        [toastsRef]
    );

    return (
        <ToastContext.Provider value={addToastMessage}>
            {children}
            <ToastMessages ref={toastsRef} className={className} />
        </ToastContext.Provider>
    );
}
