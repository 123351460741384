import React from 'react';

import { AiOutlineMessage } from 'react-icons/ai';
import { BiArea, BiBath, BiBed, BiInfoCircle, BiSave, BiShareAlt } from 'react-icons/bi';
import { BsCardChecklist, BsFillDoorOpenFill, BsQuestion } from 'react-icons/bs';
import { FaEuroSign } from 'react-icons/fa';
import { IoIosConstruct } from 'react-icons/io';
import { MdLocalParking, MdStairs } from 'react-icons/md';

import styles from './PropertyFeatureIndicator.module.css';

export const PropertyFeatureIndicator = ({ type, title, size = 25 }) => {
    const defaultIcon = <BsQuestion />;

    const mapIcons = {
        save: <BiSave size={size} />,
        share: <BiShareAlt size={size} />,
        information: <BiInfoCircle size={size} />,
        feature: <BsCardChecklist size={size} />,
        euro: <FaEuroSign size={size} />,
        contact: <AiOutlineMessage size={size} />,
        floor: <MdStairs size={size} />,
        spaces: <BsFillDoorOpenFill size={size} />,
        constructionDate: <IoIosConstruct size={size} />,
        area: <BiArea size={size} />,
        parking: <MdLocalParking size={size} />,
        bath: <BiBath size={size} />,
        bed: <BiBed size={size} />
    };

    return (
        <div className={styles.indicationWrap}>
            <div className={styles.icon}> {mapIcons[type] || defaultIcon} </div>
            <div className={styles.content}> {title || 'default-title'} </div>
        </div>
    );
};
