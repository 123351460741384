// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ViewModeTasks_viewModeMainContainer__MR3eF{\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    justify-content: center;\n\n    gap: 80px;\n\n    width: 100%;\n\n}\n\n.ViewModeTasks_viewsDiv__MSeWf{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-end;\n\n    gap:10px;\n    width: 100%;\n}\n\n.ViewModeTasks_viewsButton__3lYUZ{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n\n    gap: 10px;\n    border-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/TaskManagement/ViewModeTasks.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;;IAEvB,SAAS;;IAET,WAAW;;AAEf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;;IAEzB,QAAQ;IACR,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;;IAEnB,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".viewModeMainContainer{\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n    justify-content: center;\n\n    gap: 80px;\n\n    width: 100%;\n\n}\n\n.viewsDiv{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-end;\n\n    gap:10px;\n    width: 100%;\n}\n\n.viewsButton{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n\n    gap: 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewModeMainContainer": "ViewModeTasks_viewModeMainContainer__MR3eF",
	"viewsDiv": "ViewModeTasks_viewsDiv__MSeWf",
	"viewsButton": "ViewModeTasks_viewsButton__3lYUZ"
};
export default ___CSS_LOADER_EXPORT___;
