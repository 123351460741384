import i18next from 'i18next';

import Utils from '../../utils/utils';

const getDescription = () => {
    return {
        title: i18next.t('propertyInfoForm'),
        showTitle: false,
        name: 'propertyInfoForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Γενικές Πληροφορίες'),
                name: 'propertyStatsInfo',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Όροφοι'),
                name: 'floor',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Χώροι'),
                name: 'spaces',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Έτος Κατασκευής'),
                name: 'yearOfConstruction',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                placeholder: i18next.t('Select..'),
                choices: Utils.generateFormYearOptions(80)
            },
            {
                title: i18next.t('Έτος Ανακαίνισης'),
                name: 'yearOfRenovation',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                placeholder: i18next.t('Select..'),
                choices: Utils.generateFormYearOptions(80)
            },
            {
                title: i18next.t('Τεχνικά Στοιχεία'),
                name: 'propertyStatsTechical',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Ονομασία Ακινήτου'),
                name: 'name',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Κωδικός Ακινήτου'),
                name: 'APC',
                type: 'text',
                isRequired: false,
                space: '50%',
                isDisabled: true
            },
            {
                title: i18next.t('ΚΑΕΚ Κτίσματος'),
                name: 'kaekBuilding',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('ΑΤΑΚ'),
                name: 'atak',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Αριθμός Ο-Α'),
                name: 'numberOA',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Έτος Ο-Α'),
                name: 'yearOA',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                placeholder: i18next.t('Select..'),
                choices: Utils.generateFormYearOptions(80, false)
            },
            {
                title: i18next.t('Υφιστάμενη Χρήση'),
                name: 'currentUse',
                placeholder: 'Υφιστάμενη Χρήση',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Νομική Πολεοδομική Κατάσταση/Χρήση'),
                name: 'legalCityPlanningUse',
                placeholder: 'Νομική Πολεοδομική Κατάσταση/Χρήση',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Εμβαδόν'),
                name: 'propertyStatsArea',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Εμβαδόν Βάσει Ο-Α'),
                name: 'areaOA',
                type: 'number',
                isRequired: false,
                space: '30%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Εμβαδόν Βάσει Τίτλου'),
                name: 'areaTitle',
                type: 'number',
                isRequired: false,
                space: '30%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Εμβαδόν Κλειστών Η/Χ'),
                name: 'areaHX',
                type: 'number',
                isRequired: false,
                space: '30%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Εμβαδόν Υφιστάμενης Κατάστασης'),
                name: 'areaCurrentState',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t("Εμβαδόν καθ' υπέρβαση ΣΔ"),
                name: 'areaSD',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Αριθμοί Παροχής'),
                name: 'propertyStatsNumbers',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Ηλεκτρικού Ρεύματος'),
                name: 'poweredNumberSupply',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: 50
                }
            },
            {
                title: i18next.t('Ηλεκτροδοτείται'),
                name: 'electrified',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εμβαδόν Υδροδότησης'),
                name: 'waterNumberSupply',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: 50
                }
            },
            {
                title: i18next.t('Υδροδοτείται'),
                name: 'waterSupplied',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            }
        ]
    };
};

export default getDescription;
