import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FaPercent } from 'react-icons/fa';
import { IoPersonRemoveOutline } from 'react-icons/io5';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import requests from '../../utils/requests';

import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import styles from './OwnersList.module.css';

const OwnersList = ({ owners, onOwnerRemove, onPercentageChange, readOnly }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const addToast = useToasts();

    useEffect(() => {
        if (!owners || readOnly) {
            return;
        }

        if (
            owners.some(({ percentage }) => percentage <= 0 || percentage > 100) ||
            owners.reduce((acc, { percentage }) => acc + Number(percentage), 0) !== 100
        ) {
            addToast({
                type: 'failure',
                message: t('invalid-owners-precentage'),
                duration: 5000
            });
        }
    }, [owners, readOnly, addToast, t]);

    const onPercentageChangeWrap = (e, owner) => {
        onPercentageChange(owner, e.target.value);
    };

    return (
        <div className={styles.mainContainer}>
            <h3 className={styles.title}>
                {t('current-property-owners')} ({owners ? owners.length : 0})
            </h3>
            {!owners ? (
                <p className={styles.noOwnersMsg}>{t('property-without-owners')}</p>
            ) : (
                <ul className={styles.ownersContainer}>
                    {owners.map((owner, i) => (
                        <li key={`owner_${owner.email}`} className={styles.ownerContainer}>
                            <SmallPreviewNameImage
                                imageSrc={owner.image}
                                fullname={owner.name}
                                onClick={() => navigate(`../../users/${owner.email}/info`)}
                                request={() => requests.getUser(owner.email)}
                            />
                            <div className={styles.ownerActionsContainer}>
                                <label className={styles.ownerPercentage}>
                                    {t('onwer-percentage-label')}
                                    <input
                                        className={`${styles.ownerPercentageInput} ${styles.invalidInput}`}
                                        type='number'
                                        min='1'
                                        max='100'
                                        defaultValue={owner.percentage}
                                        onBlur={e => !readOnly && onPercentageChangeWrap(e, owner)}
                                        disabled={readOnly}
                                    />
                                    <FaPercent />
                                </label>
                                {!readOnly && (
                                    <button
                                        className={styles.ownerRmBtn}
                                        onClick={() => onOwnerRemove(owner)}
                                    >
                                        <IoPersonRemoveOutline size='24' />
                                    </button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OwnersList;
