import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiOutlineFileText, AiOutlineFolderOpen } from 'react-icons/ai';
import { BiImage, BiVideo } from 'react-icons/bi';
import { BsCurrencyDollar, BsHouseDoor, BsInfoCircle, BsLamp } from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa';
import { MdOutlineImagesearchRoller } from 'react-icons/md';
import { MdOutlineFeaturedPlayList } from 'react-icons/md';
import { RiKey2Line } from 'react-icons/ri';
import { TiArrowForwardOutline } from 'react-icons/ti';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';

import requests from '../../utils/requests';

import { SidebarPage } from '../generalPages/SidebarPage';
import PropertyDigitalFolderPage from './PropertyDigitalFolderPage';
import { PropertyEditImagesPage } from './PropertyEditImagesPage';
import { PropertyEditVideosPage } from './PropertyEditVideosPage';
import { PropertyEditSuggestionsPage } from './SuggestionPages/PropertyEditSuggestionsPage';
import {
    PropertyEditEstimatesPage,
    PropertyEstimatesHistoryPage
} from './estimatesPages/PropertyEditEstimatesPage';
import {
    PropertyEditExpensesPage,
    PropertyExpensesMainPage
} from './expensesPages/PropertyEditExpensesPage';
import {
    PropertyEditLeasesPage,
    PropertyLeasesHistoryPage
} from './leasesPages/PropertyEditLeasesPage';
import PropertyEditInfoPage from './propertyEditPages/PropertyEditInfoPage';
import PropertyEditPropertyPage from './propertyEditPages/PropertyEditPropertyPage';
import {
    PropertyEditRenovationPage,
    PropertyRenovationMainPage
} from './renovationPages/PropertyRenovatioPage';
import { PropertyEditSmartFeaturePage } from './smartFeaturePages/PropertySmartFeaturePage';

import styles from './PropertyPartsEditPage.module.css';

const PropertyPartsEditPage = ({ permissions = {} }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { propertyId, propertyPartId } = useParams();
    const addToast = useToasts();
    const [buildingPart, setBuildingPart] = useState(null);

    useEffect(() => {
        if (!propertyId || !propertyPartId) {
            return;
        }

        requests
            .getProperty(propertyId)
            .then(({ data: property }) =>
                requests
                    .getBuildingPart(property.building.id, propertyPartId)
                    .then(({ data }) => setBuildingPart(data))
            );
    }, [propertyId, propertyPartId]);

    const getBackPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'renovations',
            'smartfeatures',
            'digital-folder'
        ];

        const urlParts = location.pathname.split('/').reverse();
        const urlSuffix = urlParts[0];
        if (validSuffixes.includes(urlSuffix)) {
            return `../properties/view/${propertyId}/part/${propertyPartId}/${urlSuffix}`;
        }
        return `../properties/view/${propertyId}/part/${propertyPartId}/${urlParts[1]}/${urlSuffix}`;
    }, [location, propertyId, propertyPartId]);

    if (!buildingPart) {
        return <LoaderWrapper />;
    }

    const updatePropertyToDB = (id, data) => {
        requests.updateBuilding(id, data).then(({ data }) => setBuildingPart(data));

        addToast({
            type: 'success',
            message: t('success-property-part-update'),
            duration: 3000
        });
    };

    const options = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <PropertyEditInfoPage
                                property={buildingPart}
                                updatePropertyToDB={updatePropertyToDB}
                                getOwners={async () => {
                                    const { data } = await requests.getPropertyOwners(propertyId);
                                    if (!data) {
                                        return null;
                                    }

                                    return data.map(elem => ({
                                        ...elem.owner,
                                        percentage: elem.percentage
                                    }));
                                }}
                                updateOwners={(_propertyId, owners) =>
                                    requests.updatePropertyOwners(propertyId, owners)
                                }
                            />
                            <PropertyEditPropertyPage
                                property={buildingPart}
                                updatePropertyToDB={updatePropertyToDB}
                            />
                        </>
                    ),
                    Icon: BsInfoCircle
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: <PropertyEditImagesPage />,
                    Icon: BiImage
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyEditVideosPage />,
                    Icon: BiVideo
                },
                {
                    path: 'smartfeatures',
                    title: t('Smart Features'),
                    element: <PropertyEditSmartFeaturePage buildingId={propertyPartId} />,
                    Icon: BsLamp
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: (
                        <PropertyDigitalFolderPage
                            canEdit
                            property={propertyPartId}
                            updatePropertyToDB={updatePropertyToDB}
                        />
                    ),
                    Icon: AiOutlineFolderOpen,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    }
                }
            ]
        },
        {
            title: t('Information'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage isEdit buildingId={propertyPartId} />,
                    Icon: BsCurrencyDollar
                },
                {
                    path: 'expenses/new',
                    element: (
                        <PropertyEditExpensesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'expenses/:id',
                    element: (
                        <PropertyEditExpensesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    element: <PropertyLeasesHistoryPage isEdit buildingId={propertyPartId} />,
                    Icon: RiKey2Line
                },
                {
                    path: 'leases/new',
                    element: (
                        <PropertyEditLeasesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases/:id',
                    element: (
                        <PropertyEditLeasesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: <PropertyEstimatesHistoryPage isEdit buildingId={propertyPartId} />,
                    Icon: AiOutlineFileText
                },
                {
                    path: 'estimates/new',
                    element: (
                        <PropertyEditEstimatesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates/:id',
                    element: (
                        <PropertyEditEstimatesPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: <PropertyEditSuggestionsPage buildingId={propertyPartId} />,
                    Icon: FaRegLightbulb
                },
                {
                    path: 'renovations',
                    title: t('renovations'),
                    element: <PropertyRenovationMainPage isEdit buildingId={propertyPartId} />,
                    Icon: MdOutlineImagesearchRoller
                },
                {
                    path: 'renovations/new',
                    element: (
                        <PropertyEditRenovationPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'renovations/:id',
                    element: (
                        <PropertyEditRenovationPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                }
            ]
        }
    ];

    return (
        <div className={styles.pageWrap}>
            <SidebarPage options={options}>
                <div className={styles.propertyInfoContainer}>
                    <div className={styles.firstRow}>
                        <h2 className={styles.propertyInfoSubcategory}>
                            {t(buildingPart[buildingPart.pType].pType)}
                        </h2>
                        <Link className={`${styles.returnBtn}`} to={getBackPage()}>
                            <div className={styles.actionBtnContent}>
                                <TiArrowForwardOutline size={25} />
                            </div>
                        </Link>
                    </div>
                    <h3 className={styles.propertyInfoCategory}>{t('build-part')}</h3>
                </div>
            </SidebarPage>
        </div>
    );
};

export default PropertyPartsEditPage;
