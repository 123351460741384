import React, { useRef, useState } from 'react';

import { Collapse } from 'react-collapse';
import { Form } from 'react-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RiArrowRightSLine } from 'react-icons/ri';

import TaskComponent from '../../components/TaskManagement/TaskComponent';
import ViewModeTasks from '../../components/TaskManagement/ViewModeTasks';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';

import taskBackgroundImage from '../../assets/images/taskBackgroundImage.jpg';
import { data } from '../../assets/json/TaskManagementFakeData';
import { data2 } from '../../assets/json/TaskManagementFakeData2';
import newTaskDescr from '../../assets/json/newTaskDescr';

import styles from './TaskManagementPage.module.css';

const TaskManagementPage = ({ inTeam, inUser }) => {
    const { t } = useTranslation();
    const [isDisableAddModal, setIsDisabledAddModal] = useState(false);
    const formRef = useRef(null);
    const desc = newTaskDescr();
    const desc2 = newTaskDescr();

    const nullData = {
        taskColor: '#8B939C',
        name: 'Νέα Εργασία',
        user: null,
        team: null,
        priorityColor: null,
        property: null,
        progress: 0,
        dateTo: null,
        dateFrom: null
    };
    function renderAddModal() {
        if (!isDisableAddModal) return null;

        return (
            <Modal
                onClose={() => {
                    setIsDisabledAddModal(false);
                }}
                header={t('add-new-task')}
                content={
                    <div className={styles.recaptchaContainer}>
                        <Form description={desc} ref={formRef} />
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledAddModal(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className={`btn btn-primary-0`}>
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }
    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                {!inTeam && !inUser ? (
                    <Header title={t('Task Management')} backgroundImg={taskBackgroundImage}>
                        {
                            <span
                                className={`btn btn-primary-0 ${styles.addButton}`}
                                onClick={() => setIsDisabledAddModal(true)}
                            >
                                <IoMdAddCircleOutline size={'20px'} />
                                <span className={styles.buttonTitle}>{t('Add-new-task')}</span>
                            </span>
                        }
                    </Header>
                ) : (
                    <div className={styles.topBar}>
                        <h1>{t('Task Management')}</h1>
                        <button
                            className={`btn btn-primary-0 ${styles.actionBtn}`}
                            onClick={() => setIsDisabledAddModal(true)}
                        >
                            <IoMdAddCircleOutline color={'var(--theme-bg)'} />
                            {t('Add')}
                        </button>
                    </div>
                )}

                <div className={inTeam ? styles.mainContainerTeam : styles.mainContainer}>
                    <ViewModeTasks inUser={inUser} inTeam={inTeam} />
                    <TaskComponent
                        subtasks={nullData}
                        taskName={'Σημαντικά'}
                        inTeam={inTeam}
                        inUser={inUser}
                    />
                    <TaskComponent
                        subtasks={data}
                        taskName={'Νέες Διεργασίες'}
                        inTeam={inTeam}
                        inUser={inUser}
                    />
                    <TaskComponent
                        subtasks={data2}
                        taskName={'Σημαντικές Διεργασίες'}
                        inTeam={inTeam}
                        inUser={inUser}
                    />
                </div>
            </div>
            {renderAddModal()}
        </div>
    );
};

export default TaskManagementPage;
