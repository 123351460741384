import React from 'react';

import { Bar } from 'react-chartjs-2';

export const PlotBar = ({ data }) => {
    const options = {
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: false // Hide legend
        },
        aspectRatio: 1.5 // Adjust the value to change the aspect ratio of the chart
    };

    return <Bar data={data} options={options} />;
};
