import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import styles from './PropertyEstimateCard.module.css';
import requests, { URI } from '../../utils/requests';
import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';


export const PropertyEstimateCard = ({ id, date, name, values, estimator, author}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const[image,setImage] = useState(null);
    const [nameAuthor,setNameAuthor] = useState(null);


    useEffect(() => {
        requests.getUser(author).then(({data})=>{setNameAuthor(data.name); setImage(data.image.path)}).catch(console.error)
    }, []);


    return (
        <div className={styles.mainDiv}>
            <div className={styles.title} onClick={() => navigate(`./${id}`)}>
                {date} - {name}
            </div>

            <div className={styles.estimatorData}>
                <div>
                    {t('Estimator')} : {estimator.name ? estimator.name : '-'}
                </div>
                <div>
                    {t('AFM')} : {estimator.afm ? estimator.afm : '-'}
                </div>
                <div className={styles.authorDiv}>
                    {t('author')}
                    <SmallPreviewNameImage
                        fullname={nameAuthor}
                        imageSrc={`${URI}/${image}`}
                        onClick={() =>navigate(`../../users/${author}/info`)}
                        
                    />
                    {/* {t('author')}: {author ? author : '-'} */}
                </div>
            </div>

            <div className={styles.values}>
                <div>
                    {t('Μισθωτική Αξία')}: {values.val1 ? values.val1 + ' €' : '-'}
                </div>
                <div>
                    {t('Υλική Αξία')}: {values.val2 ? values.val2 + ' €' : '-'}
                </div>
                <div>
                    {t('Ασφαλιστέα Αξία')}: {values.val3 ? values.val3 + ' €' : '-'}
                </div>
                <div>
                    {t('Εμπορική Αξία')}: {values.val4 ? values.val4 + ' €' : '-'}
                </div>
                <div>
                    {t('Τελική Αγοράια Αξία')}: {values.val5 ? values.val5 + ' €' : '-'}
                </div>
            </div>
        </div>
    );
};
