// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPropertyForm_searchPropertyForm__uIQtK {\n    width: 100%;\n    position: sticky;\n    top: var(--navbar-height);\n    padding: 2rem;\n    background-color: rgba(255, 255, 255, 0.85);\n    border-radius: 0.5rem;\n    transition: max-width 300ms ease-out;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.searched .SearchPropertyForm_searchPropertyForm__uIQtK {\n    background-color: transparent !important;\n}\n\n.unfolded-drp-label {\n    background-color: var(--theme-bg);\n}\n\n.SearchPropertyForm_addButton__i4WlW {\n    flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/searchPropertyForm/SearchPropertyForm.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,2CAA2C;IAC3C,qBAAqB;IACrB,oCAAoC;IACpC,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".searchPropertyForm {\n    width: 100%;\n    position: sticky;\n    top: var(--navbar-height);\n    padding: 2rem;\n    background-color: rgba(255, 255, 255, 0.85);\n    border-radius: 0.5rem;\n    transition: max-width 300ms ease-out;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n:global(.searched) .searchPropertyForm {\n    background-color: transparent !important;\n}\n\n:global(.unfolded-drp-label) {\n    background-color: var(--theme-bg);\n}\n\n.addButton {\n    flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPropertyForm": "SearchPropertyForm_searchPropertyForm__uIQtK",
	"addButton": "SearchPropertyForm_addButton__i4WlW"
};
export default ___CSS_LOADER_EXPORT___;
