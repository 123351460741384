// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserPropertyPage_pageWrap__HK\\+dR {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.UserPropertyPage_header__AxJBt {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    align-items: center;\n}\n\n.UserPropertyPage_addButton__3UZMK {\n    display: flex;\n    width: 'fit-content';\n    border-radius: 5px;\n    gap: 10px;\n    justify-content: center;\n}\n\n.UserPropertyPage_addPropertyFormFooter__QeFjj {\n    display: flex;\n    gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/userProfilePages/UserPropertyPage.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,kBAAkB;IAClB,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".pageWrap {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 20px;\n    align-items: center;\n}\n\n.addButton {\n    display: flex;\n    width: 'fit-content';\n    border-radius: 5px;\n    gap: 10px;\n    justify-content: center;\n}\n\n.addPropertyFormFooter {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "UserPropertyPage_pageWrap__HK+dR",
	"header": "UserPropertyPage_header__AxJBt",
	"addButton": "UserPropertyPage_addButton__3UZMK",
	"addPropertyFormFooter": "UserPropertyPage_addPropertyFormFooter__QeFjj"
};
export default ___CSS_LOADER_EXPORT___;
