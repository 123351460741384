import i18next from 'i18next';

const getDescription = () => {
    return {
        title: 'propertyLeaseForm',
        showTitle: false,
        name: 'propertyLeaseForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Γενικές Πληροφορίες', 'Γενικές Πληροφορίες'),
                name: 'propertyLeaseGeneralInfo',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Ημερομηνία Έναρξης'),
                name: 'durationFrom',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Ημερομηνία Λήξης'),
                name: 'durationTo',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Συμβόλαιο Μίσθωσης'),
                name: 'contract',
                type: 'file',
                isRequired: false,
                space: '100%',
                maxFiles: 1
            },
            {
                title: i18next.t('Μισθωτής'),
                name: 'leaser',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Ονοματεπώνυμο Μισθωτή'),
                name: 'name',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('ΑΦΜ Μισθωτή'),
                name: 'afm',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 10
                }
            },
            {
                title: i18next.t('Πληρωμή'),
                name: 'Pay',
                type: 'label',
                space: '100%'
            },
            {
                title: i18next.t('Μίσθιο'),
                name: 'leasedProperty',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Μίσθιο/τμ'),
                name: 'leasedPropertyPerSM',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Αναπροσαρμογές'),
                name: 'leaseAdjustable',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Απόδοση'),
                name: 'leasePerformance',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Τρόπος Πληρωμής'),
                name: 'paymentType',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Πληρωμή Μισθωμάτων'),
                name: 'leasePayment',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            }
        ]
    };
};

export default getDescription;
