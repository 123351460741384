// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BuildingOptionsPage_pageWrap__\\+wZjG {\n    display: flex;\n    flex-direction: column;\n}\n\n.BuildingOptionsPage_actionBtnsContainer__CuoT1 {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end;\n}\n\n.BuildingOptionsPage_actionBtn__5RwF6 {\n    border: 0;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n.BuildingOptionsPage_title__qWean {\n    padding-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/BuildingOptionsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;;IAEX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-end;\n}\n\n.actionBtn {\n    border: 0;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n.title {\n    padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "BuildingOptionsPage_pageWrap__+wZjG",
	"actionBtnsContainer": "BuildingOptionsPage_actionBtnsContainer__CuoT1",
	"actionBtn": "BuildingOptionsPage_actionBtn__5RwF6",
	"title": "BuildingOptionsPage_title__qWean"
};
export default ___CSS_LOADER_EXPORT___;
