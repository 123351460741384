
import React from 'react';

import { useTranslation } from 'react-i18next';

import ManagePropertiesCardList from '../../../components/ManagePropertiesCardList/ManagePropertiesCardList';
import { Header } from '../../../components/header/Header';

import backgroundImg from '../../../assets/images/controlPanel.jpg';

import styles from './MyOverviewPage.module.css';

export const MyOverviewPage = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.mainDiv}>

            <Header backgroundImg={backgroundImg} title={t('Overview')} />
            <div className={styles.cardsDiv}>
                <ManagePropertiesCardList />
            </div>
        </div>
    );
};
