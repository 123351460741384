import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyImagesForm'),
        showTitle: false,
        name: 'propertyImagesForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Interior-Photos'),
                name: 'propertyΙnterior',
                type: 'file',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Extirior-Photos'),
                name: 'propertyExtirior',
                type: 'file',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Floor-Plans'),
                name: 'propertyFloorPlans',
                type: 'file',
                isRequired: false,
                space: '100%'
            }
        ]
    };
};

export default getDescription;
