import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import SmartFeaturesDescription from '../../../assets/json/SmartFeaturesForm.js';

import styles from './PropertySmartFeaturePage.module.css';

export const PropertyViewSmartFeaturePage = ({ buildingId }) => {
    const [data, setData] = useState(null);
    const { t } = useTranslation();
    const formRef = useRef(null);

    useEffect(() => {
        requests.getBuildingSmartFeatures(buildingId).then(({ data }) => setData(data));
    }, [buildingId]);

    if (data === null) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('Smart Features')}</h1>
            </div>

            <div className={styles.formContainer}>
                <Form
                    description={SmartFeaturesDescription()}
                    initialValue={data}
                    readOnly
                    ref={formRef}
                ></Form>
            </div>
        </div>
    );
};

export const PropertyEditSmartFeaturePage = ({ buildingId }) => {
    const [data, setData] = useState(null);
    const { t } = useTranslation();
    const addToast = useToasts();
    const formRef = useRef(null);

    useEffect(() => {
        requests.getBuildingSmartFeatures(buildingId).then(({ data }) => setData(data));
    }, [buildingId]);

    if (data === null) return <LoaderWrapper />;
    return (
        <div className={styles.pageWrapper}>
            <Header title={t('Smart Features')} />

            <div className={styles.formContainer}>
                <Form
                    description={SmartFeaturesDescription()}
                    initialValue={data}
                    ref={formRef}
                ></Form>
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={async () => {
                        onSubmit(formRef, buildingId, data).then(res => {
                            addToast({
                                type: 'success',
                                message: t('success-property-update'),
                                duration: 3000
                            });
                            return res;
                        });
                    }}
                >
                    <div className={styles.actionBtn}>
                        <AiOutlineCheck />
                        {t('save')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

async function onSubmit(formRef, buildingId, currData) {
    if (!formRef.current) {
        return false;
    }
    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();

    if (sanityCheckRes.length) {
        return false;
    }
    if (!currData.id)
        return requests.createBuildingSmartFeatures(buildingId, formRef.current.getData());

    return requests.updateBuildingSmartFeatures(buildingId, currData.id, formRef.current.getData());
}
