import { BsCheckCircleFill } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';

import styles from './SuccessMessage.module.css';

export const SuccessMessage = ({ children }) => {
    return (
        <div className={styles.container}>
            <GiCheckMark className={styles.icon} />
            <span className={styles.message}>{children}</span>
        </div>
    );
};
