import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('budgetForm'),
        showTitle: false,
        name: 'improvementsForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Επισκευές'),
                name: 'RepairsLabel',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Προϋπολογισμός'),
                name: 'repairsBudget',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εκτιμώμενο Κόστος'),
                name: 'estimatedRepairsCost',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            // {
            //     title: i18next.t('Παρατηρήσεις'),
            //     name: 'repairsComment',
            //     type: 'text',
            //     isRequired: false,
            //     space: '50%'
            // },
            {
                title: i18next.t('Βελτιώσεις-Προσθήκες'),
                name: 'improvementsLabel',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Προϋπολογισμός'),
                name: 'improvementsBudget',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εκτιμώμενο Κόστος'),
                name: 'estimatedImprovementsCost',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            // {
            //     title: i18next.t('Παρατηρήσεις'),
            //     name: 'improvementsComment',
            //     type: 'text',
            //     isRequired: false,
            //     space: '50%'
            // },
            {
                title: i18next.t('Ανακαινίσεις'),
                name: 'price',
                type: 'renovationLabel',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Προϋπολογισμός'),
                name: 'renovationsBudget',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εκτιμώμενο Κόστος'),
                name: 'estimatedRenovationsCost',
                type: 'float',
                isRequired: false,
                space: '50%'
            }
            // {
            //     title: i18next.t('Παρατηρήσεις'),
            //     name: 'renovationsComment',
            //     type: 'text',
            //     isRequired: false,
            //     space: '50%'
            // }
        ]
    };
};

export default getDescription;
