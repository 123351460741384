import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Forgot Password'),
        showTitle: false,
        name: 'ForgotPassword',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Email'),
                placeholder: 'example@domain.com',
                name: 'email',
                isRequired: true,
                type: 'text'
            }
        ]
    };
};

export default getDescription;
