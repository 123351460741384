import ErrorPrompt from 'react-form/dist/Form/form-inputs/ErrorPrompt';
import { Input } from 'react-form/dist/Form/form-inputs/Input';

// ---------------------------------------------------------------------------------------

export class UnfoldedDropdownInput extends Input {
    constructor(props) {
        super(props);
        this.state.choices = props.description.choices || [];
        this.onChange = this.onChange.bind(this);
        this.setOptions = this.setOptions.bind(this);
        this.onChangeSelection = this.onChangeSelection.bind(this);
        this.getTypeOfComponents = this.getTypeOfComponents.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.state.choices.forEach(element => {
            element.checked = false;
        });
        this.state.typeofComponents = this.getTypeOfComponents();
    }

    setOptions(choices) {
        this.setState({
            choices
        });
    }

    computeValueToStore(value) {
        switch (this.props.description.dataType) {
            case 'text':
                return value && value.split(',');
            case 'array':
            default:
                return value;
        }
    }

    setData(data, onFinish) {
        this.state.choices.forEach(elem => {
            elem.value === this.computeValueToStore(data)[0]
                ? (elem.checked = true)
                : (elem.checked = false);
        });
        this.onChange(
            {
                target: {
                    value: this.computeValueToStore(data)[0]
                }
            },
            onFinish
        );
    }

    // Default value not NULL
    getOptions() {
        const options = [];

        // if (!this.props.description.isRequired)
        //   options.push({ value: "default", label: "-" });

        options.push(
            ...this.state.choices.map(opt => ({
                value: opt.value,
                label: opt.title || opt.name
            }))
        );

        return options;
    }

    getData() {
        if (this.state.value === 'default') return null;
        return this.state.value;
    }

    onChange(e, onFinish) {
        const value = e.target.value;
        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    onChangeSelection(e) {
        var value = e.target.value;
        this.state.choices.forEach(elem => {
            elem.value === e.target.value
                ? (elem.checked = e.target.checked)
                : (elem.checked = false);
        });
        if (this.state.value === e.target.value) {
            this.valueUpdate(this.state.value, null);
            this.setState({ value: null });
        } else {
            this.valueUpdate(this.state.value, value);
            this.setState({ value: e.target.value });
        }
    }

    getTypeOfComponents() {
        var comps = this.props.description.components;
        if (comps) {
            if (Array.isArray(comps)) return 'array';
            else return 'object';
        } else return null;
    }

    readOnlyRender() {
        const options = this.getOptions();

        const selectedOption = options.find(c => c.value === this.state.value) || null;

        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>
                    {selectedOption ? selectedOption.label : '-'}
                </div>
            </>
        );
    }

    renderOption(elem, index) {
        const Components = this.props.description.components;
        const optionId = `${this.props.description.name}-${elem.value}`;
        return (
            <div
                className='unfolded-drp-item '
                key={`${
                    this.props.description.title || this.props.description.name
                }_option_${index}`}
            >
                <input
                    id={optionId}
                    className='unfolded-drp-input'
                    type='checkbox'
                    name={this.props.description.title}
                    value={elem.value}
                    onChange={this.onChangeSelection}
                    checked={this.state.value === elem.value ? true : false}
                />
                <label className='unfolded-drp-label' htmlFor={optionId}>
                    {Components &&
                        this.state.typeofComponents === 'array' &&
                        (Comp => Comp && <Comp title={elem.title} value={elem.value} />)(
                            Components[index]
                        )}
                    {Components && this.state.typeofComponents === 'object' && (
                        <Components title={elem.title} value={elem.value} />
                    )}
                    {!Components && elem.label}
                </label>
            </div>
        );
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        if (this.props.readOnly) {
            return this.readOnlyRender();
        }

        const options = this.getOptions();

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div className='form-group' style={this.props.style}>
                    <label
                        style={{ width: '100%' }}
                        aria-label={this.props.description.title || this.props.description.name}
                    ></label>
                    <div className='unfolded-drp-container'>
                        {options && options.map(this.renderOption)}
                    </div>
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}
