import { React, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';

import { IoIosArrowBack } from 'react-icons/io';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import requests from '../../../utils/requests';

import RoleForm from '../../../assets/json/RoleForm';

import styles from './CreateRolePage.module.css';

export default function CreateRolePage() {
    const { t } = useTranslation();
    const { teamId } = useParams();
    const addToast = useToasts();
    const navigate = useNavigate();

    const formRef = useRef();

    const createRole = async () => {
        const data = formRef.current.getData();

        const sanityCheck = formRef.current.sanityCheckForm();

        if (!!sanityCheck.length) return null;

        try {
            await requests.createRoleOnTeam(teamId, data);
        } catch (err) {
            return addToast({
                type: 'failure',
                message: t('There was an error creating the role. Please try again later.'),
                duration: 3000
            });
        }

        addToast({
            type: 'success',
            message: t('Role was added successfully'),
            duration: 3000
        });

        return navigate('../roles');
    };

    const permissions = [
        'canAddNewProperty',
        'hasViewAccess',
        'hasEditAccess',
        'hasDigitalFolderAccess',
        'hasLeaseAccess',
        'hasRenovationAccess',
        'hasExpensesAccess',
        'hasEstimatesAccess',
        'hasSuggestionAccess',
        'hasMaturityActionsAccess',
        'hasDigitalFolderEdit',
        'hasLeaseEdit',
        'hasRenovationEdit',
        'hasExpensesEdit',
        'hasEstimatesEdit',
        'hasSuggestionEdit',
        'hasMaturityActionsEdit'
    ];

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <ButtonWithLoading
                    className={`btn btn-cancel ${styles.backBtn}`}
                    onClick={() => navigate('../roles')}
                >
                    <IoIosArrowBack />
                    {t('Back')}
                </ButtonWithLoading>
                <h1>{t('New Role')}</h1>
            </div>
            <Form ref={formRef} description={RoleForm(permissions)} />
            <ButtonWithLoading
                className={`btn btn-primary-0 ${styles.saveBtn}`}
                onClick={createRole}
            >
                {t('Save')}
            </ButtonWithLoading>
        </div>
    );
}
