import { ChromePicker, CirclePicker } from 'react-color';

import ErrorPrompt from 'react-form/dist/Form/form-inputs/ErrorPrompt';
import { Input } from 'react-form/dist/Form/form-inputs/Input';

import { AiFillEdit } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';

import Utils from '../utils';

// ---------------------------------------------------------------------------------------

export class FancyColorInput extends Input {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state.colorPickerIsVisible = false;
    }

    toggleColorPicker = () => {
        this.setState({ colorPickerIsVisible: !this.state.colorPickerIsVisible });
    };

    hideColorPicker = () => {
        this.setState({ colorPickerIsVisible: false });
    };

    onChange(e, onFinish) {
        const value = e.target.value !== '' ? e.target.value : null;

        this.valueUpdate(this.state.value, value);
        this.setState({ value }, onFinish);
    }

    readOnlyRender() {
        const color = this.state.value || '#ffffff';

        return (
            <>
                {this.props.label}
                <div className='readOnly-value-wrap'>
                    <div className='facny-color-readOnly' style={{ backgroundColor: color }} />
                </div>
            </>
        );
    }

    handleChange = color => {
        this.onChange({ target: { value: color.hex } });
    };

    render() {
        if (this.state.isHidden) return null;

        if (this.props.readOnly) return this.readOnlyRender();

        const color = this.state.value || '#ffffff';

        const iconColor = Utils.isColorLight(color) ? '#333333' : '#eeeeee';

        return (
            <>
                <div
                    ref={this.labelRef}
                    className={`form-item-input-title${this.state.problem ? '-error' : ''}`}
                >
                    {this.props.label}
                </div>
                <div className='form-group' style={this.props.style}>
                    <div className='fancy-color-picker-container'>
                        <div
                            className='facny-color-selected-container'
                            onBlur={this.hideColorPicker}
                            tabIndex='1'
                            onClick={this.toggleColorPicker}
                        >
                            <div
                                className='facny-color-selected'
                                style={{ backgroundColor: color }}
                            >
                                {!this.state.colorPickerIsVisible && (
                                    <>
                                        <AiFillEdit
                                            className='fancy-color-picker-edit'
                                            color={iconColor}
                                        />
                                        {this.state.value && (
                                            <BsCheckLg
                                                className='fancy-color-picker-check'
                                                color={iconColor}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                            {this.state.colorPickerIsVisible && (
                                <ChromePicker
                                    color={color}
                                    onChange={this.handleChange}
                                    onChangeComplete={this.handleChange}
                                    disableAlpha
                                    className={'fancy-color-picker'}
                                />
                            )}
                        </div>
                        <span className='fancy-color-picker-separator' />
                        <CirclePicker onChange={this.handleChange} color={color} />
                    </div>
                </div>
                {this.state.problem && <ErrorPrompt>{this.state.problem}</ErrorPrompt>}
            </>
        );
    }
}
