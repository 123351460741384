import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';

import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper.js';

import requests from '../../../utils/requests';

import expenseForm from '../../../assets/json/ExpensesForm.js';

import styles from './PropertyExpensesViewPage.module.css';

export const PropertyExpensesViewPage = ({ buildingId, plotId }) => {
    const { t } = useTranslation();
    const { id: expenseId } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!buildingId && !plotId) {
            return;
        }

        (buildingId
            ? requests.getBuildingExpense(buildingId, expenseId)
            : requests.getPlotExpense(plotId, expenseId)
        ).then(({ data }) => {
            if (data.paymentNotifFile && data.paymentNotifFile.file) {
                data.paymentNotifFile = {
                    name: data.paymentNotifFile.file.name,
                    path: data.paymentNotifFile.file.path
                };
            } else {
                data.paymentNotifFile = null;
            }
            if (data.paymentReceiptFile && data.paymentReceiptFile.file) {
                data.paymentReceiptFile = {
                    name: data.paymentReceiptFile.file.name,
                    path: data.paymentReceiptFile.file.path
                };
            } else {
                data.paymentReceiptFile = null;
            }
            setData(data);
        });
    }, [buildingId, plotId, expenseId]);

    if (!data) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('expenseId = {{id}}', { id: expenseId })}</h1>
            </div>

            <div className={styles.formContainer}>
                <Form description={expenseForm()} initialValue={data} readOnly />
            </div>

            <div className={styles.footerButtons}>
                <Link className='btn btn-primary-0' to='../expenses'>
                    <div className={styles.btnContent}>
                        <IoIosArrowRoundBack />
                        {t('back')}
                    </div>
                </Link>
            </div>
        </div>
    );
};
