import React from 'react';

import { useTranslation } from 'react-i18next';
import {
    AiOutlineCalendar,
    AiOutlineTeam,
    AiOutlineUnorderedList,
    AiOutlineUser
} from 'react-icons/ai';
import { BsCalendar3, BsFilter } from 'react-icons/bs';
import { CiViewBoard } from 'react-icons/ci';

import styles from './ViewModeTasks.module.css';

const ViewModeTasks = ({ inTeam, inUser }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.viewModeMainContainer}>
            <div className={styles.viewsDiv}>
                <div className={`btn btn-primary-0 ${styles.viewsButton}`}>
                    <AiOutlineUnorderedList /> {t('list')}
                </div>
                <div className={`btn btn-cancel ${styles.viewsButton}`}>
                    <CiViewBoard /> {t('board')}
                </div>
                <div className={`btn btn-cancel ${styles.viewsButton}`}>
                    <AiOutlineCalendar /> {t('calendar')}
                </div>
                {!inTeam && (
                    <div className={`btn btn-cancel ${styles.viewsButton}`}>
                        <AiOutlineTeam /> {t('team')}
                    </div>
                )}
                {!inUser && (
                    <div className={`btn btn-cancel ${styles.viewsButton}`}>
                        <AiOutlineUser /> {t('user')}
                    </div>
                )}
            </div>
            <div className={`btn btn-primary-0 ${styles.viewsButton}`}>
                <BsFilter />
                {t('Filters')}
            </div>
        </div>
    );
};

export default ViewModeTasks;
