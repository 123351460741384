import React from 'react';

import { useTranslation } from 'react-i18next';
import { BiTrash } from 'react-icons/bi';
import { BsFillCameraFill } from 'react-icons/bs';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import { URI } from '../../utils/requests';

import styles from './ProfileImage.module.css';

export const ProfileImage = ({
    accountData,
    updateRequest,
    deleteRequest,
    updateData,
    toastMessages
}) => {
    const { t } = useTranslation();

    const addToast = useToasts();

    const uploadImage = async event => {
        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('file', file);

        //confict with team.id and user.email....
        const ident = accountData.email || accountData.id;
        console.log(ident);
        const { data } = await updateRequest(ident, formData).catch(console.log);
        updateData({ ...accountData, image: data.image });
        addToast({
            type: toastMessages.onUpdate.type,
            message: toastMessages.onUpdate.text,
            duration: 3000
        });
    };

    const deleteImage = async event => {
        //confict with team.id and user.email....
        const ident = accountData.email || accountData.id;

        await deleteRequest(ident);
        updateData({ ...accountData, image: undefined });
        addToast({
            type: toastMessages.onDelete.type,
            message: toastMessages.onDelete.text,
            duration: 3000
        });
    };

    return (
        <div className={styles.wrap}>
            {accountData.image ? (
                <>
                    <div
                        className={styles.image}
                        style={{
                            backgroundImage: `url(${URI}/${accountData.image.path})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    >
                        <label>
                            <div className={styles.imageCover}>
                                <BsFillCameraFill size={30} title={t('upload-image')} />
                                <input
                                    style={{ display: 'none' }}
                                    type='file'
                                    accept='image/*'
                                    onChange={uploadImage}
                                />
                            </div>
                        </label>
                    </div>
                    <BiTrash
                        className={styles.bin}
                        title={t('remove-image')}
                        onClick={() => deleteImage()}
                    />
                </>
            ) : (
                <div className={styles.imageDefault}>
                    <label>
                        <div className={styles.imageCover}>
                            <BsFillCameraFill size={30} title={t('upload-image')} />
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                accept='image/*'
                                onChange={uploadImage}
                            />
                        </div>
                    </label>
                </div>
            )}
        </div>
    );
};
