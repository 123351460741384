import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import {
    AiFillFolderOpen,
    AiOutlineDelete,
    AiOutlineFileText,
    AiOutlineFolderOpen
} from 'react-icons/ai';
import { BiEuro, BiImage, BiVideo } from 'react-icons/bi';
import { BsCurrencyDollar, BsFileEarmarkText, BsInfo, BsInfoCircle } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { IoBulbOutline } from 'react-icons/io5';
import { MdOutlineFeaturedPlayList, MdOutlineInsertPhoto } from 'react-icons/md';
import { RiLandscapeLine } from 'react-icons/ri';
import { RiKey2Line } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';

import useOwnersEdit from '../../hooks/useOwnersEdit';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { FormLabel } from '../../components/formLabel/FormLabel';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import OwnersList from '../../components/ownersList/OwnersList';

import requests, { URI } from '../../utils/requests';

import { SidebarPage } from '../generalPages/SidebarPage';
import PropertyDigitalFolderPage from './PropertyDigitalFolderPage';
import PropertyLocationPage from './PropertyLocationPage';
import { PropertyViewImagesPage } from './PropertyViewImagesPage';
import { PropertyViewVideosPage } from './PropertyViewVideosPage';
import { PropertyViewSuggestionsPage } from './SuggestionPages/PropertyViewSuggestionsPage';
import { PropertyEstimatesHistoryPage } from './estimatesPages/PropertyEditEstimatesPage';
import { PropertyEstimateViewPage } from './estimatesPages/PropertyEstimateViewPage';
import { PropertyExpensesMainPage } from './expensesPages/PropertyEditExpensesPage';
import { PropertyExpensesViewPage } from './expensesPages/PropertyExpensesViewPage';
import { PropertyLeasesHistoryPage } from './leasesPages/PropertyEditLeasesPage';
import { PropertyLeasesViewPage } from './leasesPages/PropertyLeasesViewPage';

import imageProperty from '../../assets/images/AlphaLogo.png';
import infoDescription from '../../assets/json/PropertyInfoView';
import landDescription from '../../assets/json/PropertyLand.js';

import styles from './PropertyViewPage.module.css';
import './propertyFormView.css';

const LandViewPage = ({ property }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { propertyId } = useParams();
    const [owners, setOwners] = useOwnersEdit();
    const [mainImage, setMainImage] = useState(null);
    const [imageExists, setImageExists] = useState(true);
    const [isPortaitImage, setIsPrortaitImage] = useState(false);
    const [isRemoveModalActive, setIsRemoveModalActive] = useState(false);

    useEffect(() => {
        Promise.all([requests.getPropertyAssetsMain(propertyId)]).then(([{ data: _mainImage }]) => {
            setMainImage(_mainImage);
        });
    }, [propertyId]);

    useEffect(() => {
        if (!property) {
            return;
        }

        requests.getPropertyOwners(property.id).then(({ data }) => {
            if (!data) {
                setOwners(null);
            }

            setOwners(data.map(elem => ({ ...elem.owner, percentage: elem.percentage })));
        });
    }, [property, setOwners]);

    const getEditPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'property',
            'land',
            'location',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'digital-folder'
        ];

        const urlParts = location.pathname.split('/').reverse();
        if (validSuffixes.includes(urlParts[0])) {
            return `../properties/edit/${property.id}/${urlParts[0]}`;
        }
        return `../properties/edit/${property.id}/${urlParts[1]}/${urlParts[0]}`;
    }, [location, property]);

    if (!property) return <LoaderWrapper />;

    const links = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <h1 className={styles.pageTitle}>{t('general')}</h1>
                            <FormLabel readOnly> {t('Ownership')} </FormLabel>
                            <Form
                                description={infoDescription()}
                                readOnly
                                initialValue={property.plot}
                            />
                            <OwnersList owners={owners} readOnly />
                            <Form
                                description={landDescription()}
                                initialValue={property.plot}
                                readOnly
                            />
                        </>
                    ),
                    Icon: BsInfo
                },
                {
                    path: 'location',
                    title: t('location'),
                    element: <PropertyLocationPage property={property} readOnly />,
                    Icon: GoLocation
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: <PropertyViewImagesPage />,
                    Icon: MdOutlineInsertPhoto
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyViewVideosPage />,
                    Icon: BiVideo
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: <PropertyDigitalFolderPage property={property} />,
                    Icon: AiOutlineFolderOpen,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    }
                }
            ]
        },
        {
            title: t('Actions'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage plotId={property.plot.id} />,
                    Icon: BiEuro
                },
                {
                    path: 'expenses/:id',
                    element: <PropertyExpensesViewPage plotId={property.plot.id} />,
                    Icon: BiEuro
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    Icon: RiKey2Line,
                    element: <PropertyLeasesHistoryPage plotId={property.plot.id} />,
                    hasNestedRoutes: true
                },
                {
                    path: 'leases/:id',
                    element: <PropertyLeasesViewPage plotId={property.plot.id} />
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: <PropertyEstimatesHistoryPage plotId={property.plot.id} />,
                    Icon: BsFileEarmarkText,
                    hasNestedRoutes: true
                },
                {
                    path: 'estimates/:id',
                    element: <PropertyEstimateViewPage plotId={property.plot.id} />
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: <PropertyViewSuggestionsPage plotId={property.plot.id} />,
                    Icon: IoBulbOutline
                }
            ]
        }
    ];
    const imageResolution = () => {
        const img = new Image();
        img.src = `${URI}/${mainImage.file.path}`;
        img.addEventListener('load', () => {
            if (img.naturalWidth > img.naturalHeight) {
                setIsPrortaitImage(false);
            } else {
                setIsPrortaitImage(true);
            }
        });
        return;
    };
    const removeModal = () => {
        if (!isRemoveModalActive) return null;

        return (
            <Modal
                onClose={() => setIsRemoveModalActive(false)}
                header={t('delete-property')}
                content={<div>{t('Are you sure you want to delete the property')}</div>}
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsRemoveModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-error'>
                            {t('remove')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    return (
        <SidebarPage options={links}>
            {imageExists && mainImage && (
                <img
                    src={`${URI}/${mainImage.file.path}`}
                    alt={property?.building?.name}
                    className={!isPortaitImage ? styles.portraitImage : styles.imageProperty}
                    onError={() => setImageExists(false)}
                />
            )}
            {(!imageExists || mainImage === '') && (
                <img
                    src={imageProperty}
                    style={{ objectFit: 'scale-down' }}
                    className={styles.imageProperty}
                    alt={property?.building?.name}
                />
            )}
            <div className={styles.childrenSideBar}>
                <div className={styles.nameBuilding}>
                    <div className={styles.titleSidebar}> {property.plot.name}</div>

                    <div className={styles.actionsContainer}>
                        <Link to='../properties'>
                            <TiArrowBackOutline size={23} />
                        </Link>
                        <Link
                            className={`${styles.actionBars} ${styles.editBtn}`}
                            to={getEditPage()}
                        >
                            <div className={styles.actionBtnContent}>
                                <FaEdit size={20} />
                            </div>
                        </Link>
                        <AiOutlineDelete
                            className={styles.deleteBtn}
                            color={'red'}
                            size={23}
                            onClick={() => {
                                setIsRemoveModalActive(true);
                            }}
                        />
                    </div>
                </div>
                <div className={styles.street}>{`${property.street} ${property.streetNumber}`}</div>
            </div>
            {removeModal()}
        </SidebarPage>
    );
};

export default LandViewPage;
