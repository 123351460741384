import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineUpload } from 'react-icons/ai';
import { BiTrash, BiX } from 'react-icons/bi';
import { BsFillPlayCircleFill } from 'react-icons/bs';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';

import requests, { URI } from '../../utils/requests';

import styles from './PropertyEditVideosPage.module.css';
import { IoAddCircleOutline } from 'react-icons/io5';

export const PropertyEditVideosPage = () => {
    const { t } = useTranslation();
    const { propertyId } = useParams();

    const [isAddModalActive, setIsAddModalActive] = useState(false);
    const [isRemoveModalActive, setIsRemoveModalActive] = useState(false);

    const [currentVideoPlaying, setCurrentVideoPlaying] = useState(null);
    const addToast = useToasts();
    const [videos, setVideos] = useState(null);

    const formRef = useRef();

    const getAndSetData = useCallback(() => {
        requests.getPropertyVideos(propertyId).then(({ data }) => {
            setVideos(data);
        });
    }, [propertyId]);

    useEffect(() => {
        getAndSetData();
    }, [getAndSetData]);

    if (!videos) return null;

    const getExtendedImages = videoEntries => {
        if (videoEntries.length === 0)
            return (
                <LoaderWrapper>
                    <div className={styles.emptyPrompt}>{t('no-videos')}</div>
                </LoaderWrapper>
            );
        return videoEntries.map((ve, i) => (
            <div className={styles.extendedSingleWrap} key={i}>
                <LoaderWrapper>
                    {currentVideoPlaying !== ve.id ? (
                        <div className={styles.mainContainer}>
                            <img
                                className={styles.video}
                                onClick={() => setCurrentVideoPlaying(ve.id)}
                                src={`${URI}/${ve.thumbnail.path}`}
                                alt={`${URI}/${ve.thumbnail.path}`}
                            />
                            <BsFillPlayCircleFill
                                className={styles.playButton}
                                onClick={() => setCurrentVideoPlaying(ve.id)}
                            />
                            <BiTrash
                                onClick={e => {
                                    e.stopPropagation();
                                    setIsRemoveModalActive(ve);
                                }}
                                className={styles.removeIcon}
                            />
                        </div>
                    ) : (
                        <>
                            <video
                                autoPlay={true}
                                controls={true}
                                className={styles.video}
                                src={`${URI}/${ve.video.path}`}
                            />
                            <BiX
                                onClick={e => {
                                    e.stopPropagation();
                                    setCurrentVideoPlaying(null);
                                }}
                                className={styles.closeIcon}
                            />
                        </>
                    )}
                    <div className={styles.videoTitle}>{ve.title}</div>
                </LoaderWrapper>
            </div>
        ));
    };
    const extendedView = () => {
        return (
            <div className={styles.extendedWrap}>
                <div className={styles.extendedVideoWrap}>
                    <LoaderWrapper>{getExtendedImages(videos)}</LoaderWrapper>
                </div>
            </div>
        );
    };

    const description = {
        questions: [
            {
                type: 'file',
                name: 'thumbnail',
                title: t('thumbnail'),
                isRequired: true,
                maxFiles: 1
            },
            {
                type: 'file',
                name: 'video',
                title: t('video'),
                isRequired: true,
                maxFiles: 1
            },
            {
                type: 'text',
                name: 'title',
                title: t('title'),
                isRequired: true
            }
        ]
    };

    const uploadVideo = async () => {
        formRef.current.clearSanityCheck();

        const sanity = formRef.current.sanityCheckForm();
        if (!!sanity.length) return;

        const data = formRef.current.getData();

        if (!/^image\//.test(data.thumbnail.type)) {
            addToast({
                type: 'failure',
                message: t('Only image files are accepted (jpeg, png, jpg..)'),
                duration: 10000
            });
            return;
        }

        if (!/^video\//.test(data.video.type)) {
            addToast({
                type: 'failure',
                message: t('Only video files are accepted'),
                duration: 10000
            });
            return;
        }
        const formData = new FormData();
        if (data.thumbnail !== 'images') formData.append('file', data.thumbnail);
        formData.append('file', data.video);
        formData.append('title', data.title);

        await requests.addPropertyVideos(propertyId, formData);
        setVideos(null);
        setIsAddModalActive(false);
        getAndSetData();
    };

    const addModal = () => {
        if (!isAddModalActive) return null;

        return (
            <Modal
                onClose={() => setIsAddModalActive(false)}
                header={t('add-video')}
                content={
                    <div>
                        <Form description={description} ref={formRef} />
                    </div>
                }
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsAddModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-primary-0' onClick={uploadVideo}>
                            {t('create')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    const removeModal = () => {
        if (!isRemoveModalActive) return null;

        const removeVideo = async () => {
            await requests.removePropertyVideo(
                isRemoveModalActive.propertyId,
                isRemoveModalActive.id
            );
            const im = videos.filter(vi => vi.id !== isRemoveModalActive.id);
            setVideos(im);
            setIsRemoveModalActive(false);
        };

        return (
            <Modal
                onClose={() => setIsRemoveModalActive(false)}
                header={t('remove-video')}
                content={<div>{t('Are you sure you want to remove the video')}</div>}
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsRemoveModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-error' onClick={removeVideo}>
                            {t('remove')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };

    return (
        <div className={styles.pageWrap}>
            <div className={styles.topBar}>
                <h1>{t('Videos')}</h1>
                <button
                    className={`btn btn-primary-0 ${styles.actionBtn}`}
                    onClick={() => setIsAddModalActive(true)}
                >
                    <IoAddCircleOutline />
                    {t('upload')}
                </button>
            </div>
            <LoaderWrapper>
                {extendedView()}
                {addModal()}
                {removeModal()}
            </LoaderWrapper>
        </div>
    );
};
