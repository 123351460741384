import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import i18next from 'i18next';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { AiFillDelete } from 'react-icons/ai';
import { FaEdit } from 'react-icons/fa';
import { GrFormView } from 'react-icons/gr';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Table } from 'table';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';
import { useUser } from '../../../context/withUser';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../../components/modal/Modal';
import { Tabs } from '../../../components/tabs/Tabs';

import requests, { URI } from '../../../utils/requests';
import Utils from '../../../utils/utils';

import AddExpenses from '../../../assets/json/AddExpenses';
import ExpensesForm from '../../../assets/json/ExpensesForm';

import styles from './PropertyEditExpensesPage.module.css';
import { SmallPreviewNameImage } from '../../../components/smallPreviewNameImage/SmallPreviewNameImage';

const handleExpensesSubmit = async (data, formRef, buildingId, plotId) => {
    const { propertyId, expenseId, category, subcategory,userEmail } = data;

    if (!formRef.current) {
        return false;
    }

    formRef.current.clearSanityCheck();

    const sanityCheckRes = formRef.current.sanityCheckForm();
    if (!!sanityCheckRes.length) {
        return false;
    }

    const _data = formRef.current.getData();
    const formData = new FormData();
    for (const field in _data) {
        formData.append(field, _data[field]);
    }
    formData.append("author", userEmail);
    if (_data.paymentNotifFile instanceof File || !_data.paymentNotifFile) {
        // NOTE new file has been uploaded
        formData.append('paymentNotifFile', _data.paymentNotifFile);
    } else {
        // NOTE keep the current file
        const { data } = await axios.get(`${URI}/${_data.paymentNotifFile.path}`, {
            responseType: 'blob'
        });
        formData.append('paymentNotifFile', new File([data], _data.paymentNotifFile.name));
    }
    if (_data.paymentReceiptFile instanceof File || !_data.paymentReceiptFile) {
        // NOTE new file has been uploaded
        formData.append('paymentReceiptFile', _data.paymentReceiptFile);
    } else {
        // NOTE keep the current file
        const { data } = await axios.get(`${URI}/${_data.paymentReceiptFile.path}`, {
            responseType: 'blob'
        });
        formData.append('paymentReceiptFile', new File([data], _data.paymentReceiptFile.name));
    }
    formData.append('propertyId', propertyId);
    const tranlateData1 = [
        i18next.t('CONTRACTS'),
        i18next.t('NON-CONTRACT-EXPENSES'),
        i18next.t('MAINTENANCE')
    ];

    const tranlateData2 = [
        i18next.t('CLEANING'),
        i18next.t('WATER'),
        i18next.t('POWER'),
        i18next.t('ALARM')
    ];

    if (!expenseId) {
        const cats = ['CONTRACTS', 'NON-CONTRACT-EXPENSES', 'MAINTENANCE'];
        const subcats = ['CLEANING', 'WATER', 'POWER', 'ALARM'];
        if (cats.includes(category) && subcats.includes(subcategory)) {
            formData.append('category', category);
            formData.append('expenseSubCategory', subcategory);
            return buildingId
                ? requests.createBuildingExpense(buildingId, formData)
                : requests.createPlotExpense(plotId, formData);
        }
        //TODO: error messege , use only building Id
        return true;
    } else {
        return buildingId
            ? requests.updateBuildingExpense(buildingId, expenseId, formData)
            : requests.updatePlotExpense(plotId, expenseId, formData);
    }
};

export const PropertyEditExpensesPage = ({ propertyId, buildingId, plotId, navigateBack }) => {
    const { t } = useTranslation();
    const { id: expenseId } = useParams();
    const [data, setData] = useState(null);
    const formRef = useRef(null);
    const {user} = useUser();

    const searchParams = new URLSearchParams(document.location.search);
    const category = searchParams.get('category');
    const subcategory = searchParams.get('subcategory');

    useEffect(() => {
        if (expenseId)
            (buildingId
                ? requests.getBuildingExpense(buildingId, expenseId)
                : requests.getPlotExpense(plotId, expenseId)
            ).then(({ data }) => {
                if (data.paymentNotifFile && data.paymentNotifFile.file) {
                    data.paymentNotifFile = {
                        name: data.paymentNotifFile.file.name,
                        path: data.paymentNotifFile.file.path
                    };
                } else {
                    data.paymentNotifFile = null;
                }
                if (data.paymentReceiptFile && data.paymentReceiptFile.file) {
                    data.paymentReceiptFile = {
                        name: data.paymentReceiptFile.file.name,
                        path: data.paymentReceiptFile.file.path
                    };
                } else {
                    data.paymentReceiptFile = null;
                }
                setData(data);
            });
    }, [buildingId, plotId, expenseId]);

    if (data === null && expenseId) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.pageTitle}>
                {expenseId ? t('Edit expense') : t('Create new expense')}
            </h1>

            <div className={styles.formContainer}>
                <Form description={ExpensesForm()} initialValue={data} ref={formRef} />
            </div>
            <div className={styles.footerButtons}>
                <ButtonWithLoading className='btn btn-primary-0' onClick={navigateBack}>
                    <div className={styles.btnContent}>
                        <AiOutlineClose />
                        {t('cancel')}
                    </div>
                </ButtonWithLoading>
                <ButtonWithLoading
                    className='btn btn-primary-0'
                    onClick={async () => {
                        (await handleExpensesSubmit(
                            { propertyId, expenseId, category, subcategory, userEmail: user.email },
                            formRef,
                            buildingId,
                            plotId
                        )) && navigateBack();
                    }}
                >
                    <div className={styles.btnContent}>
                        <AiOutlineCheck />
                        {t('save')}
                    </div>
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export const PropertyExpensesMainPage = ({ isEdit = false, buildingId, plotId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const addToast = useToasts();
    const [expenses, setExpenses] = useState(null);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const formRef = useRef(null);
   

    useEffect(() => {
        (buildingId
            ? requests.getAllBuildingExpenses(buildingId)
            : requests.getAllPlotExpenses(plotId)
        // ).then(({ data }) => {
        //     setExpenses(data);
        // });
        ).then(({ data }) => {
            Promise.all(data.map(async expense => ({
              ...expense,
              author: (await requests.getUser(expense.author)).data
            }))).then(setExpenses)
          });
        
    }, [buildingId, plotId]);

  
    

    function renderAddModal() {
        if (!addModal) return null;

        function queryBuilder() {
            if (!formRef.current) return;
            const { expenseCategory, expenseSubCategory } = formRef.current.getData();

            const obj = { category: expenseCategory, subcategory: expenseSubCategory };
            if (!obj) return '';

            const result = [];

            for (const [key, value] of Object.entries(obj)) {
                if (value === undefined) continue;

                result.push(`${key}=${value}`);
            }
            if (result.length === 0) return '';

            return '?' + result.join('&');
        }

        return (
            <Modal
                onClose={() => {
                    setAddModal(false);
                }}
                header={t('add-expenses')}
                content={<Form description={AddExpenses()} ref={formRef} />}
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setAddModal(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={() => {
                                navigate(`./new${queryBuilder()}`);
                                setAddModal(false);
                            }}
                        >
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    const deleteExpense = async id => {
        buildingId
            ? await requests.deleteBuildingExpense(buildingId, id)
            : await requests.deletePlotExpense(plotId, id);

        const { data } = buildingId
            ? await requests.getAllBuildingExpenses(buildingId)
            : await requests.getAllPlotExpenses(plotId);
        setExpenses(data);
        setDeleteModal(null);
        addToast({
            type: 'success',
            message: t('delete-expense-success'),
            duration: 3000
        });
    };

    const getImageAuthor = async value =>{
        const { data } = await requests.getUser(value);
        return data.image.path;
    }

    const TABLE_COLUMNS_SIMPLE = useMemo(() => {
        const columns = [
            {
                Header: t('Name'),
                accessor: 'name',
                sticky: 'left',
                Cell: ({ value }) =>
                    value === 'null' || value == undefined || value === '' ? t('no-name') : value
                // filter: () => {}
            },

            {
                Header: t('dateFrom'),
                accessor: 'dateFrom'
            },
            {
                Header: t('dateTo'),
                accessor: 'dateTo'
            },
            {
                Header: t('isPaid'),
                accessor: 'isPaid'
            },
            {
                Header: t('createdAt'),
                accessor: 'createdAt'
            },
            {
                Header: t('updatedAt'),
                accessor: 'updatedAt'
            },
            {
                Header: t('author'),
                accessor: 'author',
                Cell: ({value}) =>(
                    <>
                        <SmallPreviewNameImage
                            imageSrc={value.image && `${URI}/${value.image.path}`}
                            fullname={value.name}
                            onClick={() => navigate(`../../users/${value.email}/info`)}
                        />
                    </>
                )
            },
            {
                accessor: 'paymentNotifFile',
                Header: t('notification'),
                Cell: ({ value }) => (
                    <>
                        {value && (
                            <a
                                className={styles.fileLink}
                                href={`${URI}/${value.file.path}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {value.file.name}
                            </a>
                        )}
                    </>
                )
            },
            {
                accessor: 'paymentReceiptFile',
                Header: t('receipt'),
                Cell: ({ value }) => (
                    <>
                        {value && (
                            <a
                                className={styles.fileLink}
                                href={`${URI}/${value.file.path}`}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {value.file.name}
                            </a>
                        )}
                    </>
                )
            }
        ];
        if (isEdit) {
            columns.push({
                accessor: 'actions',
                Header: t('actions-edit-delete'),
                Cell: ({ value: id }) => {
                    return (
                        <div className={styles.actionsDiv}>
                            <Link className={styles.editBtn} to={`./${id}`}>
                                <FaEdit color={'var(--theme-primary)'} size={20} />
                            </Link>
                            <button
                                className={styles.deleteBtn}
                                onClick={() => setDeleteModal({ id })}
                            >
                                <AiFillDelete color={'var(--theme-error-10)'} size={20} />
                            </button>
                        </div>
                    );
                },
                sticky: 'right'
            });
        } else {
            columns.push({
                accessor: 'actions',
                Header: t('actions-edit-delete'),
                Cell: ({ value: id }) => {
                    return (
                        <div className={styles.actionsDiv}>
                            <Link className={styles.editBtn} to={`./${id}`}>
                                <GrFormView color={'var(--theme-primary)'} size={20} />
                            </Link>
                        </div>
                    );
                },
                sticky: 'right'
            });
        }

        return columns;
    }, [isEdit, t]);

    const transformDataSimple = (data = []) => {
        if (!data || !data.length) return [];

        return data.map(exp => {
            return {
                name: exp.nameOfExpense,
                dateFrom: exp.dateFrom === null ? "-" :`${Utils.timestampToHumanizedDate(
                    exp.dateFrom
                )}`,
                dateTo: exp.dateTo === null ? "-" :`${Utils.timestampToHumanizedDate(
                    exp.dateTo
                )}`,
                isPaid: exp.isPaid ? <AiOutlineCheck color='green'/> : t('isNotPaid'),
                createdAt: `${Utils.timestampToHumanizedDate(
                    exp.createdAt
                )} `,
                updatedAt: `${Utils.timestampToHumanizedDate(
                    exp.updatedAt
                )} `,
                paymentNotifFile: exp.paymentNotifFile,
                paymentReceiptFile: exp.paymentReceiptFile,
                actions: exp.id,
                author: exp.author,
            };
        });
    };

    const tabs = [
        {
            title: t('contracts'),
            content: (
                <LoaderWrapper size={50} thickness={8}>
                    <Table
                        key={'table_contracts'}
                        pagination={{ pageSize: 10 }}
                        columns={TABLE_COLUMNS_SIMPLE}
                        data={transformDataSimple(
                            expenses?.filter(e => e.category === 'CONTRACTS')
                        )}
                    />
                </LoaderWrapper>
            )
        },
        {
            title: t('Non-Contract Expenses'),

            content: (
                <LoaderWrapper size={50} thickness={8}>
                    <Table
                        key={'table_non_contracts'}
                        pagination={{ pageSize: 10 }}
                        columns={TABLE_COLUMNS_SIMPLE}
                        data={transformDataSimple(
                            expenses?.filter(e => e.category === 'NON-CONTRACT-EXPENSES')
                        )}
                    />
                </LoaderWrapper>
            )
        },
        {
            title: t('Maintenance'),
            content: (
                <LoaderWrapper size={50} thickness={8}>
                    <Table
                        key={'table_maintenance'}
                        pagination={{ pageSize: 10 }}
                        columns={TABLE_COLUMNS_SIMPLE}
                        data={transformDataSimple(
                            expenses?.filter(e => e.category === 'MAINTENANCE')
                        )}
                    />
                </LoaderWrapper>
            )
        }
    ];

    if (expenses === null) return <LoaderWrapper />;

    return (
        <div>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('expenses')}</h1>
                {isEdit && (
                    <span
                        className={`btn btn-primary-0 ${styles.addButton}`}
                        onClick={() => {
                            setAddModal(true);
                        }}
                    >
                        <IoAddCircleOutline size={'20px'} />
                        <span className={styles.buttonTitle}>{t('Add')}</span>
                    </span>
                )}
            </div>

            <Tabs tabs={tabs} />
            {renderAddModal()}
            {deleteModal && (
                <Modal
                    onClose={() => setDeleteModal(null)}
                    header={t('delete-expense')}
                    content={t('confirm-delete-expense')}
                    footer={
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <button
                                className='btn'
                                onClick={() => {
                                    setDeleteModal(null);
                                }}
                            >
                                {t('cancel')}
                            </button>
                            <ButtonWithLoading
                                className={`btn btn-error`}
                                onClick={() => deleteExpense(deleteModal.id)}
                            >
                                {t('delete')}
                            </ButtonWithLoading>
                        </div>
                    }
                />
            )}
        </div>
    );
};
