import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BsBuilding, BsInfoCircle, BsOption, BsPeople, BsPieChart } from 'react-icons/bs';
import { IoMdPulse } from 'react-icons/io';
import { MdOutlineManageAccounts } from 'react-icons/md';

import { useUser } from '../../context/withUser';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { ProfileInfo } from '../../components/profileInfo/ProfileInfo';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';
import { BsListTask } from 'react-icons/bs';
import { RecentActivity } from '../teamPages/RecentActivity';
import { TeamInformationPage } from '../teamPages/TeamInformationPage';
import CreateRolePage from '../teamPages/createRolePage/CreateRolePage';
import EditRolePage from '../teamPages/editRolePage/EditRolePage';
import { TeamMembersPage } from '../teamPages/TeamMembersPage';
import { TeamOptionsPage } from '../teamPages/TeamOptionsPage';
import TeamPropertiesPage from '../teamPages/TeamPropertiesPage';
import TeamRolePage from '../teamPages/TeamRolePage';

import { SidebarPage } from './SidebarPage';

import styles from './TeamProfilePage.module.css';
import {
    TeamOverviewRenovations,
    TeamStatisticRenovations
} from '../teamPages/statisticsPages/TeamStatisticRenovations';
import { TeamStatisticEstimates } from '../teamPages/statisticsPages/TeamStatisticEstimates';
import { TeamStatisticExpenses } from '../teamPages/statisticsPages/TeamStatisticExpenses';
import { TeamStatisticLeases } from '../teamPages/statisticsPages/TeamStatisticLeases';
import TeamStatisticsPage from '../teamPages/TeamStatisticsPage';
import TaskManagementPage from '../taskManagementPages/TaskManagementPage';

// --------------------------------------------------------------------------------------

export const TeamProfilePage = props => {
    const { t } = useTranslation();
    const params = useParams();
    const teamId = params.teamId;
    const { user } = useUser();
    const [visitedTeam, setVisitedTeam] = useState(null);
    const [permissions, setPermissions] = useState(null);

    /**
     *
     * get this user's permissions  for this Team page
     *
     */

    const fetchAndSet = useCallback(() => {
        requests
            .getTeam(teamId)
            .then(({ data: team }) => {
                setVisitedTeam(team);
            })
            .catch(console.log);

        // requests
        //     .getUserPermissionsForTeam(teamId, user.email)
        //     .then(({ data }) => setPermissions(data))
        //     .catch(console.log);

        setPermissions({
            canPreviewActivity: true,
            canPreviewMembers: true,
            canAddMemberToTeam: user.type === 'ADMIN' ? true : false,
            canRemoveMemberFromTeam: user.type === 'ADMIN' ? true : false,
            canPreviewProperties: true,
            canPreviewRoles: true,
            canAddProperty: true,
            canPreviewStatistics: true,
            canPreviewOther: true,
            canDisable: true,
            canAccessOptions: user.type === 'ADMIN' ? true : false, //canEdit only if canAccessOptions
            canEditProfile: user.type === 'ADMIN' ? true : false
        });
    }, [teamId, user]);

    useEffect(() => {
        fetchAndSet();
    }, [fetchAndSet]);

    if (!permissions || !visitedTeam) return <LoaderWrapper></LoaderWrapper>;

    const links = [
        permissions.canAccessOptions && {
            path: 'info',
            title: t('Information'),
            element: (
                <TeamOptionsPage
                    team={visitedTeam}
                    permissions={permissions}
                    updateTeamData={({ ...data }) => setVisitedTeam({ ...visitedTeam, ...data })}
                />
            ),
            Icon: BsOption
        },
        permissions.canPreviewProperties && {
            path: 'properties',
            title: t('Properties'),
            element: <TeamPropertiesPage permissions={permissions} />,
            Icon: BsBuilding
        },
        permissions.canPreviewMembers && {
            path: 'members',
            title: t('Members'),
            element: <TeamMembersPage permissions={permissions} />,
            Icon: BsPeople
        },
        permissions.canPreviewRoles && {
            path: 'roles',
            title: t('Roles'),
            element: <TeamRolePage permissions={permissions} />,
            Icon: MdOutlineManageAccounts
        },
        permissions.canPreviewStatistics && {
            path: 'statistics',
            title: t('statistics'),
            element: <TeamStatisticsPage permissions={permissions} />,
            Icon: BsPieChart
        },
        permissions.canAccessOptions && {
            path: 'tasks',
            title: t('task'),
            element: (
                <TaskManagementPage inTeam={true} />
            ),
            Icon: BsListTask
        },

        permissions.canPreviewActivity && {
            path: 'activity',
            title: t('Activity'),
            element: <RecentActivity permissions={permissions} />,
            Icon: IoMdPulse
        },
        {
            /* TODO: add an appropriate permission */
            path: 'roles/create',
            element: <CreateRolePage />
        },
        {
            /* TODO: add an appropriate permission */
            path: 'roles/:roleId',
            element: <EditRolePage />
        },
        {
            path: 'statistics/expenses',
            element: <TeamStatisticExpenses />
        },
        {
            path: 'statistics/leases',
            element: <TeamStatisticLeases />
        },
        {
            path: 'statistics/estimates',
            element: <TeamStatisticEstimates />
        },
        {
            path: 'statistics/renovations',
            element: <TeamStatisticRenovations />
        }
    ];

    return (
        <SidebarPage options={links}>
            <div className={styles.teamInfo}>
                <LoaderWrapper>
                    {visitedTeam ? (
                        <ProfileInfo
                            data-testid='profileIfoComponent'
                            name={visitedTeam.name}
                            subName={Utils.timestampToHumanizedDate(visitedTeam.createdAt)}
                            imageSrc={visitedTeam.image && `${URI}/${visitedTeam.image.path}`}
                        />
                    ) : (
                        <ProfileInfo name={''} subName={''} />
                    )}
                </LoaderWrapper>

                <div className={styles.actionBar}>
                    {visitedTeam.isActive ? t('active') : t('inactive')}
                </div>
            </div>
        </SidebarPage>
    );
};
