// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuccessMessage_container__l69AI {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n\n    min-width: 200px;\n    width: 900px;\n\n    margin-top: 30px;\n}\n\n.SuccessMessage_message__5WpYp {\n    font-size: var(--font-2);\n    width: 100%;\n    text-align: center;\n    color: var(--theme-text);\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    gap: 20px;\n}\n\n.SuccessMessage_icon__w1Bx1 {\n    fill: var(--theme-primary);\n    width: 8rem;\n    height: 8rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SuccessMessage/SuccessMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;;IAET,gBAAgB;IAChB,YAAY;;IAEZ,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,kBAAkB;IAClB,wBAAwB;;IAExB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,SAAS;AACb;;AAEA;IACI,0BAA0B;IAC1B,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n\n    min-width: 200px;\n    width: 900px;\n\n    margin-top: 30px;\n}\n\n.message {\n    font-size: var(--font-2);\n    width: 100%;\n    text-align: center;\n    color: var(--theme-text);\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    gap: 20px;\n}\n\n.icon {\n    fill: var(--theme-primary);\n    width: 8rem;\n    height: 8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SuccessMessage_container__l69AI",
	"message": "SuccessMessage_message__5WpYp",
	"icon": "SuccessMessage_icon__w1Bx1"
};
export default ___CSS_LOADER_EXPORT___;
