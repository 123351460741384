import React, { useEffect, useRef, useState } from 'react';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck } from 'react-icons/ai';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import useOwnersEdit from '../../../hooks/useOwnersEdit';

import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';
import OwnersList from '../../../components/ownersList/OwnersList';

import infoDescription from '../../../assets/json/PropertyInfo';

import styles from './PropertyEditInfoPage.module.css';

const MAX_LENGTH_DESCRIPTION = 5000;

const PropertyEditInfoPage = ({
    property,
    updatePropertyToDB,
    getOwners,
    updateOwners,
    returnBackBtn
}) => {
    const { t } = useTranslation();
    const addToast = useToasts();
    const [owners, setOwners, , addOwner, removeOwner, updatePercentage, searchForOwners] =
        useOwnersEdit();
    const [availableUsers, setAvailableUsers] = useState(null);
    const [debouncedName, setDebouncedName] = useState('');
    const [propertyDescription, setPropertyDescription] = useState(null);
    const formRef = useRef(null);

    const onOwnerChange = email =>
        availableUsers && addOwner(availableUsers.find(user => user.email === email));

    const submitInfos = async () => {
        if (!property || !formRef.current) {
            return;
        }

        formRef.current.clearSanityCheck();

        const sanityCheckRes = formRef.current.sanityCheckForm();
        if (!!sanityCheckRes.length) {
            return;
        }

        const _property = JSON.parse(JSON.stringify(property));
        const des = formRef.current.getData().description;
        if (_property.building) {
            _property.building.description = formRef.current.getData().description;
        } else if (_property.plot) {
            _property.plot.description = formRef.current.getData().description;
        } else {
            _property.description = formRef.current.getData().description;
        }

        // NOTE update owners
        const res = await updateOwners(property.id, owners);

        if (JSON.stringify(property) !== JSON.stringify(_property)) {
            await updatePropertyToDB(property.id, _property);
        }
        addToast({
            type: 'success',
            message: t('success-property-update'),
            duration: 3000
        });
    };

    useEffect(() => {
        if (!property) {
            return;
        }

        getOwners(property.id).then(setOwners);
        setPropertyDescription(
            !!property.building
                ? property.building.description
                : !!property.plot
                ? property.plot.description
                : property.description
        );
    }, [property, getOwners, setOwners]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!debouncedName || debouncedName.length < 3) {
                return;
            }

            searchForOwners(debouncedName).then(setAvailableUsers);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [debouncedName, searchForOwners]);

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        formRef.current.getQuestion('owners').setOnInputChange(setDebouncedName);
        formRef.current.setData({ description: propertyDescription });
    }, [propertyDescription]);

    useEffect(() => {
        if (!formRef.current || !availableUsers) {
            return;
        }

        formRef.current.getQuestion('owners').setOptions(
            availableUsers.map(user => ({
                title: user.name,
                value: user.email
            }))
        );
    }, [availableUsers]);

    if (!property) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <h1 className={styles.titlePage}>{t('general')}</h1>

            <Form
                description={infoDescription()}
                onChange={(path, val) => path.includes('owners') && onOwnerChange(val)}
                ref={formRef}
            />
            <OwnersList
                owners={owners}
                onPercentageChange={updatePercentage}
                onOwnerRemove={removeOwner}
            />
            <div
                className={styles.actionBtnsContainer}
                style={{
                    justifyContent: !!!property.building ? 'space-between' : 'flex-end'
                }}
            >
                {returnBackBtn}
                <ButtonWithLoading
                    className={`btn btn-primary-0 ${styles.actionBtn}`}
                    onClick={submitInfos}
                >
                    <AiOutlineCheck /> {t('save')}
                </ButtonWithLoading>
            </div>
        </div>
    );
};

export default PropertyEditInfoPage;
