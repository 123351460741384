import React from 'react';

import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Bar, Doughnut, Line, Pie, Scatter } from 'react-chartjs-2';

import { generateNColors } from '../statistics/helpers/ColorGenerator';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

const generateColors = number => {
    // const colors = [];
    // for (let i = 0; i < number; i++) {
    //     colors.push(`rgba(${Math.random() * 200},${Math.random() * 200},${Math.random() * 200})`);
    // }
    return generateNColors(number);
};

export const GenericChart = ({ type = 'doughnut', data = [], labels = [], moreOptions = {} }) => {
    var chartData = {
        labels,
        datasets: [
            {
                data: data,
                backgroundColor: generateColors(labels.length),
                hoverOffset: 4
            }
        ]
    };

    var options = {
        maintainAspectRatio: false, // Set maintainAspectRatio to false

        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                labels: {
                    font: {
                        size: 16
                    }
                }
            }
        },
        responsive: true
    };

    if (type === 'doughnut') {
        return <Doughnut data={chartData} options={options} />;
    } else if (type === 'bar') {
        options.plugins.legend.display = false;
        return <Bar data={chartData} options={options} />;
    } else if (type === 'pie') {
        return <Pie data={chartData} options={options} />;
    } else if (type === 'line') {
        options.scales = {
            x: {
                type: 'time',
                time: {},
                ticks: {}
            },
            y: {
                beginAtZero: true
            }
        };

        if (moreOptions.viewBy === 'YEAR') {
            options.scales.x.time = { unit: 'year' };

            chartData.datasets = data.map(ds => {
                ds.data.forEach(d => {
                    d.x = new Date(d.x);
                });

                ds.backgroundColor = generateColors(1);
                ds.hoverOffset = 4;
                ds.pointRadius = 2;
                ds.pointHoverRadius = 2;

                return ds;
            });
        } else if (moreOptions.viewBy === 'MONTH') {
            options.scales.x.time = { unit: 'month' };

            chartData.datasets = data.map(ds => {
                ds.data.forEach(d => {
                    d.x = new Date(d.x);
                });

                const color = generateColors(1);
                ds.backgroundColor = color;
                ds.borderColor = color;
                ds.hoverOffset = 4;
                ds.pointRadius = 2;
                ds.pointHoverRadius = 2;

                return ds;
            });
        }

        return <Line data={chartData} options={options} />;
    } else if (type === 'scatter') {
        options.scales = {
            x: {
                type: 'time',
                time: {},
                ticks: {}
            },
            y: {
                beginAtZero: true
            }
        };

        if (moreOptions.viewBy === 'YEAR') {
            options.scales.x.time = { unit: 'year' };

            chartData.datasets = data.map(ds => {
                ds.data.forEach(d => {
                    d.x = new Date(d.x);
                });

                ds.backgroundColor = generateColors(1);
                ds.hoverOffset = 4;
                ds.pointRadius = 2;
                ds.pointHoverRadius = 2;

                return ds;
            });
        } else if (moreOptions.viewBy === 'MONTH') {
            options.scales.x.time = { unit: 'month' };

            chartData.datasets = data.map(ds => {
                ds.data.forEach(d => {
                    d.x = new Date(d.x);
                });

                const color = generateColors(1);
                ds.backgroundColor = color;
                // ds.borderColor = color;
                ds.hoverOffset = 4;
                ds.pointRadius = 2;
                ds.pointHoverRadius = 2;

                return ds;
            });
        }

        return <Scatter data={chartData} options={options} />;
    } else {
        console.assert(0);
    }
};
