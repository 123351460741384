// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyViewSuggestionsPage_title__J7HBF {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.PropertyViewSuggestionsPage_buttonContent__0tjR2 {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    /* padding: 0px 20px 0px 20px; */\n\n    border-radius: 5px;\n    gap: 10px;\n\n    padding: 15px 30px;\n    border: 0;\n    font-size: 1rem;\n    cursor: pointer;\n}\n\n.PropertyViewSuggestionsPage_pageWrap__GZJW2 {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 6px 0px;\n}\n\n.PropertyViewSuggestionsPage_formContainer__hDNXX {\n    display: flex;\n    width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/SuggestionPages/PropertyViewSuggestionsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,gCAAgC;;IAEhC,kBAAkB;IAClB,SAAS;;IAET,kBAAkB;IAClB,SAAS;IACT,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":[".title {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.buttonContent {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    /* padding: 0px 20px 0px 20px; */\n\n    border-radius: 5px;\n    gap: 10px;\n\n    padding: 15px 30px;\n    border: 0;\n    font-size: 1rem;\n    cursor: pointer;\n}\n\n.pageWrap {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 6px 0px;\n}\n\n.formContainer {\n    display: flex;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "PropertyViewSuggestionsPage_title__J7HBF",
	"buttonContent": "PropertyViewSuggestionsPage_buttonContent__0tjR2",
	"pageWrap": "PropertyViewSuggestionsPage_pageWrap__GZJW2",
	"formContainer": "PropertyViewSuggestionsPage_formContainer__hDNXX"
};
export default ___CSS_LOADER_EXPORT___;
