import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('propertyViewForm'),
        showTitle: false,
        name: 'propertyViewForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Γενικές Πληροφορίες'),
                name: 'generalInfo',
                type: 'label',
                isRequired: false,
                space: '100%',
                choices: []
            },
            {
                title: i18next.t('Όνομα Ανακαίνισης'),
                name: 'name',
                type: 'text',
                isRequired: false,
                space: '100%',
                choices: []
            },
            {
                title: i18next.t('Ημερομηνία Έναρξης'),
                name: 'dateFrom',
                type: 'date',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Ημερομηνία Λήξης (Πιθανή)'),
                name: 'dateTo',
                type: 'date',
                isRequired: false,
                space: '50%',
                choices: []
            },
            {
                title: i18next.t('Υπεύθυνος Ανακαίνισης'),
                name: 'responsiblePerson',
                type: 'label',
                isRequired: false,
                space: '100%',
                choices: []
            },
            {
                title: i18next.t('Ονοματεπώνυμο Υπευθύνου'),
                name: 'nameResponsiblePerson',
                type: 'text',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('ΑΦΜ'),
                name: 'AFMReponsiblePerson',
                type: 'number',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Κόστος'),
                name: 'price',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Προϋπολογισμός'),
                name: 'budget',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εκτιμώμενο Κόστος'),
                name: 'estimatedCost',
                type: 'float',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Περιγραφή Ανακαίνισης'),
                name: 'descriptionLabel',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Περιγραφή'),
                name: 'description',
                type: 'text',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Παρακολούθηση Εργου'),
                name: 'trackRepairs',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'comemntsDiv',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'comments',
                type: 'text',
                isRequired: false,
                space: '50%'
            }
        ]
    };
};

export default getDescription;
