import React, { useEffect, useState } from 'react';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import i18next from 'i18next';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const generateColors = number => {
    const colors = [];
    for (let i = 0; i < number; i++) {
        colors.push(`rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255})`);
    }
    return colors;
};

const labels = [
    { type: i18next.t('Λειτουργικά έξοδα'), value: Math.floor(Math.random() * 188) },
    { type: i18next.t('Ρεύμα'), value: Math.floor(Math.random() * 188) },
    { type: i18next.t('Νερό'), value: Math.floor(Math.random() * 188) },
    { type: i18next.t('Κοινόχρηστα'), value: Math.floor(Math.random() * 188) },
    { type: i18next.t('Λοιπά'), value: Math.floor(Math.random() * 188) }
];

export const ExpensesSubPage = () => {
    const [expensesData, setExpensesData] = useState(null);

    useEffect(() => {
        // requests.getMyExpenses...
        const data = {
            labels: labels.map(label => label.type),
            datasets: [
                {
                    label: i18next.t('Έξοδα'),
                    data: labels.map(label => label.value),
                    backgroundColor: generateColors(labels.length),
                    borderWidth: 1
                }
            ]
        };
        setExpensesData(data);
    }, []);

    if (!expensesData) return null;

    return (
        <div style={{ maxWidth: '1000px', width: '50%' }}>
            <Doughnut
                options={{
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right',
                            align: 'center'
                        }
                    }
                }}
                data={expensesData}
            />
        </div>
    );
};
