import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiOutlineTeam } from 'react-icons/ai';
import { BsInfoCircle, BsOption, BsPieChart } from 'react-icons/bs';
import { IoMdPulse } from 'react-icons/io';
import { MdOutlineMapsHomeWork } from 'react-icons/md';

import { useUser } from '../../context/withUser';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { ProfileInfo } from '../../components/profileInfo/ProfileInfo';

import requests, { URI } from '../../utils/requests';
import { BsListTask } from 'react-icons/bs';
import { UserActivityPage } from '../userProfilePages/UserActivityPage';
import { UserInfoPage } from '../userProfilePages/UserInfoPage';
import { UserOptionsPage } from '../userProfilePages/UserOptionsPage';
import UserOverviewPage from '../userProfilePages/UserStatisticsPage';
import { UserPropertyPage } from '../userProfilePages/UserPropertyPage';
import { UserTeamsPage } from '../userProfilePages/UserTeamsPage';

import { SidebarPage } from './SidebarPage';

import styles from './UserProfilePage.module.css';
import UserStatisticsPage from '../userProfilePages/UserStatisticsPage';
import { UserStatisticExpensesPage } from '../userProfilePages/statisticsPages/UserStatisticExpenses';
import { UserStatisticEstimatesPage } from '../userProfilePages/statisticsPages/UserStatisticEstimates';
import { UserStatisticLeasesPage } from '../userProfilePages/statisticsPages/UserStatisticLeases';
import { UserStatisticRenovationsPage } from '../userProfilePages/statisticsPages/UserStatisticRenovations';
import TaskManagementPage from '../taskManagementPages/TaskManagementPage';

// --------------------------------------------------------------------------------------

export const UserProfilePage = () => {
    const params = useParams();

    const { t } = useTranslation();
    const { user } = useUser();
    const paramsUserId = params.userId;
    const [visitedUser, setVisitedUser] = useState(null);
    const [permissions, setPermissions] = useState(null);

    /**
     * set manually permissions
     */

    const fetchAndSet = useCallback(() => {
        requests
            .getUser(paramsUserId)
            .then(({ data: user }) => {
                setVisitedUser(user);
            })
            .catch(console.log);

        setPermissions({
            canPreviewActivity: user.type === 'ADMIN' ? true : false,
            canPreviewTeams: user.type === 'ADMIN' ? true : false,
            canPreviewOther: user.type === 'ADMIN' ? true : false,
            canDisable: user.type === 'ADMIN' ? true : false,
            canAccessOptions: user.type === 'ADMIN' ? true : false, //canEdit only if canAccessOptions
            canEditProfile: user.type === 'ADMIN' ? true : false
        });
    }, [paramsUserId, user]);

    useEffect(() => {
        fetchAndSet();
    }, [fetchAndSet]);

    if (!permissions || !visitedUser) {
        return <LoaderWrapper />;
    }

    const links = [
        permissions.canAccessOptions && {
            path: 'info',
            title: t('Information'),
            element: (
                <UserOptionsPage
                    user={visitedUser}
                    permissions={permissions}
                    updateUserData={({ ...data }) => {
                        setVisitedUser({ ...visitedUser, ...data });
                    }}
                />
            ),
            Icon: BsOption
        },
        permissions.canPreviewTeams && {
            path: 'teams',
            title: t('Teams'),
            element: <UserTeamsPage permissions={permissions} />,
            Icon: AiOutlineTeam
        },
        permissions.canPreviewTeams && {
            path: 'tasks',
            title: t('my-task'),
            element: <TaskManagementPage inUser={true} />,
            Icon: BsListTask
        },
        {
            path: 'statistics',
            title: t('statistics'),
            element: <UserStatisticsPage permissions={permissions} />,
            Icon: BsPieChart
        },
        permissions.canPreviewActivity && {
            path: 'activity',
            title: t('Activity'),
            element: <UserActivityPage permissions={permissions} />,
            Icon: IoMdPulse
        },
        {
            path: 'statistics/expenses',
            element: <UserStatisticExpensesPage />,
            UserhasNestedRoutes: true
        },
        {
            path: 'statistics/estimates',
            element: <UserStatisticEstimatesPage />,
            hasNestedRs: true
        },
        {
            path: 'statistics/leases',
            element: <UserStatisticLeasesPage />,
            hasNestedRoutes: true
        },
        // {
        //     path: 'overview/digital-folder',
        //     element: <div>user Folder</div>,
        //     hasNestedRoutes: true
        // },
        // {
        //     path: 'overview/suggestions',
        //     element: <UserOverviewSuggestionsPage />,
        //     hasNestedRoutes: true
        // },
        {
            path: 'statistics/renovations',
            element: <UserStatisticRenovationsPage />,
            hasNestedRoutes: true
        }

        // {
        //     path: 'overview/ripening-actions',
        //     element: <div>user Ripening Actions</div>,
        //     hasNestedRoutes: true
        // },
        // {
        //     path: 'overview/smart-building',
        //     element: <UserOverviewSmartFeaturesPage />,
        //     hasNestedRoutes: true
        // },
        // {
        //     path: 'overview/statistics',
        //     element: <div> user Statistics</div>,
        //     hasNestedRoutes: true
        // },
    ];

    if (!visitedUser) return <LoaderWrapper></LoaderWrapper>;

    return (
        <SidebarPage options={links}>
            <div className={styles.userInfo}>
                {visitedUser ? (
                    <ProfileInfo
                        name={visitedUser.name}
                        subName={visitedUser.email}
                        imageSrc={visitedUser.image && `${URI}/${visitedUser.image.path}`}
                    />
                ) : (
                    <ProfileInfo name={''} subName={''} />
                )}
            </div>
        </SidebarPage>
    );
};
