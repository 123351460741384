import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('expensesForm'),
        showTitle: false,
        name: 'expensesForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Γενικές Πληροφορίες'),
                name: 'generalInfo',
                type: 'label',
                isRequired: false,
                space: '100%',
                choices: []
            },
            {
                title: i18next.t('Ονομασία'),
                name: 'nameOfExpense',
                type: 'text',
                isRequired: true,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Συχνότητα'),
                name: 'periodOfExpense',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Ημερομηνία Έναρξης'),
                name: 'dateFrom',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Ημερομηνία Λήξης (Πιθανή)'),
                name: 'dateTo',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Κόστος'),
                name: 'price',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Ποσό'),
                name: 'valueOfPayment',
                type: 'float',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsFloat: true
                }
            },
            {
                title: i18next.t('Τρόπος Πληρωμής'),
                name: 'wayOfPayment',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: [
                    {
                        title: i18next.t('cash'),
                        value: 'cash'
                    },
                    {
                        title: i18next.t('credit-card'),
                        value: 'credit-card'
                    }
                ]
            },
            {
                title: i18next.t('Ημερομηνία Εξόφλησης'),
                name: 'dateOfPayment',
                type: 'date',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Εξοφλημένο'),
                name: 'isPaid',
                type: 'checkbox',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Ειδοποιητήριο Πληρωμής'),
                name: 'paymentFileLabel',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Ειδοποιητήριο Πληρωμής'),
                name: 'paymentNotifFile',
                type: 'file',
                isRequired: false,
                space: '100%',
                maxFiles: 1
            },
            {
                title: i18next.t('Εξοφλητήρια Απόδειξη'),
                name: 'paymentFileLabel',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Εξοφλητήρια Απόδειξη'),
                name: 'paymentReceiptFile',
                type: 'file',
                isRequired: false,
                space: '100%',
                maxFiles: 1
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'comemntsDiv',
                type: 'label',
                isRequired: false,
                space: '100%'
            },
            {
                title: i18next.t('Παρατηρήσεις'),
                name: 'comments',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 500
                }
            }
        ]
    };
};

export default getDescription;
