import { useRef } from 'react';

import i18next from 'i18next';
import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { FiMapPin } from 'react-icons/fi';
import { HiOfficeBuilding } from 'react-icons/hi';
import { MdOutlineGrid4X4 } from 'react-icons/md';
import { components } from 'react-select';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';

import { onInputChangeAutocompleteCities } from '../../utils/autoCompleteCities';
import Utils from '../../utils/utils';

import styles from './SearchPropertyForm.module.css';

// ---------------------------------------------------------------------------------------

const defaultSubcategories = {
    // building: 'apartment',
    building: 'workspace',
    plot: 'plotOfLand'
};

// ---------------------------------------------------------------------------------------

export const SearchPropertyForm = props => {
    const { t } = useTranslation();
    const formRef = useRef(null);

    const description = {
        name: 'searchPropertyForm',
        questions: [
            {
                name: 'category',
                title: t('Property-Category'),
                type: 'unfolded-dropdown',
                dataType: 'array',
                space: '50%',
                value: 'building',
                isRequired: true,
                bindQuestions: {
                    building: ['buildingSubcategory'],
                    plot: ['plotSubcategory']
                },
                choices: [
                    {
                        title: <Building />,
                        value: 'building'
                    },
                    {
                        title: <Plot />,
                        value: 'plot'
                    }
                ]
            },
            {
                name: 'buildingSubcategory',
                title: t('Property-SubCategory'),
                type: 'dropdown',
                space: '50%',
                isRequired: true,
                value: 'workspace',
                choices: [
                    {
                        title: t('Επαγγελματική Στέγη'),
                        value: 'workspace'
                    },
                    {
                        title: t('apartment'),
                        value: 'apartment'
                    },
                    {
                        title: t('Λοιπά Κτίρια'),
                        value: 'otherSpace'
                    },
                    {
                        title: t('Σταθμοί Αυτοκινήτων Δημόσιας Χρήσης'),
                        value: 'publicParking'
                    },
                    {
                        title: t('Πολυκατοικία'),
                        value: 'blockOfFlats'
                    },
                    {
                        title: t('Μεζονέτα'),
                        value: 'maisonette'
                    },
                    {
                        title: t('Μονοκατοικία'),
                        value: 'house'
                    },
                    {
                        title: t('Ειδικό Κτίριο Γεωργικής Χρήσης'),
                        value: 'agricalturalUse'
                    },
                    {
                        title: t('Ειδικό Κτίριο Κτηνοτροφικής Χρήσης'),
                        value: 'stockFarmingUse'
                    },
                    {
                        title: t('Θέσεις Στάθμευσης'),
                        value: 'parking'
                    },
                    {
                        title: t('Βιομηχανικά - Βιοτεχνικά Κτίρια'),
                        value: 'industrial'
                    },
                    {
                        title: t('Εκπαιδευτήρια'),
                        value: 'school'
                    },
                    {
                        title: t('Αθλητικές Εγκαταστάσεις'),
                        value: 'sportFacility'
                    },
                    {
                        title: t('Τουριστικές Εγκαταστάσεις / Νοσηλευτήρια / Ευαγή'),
                        value: 'touristAndHospital'
                    }
                ]
            },
            {
                name: 'plotSubcategory',
                title: t('Υποκατηγορία'),
                type: 'dropdown',
                space: '50%',
                isRequired: true,
                value: 'plotOfLand',
                choices: [
                    {
                        title: t('Οικόπεδο'),
                        value: 'plotOfLand'
                    },
                    {
                        title: t('Αγροτεμάχιο'),
                        value: 'parcelOfLand'
                    }
                ]
            },
            {
                name: 'area',
                title: t('area'),
                type: 'multipleChoice',
                components: { DropdownIndicator },
                choices: [],
                onInputChange: onInputChangeAutocompleteCities,
                debounceDuration: 300,
                minInputLengthToFireOnInputChange: 3
            }
        ]
    };

    function setDefaultSubcategory(category) {
        console.log(category);
        const key = `${category}Subcategory`;
        const value = defaultSubcategories[category];
        formRef.current?.setData({
            [key]: value
        });
    }

    function onChange(path, value) {
        if (path === 'searchPropertyForm/category') {
            console.log(value);
            setDefaultSubcategory(value);
        }
    }

    function onSubmit() {
        const data = formRef.current?.getData();
        if (!data) return;
        data.subcategory = data.buildingSubcategory || data.plotSubcategory;
        delete data.buildingSubcategory;
        delete data.plotSubcategory;
        props.onSubmit?.(data);
    }

    return (
        <div
            style={props.style}
            className={Utils.classes(styles.searchPropertyForm, props.className)}
        >
            <h1>{t('search-property')}</h1>
            <Form ref={formRef} onChange={onChange} description={description} />
            <ButtonWithLoading className={`btn btn-primary-0`} onClick={onSubmit}>
                {t('Search')}
            </ButtonWithLoading>
        </div>
    );
};

// ---------------------------------------------------------------------------------------

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <FiMapPin />
        </components.DropdownIndicator>
    );
};

// ---------------------------------------------------------------------------------------

const OptionTitleWithIcon = ({ title, icon }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '.3rem'
            }}
        >
            {icon}
            {title}
        </div>
    );
};

const Building = () => (
    <OptionTitleWithIcon title={i18next.t('building')} icon={<HiOfficeBuilding />} />
);
const Plot = () => <OptionTitleWithIcon title={i18next.t('Γη')} icon={<MdOutlineGrid4X4 />} />;
