// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar_avatarWrapper__Yj1wE {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n    font-size: var(--font-2);\n    color: aliceblue;\n    background-color: black;\n}\n.Avatar_wrap__uI1ds {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n.Avatar_image__mgqIJ {\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n}\n.Avatar_imageDefault__9qBjd {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n    background-color: black;\n    color: white;\n    font-size: var(--font-2);\n}\n", "",{"version":3,"sources":["webpack://./src/components/profile/Avatar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,wBAAwB;AAC5B","sourcesContent":[".avatarWrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n    font-size: var(--font-2);\n    color: aliceblue;\n    background-color: black;\n}\n.wrap {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: nowrap;\n    justify-content: center;\n}\n.image {\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n}\n.imageDefault {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100px;\n    height: 100px;\n    border-radius: 100%;\n    background-color: black;\n    color: white;\n    font-size: var(--font-2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarWrapper": "Avatar_avatarWrapper__Yj1wE",
	"wrap": "Avatar_wrap__uI1ds",
	"image": "Avatar_image__mgqIJ",
	"imageDefault": "Avatar_imageDefault__9qBjd"
};
export default ___CSS_LOADER_EXPORT___;
