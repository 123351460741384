import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { AiOutlineDashboard, AiOutlineHeart } from 'react-icons/ai';
import { BiArea } from 'react-icons/bi';
import { BiHide } from 'react-icons/bi';
import { GrSteps } from 'react-icons/gr';
import { IoConstructOutline } from 'react-icons/io5';
import { MdOutlineStairs } from 'react-icons/md';
import { VscTools } from 'react-icons/vsc';

import requests from '../../utils/requests';
import { URI } from '../../utils/requests';

import { SmallPreviewNameImage } from '../smallPreviewNameImage/SmallPreviewNameImage';

import propertyImage from '../../assets/images/AlphaLogo.png';

import styles from './PropertyCard.module.css';

const SIZE_ICON = 20;

function validateDescriptionLength(description) {
    const maxLength = 100;
    const currentLength = description?.length;

    if (currentLength >= maxLength) {
        return description.slice(0, maxLength) + '...';
    }

    return description;
}

const PropertyCard = ({ property, path, guestView }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [teamFull, setTeamFull] = useState(null);
    const [imageExists, setImageExists] = useState(true);

    useEffect(() => {
        if (!property) {
            return;
        }
        requests.getTeamFull(property.teamId).then(({ data }) => setTeamFull(data));
    }, [property]);

    const labelFloor = floor => {
        const floorNumber = floor;
        let floorLabel;
        if (floorNumber === null) {
            floorLabel = '-';
        } else if (floorNumber === 0) {
            floorLabel = t('Ground');
        } else {
            const absFloorNumber = Math.abs(floorNumber);
            const lastDigit = absFloorNumber % 10;
            const isTeen = absFloorNumber % 100 >= 11 && absFloorNumber % 100 <= 13;
            if (lastDigit === 1 && !isTeen) {
                floorLabel = `${floorNumber}${t('st')}`;
            } else if (lastDigit === 2 && !isTeen) {
                floorLabel = `${floorNumber}${t('nd')}`;
            } else if (lastDigit === 3 && !isTeen) {
                floorLabel = `${floorNumber}${t('rd')}`;
            } else {
                floorLabel = `${floorNumber}${t('th')}`;
            }
        }
        return floorLabel;
    };
    return (
        <div className={styles.mainDiv}>
            <div className={styles.informationSection}>
                <div className={styles.propertyGeneralInfo}>
                    <div className={styles.colDiv}>
                        <Link
                            className={styles.name}
                            to={`${path}/${property.propertyId}${!guestView ? '/info' : ''}`}
                        >
                            {property?.name ? property?.name : t('not-available-name')}
                        </Link>
                        <div className={styles.propertyId}>
                            {'APC: '}
                            {property?.propertyId || '-'}
                        </div>
                        <div className={styles.address}>
                            {property?.street + ' ' + property?.streetNumber || '-'}
                        </div>
                    </div>
                    <div className={styles.price}>
                        {property?.price ? property?.price + '€' : ''}
                    </div>
                </div>
                <div className={styles.descriptionDiv}>
                    {validateDescriptionLength(property?.description)}
                </div>
                {(property?.pType === 'buildingPart' || property?.pType === 'buildingSingle') && (
                    <div className={styles.characteristicsDiv}>
                        <div className={styles.characteristics} title={t('floor')}>
                            <MdOutlineStairs className={styles.icon} size={SIZE_ICON} />
                            {labelFloor(property?.floor)}
                        </div>
                        <div className={styles.characteristics} title={t('yearOfConstruction')}>
                            <IoConstructOutline className={styles.icon} size={SIZE_ICON} />
                            {(property?.yearOfConstruction === null && '-') ||
                                property?.yearOfConstruction + ' ' + t('year') ||
                                '-'}
                        </div>
                        <div className={styles.characteristics} title={t('areaSX')}>
                            <BiArea className={styles.icon} size={SIZE_ICON} />
                            <div className={styles.cm2}>{`${
                                ((property?.areaSqM === undefined || property?.areaSqM === null) &&
                                    '- ' + t('㎡')) ||
                                property?.areaSqM + ' ' + `${t('㎡')}`
                            } `}</div>
                        </div>
                    </div>
                )}

                {property?.pType === 'buildingMulti' &&
                    property?.type === 'BUILDING' &&
                    property?.buildingMulti?.blockOfFlat && (
                        <div className={styles.characteristicsDiv}>
                            <div className={styles.characteristics} title={t('Floor')}>
                                <MdOutlineStairs className={styles.icon} size={SIZE_ICON} />
                                {labelFloor(property?.floor) || '-'}
                            </div>
                            <div
                                className={styles.characteristics}
                                title={t('Year Of Construction')}
                            >
                                <IoConstructOutline className={styles.icon} size={SIZE_ICON} />
                                {(property?.yearOfConstruction === null && '-') ||
                                    property?.yearOfConstruction + ' ' + t('year') ||
                                    '-'}
                            </div>
                            <div className={styles.characteristics} title={t('Area')}>
                                <BiArea className={styles.icon} size={SIZE_ICON} />
                                <div className={styles.cm2}>{`${
                                    (property?.areaSqM === null && '-') ||
                                    property?.areaSqM + ' ' + `${t('㎡')}`
                                } `}</div>
                            </div>
                        </div>
                    )}

                {property?.type === 'LAND' && (
                    <div className={styles.characteristicsDiv}>
                        <div className={styles.characteristics} title={t('Area')}>
                            <BiArea className={styles.icon} size={SIZE_ICON} />
                            {`${
                                (property?.areaSqM === null && '-') ||
                                (property?.areaSqM === undefined && '- ' + `${t('㎡')}`) ||
                                property?.areaSqM + ' ' + `${t('㎡')}`
                            } `}
                        </div>
                    </div>
                )}

                <div className={styles.features} style={{ display: 'flex' }}>
                    <div className={styles.iconWrapper}>
                        <div title={t('Hide')}>
                            <BiHide size={30} className={styles.icon} />
                        </div>
                        <div title={t('Favorite')}>
                            <AiOutlineHeart size={30} className={styles.icon} />
                        </div>
                    </div>
                    <abbr>
                        <SmallPreviewNameImage
                            imageSrc={`${URI}/${teamFull?.image?.path}`}
                            showName={false}
                            fullname={teamFull?.name}
                            onClick={() => navigate(`../teams/${property.teamId}/info`)}
                        />
                    </abbr>
                </div>
            </div>
            <div className={styles.imageSection}>
                {imageExists && (
                    <img
                        src={`${URI}/${property.mainImagePath}`}
                        alt={`${property.name}_${property.id}`}
                        className={styles.image}
                        onError={() => setImageExists(false)}
                        onClick={() =>
                            navigate(`${path}/${property.propertyId}${!guestView ? '/info' : ''}`)
                        }
                    />
                )}
                {!imageExists && (
                    <img
                        src={propertyImage}
                        style={{ objectFit: 'scale-down' }}
                        className={styles.image}
                        alt={
                            property.name ||
                            (property.building && property.building.name) ||
                            (property.plot && property.plot.name)
                        }
                        onClick={() =>
                            navigate(`${path}/${property.propertyId}${!guestView ? '/info' : ''}`)
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default PropertyCard;
