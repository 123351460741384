import React, { useState } from 'react';

import { LoaderWrapper } from '../loaderWrapper/LoaderWrapper';

import styles from './Tabs.module.css';

// --------------------------------------------------------------------------------------

const Tab = ({ title, onClick, selected }) => {
    const getClasses = () => {
        const classes = [styles.tab];

        selected && classes.push(styles.selected);
        !selected && classes.push('notSelectedTab');

        return classes.join(' ');
    };

    return (
        <div className={getClasses()} onClick={onClick}>
            {title}
        </div>
    );
};

export const Tabs = ({ tabs }) => {
    const [currTab, setCurrTab] = useState(0);

    return (
        <div className={styles.container}>
            <div className={styles.tabs}>
                {tabs.map((tab, i) => (
                    <Tab
                        key={i}
                        title={tab.title}
                        selected={currTab === i}
                        onClick={() => setCurrTab(i)}
                    />
                ))}
            </div>
            <div className={styles.content}>
                <LoaderWrapper size={50} thickness={8}>
                    {tabs[currTab].content}
                </LoaderWrapper>
            </div>
        </div>
    );
};
