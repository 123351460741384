import i18next from 'i18next';

const getDescription = () => {
    const getPrevYears = yearsToShow => {
        const d = new Date();
        const years = [];

        for (let i = 0; i < yearsToShow; ++i) {
            const currYear = d.getFullYear();
            years.push({
                title: currYear,
                value: currYear
            });
            d.setFullYear(currYear - 1);
        }
        return years;
    };

    return {
        title: i18next.t('propertySuggestionsForm'),
        showTitle: false,
        name: 'propertySuggestionsForm',
        type: 'form',
        autoRetrievedFields: [],
        questions: [
            {
                title: i18next.t('Τεχνικός Έλεγχος'),
                name: 'techicalCheck',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Φωτογραφική Αποτύπωση'),
                name: 'images',
                type: 'file',
                isRequired: false
            },
            {
                title: i18next.t('Έλεγχος για Αυθαιρεσίες & Προτάσεις'),
                name: 'check',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Κόστος Αυθαιρεσιών για τακτοποίηση'),
                name: 'cost',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Όροι Δόμησης'),
                name: 'constractionRules',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Αρτιότητα'),
                name: 'excellence',
                type: 'checkbox',
                isRequired: false
            },
            {
                title: i18next.t('Συντελεστής Κάλυψης'),
                name: 'coverageFactor',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Συντελεστής Δόμησης'),
                name: 'structureFactor',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Θέση Δόμησης Κτιρίου'),
                name: 'constructionPosition',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Μέγιστο Ύψος'),
                name: 'maxHeight',
                type: 'number',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsInteger: true
                }
            },
            {
                title: i18next.t('Συσχετισμοί και Ελλείψεις'),
                name: 'correlationsDeficiencies',
                type: 'label',
                isRequired: false
            },
            {
                title: i18next.t('Συσχετισμός Σχεδίων Ο-Α με Αυτοψία'),
                name: 'oaCorrelation',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Συσχετισμός Τίτλων με Αυτοψία'),
                name: 'titleCorrelation',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Ελλείψεις τίτλων'),
                name: 'missingTitles',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Ελλείψεις σχεδίων'),
                name: 'missingPlans',
                type: 'freetext',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 1000
                }
            },
            {
                title: i18next.t('Αυτοψία'),
                name: 'autopsy',
                type: 'label',
                isRequired: false,
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Τύπος Θέρμανσης'),
                name: 'heatingType',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Τύπος Ανελκυστήρα'),
                name: 'elevatorType',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Επίπεδο Συντήρησης'),
                name: 'maintenanceLevel',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Έτος Ανακαίνισης'),
                name: 'yearOfRenovation',
                type: 'dropdown',
                isRequired: false,
                space: '50%',
                choices: getPrevYears(20)
            },
            {
                title: i18next.t('Φάση Κατασκευής'),
                name: 'constractionPhase',
                type: 'text',
                isRequired: false,
                space: '50%'
            },
            {
                title: i18next.t('Ποιότητα Κατασκευής'),
                name: 'constractionQuality',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Εξωτερική Κατασκευή'),
                name: 'outConstraction',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Εσωτερική Κατασκευή'),
                name: 'inConstraction',
                type: 'text',
                isRequired: false,
                space: '50%',
                restrictions: {
                    validateFieldsName: 100
                }
            },
            {
                title: i18next.t('Κατοικείται'),
                name: 'isInhabited',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Αξιοποιείται'),
                name: 'isUsed',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Ηλεκτροδοτείται'),
                name: 'isElectrified',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Υδροδοτείται'),
                name: 'hydrated',
                type: 'checkbox',
                isRequired: false,
                space: '25%'
            },
            {
                title: i18next.t('Περιγραφή Κτιρίου'),
                name: 'description',
                type: 'freetext',
                isRequired: false,
                restrictions: {
                    validateFieldsName: 1000
                }
            }
        ]
    };
};

export default getDescription;
