import i18next from 'i18next';

const getDescription = () => {
    return {
        title: i18next.t('Create-Account'),
        promptForSubmit: 'Επιβεβαίωση',
        questions: [
            {
                title: i18next.t('Name'),
                name: 'name',
                type: 'text',
                isRequired: true,
                restrictions: {
                    minChars: 3,
                    maxChars: 255
                }
            },
            {
                title: i18next.t('Email'),
                name: 'email',
                type: 'text',
                isRequired: true,
                restrictions: {
                    minChars: 5,
                    maxChars: 255,
                    validateEmail: true
                }
            },
            {
                title: i18next.t('Password'),
                name: 'password',
                isPassword: true,
                type: 'text',
                isRequired: true,
                restrictions: {
                    validatePasswordHasSpaces: true,
                    validatePassword: true
                }
            },
            {
                title: i18next.t('Confirm-Password'),
                name: 'passwordVerify',
                isPassword: true,
                type: 'text',
                isRequired: true,
                restrictions: {
                    validatePassword: true,
                    validatePasswordHasSpaces: true,
                    validateConfirmationPassword: true
                }
            }
            // {
            //     title: i18next.t('User-Type'),
            //     name: 'type',
            //     type: 'dropdown',
            //     isRequired: true,
            //     choices: [
            //         {
            //             value: 'ADMIN',
            //             title: i18next.t('admin')
            //         },
            //         {
            //             value: 'MEMBER',
            //             title: i18next.t('member')
            //         }
            //     ]
            // }
        ]
    };
};

export default getDescription;
