import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { DropdownFilter, Table } from 'table';

import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import styles from './RecentActivity.module.css';

export const RecentActivity = () => {
    const params = useParams();
    const { t } = useTranslation();
    const teamId = params.teamId;
    const navigate = useNavigate();

    const [activity, setActivity] = useState(null);

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                accessor: 'user',
                Header: t('User'),
                Cell: ({ value: user }) => {
                    if (!user) return null;

                    return (
                        <SmallPreviewNameImage
                            imageSrc={user.image && `${URI}/${user.image.path}`}
                            fullname={user.name}
                            onClick={() => navigate(`../../users/${user.email}/info`)}
                        />
                    );
                }
            },
            {
                Header: t('Date'),
                accessor: 'date'
            },
            {
                accessor: 'type',
                Header: t('Type'),
                Filter: DropdownFilter
            },
            {
                accessor: 'description',
                Header: t('Description')
            }
        ],
        [navigate, t]
    );

    function transformLog(log) {
        return {
            user: log.user,
            date: `${Utils.timestampToHumanizedDate(
                log.createdAt
            )} - ${Utils.timestampToHumanizedTime(log.createdAt)}`,
            type: t(log.type),
            description: t(log.description)
        };
    }

    const fetchAndSetData = useCallback(async () => {
        const { data } = await requests.getTeamActivity(teamId);
        setActivity(data.map(transformLog));
    }, [teamId]);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    return (
        <div className={styles.pageWrap}>
            <div className={styles.header}>
                <Header title={t('Activity')} />
            </div>
            <LoaderWrapper>
                {activity && (
                    <Table pagination={{ pageSize: 10 }} columns={TABLE_COLUMNS} data={activity} />
                )}
            </LoaderWrapper>
        </div>
    );
};
