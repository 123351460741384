import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Header } from '../../../components/header/Header';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import styles from './TeamStatistics.module.css';
import { PlotLeasesByAttributeType } from '../../../components/statistics/leases/PlotLeasesByAttributeType';
import { PlotByPlotBuildingMonth } from '../../../components/statistics/PlotByPlotBuildingMonth';

export const TeamStatisticLeases = () => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [buildingData, setBuildingData] = useState(null);
    const [plotData, setPlotData] = useState(null);
    let { teamId: id } = useParams();
    if (id === undefined) {
        id = 3;
    }
    useEffect(() => {
        requests
            .getLeasesByMonth(id)
            .then(({ data }) => setData(curr => ({ ...curr, leasesByMonth: data })));
        requests
            .getLeasesByPaymentType(id)
            .then(({ data }) => setData(curr => ({ ...curr, leasesByPaymentType: data })));
        requests
            .getLeasesByTypeOfProperty(id)
            .then(({ data }) => setData(curr => ({ ...curr, getLeasesByTypeOfProperty: data })));

        requests.getLeaseByPlot(id).then(({ data }) => setPlotData(data));
        requests.getLeaseByBuilding(id).then(({ data }) => setBuildingData(data));
    }, [id]);

    if (buildingData === null || plotData === null || data === null) return <LoaderWrapper />;

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <Header title={t('Leases-Statistics')} />
            </div>
            <div className={styles.wrap}>
                <div className={styles.chartsRow}>
                    <div className={styles.chartWrap}>
                        <h3>{t('New Leases per property type, this year')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotByPlotBuildingMonth
                                    data={data.leasesByMonth}
                                    requestedYear={new Date().getFullYear()}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Leases per Payment type')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='doughnut'
                                    data={data.leasesByPaymentType}
                                    attributeType={'count'}
                                    labelType={'paymentType'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Leases per Property type')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='doughnut'
                                    data={data.getLeasesByTypeOfProperty}
                                    attributeType={'count'}
                                    labelType={'type'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Lease per Building')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={buildingData.map(l => ({
                                        name: l.name,
                                        value: l.leasedProperty
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Lease per sqr/m by Building')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={buildingData.map(l => ({
                                        name: l.name,
                                        value: l.leasedPropertyPerSM
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease performance per Building')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={buildingData.map(l => ({
                                        name: l.name,
                                        value: l.leasePerformance
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease adjustable per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={buildingData.map(l => ({
                                        name: l.name,
                                        value: l.leaseAdjustable
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease payment per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={buildingData.map(l => ({
                                        name: l.name,
                                        value: l.leasePayment
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={plotData.map(l => ({
                                        name: l.name,
                                        value: l.leasedProperty
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease per sqr/m by Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={plotData.map(l => ({
                                        name: l.name,
                                        value: l.leasedPropertyPerSM
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>

                    <div className={styles.chartWrap}>
                        <h3>{t('Lease performance per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={plotData.map(l => ({
                                        name: l.name,
                                        value: l.leasePerformance
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease adjustable per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={plotData.map(l => ({
                                        name: l.name,
                                        value: l.leaseAdjustable
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                    <div className={styles.chartWrap}>
                        <h3>{t('Lease payment per Plot')}</h3>
                        <div className={styles.standardContainer}>
                            <LoaderWrapper>
                                <PlotLeasesByAttributeType
                                    type='bar'
                                    data={plotData.map(l => ({
                                        name: l.name,
                                        value: l.leasePayment
                                    }))}
                                    attributeType={'value'}
                                    labelType={'name'}
                                />
                            </LoaderWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
