// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertySmartFeaturePage_pageWrapper__SxiBT {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.PropertySmartFeaturePage_actionBtnsContainer__Znqnc {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-start;\n}\n.PropertySmartFeaturePage_actionBtn__-xMkf {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n\n.PropertySmartFeaturePage_footerButtons__sUqFb {\n    display: flex;\n\n    justify-content: flex-end;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/smartFeaturePages/PropertySmartFeaturePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,WAAW;;IAEX,aAAa;IACb,2BAA2B;AAC/B;AACA;IACI,SAAS;;IAET,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,aAAa;;IAEb,yBAAyB;AAC7B","sourcesContent":[".pageWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    display: flex;\n    justify-content: flex-start;\n}\n.actionBtn {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n\n.footerButtons {\n    display: flex;\n\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrapper": "PropertySmartFeaturePage_pageWrapper__SxiBT",
	"actionBtnsContainer": "PropertySmartFeaturePage_actionBtnsContainer__Znqnc",
	"actionBtn": "PropertySmartFeaturePage_actionBtn__-xMkf",
	"footerButtons": "PropertySmartFeaturePage_footerButtons__sUqFb"
};
export default ___CSS_LOADER_EXPORT___;
