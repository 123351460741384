const Utils = {};

Utils.getUniqueBuildingIDs = data => {
    const uniqueBuildings = {};

    for (const exp of data) {
        if (uniqueBuildings[exp.buildingId]) continue;
        uniqueBuildings[exp.buildingId] = {
            buildingId: exp.buildingId,
            buildingName: exp.buildingName || exp.building.name //FIXME
        };
    }

    return Object.values(uniqueBuildings);
};
module.exports = Utils;
