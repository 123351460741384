import { BsXCircleFill } from 'react-icons/bs';

import styles from './ErrorMessage.module.css';

export const ErrorMessage = ({ children }) => {
    return (
        <div className={styles.container}>
            <BsXCircleFill className={styles.icon} />
            <span className={styles.message}>{children}</span>
        </div>
    );
};
