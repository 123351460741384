// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PropertyEditLandPage_pageWrap__yekLX {\n    display: flex;\n    flex-direction: column;\n}\n\n.PropertyEditLandPage_actionBtnsContainer__1fHBs {\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    justify-content: flex-end;\n}\n.PropertyEditLandPage_pageTitle__RIAOz {\n    margin-bottom: 20px;\n}\n.PropertyEditLandPage_actionBtn__Fbf5d {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n\n.PropertyEditLandPage_actionBtnContent__ZSvuI {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/propertyPages/propertyEditPages/PropertyEditLandPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;;IAEX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,yBAAyB;AAC7B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,SAAS;;IAET,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".pageWrap {\n    display: flex;\n    flex-direction: column;\n}\n\n.actionBtnsContainer {\n    width: 100%;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    justify-content: flex-end;\n}\n.pageTitle {\n    margin-bottom: 20px;\n}\n.actionBtn {\n    border: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-radius: 5px;\n}\n\n.actionBtnContent {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "PropertyEditLandPage_pageWrap__yekLX",
	"actionBtnsContainer": "PropertyEditLandPage_actionBtnsContainer__1fHBs",
	"pageTitle": "PropertyEditLandPage_pageTitle__RIAOz",
	"actionBtn": "PropertyEditLandPage_actionBtn__Fbf5d",
	"actionBtnContent": "PropertyEditLandPage_actionBtnContent__ZSvuI"
};
export default ___CSS_LOADER_EXPORT___;
