import requests from './requests';

export function onInputChangeAutocompleteCities(ref, input) {
    if (!input) {
        ref.setOptions([]);
    }
    requests
        .getPlaces(input)
        .then(({ data: places }) => {
            ref.setOptions(
                places.map(({ description, structured_formatting: { main_text } }) => ({
                    title: description,
                    value: main_text
                }))
            );
        })
        .catch(console.log);
}
