import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { SiMicrosoftexcel } from 'react-icons/si';

import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import leaseDescription from '../../../assets/json/PropertyLease.js';

import styles from './PropertyLeasesViewPage.module.css';

export const PropertyLeasesViewPage = ({ buildingId, plotId }) => {
    const { t } = useTranslation();
    const { id: leaseId } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!leaseId || (!buildingId && !plotId)) {
            return;
        }

        (buildingId
            ? requests.getBuildingLease(buildingId, leaseId)
            : requests.getPlotLease(plotId, leaseId)
        ).then(({ data }) => setData(data));
    }, [buildingId, plotId, leaseId]);

    if (!data) {
        return <LoaderWrapper />;
    }

    return (
        <div className={styles.pageWrap}>
            <div className={styles.title}>
                <h1 className={styles.pageTitle}>{t('leaseId = {{id}}', { id: leaseId })}</h1>
                <span className={`btn btn-primary-0 ${styles.buttonContent}`}>
                    <SiMicrosoftexcel size={'20px'} />
                    <span className={styles.buttonTitle}>{t('download-E2')}</span>
                </span>
            </div>

            <div className={styles.formContainer}>
                <Form
                    description={leaseDescription()}
                    initialValue={{
                        ...data,
                        contract: data.contract && {
                            name: data.contract.file.name,
                            path: data.contract.file.path
                        }
                    }}
                    readOnly
                />
            </div>
            <div className={styles.footerButtons}>
                <Link className='btn btn-primary-0' to='../leases'>
                    <div className={styles.btnContent}>
                        <IoIosArrowRoundBack />
                        {t('back')}
                    </div>
                </Link>
            </div>
        </div>
    );
};
