import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import ManagementPreviewCard from '../../components/ManagentPreviewCard/ManagementPreviewCard';
import { Header } from '../../components/header/Header';

import privateProperties from '../../assets/images/management-ad.jpg';
import backgroundImg from '../../assets/images/management-header.jpg';
import realEstateProperties from '../../assets/images/management-properties.jpg';
import teamImg from '../../assets/images/teams-header.jpg';

import styles from './ManagementPage.module.css';

export default function ManagementPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const teamManagement = [
        {
            image: teamImg,
            title: t('teams'),
            description: t('teams-desc'),
            salesItems: null,
            rentItems: null,
            exposedItems: null,
            path: '../teams'
        }
    ];

    const propertiesManagement = [
        {
            image: privateProperties,
            title: t('private-properties'),
            description: t('private-properties-desc'),
            salesItems: 2,
            rentItems: 2,
            exposedItems: 1,
            path: '../my-properties'
        },
        {
            image: realEstateProperties,
            title: t('real-estate-properties'),
            description: t('real-estate-properties-desc'),
            salesItems: 2,
            rentItems: 323,
            exposedItems: 33,
            path: '../team-properties'
        }
    ];

    return (
        <div className={styles.mainDiv}>
            <Header backgroundImg={backgroundImg} title={t('management')} />
            <div className={styles.cardsDiv}>
                {teamManagement &&
                    teamManagement.map(team => (
                        <ManagementPreviewCard
                            imagePath={team.image}
                            description={team.description}
                            title={team.title}
                            salesItems={team.salesItems}
                            rentItems={team.rentItems}
                            exposedItems={team.exposedItems}
                            teamNumber={20}
                            onClick={() => navigate(team.path)}
                        ></ManagementPreviewCard>
                    ))}
                {propertiesManagement &&
                    propertiesManagement.map(p => (
                        <ManagementPreviewCard
                            imagePath={p.image}
                            description={p.description}
                            title={p.title}
                            salesItems={p.salesItems}
                            rentItems={p.rentItems}
                            exposedItems={p.exposedItems}
                            onClick={() => navigate(p.path)}
                        ></ManagementPreviewCard>
                    ))}
            </div>
        </div>
    );
}
