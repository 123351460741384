import React from 'react';

import { useTranslation } from 'react-i18next';

import styles from './ManagementPreviewCard.module.css';

export default function ManagementPreviewCard({
    imagePath,
    title,
    description,
    salesItems,
    rentItems,
    exposedItems,
    teamNumber,
    onClick
}) {
    const { t } = useTranslation();

    return (
        <div className={styles.mainDiv} onClick={onClick}>
            <div className={styles.imgDiv}>
                <img src={imagePath} className={styles.img} alt={imagePath} />
            </div>
            <div className={styles.informationDiv}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
                {teamNumber && (
                    <div className={styles.characteristic}>
                        <div className={styles.col1}> {t('TeamNumber')}</div>
                        <div className={styles.col2}>
                            <b>{teamNumber}</b>
                        </div>
                    </div>
                )}
                {salesItems && (
                    <div className={styles.characteristic}>
                        <div className={styles.col1}> {t('sale')}</div>
                        <div className={styles.col2}>
                            <b>{salesItems + ' '}</b> {t('properties')}
                        </div>
                    </div>
                )}
                {rentItems && (
                    <div className={styles.characteristic}>
                        <div className={styles.col1}>{t('rent')}</div>
                        <div className={styles.col2}>
                            <b>{rentItems + ' '}</b> {t('properties')}
                        </div>
                    </div>
                )}
                {exposedItems && (
                    <div className={styles.characteristic}>
                        <div className={styles.col1}>{t('exposed')}</div>
                        <div className={styles.col2}>
                            <b>{exposedItems + ' '}</b> {t('properties')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
