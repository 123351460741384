import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Form } from 'react-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineDelete, AiOutlineFileText, AiOutlineFolderOpen } from 'react-icons/ai';
import { BiImage, BiVideo } from 'react-icons/bi';
import { BsCurrencyDollar, BsHouseDoor, BsInfoCircle, BsLamp } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { FaRegLightbulb } from 'react-icons/fa';
import { MdOutlineImagesearchRoller } from 'react-icons/md';
import { RiKey2Line } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';

import useOwnersEdit from '../../hooks/useOwnersEdit';

import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import OwnersList from '../../components/ownersList/OwnersList';

import requests, { URI } from '../../utils/requests';

import { SidebarPage } from '../generalPages/SidebarPage';
import PropertyDigitalFolderPage from './PropertyDigitalFolderPage';
import { PropertyViewVideosPage } from './PropertyViewVideosPage';
import { PropertyEditSuggestionsPage } from './SuggestionPages/PropertyEditSuggestionsPage';
import { PropertyEstimatesHistoryPage } from './estimatesPages/PropertyEditEstimatesPage';
import { PropertyEstimateViewPage } from './estimatesPages/PropertyEstimateViewPage';
import { PropertyExpensesMainPage } from './expensesPages/PropertyEditExpensesPage';
import { PropertyExpensesViewPage } from './expensesPages/PropertyExpensesViewPage';
import { PropertyLeasesHistoryPage } from './leasesPages/PropertyEditLeasesPage';
import { PropertyLeasesViewPage } from './leasesPages/PropertyLeasesViewPage';
import {
    PropertyRenovationMainPage,
    PropertyViewRenovationPage
} from './renovationPages/PropertyRenovatioPage';
import { PropertyViewSmartFeaturePage } from './smartFeaturePages/PropertySmartFeaturePage';

import imageProperty from '../../assets/images/AlphaLogo.png';
import imagesDescription from '../../assets/json/PropertyImages';
import infoDescription from '../../assets/json/PropertyInfoView';
import statsDescription from '../../assets/json/PropertyStats';

import styles from './PropertyViewPage.module.css';
import './propertyFormView.css';
import { Modal } from '../../components/modal/Modal';
import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { FormLabel } from '../../components/formLabel/FormLabel';

const PropertyPartViewPage = () => {
    const { t } = useTranslation();
    const { propertyId, propertyPartId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [owners, setOwners] = useOwnersEdit();
    const [buildingPart, setBuildingPart] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [imageExists, setImageExists] = useState(true);
    const [isRemoveModalActive, setIsRemoveModalActive] = useState(false);

    useEffect(() => {
        Promise.all([requests.getPropertyAssetsMain(propertyId)]).then(([{ data: _mainImage }]) => {
            setMainImage(_mainImage);
        });
    }, [propertyId]);

    useEffect(() => {
        if (!propertyId) {
            return;
        }

        requests.getProperty(propertyId).then(({ data: property }) =>
            requests.getPropertyOwners(property.id).then(({ data }) => {
                if (!data) {
                    setOwners(null);
                }

                setOwners(data.map(elem => ({ ...elem.owner, percentage: elem.percentage })));
            })
        );
    }, [propertyId, setOwners]);

    useEffect(() => {
        if (!propertyId || !propertyPartId) {
            return;
        }

        requests
            .getProperty(propertyId)
            .then(({ data: property }) =>
                requests
                    .getBuildingPart(property.building.id, propertyPartId)
                    .then(({ data }) => setBuildingPart(data))
            );
    }, [propertyId, propertyPartId]);

    const getEditPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'property',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'renovations',
            'smartfeatures',
            'digital-folder'
        ];

        const urlParts = location.pathname.split('/').reverse();
        const urlSuffix = urlParts[0];
        if (validSuffixes.includes(urlSuffix)) {
            return `../properties/edit/${propertyId}/part/${propertyPartId}/${urlSuffix}`;
        }
        return `../properties/edit/${propertyId}/part/${propertyPartId}/${urlParts[1]}/${urlSuffix}`;
    }, [location, propertyId, propertyPartId]);

    if (!buildingPart) {
        return <LoaderWrapper />;
    }

    const links = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <h1 className={styles.pageTitle}>{t('general')}</h1>
                            <FormLabel readOnly> {t('Ownership')} </FormLabel>
                            <Form
                                description={infoDescription()}
                                readOnly
                                initialValue={buildingPart}
                            />
                            <OwnersList owners={owners} readOnly />
                            <Form
                                description={statsDescription()}
                                readOnly
                                initialValue={buildingPart}
                            />
                        </>
                    ),
                    Icon: BsInfoCircle
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: (
                        <>
                            <h1 className={styles.pageTitle}>{t('photos')}</h1>
                            <Form description={imagesDescription()} readOnly />
                        </>
                    ),
                    Icon: BiImage
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyViewVideosPage />,
                    Icon: BiVideo
                },
                {
                    path: 'smartfeatures',
                    title: t('Smart Features'),
                    element: <PropertyViewSmartFeaturePage buildingId={propertyPartId} />,
                    Icon: BsLamp
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: <PropertyDigitalFolderPage property={buildingPart} />,
                    Icon: AiOutlineFolderOpen,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    }
                }
            ]
        },
        {
            title: t('Actions'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage buildingId={propertyPartId} />,
                    Icon: BsCurrencyDollar
                },
                {
                    path: 'expenses/:id',
                    element: <PropertyExpensesViewPage buildingId={propertyPartId} />,
                    Icon: BsCurrencyDollar
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    element: <PropertyLeasesHistoryPage buildingId={propertyPartId} />,
                    hasNestedRoutes: true,
                    Icon: RiKey2Line
                },
                {
                    path: 'leases/:id',
                    element: <PropertyLeasesViewPage buildingId={propertyPartId} />
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: <PropertyEstimatesHistoryPage buildingId={propertyPartId} />,
                    hasNestedRoutes: true,
                    Icon: AiOutlineFileText
                },
                {
                    path: 'estimates/:id',
                    element: <PropertyEstimateViewPage buildingId={propertyPartId} />
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: <PropertyEditSuggestionsPage buildingId={propertyPartId} />,
                    Icon: FaRegLightbulb
                },
                {
                    path: 'renovations',
                    title: t('renovations'),
                    element: <PropertyRenovationMainPage buildingId={propertyPartId} />,
                    Icon: MdOutlineImagesearchRoller
                },
                {
                    path: 'renovations/:id',
                    element: (
                        <PropertyViewRenovationPage
                            buildingId={propertyPartId}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                }
            ]
        }
    ];
    const removeModal = () => {
        if (!isRemoveModalActive) return null;

        return (
            <Modal
                onClose={() => setIsRemoveModalActive(false)}
                header={t('delete-property')}
                content={<div>{t('Are you sure you want to delete the property')}</div>}
                footer={
                    <>
                        <ButtonWithLoading
                            className='btn'
                            onClick={() => {
                                setIsRemoveModalActive(false);
                            }}
                        >
                            {t('cancel')}
                        </ButtonWithLoading>
                        <ButtonWithLoading className='btn btn-error'>
                            {t('remove')}
                        </ButtonWithLoading>
                    </>
                }
            />
        );
    };
    return (
        <SidebarPage options={links}>
            {imageExists && mainImage && (
                <img
                    src={`${URI}/${buildingPart?.mainImage?.file?.path}`}
                    alt={buildingPart.name}
                    className={styles.imageProperty}
                    onError={() => setImageExists(false)}
                />
            )}
            {(!imageExists || mainImage === '') && (
                <img
                    src={imageProperty}
                    style={{ objectFit: 'scale-down' }}
                    className={styles.imageProperty}
                    alt={
                        buildingPart.name ||
                        (buildingPart.building && buildingPart.building.name) ||
                        (buildingPart.plot && buildingPart.plot.name)
                    }
                />
            )}
            <div className={styles.childrenSideBar}>
                <div className={styles.nameBuilding}>
                    {buildingPart.name}
                    <div className={styles.actionsContainer}>
                        <Link to={`../properties/view/${propertyId}/parts`}>
                            <TiArrowBackOutline size={20} className={styles.backBtn} />
                        </Link>
                        <Link
                            className={`${styles.actionBars} ${styles.editBtn}`}
                            to={getEditPage()}
                        >
                            <div className={styles.actionBtnContent}>
                                <FaEdit size={20} />
                            </div>
                        </Link>
                        <AiOutlineDelete
                            className={styles.deleteBtn}
                            color={'red'}
                            size={23}
                            onClick={() => {
                                setIsRemoveModalActive(true);
                            }}
                        />
                    </div>
                </div>
            </div>
            {removeModal()}
        </SidebarPage>
    );
};

export default PropertyPartViewPage;
