// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileInfo_container__XTuX- {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 0.6rem;\n}\n\n.ProfileInfo_icon__8sE4h {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    font-size: 1.5rem;\n    border-radius: 50%;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n\n    color: white;\n    background-color: black;\n}\n\n.ProfileInfo_iconContainer__CU1\\+q {\n    width: 100px;\n    height: 100px;\n    margin-bottom: 10px;\n}\n\n.ProfileInfo_infoRow__mmG8p {\n    margin-bottom: 5px;\n    white-space: nowrap;\n    font-size: var(--font-3);\n}\n\n.ProfileInfo_subName__K-zRB {\n    font-size: 0.875rem;\n    font-size: var(--font-6);\n}\n\n@media only sreen and (max-width: 1000px) {\n}\n", "",{"version":3,"sources":["webpack://./src/components/profileInfo/ProfileInfo.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;;IAEjB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;AACA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-top: 0.6rem;\n}\n\n.icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    font-size: 1.5rem;\n    border-radius: 50%;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n\n    color: white;\n    background-color: black;\n}\n\n.iconContainer {\n    width: 100px;\n    height: 100px;\n    margin-bottom: 10px;\n}\n\n.infoRow {\n    margin-bottom: 5px;\n    white-space: nowrap;\n    font-size: var(--font-3);\n}\n\n.subName {\n    font-size: 0.875rem;\n    font-size: var(--font-6);\n}\n\n@media only sreen and (max-width: 1000px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProfileInfo_container__XTuX-",
	"icon": "ProfileInfo_icon__8sE4h",
	"iconContainer": "ProfileInfo_iconContainer__CU1+q",
	"infoRow": "ProfileInfo_infoRow__mmG8p",
	"subName": "ProfileInfo_subName__K-zRB"
};
export default ___CSS_LOADER_EXPORT___;
