import QuickViewCard from '../QuickViewCard/QuickViewCard';
import React from 'react';

import { useTranslation } from 'react-i18next';

import estimates from '../../assets/images/estimates.png';
import expenses from '../../assets/images/expenses.jpg';
import leases from '../../assets/images/leases.png';
import renovations from '../../assets/images/renovations.png';

import styles from './ManageStatisticsPage.module.css';

export const ManageStatisticsPage = ({ advertise = false, path = null }) => {
    const { t } = useTranslation();

    const services = [
        {
            image: expenses,
            title: t('expenses'),
            path: `${path ? `${path}/` : ''}expenses`
            // description: t(
            //     'With the help of our platform, you can record the monthly expenses of your properties and see their statistics'
            // )
        },
        {
            image: estimates,
            title: t('estimates'),
            path: `${path ? `${path}/` : ''}estimates`
            // description: t(
            //     'With the help of our platform, you can easily create and manage estimates for your properties, providing accurate and detailed cost projections to help you make informed decisions'
            // )
        },
        {
            image: leases,
            title: t('leases'),
            path: `${path ? `${path}/` : ''}leases`
            // description: t(
            //     'Our platform allows you to easily manage leases and concessions for your properties, providing a comprehensive view of all agreements and their associated details'
            // )
        },
        {
            image: renovations,
            title: t('renovations'),
            path: `${path ? `${path}/` : ''}renovations`
            // description: t(
            //     'With our platform, you can easily manage and track renovation and repair projects for your properties, ensuring they are completed efficiently and on budget'
            // )
        }
    ];

    return (
        <div className={styles.pageWrap}>
            <div className={styles.servicesList}>
                {services.map((s, i) => (
                    <div key={`Card_${i}`}>
                        <QuickViewCard {...s} advertise={advertise} />
                        <br></br>
                    </div>
                ))}
            </div>
        </div>
    );
};
