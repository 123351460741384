/**
 * i18N dynamic key types:
 *  t('Attica')
 *  t('Central Greece')
 *  t('Central Macedonia')
 *  t('Crete')
 *  t('Eastern Macedonia and Thrace')
 *  t('Epirus')
 *  t('Ionian Islands')
 *  t('North Aegean')
 *  t('Peloponnese')
 *  t('South Aegean')
 *  t('Thessaly')
 *  t('Western Greece')
 *  t('Western Macedonia')
 *  t('North Athens')
 *  t('West Athens')
 *  t('Central Athens')
 *  t('South Athens')
 *  t('East Attica')
 *  t('Piraeus')
 *  t('Islands')
 *  t('West Attica')
 *  t('Boeotia')
 *  t('Euboea')
 *  t('Evrytania')
 *  t('Phocis')
 *  t('Phthiotis')
 *  t('Imathia')
 *  t('Thessaloniki')
 *  t('Kilkis')
 *  t('Pella')
 *  t('Pieria')
 *  t('Serres')
 *  t('Chalkidiki')
 *  t('Chania')
 *  t('Heraklion')
 *  t('Lasithi')
 *  t('Rethymno')
 *  t('Drama')
 *  t('Evros')
 *  t('Thasos')
 *  t('Kavala')
 *  t('Xanthi')
 *  t('Rhodope')
 *  t('Arta')
 *  t('Ioannina')
 *  t('Preveza')
 *  t('Thesprotia')
 *  t('Corfu')
 *  t('Ithaca')
 *  t('Cephalonia')
 *  t('Lefkada')
 *  t('Zakynthos')
 *  t('Chios')
 *  t('Ikaria')
 *  t('Lemnos')
 *  t('Lesbos')
 *  t('Samos')
 *  t('Arcadia')
 *  t('Argolis')
 *  t('Corinthia')
 *  t('Laconia')
 *  t('Messenia')
 *  t('Andros')
 *  t('Kalymnos')
 *  t('Karpathos')
 *  t('Kea-Kythnos')
 *  t('Kos')
 *  t('Milos')
 *  t('Mykonos')
 *  t('Naxos')
 *  t('Paros')
 *  t('Rhodes')
 *  t('Syros')
 *  t('Santorini')
 *  t('Tinos')
 *  t('Karditsa')
 *  t('Larissa')
 *  t('Magnesia')
 *  t('Sporades')
 *  t('Trikala')
 *  t('Achaea')
 *  t('Aetolia-Acarnania')
 *  t('Elis')
 *  t('Florina')
 *  t('Grevena')
 *  t('Kastoria')
 *  t('Kozani')
 */

const data = {
    Attica: [
        'North Athens',
        'West Athens',
        'Central Athens',
        'South Athens',
        'East Attica',
        'Piraeus',
        'Islands',
        'West Attica'
    ],
    'Central Greece': ['Boeotia', 'Euboea', 'Evrytania', 'Phocis', 'Phthiotis'],
    'Central Macedonia': [
        'Imathia',
        'Thessaloniki',
        'Kilkis',
        'Pella',
        'Pieria',
        'Serres',
        'Chalkidiki'
    ],
    Crete: ['Chania', 'Heraklion', 'Lasithi', 'Rethymno'],
    'Eastern Macedonia and Thrace': ['Drama', 'Evros', 'Thasos', 'Kavala', 'Xanthi', 'Rhodope'],
    Epirus: ['Arta', 'Ioannina', 'Preveza', 'Thesprotia'],
    'Ionian Islands': ['Corfu', 'Ithaca', 'Cephalonia', 'Lefkada', 'Zakynthos'],
    'North Aegean': ['Chios', 'Ikaria', 'Lemnos', 'Lesbos', 'Samos'],
    Peloponnese: ['Arcadia', 'Argolis', 'Corinthia', 'Laconia', 'Messenia'],
    'South Aegean': [
        'Andros',
        'Kalymnos',
        'Karpathos',
        'Kea-Kythnos',
        'Kos',
        'Milos',
        'Mykonos',
        'Naxos',
        'Paros',
        'Rhodes',
        'Syros',
        'Santorini',
        'Tinos'
    ],
    Thessaly: ['Karditsa', 'Larissa', 'Magnesia', 'Sporades', 'Trikala'],
    'Western Greece': ['Achaea', 'Aetolia-Acarnania', 'Elis'],
    'Western Macedonia': ['Florina', 'Grevena', 'Kastoria', 'Kozani']
};

export default data;
