import React, { useState } from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ManagePropertiesCardList from '../../components/ManagePropertiesCardList/ManagePropertiesCardList';
import { Header } from '../../components/header/Header';
import { HighlightMap } from '../../components/highlightMap/HighlightMap';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SearchPaginatedPropertiesWithMap } from '../../components/searchPaginatedPropertiesWithMap/SearchPaginatedPropertiesWithMap';

import requests from '../../utils/requests';
import Utils from '../../utils/utils';

import styles from './AdvertisePage.module.css';

import propertyCollage from '../../assets/images/propertyCollage.jpg';

export const AdvertisePage = () => {
    const { t } = useTranslation();
    const [properties, setProperties] = useState(null);
    const [searchIsActive, setSearchIsActive] = useState(false);

    useEffect(() => {
        requests.getAllPublicProperties().then(({ data }) => setProperties(data));
    }, []);

    if (!properties) {
        return <LoaderWrapper />;
    }

    const Background = props => {
        return (
            <div className={Utils.classes(styles.welcome, props.className)}>
                <div className={`${styles.welcomeBg} welcomeBG`}>
                    {/* <HighlightMap properties={properties} height={'100%'} /> */}
                    <img src={propertyCollage} className={styles.propertyImg} />
                </div>
                <div className={styles.overlay} />
                <div className={styles.content}>{props.children}</div>
            </div>
        );
    };

    return (
        <div className={styles.pageWrap}>
            <SearchPaginatedPropertiesWithMap
                Background={Background}
                extraFilters={{ isPublic: true }}
                path='../property'
                setSearchIsActive={setSearchIsActive}
                guestView
            />
            {!searchIsActive && (
                <div className={styles.servicesDiv}>
                    <div className={styles.headerTitle} style={{ width: '100%' }}>
                        <Header title={t('find-services-properties')} advertise></Header>
                    </div>
                    <ManagePropertiesCardList advertise path={'#'} />
                </div>
            )}
        </div>
    );
};
