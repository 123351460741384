import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import { ErrorMessage } from '../../../components/ErrorMessage/ErrorMessage';
import { SuccessMessage } from '../../../components/SuccessMessage/SuccessMessage';
import ButtonWithLoading from '../../../components/buttonWithLoading/ButtonWithLoading';
import { LoaderWrapper } from '../../../components/loaderWrapper/LoaderWrapper';

import requests from '../../../utils/requests';

import styles from './VerificationPage.module.css';

export const VerificationPage = () => {
    const [searchParams] = useSearchParams();

    const token = searchParams.get('token');
    const email = searchParams.get('email');

    const addToast = useToasts();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [success, setSuccess] = useState();
    const [errMessage, setErrMessage] = useState();

    const verify = useCallback(async () => {
        try {
            await requests.verify(email, token);

            addToast({
                type: 'success',
                message: t('Your email was successfully verified'),
                duration: 5000
            });

            setSuccess(true);
        } catch (err) {
            addToast({
                type: 'failure',
                message: err.response?.data?.message || err.message,
                duration: 5000
            });

            setSuccess(false);
            setErrMessage(err.response?.data?.message || err.message);
        }
    }, [addToast, email, t, token]);

    useEffect(() => {
        verify();
    }, [verify]);

    const sendVerificationEmail = async () => {
        try {
            await requests.sendVerificationEmail(email);
        } catch (err) {
            addToast({
                type: 'failure',
                message: err.response?.data?.message || err.message,
                duration: 5000
            });

            setErrMessage(err.response?.data?.message || err.message);
        }
    };

    return (
        <div className={styles.container}>
            <LoaderWrapper>
                {success && (
                    <SuccessMessage>
                        <div className={styles.messageContainer}>
                            {t('You successfully verified your email address!')}
                            <ButtonWithLoading
                                className={'btn btn-primary-0'}
                                onClick={() => navigate('/login')}
                            >
                                {t('Login')}
                            </ButtonWithLoading>
                        </div>
                    </SuccessMessage>
                )}
                {success === false && (
                    <ErrorMessage>
                        <div className={styles.messageContainer}>
                            {errMessage}
                            <ButtonWithLoading
                                className={'btn btn-primary-0'}
                                onClick={sendVerificationEmail}
                            >
                                {t('Send verification email')}
                            </ButtonWithLoading>
                        </div>
                    </ErrorMessage>
                )}
            </LoaderWrapper>
        </div>
    );
};
