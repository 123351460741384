import { createContext, useContext } from 'react';

export const ScrollContext = createContext();

export const useScroll = () => useContext(ScrollContext);

export const withScroll = Component => {
    let scrollContainer;

    const setScrollContainer = container => {
        scrollContainer = container;
    };

    const getScrollContainer = () => {
        return scrollContainer;
    };

    const api = { setScrollContainer, getScrollContainer };

    return props => (
        <ScrollContext.Provider value={api}>
            <Component {...props}></Component>
        </ScrollContext.Provider>
    );
};
