// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecentActivity_pageWrap__OGOWB {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n.RecentActivity_header__YHcPN {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.RecentActivity_redirectButton__PiBng {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/teamPages/RecentActivity.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":[".pageWrap {\n    /* padding: 50px; */\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n.header {\n    display: flex;\n    justify-content: flex-start;\n}\n\n.redirectButton {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageWrap": "RecentActivity_pageWrap__OGOWB",
	"header": "RecentActivity_header__YHcPN",
	"redirectButton": "RecentActivity_redirectButton__PiBng"
};
export default ___CSS_LOADER_EXPORT___;
