class MarkerOverlay {
    constructor(position, content, map, marker) {
        this.position = position;
        this.content = content;
        this.container = null;
        this.visible = true;
        this.map = map;

        this.marker = marker;
    }
}
export default MarkerOverlay;
