import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useToasts } from '../../context/withToastMessages/withToastMessages';

import ButtonWithLoading from '../../components/buttonWithLoading/ButtonWithLoading';
import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { Modal } from '../../components/modal/Modal';
import { EditUserProfile } from '../../components/profile/EditUserProfile';

import requests from '../../utils/requests';

import styles from './UserOptionsPage.module.css';
import Toggle from 'react-toggle';

export const UserOptionsPage = ({ user, permissions, updateUserData }) => {
    const { t } = useTranslation();
    const [isDisableUserModalActive, setIsDisabledUserModalActive] = useState(false);

    const addToast = useToasts();

    async function changeActiveness(user) {
        const res = (await user.isDisabled)
            ? requests.enableUser(user.email)
            : requests.disableUser(user.email);

        if (!res) return false;

        user.isDisabled = !user.isDisabled;
        setIsDisabledUserModalActive(false);

        updateUserData({ isDisabled: user.isDisabled });
        addToast({
            type: 'success',
            message: t('User {{id}} updated successfully', { id: user.email }),
            duration: 3000
        });
    }

    function renderDisableModal() {
        if (!isDisableUserModalActive) return null;

        const user = isDisableUserModalActive;
        return (
            <Modal
                onClose={() => setIsDisabledUserModalActive(false)}
                header={
                    isDisableUserModalActive.isDisabled ? t('enable-account') : t('disable-account')
                }
                content={
                    <div>
                        {t('are you sure you want to change status of')}
                        {`${user.name}(${user.email})`}
                    </div>
                }
                footer={
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <ButtonWithLoading
                            className={`btn`}
                            onClick={() => {
                                setIsDisabledUserModalActive(false);
                            }}
                        >
                            {t('reject')}
                        </ButtonWithLoading>
                        <ButtonWithLoading
                            className={`btn btn-primary-0`}
                            onClick={async () => changeActiveness(user)}
                        >
                            {t('confirm')}
                        </ButtonWithLoading>
                    </div>
                }
            />
        );
    }

    return (
        <div className={styles.pageWrap}>
            <Header title={t('Information')} />

            <div className={styles.section}>
                <div className={styles.profileRow}>
                    <LoaderWrapper>
                        {user && <EditUserProfile user={user} updateUserData={updateUserData} />}
                    </LoaderWrapper>
                </div>
            </div>
            {permissions.canDisable && (
                <div className={styles.section2}>
                    <div className={styles.sectionTitle}>{t('Status')}</div>

                    <Toggle
                        onChange={() => {
                            setIsDisabledUserModalActive(user);
                        }}
                        className={styles.customToggle}
                        checked={!user.isDisabled}
                        icons={{
                            unchecked: null
                        }}
                    />
                    <span>{!user.isDisabled ? t('Active') : t('Inactive')}</span>
                </div>
            )}
            {renderDisableModal()}
        </div>
    );
};
