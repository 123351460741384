import React, { useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
    AiFillFolderOpen,
    AiOutlineFileText,
    AiOutlineFolderOpen,
    AiOutlineFormatPainter
} from 'react-icons/ai';
import { BiBuildings, BiEuro, BiImage, BiVideo } from 'react-icons/bi';
import {
    BsCurrencyDollar,
    BsFileEarmarkText,
    BsHouseDoor,
    BsInfo,
    BsInfoCircle,
    BsLamp
} from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { IoBulbOutline, IoOptions } from 'react-icons/io5';
import { MdOutlineImagesearchRoller, MdOutlineInsertPhoto } from 'react-icons/md';
import { MdOutlineFeaturedPlayList } from 'react-icons/md';
import {
    PiPencilSimpleSlashLight,
    PiPencilSimpleSlashThin,
    PiPencilSlashDuotone
} from 'react-icons/pi';
import { RiMap2Line } from 'react-icons/ri';
import { RiKey2Line, RiLandscapeLine } from 'react-icons/ri';
import { TfiMapAlt } from 'react-icons/tfi';
import { TiArrowBackOutline } from 'react-icons/ti';

import { useToasts } from '../../../context/withToastMessages/withToastMessages';

import requests from '../../../utils/requests';

import { SidebarPage } from '../../generalPages/SidebarPage';
import BuildingOptionsPage from '../BuildingOptionsPage';
import MapFeaturesEdit from '../MapFeaturesEdit';
import PropertyDigitalFolderPage from '../PropertyDigitalFolderPage';
import { PropertyEditImagesPage } from '../PropertyEditImagesPage';
import { PropertyEditVideosPage } from '../PropertyEditVideosPage';
import PropertyLocationPage from '../PropertyLocationPage';
import PropertyPartsPage from '../PropertyPartsPage';
import { PropertyEditSuggestionsPage } from '../SuggestionPages/PropertyEditSuggestionsPage';
import {
    PropertyEditEstimatesPage,
    PropertyEstimatesHistoryPage
} from '../estimatesPages/PropertyEditEstimatesPage';
import {
    PropertyEditExpensesPage,
    PropertyExpensesMainPage
} from '../expensesPages/PropertyEditExpensesPage';
import {
    PropertyEditLeasesPage,
    PropertyLeasesHistoryPage
} from '../leasesPages/PropertyEditLeasesPage';
import {
    PropertyEditRenovationPage,
    PropertyRenovationMainPage
} from '../renovationPages/PropertyRenovatioPage';
import { PropertyEditSmartFeaturePage } from '../smartFeaturePages/PropertySmartFeaturePage';
import PropertyEditInfoPage from './PropertyEditInfoPage';
import PropertyEditLandPage from './PropertyEditLandPage';
import PropertyEditPropertyPage from './PropertyEditPropertyPage';

import styles from './BuildingEditPage.module.css';

const BuildingEditPage = ({ property, setProperty }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const addToast = useToasts();

    const getBackPage = useCallback(() => {
        const validSuffixes = [
            'info',
            'images',
            'videos',
            'property',
            'land',
            'location',
            'parts',
            'expenses',
            'leases',
            'estimates',
            'suggestions',
            'renovations',
            'smartfeatures',
            'digital-folder'
        ];

        const urlParts = location.pathname.split('/').reverse();
        if (validSuffixes.includes(urlParts[0])) {
            return `../properties/view/${property.id}/${urlParts[0]}`;
        }
        return `../properties/view/${property.id}/${urlParts[1]}/${urlParts[0]}`;
    }, [location, property]);

    const updatePropertyToDB = async (propertyId, data) => {
        const { data: _data } = await requests.updateProperty(propertyId, data);
        setProperty(_data);
        addToast({
            type: 'success',
            message: t('success-property-update'),
            duration: 3000
        });
    };

    const options = [
        {
            title: t('Information'),
            options: [
                {
                    path: 'info',
                    title: t('general'),
                    element: (
                        <>
                            <PropertyEditInfoPage
                                property={property}
                                updatePropertyToDB={updatePropertyToDB}
                                getOwners={async propertyId => {
                                    const { data } = await requests.getPropertyOwners(propertyId);
                                    if (!data) {
                                        return null;
                                    }

                                    return data.map(elem => ({
                                        ...elem.owner,
                                        percentage: elem.percentage
                                    }));
                                }}
                                updateOwners={requests.updatePropertyOwners}
                            />
                            <PropertyEditPropertyPage
                                property={property}
                                updatePropertyToDB={updatePropertyToDB}
                            />
                        </>
                    ),
                    Icon: BsInfo
                },
                {
                    path: 'location',
                    title: t('location'),
                    element: (
                        <PropertyLocationPage
                            property={property}
                            updatePropertyToDB={updatePropertyToDB}
                        />
                    ),
                    Icon: GoLocation
                },
                {
                    path: 'land',
                    title: t('land'),
                    element: (
                        <PropertyEditLandPage
                            property={property}
                            updatePropertyToDB={updatePropertyToDB}
                        />
                    ),
                    Icon: RiLandscapeLine
                },
                {
                    path: 'images',
                    title: t('photos'),
                    element: <PropertyEditImagesPage />,
                    Icon: MdOutlineInsertPhoto
                },
                {
                    path: 'videos',
                    title: t('Videos'),
                    element: <PropertyEditVideosPage />,
                    Icon: BiVideo
                },
                property.building.pType === 'buildingMulti' && {
                    path: 'parts',
                    title: t('property-parts'),
                    element: <PropertyPartsPage property={property} />,
                    Icon: BiBuildings
                },
                {
                    path: 'smartfeatures',
                    title: t('Smart Features'),
                    element: <PropertyEditSmartFeaturePage buildingId={property.building.id} />,
                    Icon: BsLamp
                },
                {
                    path: 'digital-folder',
                    title: t('digital-folder'),
                    element: <PropertyDigitalFolderPage canEdit property={property} />,
                    Icon: AiOutlineFolderOpen,
                    className: isActive => {
                        if (isActive) return false;

                        return styles.digitalFolderMenuItem;
                    }
                },
                {
                    path: 'options',
                    title: t('options'),
                    element: (
                        <BuildingOptionsPage property={property} building={property.building} />
                    ),
                    Icon: IoOptions
                }
            ]
        },
        {
            title: t('Actions'),
            options: [
                {
                    path: 'expenses',
                    title: t('expenses'),
                    element: <PropertyExpensesMainPage isEdit buildingId={property.building.id} />,
                    Icon: BiEuro
                },
                {
                    path: 'expenses/new',
                    element: (
                        <PropertyEditExpensesPage
                            propertyId={property.id}
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'expenses/:id',
                    element: (
                        <PropertyEditExpensesPage
                            propertyId={property.id}
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases',
                    title: t('leases'),
                    element: <PropertyLeasesHistoryPage isEdit buildingId={property.building.id} />,
                    Icon: RiKey2Line
                },
                {
                    path: 'leases/new',
                    element: (
                        <PropertyEditLeasesPage
                            propertyId={property.id}
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'leases/:id',
                    element: (
                        <PropertyEditLeasesPage
                            propertyId={property.id}
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates',
                    title: t('estimates'),
                    element: (
                        <PropertyEstimatesHistoryPage
                            isEdit={true}
                            buildingId={property.building.id}
                        />
                    ),
                    Icon: BsFileEarmarkText
                },
                {
                    path: 'estimates/new',
                    element: (
                        <PropertyEditEstimatesPage
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'estimates/:id',
                    element: (
                        <PropertyEditEstimatesPage
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'suggestions',
                    title: t('suggestions'),
                    element: (
                        <PropertyEditSuggestionsPage
                            buildingId={property.building.id}
                            propertyId={property.id}
                        />
                    ),
                    Icon: FaRegLightbulb
                },
                {
                    path: 'renovations',
                    title: t('renovations'),
                    element: (
                        <PropertyRenovationMainPage isEdit buildingId={property.building.id} />
                    ),
                    Icon: AiOutlineFormatPainter
                },
                {
                    path: 'renovations/new',
                    element: (
                        <PropertyEditRenovationPage
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                },
                {
                    path: 'renovations/:id',
                    element: (
                        <PropertyEditRenovationPage
                            buildingId={property.building.id}
                            navigateBack={() =>
                                navigate(location.pathname.split('/').slice(0, -1).join('/'))
                            }
                        />
                    )
                }
                // {
                //     path: 'map-edit',
                //     title: t('map-edit'),
                //     element: <MapFeaturesEdit property={property} />,
                //     Icon: TfiMapAlt
                // }
            ]
        }
    ];

    return (
        <div className={styles.pageWrap}>
            <SidebarPage options={options}>
                <div className={styles.propertyInfoContainer}>
                    <div className={styles.firstRow}>
                        <h2 className={styles.propertyInfoSubcategory}>
                            {t(property.building[property.building.pType].pType)}
                        </h2>
                        <Link className={`${styles.returnBtn}`} to={getBackPage()}>
                            <PiPencilSlashDuotone size={30} className={styles.icon} />
                        </Link>
                    </div>
                    <h3 className={styles.propertyInfoCategory}>{t('building')}</h3>
                </div>
            </SidebarPage>
        </div>
    );
};

export default BuildingEditPage;
