// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeamStatisticsPage_mainDiv__yqaP1 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}\n\n.TeamStatisticsPage_headerDiv__Cz9TY {\n    margin-bottom: 30px;\n}\n.TeamStatisticsPage_cardsDiv__1FV2p {\n    width: 100%;\n    align-self: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/teamPages/TeamStatisticsPage.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".mainDiv {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}\n\n.headerDiv {\n    margin-bottom: 30px;\n}\n.cardsDiv {\n    width: 100%;\n    align-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": "TeamStatisticsPage_mainDiv__yqaP1",
	"headerDiv": "TeamStatisticsPage_headerDiv__Cz9TY",
	"cardsDiv": "TeamStatisticsPage_cardsDiv__1FV2p"
};
export default ___CSS_LOADER_EXPORT___;
