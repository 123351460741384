// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskManagementPage_mainContainer__GdIPA {\n    padding: 50px;\n\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n}\n\n.TaskManagementPage_topBar__jVzSs {\n    display: flex;\n    flex-direction: row;\n\n    justify-content: space-between;\n    align-items: center;\n}\n\n.TaskManagementPage_mainContainerTeam__mPy0i {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n    margin-top: 30px;\n\n}", "",{"version":3,"sources":["webpack://./src/pages/taskManagementPages/TaskManagementPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,aAAa;IACb,sBAAsB;IACtB,SAAS;;AAEb;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;IAET,gBAAgB;;AAEpB","sourcesContent":[".mainContainer {\n    padding: 50px;\n\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n}\n\n.topBar {\n    display: flex;\n    flex-direction: row;\n\n    justify-content: space-between;\n    align-items: center;\n}\n\n.mainContainerTeam {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n    margin-top: 30px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": "TaskManagementPage_mainContainer__GdIPA",
	"topBar": "TaskManagementPage_topBar__jVzSs",
	"mainContainerTeam": "TaskManagementPage_mainContainerTeam__mPy0i"
};
export default ___CSS_LOADER_EXPORT___;
