// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TeamProfilePage_teamInfo__7cz8f {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 0.6rem;\n    margin-bottom: 20px;\n}\n\n.TeamProfilePage_teamName__ctviV {\n    font-size: var(--font-3);\n    font-weight: bold;\n}\n\n.TeamProfilePage_teamEmail__mzh0c {\n    font-size: var(--font-4);\n    /* 0.875rem; */\n}\n\n.TeamProfilePage_actionBar__7hhOJ {\n    display: flex;\n    gap: 0.2rem;\n}\n\n.TeamProfilePage_actionBar__7hhOJ button {\n    background-color: inherit;\n    border: none;\n}\n\n.TeamProfilePage_actionBar__7hhOJ button svg {\n    fill: var(--theme-primary-10);\n    color: var(--theme-primary-10);\n    width: 1.25rem;\n    height: 1.25rem;\n}\n\n@media only screen and (max-width: 1000px) {\n    /* .teamInfo {\n        display: none;\n    } */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/generalPages/TeamProfilePage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,8BAA8B;IAC9B,cAAc;IACd,eAAe;AACnB;;AAEA;IACI;;OAEG;AACP","sourcesContent":[".teamInfo {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 0.6rem;\n    margin-bottom: 20px;\n}\n\n.teamName {\n    font-size: var(--font-3);\n    font-weight: bold;\n}\n\n.teamEmail {\n    font-size: var(--font-4);\n    /* 0.875rem; */\n}\n\n.actionBar {\n    display: flex;\n    gap: 0.2rem;\n}\n\n.actionBar button {\n    background-color: inherit;\n    border: none;\n}\n\n.actionBar button svg {\n    fill: var(--theme-primary-10);\n    color: var(--theme-primary-10);\n    width: 1.25rem;\n    height: 1.25rem;\n}\n\n@media only screen and (max-width: 1000px) {\n    /* .teamInfo {\n        display: none;\n    } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamInfo": "TeamProfilePage_teamInfo__7cz8f",
	"teamName": "TeamProfilePage_teamName__ctviV",
	"teamEmail": "TeamProfilePage_teamEmail__mzh0c",
	"actionBar": "TeamProfilePage_actionBar__7hhOJ"
};
export default ___CSS_LOADER_EXPORT___;
