import Select from 'react-select';

import styles from './LanguagePicker.module.css';

const Option = ({ value, label, imgs }) => {
    return (
        <div className={styles.optionContainer}>
            <span className={styles.flag}>{imgs[value]}</span>
            <span className={styles.languageTitle}>{label}</span>
        </div>
    );
};

const customSelectStyles = {
    container: (provided, state) => ({
        ...provided,
        cursor: 'pointer'
    }),
    control: (provided, state) => ({
        ...provided,
        borderWidth: '0',
        boxShadow: '0 !important',
        cursor: 'pointer'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: 'none'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '4px'
    }),
    menu: (provided, state) => ({
        ...provided,
        width: 'fit-content',
        right: '0',
        padding: 0
    }),
    menuList: (provided, state) => ({
        padding: 0
    })
};

export const LanguagePicker = ({ options, onChange, defaultValue, imgs }) => {
    return (
        <Select
            defaultValue={defaultValue || options[0]}
            styles={customSelectStyles}
            options={options}
            formatOptionLabel={({ ...props }) => <Option imgs={imgs} {...props} />}
            isSearchable={false}
            onChange={onChange}
        />
    );
};
