import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { BsCheck } from 'react-icons/bs';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Table } from 'table';

import { Header } from '../../components/header/Header';
import { LoaderWrapper } from '../../components/loaderWrapper/LoaderWrapper';
import { SmallPreviewNameImage } from '../../components/smallPreviewNameImage/SmallPreviewNameImage';

import requests, { URI } from '../../utils/requests';
import Utils from '../../utils/utils';

import backgroundImg from '../../assets/images/teams-header.jpg';

import styles from './MyTeamsPage.module.css';

export const MyTeamPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [myTeams, setMyTeams] = useState(null);

    const fetchAndSetData = useCallback(async () => {
        const { data } = await requests.getMyTeams();
        setMyTeams(data);
    }, []);

    useEffect(() => {
        fetchAndSetData();
    }, [fetchAndSetData]);

    const TABLE_COLUMNS = useMemo(
        () => [
            {
                Header: t('ID'),
                accessor: 'id'
            },
            {
                Header: t('Name'),
                accessor: 'name',
                Cell: ({ value: team }) => {
                    return (
                        <SmallPreviewNameImage
                            imageSrc={team.image && `${URI}/${team.image.path}`}
                            fullname={team.name}
                            onClick={() => navigate(`../teams/${team.id}/info`)}
                            request={() => requests.getTeam(team.id)}
                        />
                    );
                }
                // filter: () => {}
            },

            {
                Header: t('Joined at'),
                accessor: 'JoinedAt'
            },

            {
                Header: t('Manager'),
                accessor: 'isManager',
                Cell: ({ value: type }) => {
                    if (type.isManager) return <BsCheck size='30px' color='var(--theme-primary)' />;
                }
            }
        ],
        [navigate, t]
    );

    const transformData = (data = []) => {
        if (!data || !data.length) return [];

        return data.map(team => {
            return {
                id: team.id,
                name: team,
                JoinedAt: `${Utils.timestampToHumanizedDate(
                    team.teamMaps[0].joinedAt
                )} - ${Utils.timestampToHumanizedTime(team.teamMaps[0].joinedAt)}`,
                numberOfMembers: team.teamMaps.length,
                team: team,
                isManager: team.teamMaps[0]
            };
        });
    };
    const renderTeams = () => {
        const data = transformData(myTeams);
        return (
            <div style={{ padding: '0px 50px' }}>
                <Table pagination={{ pageSize: 10 }} columns={TABLE_COLUMNS} data={data} />
            </div>
        );
    };

    if (!myTeams) return <LoaderWrapper />;

    return (
        <div className={styles.pageWrap}>
            <Header
                title={t('Teams')}
                backgroundImg={backgroundImg}
                children={
                    <div className={styles.backBtn} onClick={() => navigate('../management')}>
                        <MdKeyboardBackspace size={15} />
                        {t('management')}
                    </div>
                }
            ></Header>
            {renderTeams()}
        </div>
    );
};
