// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Popover_popover__55kys {\n    position: relative;\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n.Popover_trigger__\\+ORfa {\n    width: -moz-fit-content;\n    width: fit-content;\n    cursor: pointer;\n}\n\n.Popover_content__UP5fT {\n    position: absolute;\n    z-index: 100;\n}\n\n.Popover_top__xVJbn {\n    transform: translateY(-100%);\n    top: 0;\n}\n\n.Popover_left__NwSYe {\n    left: 100%;\n    transform: translateX(-100%);\n}\n\n.Popover_top__xVJbn.Popover_left__NwSYe {\n    transform: translateY(-100%) translateX(-100%);\n}\n\n.Popover_right__vjGut {\n    left: 100%;\n}\n\n.Popover_bottom__wV5uh {\n    top: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/popover/Popover.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,MAAM;AACV;;AAEA;IACI,UAAU;IACV,4BAA4B;AAChC;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb","sourcesContent":[".popover {\n    position: relative;\n    width: fit-content;\n}\n\n.trigger {\n    width: fit-content;\n    cursor: pointer;\n}\n\n.content {\n    position: absolute;\n    z-index: 100;\n}\n\n.top {\n    transform: translateY(-100%);\n    top: 0;\n}\n\n.left {\n    left: 100%;\n    transform: translateX(-100%);\n}\n\n.top.left {\n    transform: translateY(-100%) translateX(-100%);\n}\n\n.right {\n    left: 100%;\n}\n\n.bottom {\n    top: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "Popover_popover__55kys",
	"trigger": "Popover_trigger__+ORfa",
	"content": "Popover_content__UP5fT",
	"top": "Popover_top__xVJbn",
	"left": "Popover_left__NwSYe",
	"right": "Popover_right__vjGut",
	"bottom": "Popover_bottom__wV5uh"
};
export default ___CSS_LOADER_EXPORT___;
