import React, { useEffect } from 'react';

import PropertyCard from '../PropertyCard/PropertyCard';

import styles from './PropertyList.module.css';

const PropertyList = ({ properties, path, functionOnHover, onMouseLeaveProp, guestView }) => {
    return (
        <div>
            <div className={styles.mainComponent}>
                {properties &&
                    properties.map((property, i) => (
                        <div
                            key={`pCard_${i}`}
                            onMouseEnter={() =>
                                functionOnHover?.({
                                    longtitude: property?.longtitude,
                                    latitude: property?.latitude,
                                    id: property?.id,
                                    color: 'red'
                                })
                            }
                            onMouseLeave={onMouseLeaveProp}
                        >
                            <PropertyCard path={path} property={property} guestView={guestView} />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default PropertyList;
