import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { HiLogout, HiMenu } from 'react-icons/hi';
import { IoSettingsOutline } from 'react-icons/io5';
import { RiCloseFill } from 'react-icons/ri';

import { useUser } from '../../context/withUser';

import { SettingsPage } from '../../pages/settingsPage/SettingsPage';

import requests from '../../utils/requests';
import Utils from '../../utils/utils';

import { LanguagePicker } from '../languagePicker/PropertiesLanguagePicker';
import { Link } from './Link';
import { UserPopover } from './UserPopover';

import logoImg from '../../assets/images/logoSmall.png';

import styles from './Navbar.module.css';
import { DropdownLink } from './DropdownLink';

export const Navbar = ({ underlyingName = '', links = [] }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);

    const mobileNavToggle = () => {
        setToggle(!toggle);
    };

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        setToggle(false);
    }, [location]);

    const links_ = links.map((link, i) => {
        if (link.links) {
            return (
                <DropdownLink
                    key={i}
                    title={link.title}
                    path={link.path}
                    Icon={link.icon}
                    links={link.links}
                />
            );
        } else return <Link key={i} link={link} className={styles.item} />;
    });

    const { user } = useUser();

    //set the settings path... using user's type
    const prefix = user?.type === 'ADMIN' ? 'a/' : '';

    const settingsLink = {
        title: t('settings'),
        path: `${prefix}settings`,
        component: () => <SettingsPage />,
        icon: IoSettingsOutline
    };

    const logout = () => {
        requests.logout().then(res => {
            window.location.href = '/';
        });
    };

    return (
        <nav className={styles.container}>
            <div className={styles.left} style={{ width: user ? '100%' : '' }}>
                <div className={styles.groupLogo}>
                    <img
                        className={styles.logo}
                        src={logoImg}
                        onClick={() => navigate(``)}
                        alt='logo'
                    />
                    <div className={Utils.classes(styles.title, styles.item)}>
                        <div className={styles.titleMain}>Digital Orbis</div>
                        <div className={styles.titleSub}>{underlyingName}</div>
                    </div>
                </div>
                <div>
                    <div onClick={mobileNavToggle} className={styles.mobileToggleButton}>
                        {toggle ? (
                            <RiCloseFill id={styles.icon} size={'25px'} />
                        ) : (
                            <HiMenu id={styles.icon} size={'25px'} />
                        )}
                    </div>
                    <div
                        className={styles.redirectButtonsContainer}
                        style={{
                            transform:
                                width < 1000
                                    ? toggle
                                        ? 'translateY(0)'
                                        : 'translateY(-200px)'
                                    : 'none',
                            height: width < 1000 ? (toggle ? '100vh' : ' 0') : '100%'
                        }}
                    >
                        {links_}
                        {user && (
                            <div className={styles.propoverLinks}>
                                <Link link={settingsLink} />

                                <div className={styles.link} onClick={logout}>
                                    <HiLogout />
                                    {t('logout')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {user && (
                <div className={styles.right} style={{ display: width < 1000 ? 'none' : 'flex' }}>
                    <UserPopover />
                </div>
            )}
            {!user && (
                <div className={styles.right}>
                    <div id={styles.flag}>
                        <LanguagePicker />
                    </div>
                    <div
                        onClick={() => navigate('login')}
                        className='btn btn-primary-0'
                        id={styles.loginButton}
                    >
                        {/* <BiLogIn /> */}
                        {t('login')}
                    </div>
                    <div onClick={() => navigate('register')} id={styles.registerButton}>
                        {/* <CiEdit id='editIcon' /> */}
                        {t('register')}
                    </div>
                </div>
            )}
        </nav>
    );
};
